import { all } from 'redux-saga/effects';
import { watchers as authWatchers } from '../redux/modules/auth';
import { watchers as splashWatchers } from '../redux/modules/splash';
import { watchers as updatesWatchers } from '../redux/modules/updates';
import { watchers as camsWatchers } from '../redux/modules/cams';
import { watchers as modelWatchers } from '../redux/modules/model';
import { watchers as modelsWatchers } from '../redux/modules/models';
import { watchers as blogWatchers } from '../redux/modules/blogs';
import { watchers as blogPostWatchers } from '../redux/modules/blogPost';
import { watchers as commentsWatchers } from '../redux/modules/comments';
import { watchers as galleriesWatchers } from '../redux/modules/galleries';
import { watchers as photographersWatchers } from '../redux/modules/photographers';
import { watchers as topImagesWatchers } from '../redux/modules/topImages';
import { watchers as itemCountsWatchers } from '../redux/modules/itemCounts';
import { watchers as marketingWatchers } from '../redux/modules/marketing';
import { watchers as galleryWatchers } from '../redux/modules/gallery';
import { watchers as favoritesAndRatingsWatchers } from '../redux/modules/favoritesAndRatings';
import { watchers as leaderboardWatchers } from '../redux/modules/leaderboard';
import { watchers as imageGridWatchers } from '../redux/modules/imageGrid';
import { watchers as movieWatchers } from '../redux/modules/movie';
import { watchers as moviesWatchers } from '../redux/modules/movies';
import { watchers as photographerWatchers } from '../redux/modules/photographer';
import { watchers as imageWatchers } from '../redux/modules/image';
import { watchers as userCommentsWatchers } from '../redux/modules/userComments';
import { watchers as activityWatchers } from '../redux/modules/activity';
import { watchers as advancedSearchWatchers } from '../redux/modules/advancedSearch';
import { watchers as newsletterWatchers } from '../redux/modules/newsletter';
import { watchers as tagWatchers } from '../redux/modules/tags';
import { watchers as favoriteInfoWatchers } from '../redux/modules/favoriteInfo';
import { watchers as ratingInfoWatchers } from '../redux/modules/ratingInfo';
import { watchers as galleryPermissionsWatchers } from '../redux/modules/galleryPermissions';
import { watchers as searchResultWatchers } from '../redux/modules/searchResults';
import { watchers as piracyReportWatchers } from '../redux/modules/piracyReport';
import { watchers as PPSInfoWatchers } from '../redux/modules/PPSInfo';
import { watchers as specialFeatureWatchers } from '../redux/modules/specialFeatures';
import { watchers as potdWatchers } from '../redux/modules/potd';
import { watchers as playedMoviesWatchers } from '../redux/modules/playedMovies';
import { watchers as siteNotificationWatchers } from '../redux/modules/siteNotifications';
import { watchers as categoryWatchers } from '../redux/modules/category';
import { watchers as markdownsWatchers } from '../redux/modules/markdowns';
import { watchers as whiteLabelSplashWatchers } from '../redux/modules/whiteLabelSplash';
/* **************************************************************************** */
/* ****************************** WATCHERS ************************************ */
/* **************************************************************************** */

export default function* root() {
  yield all([
    ...authWatchers,
    ...camsWatchers,
    ...splashWatchers,
    ...updatesWatchers,
    ...modelWatchers,
    ...modelsWatchers,
    ...blogWatchers,
    ...blogPostWatchers,
    ...commentsWatchers,
    ...galleriesWatchers,
    ...photographersWatchers,
    ...topImagesWatchers,
    ...itemCountsWatchers,
    ...galleryWatchers,
    ...favoritesAndRatingsWatchers,
    ...leaderboardWatchers,
    ...imageGridWatchers,
    ...movieWatchers,
    ...moviesWatchers,
    ...photographerWatchers,
    ...imageWatchers,
    ...userCommentsWatchers,
    ...activityWatchers,
    ...advancedSearchWatchers,
    ...marketingWatchers,
    ...newsletterWatchers,
    ...tagWatchers,
    ...favoriteInfoWatchers,
    ...ratingInfoWatchers,
    ...galleryPermissionsWatchers,
    ...searchResultWatchers,
    ...piracyReportWatchers,
    ...PPSInfoWatchers,
    ...specialFeatureWatchers,
    ...potdWatchers,
    ...playedMoviesWatchers,
    ...siteNotificationWatchers,
    ...categoryWatchers,
    ...markdownsWatchers,
    ...whiteLabelSplashWatchers,
  ]);
}
