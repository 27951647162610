export default (config, site, cdnUrl) => ({
  title: site.name,
  description: config.description,
  meta: [
    { charset: 'utf-8' },
    { property: 'og:site_name', content: site.name },
    { property: 'og:title', content: site.name },
    { property: 'og:locale', content: 'en_US' },
    { property: 'og:card', content: 'summary' },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'og:image', content: `${cdnUrl}/metaImages/${site.abbreviation}.png` },
    { property: 'og:image:type', content: 'image/png' },
    { property: 'og:image:width', content: 200 },
    { property: 'og:image:height', content: 200 },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' },
    ...(config.isWhiteLabel ? [{ name: 'robots', content: 'noindex' }] : []),
  ],
});
