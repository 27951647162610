import { take, call, fork, select, put } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { SORT_TYPES } from 'src/shared/constants/movies';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { ModelResponse } from 'src/@types/app';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';

const LOAD = 'man-site/model/LOAD';
const LOAD_SAGA = 'man-site/model/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/model/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/model/LOAD_FAIL';
const RESET = 'man-site/model/RESET';

const endpoint = constructUrl(urls.get.model);

type CallParams = {
  after: number;
  name: string;
  sortBy: string;
};

type State = {
  loading: boolean;
  loaded: boolean;
  error?: SuperAgentError;
  item: ModelResponse | null;
  lastCallParams: CallParams;
};

type ActionLoad = {
  type: typeof LOAD;
  params: CallParams;
  lastCallParams: CallParams;
};

type ActionLoadSuccess = State & {
  type: typeof LOAD_SUCCESS;
  result: ModelResponse;
};

type ActionLoadFail = State & {
  type: typeof LOAD_FAIL;
  error: SuperAgentError;
};

type ActionReset = State & {
  type: typeof RESET;
};

type Action = ActionLoad | ActionLoadSuccess | ActionLoadFail | ActionReset;

export const initialState = {
  loading: false,
  loaded: false,
  item: null,
  error: undefined,
  lastCallParams: {},
} as State;

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case LOAD: {
      const newState: State = {
        ...state,
        loading: true,
        loaded: false,
        error: undefined,
        item: null,
        lastCallParams: action.lastCallParams,
      };

      if (state.loaded && state.lastCallParams.name === action.lastCallParams.name) {
        newState.loaded = true;
        newState.item = state.item;
      }
      return newState;
    }
    case LOAD_SUCCESS:
      // this is a workaround for the API returning '0' as a string instead of an empty string
      if (action.result?.biography === '0') {
        action.result.biography = '';
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        item: initialState.item,
      };
    case RESET:
      return {
        ...initialState,
      };
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams, params) {
  return isEqual(lastCallParams, params);
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}

const getState = (state) => state.model;
/* SAGAS */

// Trigger

function* loadGenerator(params) {
  const modelState = yield select(getState);
  const { lastCallParams } = modelState;
  if ((!isLoaded(lastCallParams, params) && !modelState.loading) || (!modelState.loading && !modelState.loaded)) {
    const sortType = SORT_TYPES.find((ele) => ele.id === params.sortBy);
    const finalParams = {
      ...params,
      ...(sortType
        ? {
            order: sortType.order,
            direction: sortType.direction,
          }
        : {}),
    };

    delete finalParams.sortBy;

    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client) => client.get(endpoint, { params: finalParams }),
      {
        lastCallParams: { ...params },
      },
    );

    yield call(loadFunc);
  }

  yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

  const newState = yield select();
  if (newState.auth.user && newState.model.item) {
    const objectUUIDs = [newState.model.item.UUID, ...newState.model.item.latestGalleries.map((item) => item.UUID)];
    yield put(loadRatingInfo({ objectUUIDs }));
    yield put(loadFavoriteInfo({ objectUUIDs }));
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
