import React from 'react';
import styled from 'styled-components/macro';
import { ConstrainedContainer, TABLET_LANDSCAPE, TABLET_PORTRAIT, DESKTOP } from 'src/client/constants/mediaQueries';
import { MediaItem } from './mediaUrls';
import { useSelector } from 'src/client/redux';

const icons = {
  gallery: '/nonnude/gallery.svg',
  movie: '/nonnude/movie.svg',
};

type Props = {
  title: string;
  mediaItems: MediaItem[];
  type: 'gallery' | 'movie';
  setOverlayMediaUrl: (url?: string) => void;
};

export const MediaRow = (props: Props) => {
  const { contentCdnUrl } = useSelector((state) => state.app);
  const { type } = props;

  return (
    <Container>
      <Title>{props.title}</Title>
      {props.mediaItems.map((item) => (
        <ThumbnailWrapper
          key={item.thumbnailUrl}
          onClick={() => props.setOverlayMediaUrl(contentCdnUrl + item.mediaUrl)}
        >
          <Thumbnail src={contentCdnUrl + item.thumbnailUrl} width={525} height={790} />
          <OverlayIcon className={type} src={contentCdnUrl + icons[type]} />
        </ThumbnailWrapper>
      ))}
    </Container>
  );
};

const Container = styled(ConstrainedContainer)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;

  @media ${TABLET_PORTRAIT} {
    grid-template-columns: repeat(4, 1fr);
  }

  & + & {
    // margin collapse means this will just eat the 20 px margin above.
    // its only 30px total between the two rows.
    margin-top: 30px;
  }
`;

const Title = styled.h3`
  color: #ffffff;
  text-align: center;
  grid-column: 1 / -1;
  margin: 0;
  font-size: 18px;

  @media ${TABLET_PORTRAIT} {
    font-size: 28px;
  }

  @media ${TABLET_LANDSCAPE} {
    font-size: 36px;
  }
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    outline: 1px solid #ffffff;
  }
`;

const Thumbnail = styled.img`
  height: auto;
  width: 100%;
  max-width: 100%;
  aspect-ratio: 525 / 790;
`;

const OverlayIcon = styled.img`
  position: absolute;
  width: 80px;
  height: 80px;
  opacity: 0.3;

  @media ${TABLET_PORTRAIT} {
    width: 40px;
    height: 40px;
  }

  @media ${TABLET_LANDSCAPE} {
    width: 60px;
    height: 60px;
  }

  @media ${DESKTOP} {
    width: 80px;
    height: 80px;
  }

  ${ThumbnailWrapper}:hover & {
    opacity: 1;
  }
`;
