import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import NetworkSlider from 'src/client/components/splash/NormalSlider/NetworkSlider';
import FooterBar from 'src/client/containers/FooterBar/FooterBar';
import { Heading } from 'src/client/components/splash/Network/Heading';
import { GalleryUpdates } from 'src/client/components/splash/Network/GalleryUpdates';
import { Button } from 'src/client/components/splash/Network/Button';
import { Outro } from 'src/client/components/splash/Network/Outro';
import { Counts } from 'src/client/components/splash/Network/Counts';
import { load } from 'src/client/redux/modules/splash';
import { load as itemLoad } from 'src/client/redux/modules/itemCounts';
import { destructUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import redirectHomepages from 'src/shared/constants/redirectHomepages';
import { useSelector, useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';
import Helmet from 'src/client/containers/Helmet/Helmet';

const countParams = ['Galleries', 'Models', 'Movies', 'Photographers'];

export const NetworkSplash = () => {
  const dispatch = useDispatch();

  const { movieUpdates, galleryUpdates } = useSelector((state) => state.splash);

  const itemCounts = useSelector((state) => state.itemCounts);

  useEffect(() => {
    const loadParams = {
      types: countParams,
    };

    dispatch(itemLoad(loadParams));
  }, [dispatch]);

  const user = useSelector((state) => state.auth.user);
  const site = useSelector((state) => state.site);
  const {
    cdnUrl,
    config: { splash },
  } = useSelector((state) => state.app);

  const guestsOnly = splash?.guestsOnly;

  const mainPageUrl = useSettingsSelector('homepage');

  useEffect(() => {
    if (!user || !guestsOnly) {
      dispatch(load());
    }
  }, [dispatch, user, guestsOnly]);

  if (user && (guestsOnly || mainPageUrl !== 'splash')) {
    return <Redirect to={redirectHomepages[mainPageUrl]} />;
  }

  return (
    <Background>
      <Helmet
        id="homepage"
        variables={{
          siteDomain: site.domain,
        }}
      >
        <meta property="prerender:ttl" content="86400" />
      </Helmet>
      <SplashWrapper>
        <NetworkSlider />

        {itemCounts && itemCounts?.Galleries && (
          <Counts
            data={[
              { title: 'Top Erotic Sites', count: '10' },
              { title: 'Films', count: itemCounts?.Movies?.total + '+' },
              { title: 'Photo Sets', count: itemCounts?.Galleries?.total + '+' },
              { title: 'Models', count: itemCounts?.Models?.total + '+' },
              { title: 'Artists', count: itemCounts?.Photographers?.total + '+' },
            ]}
          />
        )}
        <Heading title="Latest Films" url="/erotic-films" />
        <GalleryUpdates
          updates={movieUpdates}
          updatesPrefix="wide"
          updatesPrefixHover="wide_clean"
          updatesMax={12}
          coverAttributes={{ wide: true }}
          coverAttributesHover={{ wide: true, clean: true }}
        />
        {itemCounts && itemCounts?.Movies && (
          <Button title={`Show all ${itemCounts?.Movies?.total.toLocaleString()} films`} url="/erotic-films" />
        )}
        <Outro
          title="The World's Largest Collection of Elite Beautiful Girls"
          background={`${cdnUrl}/splash/${destructUUID(site.networkUUID)}/network_premium_bg.jpg`}
          premium={true}
          signup="Get 10 Sites Now"
        />
        <Heading title="Latest Galleries" url="/galleries" />
        <GalleryUpdates
          updates={galleryUpdates}
          updatesPrefix="cover"
          updatesPrefixHover="clean"
          updatesMax={20}
          coverAttributes={{}}
          coverAttributesHover={{ clean: true }}
        />
        {itemCounts && itemCounts?.Galleries && (
          <Button title={`Show all ${itemCounts?.Galleries?.total.toLocaleString()} galleries`} url="/galleries" />
        )}
        <Outro title="What is the MetArt Network?" background="#000" premium={false} signup="Watch All 10 Sites Now" />
      </SplashWrapper>
      <FooterBar />
    </Background>
  );
};

const Background = styled.div`
  background-color: #000;
`;

const SplashWrapper = styled.div<{
  promotionsActive?: boolean;
}>`
  position: relative;
  z-index: 0;
  ${({ promotionsActive }) => promotionsActive && 'z-index: 1'};
  border-bottom: 2px solid #2b2b2b;

  .splash-hero {
    text-align: center;
  }

  .splash-mobile ins > a > img,
  .splash-tablet ins > a > img,
  .splash-hero ins > a > img {
    height: auto;
    width: 100%;
  }
  .splash-hero ins > a > img {
    max-width: 2162px;
  }
`;
