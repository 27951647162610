import { DeviceType, DeviceTypes } from 'src/shared/constants/userMeasurement';
import { UserType, UserTypes } from 'src/shared/constants/userTypes';

export type CamTrackingPosition = 'header' | 'sideBar' | 'slider' | 'sliderTop' | 'mobileMenu' | 'imagesPage';
type siteExclusivity = 'MetartAndMetartNetwork' | 'EverySiteExceptMetartAndMetartNetwork';

export type CamTrackingDefinition = {
  id: number;
  deviceTypes?: DeviceType[];
  userTypes?: UserType[];
  siteExclusivity?: siteExclusivity;
};

export const camTrackingDefinitions: Record<CamTrackingPosition, CamTrackingDefinition[]> = {
  header: [
    {
      id: 86122,
      deviceTypes: [DeviceTypes.Desktop],
      userTypes: [UserTypes.Guest, UserTypes.Free, UserTypes.Expired],
      siteExclusivity: 'MetartAndMetartNetwork',
    },
    {
      id: 201,
      deviceTypes: [DeviceTypes.Desktop],
      userTypes: [UserTypes.Paid],
    },
    {
      id: 301,
      deviceTypes: [DeviceTypes.Desktop],
      userTypes: [UserTypes.Guest, UserTypes.Free, UserTypes.Expired],
      siteExclusivity: 'EverySiteExceptMetartAndMetartNetwork',
    },
  ],
  sideBar: [
    {
      id: 202,
      deviceTypes: [DeviceTypes.Desktop],
    },
  ],
  slider: [
    {
      id: 208,
      deviceTypes: [DeviceTypes.Desktop],
      userTypes: [UserTypes.Paid],
    },
    {
      id: 209,
      deviceTypes: [DeviceTypes.Mobile],
      userTypes: [UserTypes.Paid],
    },
  ],
  sliderTop: [
    {
      id: 105,
      deviceTypes: [DeviceTypes.Mobile],
      userTypes: [UserTypes.Guest, UserTypes.Free, UserTypes.Expired],
    },
    {
      id: 205,
      deviceTypes: [DeviceTypes.Mobile],
      userTypes: [UserTypes.Paid],
    },
  ],
  mobileMenu: [
    {
      id: 106,
      deviceTypes: [DeviceTypes.Mobile],
      userTypes: [UserTypes.Guest, UserTypes.Free, UserTypes.Expired],
    },
    {
      id: 206,
      deviceTypes: [DeviceTypes.Mobile],
      userTypes: [UserTypes.Paid],
    },
  ],
  imagesPage: [
    {
      id: 110,
      userTypes: [UserTypes.Guest, UserTypes.Free, UserTypes.Expired],
    },
    {
      id: 210,
      userTypes: [UserTypes.Paid],
    },
  ],
};
