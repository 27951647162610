import { useQuery } from '@tanstack/react-query';
import { ResponseError } from 'superagent';
import { ApiClient } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { ExtendedFeaturedTag, ExtendedFeaturedTags } from 'src/@types/ExtendedFeaturedTags';
import { useSelector } from '../redux';
import { UserTypes } from 'src/shared/constants/userTypes';
import { sortBy } from 'lodash';

export type ExtendedFeaturedTagWithSeoTitle = ExtendedFeaturedTag & { calculatedSeoTitle: string };
type UseCategoriesReturnType =
  | { isLoading: true; categories?: undefined; topCategories?: undefined }
  | {
      isLoading: false;
      categories: ExtendedFeaturedTagWithSeoTitle[];
      topCategories: ExtendedFeaturedTagWithSeoTitle[];
    };

const categoriesFetcher = () => new ApiClient().get(constructUrl(urls.get.categories)).then((res) => res.body);
export const useCategories = (): UseCategoriesReturnType => {
  const UUID = useSelector((state) => state.site.UUID);
  const userType = useSelector((state) => state.auth.userType);
  const { data, isLoading } = useQuery<ExtendedFeaturedTags, ResponseError>(['categories'], () => categoriesFetcher());

  if (isLoading) {
    return { isLoading };
  }

  const categories = (data?.tags?.find(({ siteUUID }) => siteUUID === UUID)?.featuredTags ?? []).map((tag) => ({
    ...tag,
    calculatedSeoTitle: determineTitle(tag, userType === UserTypes.Guest),
  }));
  const topCategories = sortBy(
    categories.filter(({ isTopCategory }) => isTopCategory),
    ({ calculatedSeoTitle }) => calculatedSeoTitle,
  );

  return { isLoading, categories, topCategories };
};

const determineTitle = (tag: ExtendedFeaturedTag, isGuest: boolean) =>
  isGuest ? tag.displayName || tag.name : tag.seoCategoryTitle;
