import React, { useCallback, useEffect, useState } from 'react';
import { ErrorMessage } from './ErrorMessage';
import { LoaderComponent } from './Loader';

interface Props {
  reloadFunction?: (...args: unknown[]) => unknown;
  // timeout seconds
  waitPeriod?: number;
  loadingStatus?: boolean;
  reduxErrorStatus?: boolean | string | object;
  // Text that accompanies the error message
  contentType?: string;
  // Adds a default height to pages with content items
  defaultHeight?: boolean;
  streamItem?: boolean;
  disableWaitPeriod?: boolean;
  className?: string;
}

const LoaderWrapperComponent = ({
  reloadFunction = () => {},
  waitPeriod = 60000,
  contentType = '',
  defaultHeight = false,
  streamItem = false,
  reduxErrorStatus = false,
  loadingStatus = true,
  disableWaitPeriod = false,
  className = '',
}: Props) => {
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [timerId, setTimerId] = useState(0);

  const checkIfLoadingFailed = useCallback(() => {
    if (loadingStatus) {
      setTimerId(() =>
        window.setTimeout(() => {
          if (!disableWaitPeriod && loadingStatus) {
            setLoadingFailed(true);
          }
        }, waitPeriod),
      );
    }
  }, [loadingStatus, disableWaitPeriod, waitPeriod]);

  const tryReload = () => {
    reloadFunction(); // call the function passed into from redux
    setLoadingFailed(false); // show the loader again
    checkIfLoadingFailed(); // start the timer
  };

  const displayErrorMessage = () => <ErrorMessage error={reduxErrorStatus} reload={tryReload} content={contentType} />;

  useEffect(() => {
    checkIfLoadingFailed();
  }, [checkIfLoadingFailed]);

  // unmount gets its own useEffect to prevent circular loop of execution
  useEffect(() => () => clearTimeout(timerId));

  return (
    <div className={className}>
      {loadingStatus ? (
        <div>
          {loadingFailed ? (
            displayErrorMessage()
          ) : (
            <LoaderComponent defaultHeight={defaultHeight} streamItem={streamItem} />
          )}
        </div>
      ) : (
        <div>{reduxErrorStatus && displayErrorMessage()}</div>
      )}
    </div>
  );
};

export default LoaderWrapperComponent;
