import moment from 'moment';
import { capFirst } from '@tovia/man-app-utils/lib/helpers/converters';
import camelCase from 'lodash/camelCase';
import { SORT_TYPES as MODEL_SORT_TYPES } from 'src/shared/constants/models';
import { SORT_TYPES as GALLERY_SORT_TYPES } from 'src/shared/constants/galleries';
import { SORT_TYPES as MOVIE_SORT_TYPES } from 'src/shared/constants/movies';
import { SORT_TYPES as PHOTOGRAPHER_SORT_TYPES } from 'src/shared/constants/photographers';
import { SORT_TYPES as SEARCH_SORT_TYPES } from 'src/shared/constants/search';
import { modelLinks, parseSlug } from './converters';
import { RootState } from '../redux/modules/reducer';
import { BreadCrumb } from '../containers/BreadCrumbs/BreadCrumbs';
import { match } from 'react-router';

const configFilter = (arr) => (sort) => (Array.isArray(arr) ? arr.includes(sort.id) : true);
export const getModelSortTypes = (config) => MODEL_SORT_TYPES.filter(configFilter(config.modelSort));
export const getGallerySortTypes = (config) => GALLERY_SORT_TYPES.filter(configFilter(config.gallerySort));
export const getMovieSortTypes = (config) => MOVIE_SORT_TYPES.filter(configFilter(config.movieSort));
export const getPhotographerSortTypes = (config) =>
  PHOTOGRAPHER_SORT_TYPES.filter(configFilter(config.photographerSort));

type RouteParams = {
  category?: string;
  country?: string;
  displayName?: string;
  modelName?: string;
  month?: string;
  pageOrDate?: string;
  photographerName?: string;
  sortBy?: string;
  tab?: string;
  type?: string;
  year?: string;
};

type BreadcrumbParams = { state: RootState; match: match<RouteParams> };

export type LocalRoute = {
  breadcrumb?: (params: BreadcrumbParams) => false | null | BreadCrumb | BreadCrumb[];
  oldPath?: string;
  path: string | string[];
  value?: string;
};

export const routes = {
  galleries: {
    path: ['/galleries', '/images', '/photo-archive'],
    breadcrumb: () => ({
      url: '/galleries',
      text: 'Galleries',
      id: 'breadcrumbs.galleries.galleries',
    }),
    routes: {
      gallery: {
        path: '/model/:modelName/gallery/:date/:galleryName/:page?',
        breadcrumb: ({ state }: BreadcrumbParams) => {
          const gallery = state.gallery.item;
          if (gallery) {
            return [
              {
                node: modelLinks(gallery.models),
              },
              {
                text: gallery.name,
              },
            ];
          }
          return false;
        },
      },
      myGalleries: {
        value: 'PPSGalleries',
        path: '/galleries/my/:sortBy?/:page?',
        breadcrumb: () => ({
          text: 'My Galleries',
          id: 'breadcrumbs.models.ppsGalleries',
        }),
      },
      galleries: {
        value: 'Galleries',
        path: '/galleries/:sortBy?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = GALLERY_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);

          if (!sort) {
            return null;
          }

          return {
            text: sort ? sort.title : '',
            id: `breadcrumbs.galleries.${sort ? camelCase(sort.title) : 'latest'}`,
          };
        },
      },
      images: {
        value: 'Images',
        path: '/images',
        breadcrumb: () => ({
          text: 'Top Images',
          id: 'breadcrumbs.topImages',
        }),
      },
      botd: {
        path: '/photo-archive/:page?',
        breadcrumb: ({ state }: BreadcrumbParams) => {
          const text = (state.app.config.galleries.botd || {}).active ? 'Beauty of the Day' : 'Photo of the Day';
          return {
            text,
            id: (state.app.config.galleries.botd || {}).active
              ? 'breadcrumbs.beautyOfTheDay'
              : 'breadcrumbs.photoOfTheDay',
          };
        },
        value: 'Beauty of the Day',
      },
    },
  },
  images: {
    routes: {
      image: { path: '/model/:modelName/:mediaType/:date/:galleryName/image/:imageNumber/:quality?' },
      grid: { path: '/model/:modelName/:mediaType/:date/:galleryName/image/:imageNumber/grid/:galleryPageNumber?' },
    },
    path: '/model',
  },
  modelsAndArtists: {
    path: ['/models', '/photographers'],
    breadcrumb: () => ({
      url: '/models',
      text: 'Models & Artists',
      id: 'breadcrumbs.modelsAndArtists',
    }),
    routes: {
      modelMovies: {
        value: 'movies',
        path: '/model/:modelName/:tab(movies)/:sortBy?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = match.params.sortBy && MOVIE_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);
          const slug = parseSlug(match.params.modelName);

          return {
            text: `${slug} - Movies ${sort ? `(${sort.title})` : ''}`,
            values: [{ key: 'modelName', value: slug }],
          };
        },
      },
      modelPhotos: {
        value: 'photos',
        path: '/model/:modelName/:tab(photos)/:sortBy?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = match.params.sortBy && MOVIE_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);
          const slug = parseSlug(match.params.modelName);

          return {
            text: `${slug} - Photos ${sort ? `(${sort.title})` : ''}`,
            values: [{ key: 'modelName', value: slug }],
          };
        },
      },
      modelLatest: {
        value: 'latest',
        path: '/model/:modelName/:tab(latest)?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const slug = parseSlug(match.params.modelName);
          return {
            text: `${slug} Latest`,
            id: 'breadcrumbs.model.latest',
            values: [{ key: 'modelName', value: slug }],
          };
        },
      },
      modelsCountry: {
        value: 'Models',
        path: '/models/country/:country/:sortBy?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = match.params.sortBy && MODEL_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);
          return {
            text: `${parseSlug(match.params.country)} ${sort ? `(${sort.title})` : ''}`,
            id: `breadcrumbs.models.country.${sort ? camelCase(sort.title) : 'topRated'}`,
            values: [
              {
                key: 'country',
                value: parseSlug(match.params.country),
              },
            ],
          };
        },
      },
      modelsCountryAlphabetical: {
        value: 'Models',
        path: '/models/country/:country/all/:firstNameLetter?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = match.params.sortBy && MODEL_SORT_TYPES.find((cSort) => cSort.id === 'all');
          return {
            text: `${parseSlug(match.params.country)} ${sort ? `(${sort.title})` : ''}`,
            id: 'breadcrumbs.models.country.alphabetical',
            values: [
              {
                key: 'country',
                value: parseSlug(match.params.country),
              },
            ],
          };
        },
      },
      models: {
        value: 'Models',
        path: '/models/:sortBy?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = MODEL_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);

          if (!sort) {
            return null;
          }
          return {
            text: sort ? sort.title : '',
            id: `breadcrumbs.models.${sort ? camelCase(sort.title) : 'topRated'}`,
          };
        },
      },
      modelsUserRated: {
        value: 'Models',
        path: '/models/my-top/:rating?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = MODEL_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);
          return {
            text: sort ? sort.title : '',
            id: 'breadcrumbs.models.myTopRated',
          };
        },
      },
      modelsAlphabetical: {
        value: 'Models',
        path: '/models/all/:firstNameLetter?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = MODEL_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);
          return {
            text: sort ? sort.title : '',
            id: 'breadcrumbs.models.alphabetical',
          };
        },
      },
      photographer: {
        value: 'Photographer',
        path: '/photographer/:photographerName/:tab(photos|movies)?/:sortBy?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = match.params.sortBy && MOVIE_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);

          const { tab } = match.params;
          return [
            {
              text: `${parseSlug(match.params.photographerName)} ${tab ? `- ${capFirst(tab)}` : ''} ${
                sort ? `(${sort.title})` : ''
              }`,
              values: [
                {
                  key: 'photographerName',
                  value: parseSlug(match.params.photographerName),
                },
              ],
            },
          ];
        },
      },
      photographerLatest: {
        path: '/photographer/:photographerName/:tab(latest)?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const { tab } = match.params;
          return [
            {
              text: `${parseSlug(match.params.photographerName)} ${tab ? `- ${capFirst(tab)}` : ''}`,
              id: 'breadcrumbs.photographer.latest',
              values: [
                {
                  key: 'photographerName',
                  value: parseSlug(match.params.photographerName),
                },
              ],
            },
          ];
        },
      },
      photographers: {
        value: 'Photographers',
        path: '/photographers/:sortBy?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = match.params.sortBy && PHOTOGRAPHER_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);

          if (!sort) {
            return null;
          }

          return {
            text: `Photographers ${sort ? `(${sort.title})` : ''}`,
            id: `breadcrumbs.photographers.${sort ? camelCase(sort.title) : 'topRated'}`,
          };
        },
      },
    },
  },
  movies: {
    path: '/erotic-films',
    oldPath: '/movies',
    breadcrumb: () => ({
      url: '/erotic-films',
      text: 'Movies',
      id: 'breadcrumbs.movies.movies',
    }),
    routes: {
      movie: {
        path: '/model/:modelName/movie/:date/:movieName',
        breadcrumb: ({ state }: BreadcrumbParams) => {
          const movie = state.movie.item;
          if (movie) {
            return [
              {
                node: modelLinks(movie.models, movie.isStaffSelection || movie.isIntimateSelection),
              },
              {
                text: movie.name,
              },
            ];
          }
          return false;
        },
      },
      staffSelection: {
        value: 'Staff Selection',
        path: '/erotic-films/staff/:page?',
        breadcrumb: () => ({
          text: 'Staff Selection',
          id: 'breadcrumbs.movies.staffSelection',
        }),
      },
      metartIntimate: {
        value: 'MetArt Intimate',
        path: '/erotic-films/intimate/:sortBy?/:page?',
        breadcrumb: () => ({
          text: `MetArt Intimate`,
          id: `breadcrumbs.movies.metartIntimate`,
        }),
      },
      fanFiction: {
        value: 'Fan Fiction',
        path: '/erotic-films/fan-fiction/:sortBy?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = match.params.sortBy && MOVIE_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);
          return {
            text: `Fan Fiction ${sort ? `(${sort.title})` : ''}`,
            id: `breadcrumbs.movies.fanFiction.${sort ? camelCase(sort.title) : 'latest'}`,
          };
        },
      },
      myMovies: {
        value: 'PPSMovies',
        path: '/erotic-films/my/:sortBy?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = match.params.sortBy && MOVIE_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);
          return {
            text: `My Movies ${sort ? `(${sort.title})` : ''}`,
            id: `breadcrumbs.movies.myMovies.${sort ? camelCase(sort.title) : 'latest'}`,
          };
        },
      },

      movies: {
        value: 'Movies',
        path: '/erotic-films/:sortBy?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = match.params.sortBy && MOVIE_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);

          if (!sort) {
            return null;
          }

          return {
            text: sort ? sort.title : '',
            id: `breadcrumbs.movies.${sort ? camelCase(sort.title) : 'latest'}`,
          };
        },
      },
    },
  },
  updates: {
    path: '/updates',
    breadcrumb: () => ({
      url: '/updates',
      text: 'Updates',
      id: 'breadcrumbs.updates',
    }),
    routes: {
      stream: {
        value: 'updates',
        path: '/updates',
        breadcrumb: () => ({
          text: 'Stream',
          id: 'breadcrumbs.stream',
        }),
      },
      upcoming: {
        value: 'upcoming',
        path: '/updates/upcoming',
        breadcrumb: () => ({
          text: 'Upcoming',
          id: 'breadcrumbs.upcoming',
        }),
      },
      archive: {
        value: 'archive',
        path: '/updates/archive',
        breadcrumb: () => ({
          text: 'Archive',
          id: 'breadcrumbs.archive',
        }),
      },
      archiveDate: {
        value: 'archive',
        path: '/updates/archive/:year/:month/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => [
          {
            url: '/updates/archive',
            text: 'Archive',
            id: 'breadcrumbs.archive',
          },
          {
            text: moment(`${match.params.year}${match.params.month}`, 'YYYYMM').format('MMMM YYYY'),
          },
        ],
      },
      staffSelections: {
        value: 'staff',
        path: '/updates/staff/:year?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const breadcrumbs: BreadCrumb[] = [
            {
              url: '/updates/staff',
              text: 'Staff Selections',
              id: 'breadcrumbs.staffSelections',
            },
          ];

          if (match?.params?.year) {
            breadcrumbs.push({ text: moment(`${match.params.year}`, 'YYYY').format('YYYY') });
          }

          return breadcrumbs;
        },
      },
      metartIntimate: {
        value: 'intimate',
        path: '/updates/intimate/:year?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const breadcrumbs: BreadCrumb[] = [
            {
              url: '/updates/intimate',
              text: 'MetArt Intimate',
              id: 'breadcrumbs.metartIntimate',
            },
          ];

          if (match?.params?.year) {
            breadcrumbs.push({ text: moment(`${match.params.year}`, 'YYYY').format('YYYY') });
          }
          return breadcrumbs;
        },
      },

      oldArchive: { value: 'archive', path: '/updates/archive/triple' },
      oldArchive2: { value: 'archive', path: '/archive/route' },
    },
  },
  blog: {
    path: '/blog',
    breadcrumb: () => ({
      url: '/blog',
      text: 'Blog',
      id: 'breadcrumbs.blog.blog',
    }),
    routes: {
      blog: {
        path: '/blog',
        breadcrumb: () => ({
          text: 'Posts',
          id: 'breadcrumbs.blog.posts',
        }),
      },
      posts: {
        path: '/blog/:pageOrDate?',
        breadcrumb: ({ match }: BreadcrumbParams) =>
          match.params.pageOrDate?.toString().match(/\d{6}/) === null
            ? {
                text: 'Latest',
                id: 'breadcrumbs.blog.latest',
              }
            : {
                text: moment(match.params.pageOrDate, 'YYYYMMDD').format('MMMM YYYY'),
              },
      },
      category: {
        path: '/blog/category/:category/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => ({
          text: match.params.category,
        }),
      },
      post: {
        path: '/blog/:date/:name',
        breadcrumb: ({ state }: BreadcrumbParams) => {
          const post = state.blogPost.item;
          if (post) {
            return {
              text: `${post.title.match(/(\w+\W*){0,3}/)?.[0]}...`,
            };
          }
          return null;
        },
      },
    },
  },
  search: {
    path: '/search',
    routes: {
      advancedSearch: {
        path: '/search',
        breadcrumb: () => ({
          text: 'Advanced Search',
          id: 'search.advancedSearch.advancedSearch',
        }),
      },
      searchResults: {
        path: '/search/:searchTerm/:sortBy?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => {
          const sort = match.params.sortBy && SEARCH_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);
          return [
            {
              url: '/search',
              text: 'Search',
              id: 'search.search',
            },
            {
              text: `Search Results ${sort ? `(${sort.title})` : ''}`,
              id: `search.searchResults${sort ? `.${camelCase(sort.title)}` : '.searchResults'}`,
            },
          ];
        },
      },
    },
  },
  user: {
    path: '/user/:displayName/',
    breadcrumb: ({ match }: BreadcrumbParams) => [
      {
        text: `User - ${match.params.displayName}`,
        url: `/user/${match.params.displayName}/`,
      },
    ],
    routes: {
      comments: {
        path: '/user/:displayName/comments',
        breadcrumb: () => ({
          text: 'Comments',
        }),
      },
    },
  },
  cover: {
    path: '/cover/:galleryUUID',
  },
  leaderboard: {
    path: '/leaderboard/:tab/:timePeriod?',
  },
  categories: {
    path: '/tags',
    routes: {
      categoriesPage: {
        path: '/tags',
        breadcrumb: () => ({
          text: 'Categories',
          url: '/tags',
        }),
      },
      categoriesResults: {
        path: '/tags/:searchTerm/:sortBy?/:page?',
        breadcrumb: ({ match, state }: BreadcrumbParams) => {
          const sort = match.params.sortBy && SEARCH_SORT_TYPES.find((cSort) => cSort.id === match.params.sortBy);
          return [
            {
              text: 'Tags',
              url: '/tags',
            },
            {
              text: `${state.searchResults.displayName || 'Tag Results'} ${sort ? `(${sort.title})` : ''}`,
            },
          ];
        },
      },
    },
  },
  favoritesAndRatings: {
    path: ['/favorites', '/ratings'],
    routes: {
      favorites: {
        path: '/favorites/:type?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => [
          {
            url: '/favorites',
            text: 'Favorites',
          },
          {
            text: capFirst(match.params.type ?? ''),
          },
        ],
      },
      ratings: {
        path: '/ratings/:type?/:page?',
        breadcrumb: ({ match }: BreadcrumbParams) => [
          {
            url: '/ratings',
            text: 'Ratings',
          },
          {
            text: capFirst(match.params.type ?? ''),
          },
        ],
      },
    },
  },
  settings: {
    path: '/settings',
  },
  2257: {
    path: '/2257',
  },
  piracy: {
    path: '/piracy',
  },
  trustedPartners: {
    path: '/trusted-partners',
  },
  login: {
    path: '/login',
  },
  pages: {
    path: '/pages/:pageName',
  },
  signup: {
    path: '/signup',
  },
  specialFeatures: {
    path: '/category/:category/:subCategory?/:extraCategory?/:page?',
  },
  subscriptionPreview: {
    path: '/subscription/preview/:token',
  },
  newsletterConfirmed: {
    path: '/newsletter/confirmed',
  },
  allSites: {
    path: '/allsites',
  },
  report: {
    path: ['/report-abuse', '/report-content'],
    routes: {
      abuse: {
        path: '/report-abuse',
      },
    },
  },
  ageVerificationCallback: {
    path: '/age-verification-callback',
  },
};

export const updatesRoutes = [
  routes.updates.routes.stream,
  routes.updates.routes.upcoming,
  routes.updates.routes.archive,
  routes.updates.routes.archiveDate,
  routes.updates.routes.staffSelections,
  routes.updates.routes.metartIntimate,
  { path: routes.updates.path },
];

export const galleriesRoutes = [
  routes.galleries.routes.gallery,
  routes.galleries.routes.myGalleries,
  routes.galleries.routes.galleries,
  routes.galleries.routes.images,
  routes.galleries.routes.botd,
  { path: routes.galleries.path },
];

export const moviesRoutes = [
  routes.movies.routes.movie,
  routes.movies.routes.staffSelection,
  routes.movies.routes.fanFiction,
  routes.movies.routes.myMovies,
  routes.movies.routes.metartIntimate,
  routes.movies.routes.movies,
  { path: routes.movies.path },
];

export const modelsAndArtistsRoutes = [
  routes.modelsAndArtists.routes.modelLatest,
  routes.modelsAndArtists.routes.modelPhotos,
  routes.modelsAndArtists.routes.modelMovies,
  routes.modelsAndArtists.routes.modelsAlphabetical,
  routes.modelsAndArtists.routes.modelsCountryAlphabetical,
  routes.modelsAndArtists.routes.modelsCountry,
  routes.modelsAndArtists.routes.models,
  routes.modelsAndArtists.routes.photographer,
  routes.modelsAndArtists.routes.photographerLatest,
  routes.modelsAndArtists.routes.photographers,
  { path: routes.modelsAndArtists.path },
];

export const blogRoutes = [
  routes.blog.routes.blog,
  routes.blog.routes.posts,
  routes.blog.routes.category,
  routes.blog.routes.post,
  { path: routes.blog.path },
];
