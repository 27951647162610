import { select, take } from 'redux-saga/effects';
import { RootState } from '../reducer';

export function* wait(actions: string[], selector: (state: RootState) => boolean | undefined) {
  // if selector has already succeeded, stop this function and return to continue execution
  if (yield select(selector)) {
    return;
  }

  while (true) {
    // only run when specific actions are executed, pass in ['*'] to check on every action
    yield take(...actions);

    // check to ensure selector has succeeded, if not we'll try on the next action
    if (yield select(selector)) {
      return;
    }
  }
}
