import { useCallback, useState } from 'react';
import { ApiClient } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

export default function useAutoComplete() {
  const [autoCompleteValues, setAutoComplete] = useState<string[]>([]);

  const getAutoComplete = useCallback(async (searchTerm: string): Promise<void> => {
    let results = [];
    const invalidSearch = searchTerm && searchTerm.match(/[^a-zA-Z0-9\s]+/g);

    if (searchTerm && !invalidSearch) {
      try {
        const api = new ApiClient();

        // TODO: Fix API Client as any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { body } = await (api as any).get(constructUrl(urls.get.searchSuggestions), {
          params: {
            input: searchTerm,
          },
        });
        results = body;
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error('Unable to query for search results', error);
      }
    }

    setAutoComplete(results);
  }, []);

  return [autoCompleteValues, getAutoComplete] as const;
}
