import React from 'react';
import { useSettingsSelector } from 'src/client/redux';
import {
  ThemeProvider as StyledComponentsThemeProvider,
  StyleSheetManager,
  DefaultTheme,
} from 'styled-components/macro';
import { isPrerender } from '../../utils/prerenderDetect';

export type ThemeOptions = 'light' | 'dark';

type Props = {
  colorThemes: Record<ThemeOptions, DefaultTheme>;
};

export const ThemeProvider: React.FC<Props> = (props) => {
  const theme = useSettingsSelector<ThemeOptions>('template');

  return (
    <StyleSheetManager disableCSSOMInjection={isPrerender}>
      <StyledComponentsThemeProvider theme={props.colorThemes[theme]}>{props.children}</StyledComponentsThemeProvider>
    </StyleSheetManager>
  );
};
