export const Biller = {
  RocketGate: 1,
  CCBill: 2,
  Epoch: 3,
  GoCoin: 4,
  BitPay: 5,
  Internal: 6,
  Nats: 7,
  Vendo: 8,
  PayGarden: 9,
  CoinPayments: 10,
  CommerceGate: 11,
};

export const DataSource = {
  Processor: 1,
  Migration: 2,
  PartnerAPIMigration: 3,
  PartnerAPI: 4,
  CMS: 5,
  NATS: 6,
  ExternalImport: 7,
};

export const BillerById = Object.entries(Biller).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {} as Record<number, string>);

export const SubscriptionType = {
  Trial: 1,
  OneTime: 2,
  Standard: 3,
  Lifetime: 4,
};
