import { all, put } from 'redux-saga/effects';
import PropTypes from 'prop-types';
import { ToastsStore } from 'react-toasts';
import { AnyAction } from 'redux';
import { ValuesOf } from 'src/@types/type-utils';
import { SETTINGS_SET_VALUE } from 'src/shared/constants/socketMessages';

const SET_SETTINGS = 'man-site/settings/set_settings';
const SET_VALUE = 'man-site/settings/set_value';
const RESET_VALUES = 'man-site/settings/reset_values';

const SETTINGS_KEYS = [
  'template',
  'comments',
  'commentSorting',
  'descriptions',
  'downloadMirror',
  'filmsSorting',
  'fitToScreen',
  'galleriesSorting',
  'homepage',
  'imageAutoSize',
  'imageWindow',
  'language',
  'listView',
  'measurements',
  'modelsSorting',
  'navigation',
  'ratingsScale',
  'recents',
  'resolution',
  'resultsPerPage',
  'slideshow',
  'slideshowDelay',
  'tags',
  'thumbnail',
  'thumbnailsPerPage',
  'timezone',
] as const;

export type SettingsKey = ValuesOf<typeof SETTINGS_KEYS>;

type State = {
  loaded: boolean;
  loading: boolean;
  opened: boolean;
  values: Array<{ key: SettingsKey; value: string | number }>;
  defaults: Array<{ key: SettingsKey; value: number }>;
  enums: Array<{ key: SettingsKey; options: Array<{ index: number; value: number | string }> }>;
};

export const initialState: State = {
  loaded: false,
  loading: false,
  opened: false,
  values: [],
  defaults: [],
  enums: [],
};

export const galleryViewSettingsPropType = PropTypes.oneOf(['grid', 'detailed']);

export default function reducer(state = initialState, action: AnyAction): State {
  switch (action.type) {
    case SET_SETTINGS: {
      return {
        ...state,
        values: action.settings.values,
        enums: action.settings.enums,
        defaults: action.settings.defaults,
      };
    }

    case SET_VALUE: {
      return {
        ...state,
        values: [...state.values.filter((set) => set.key !== action.set.key), action.set],
      };
    }

    case RESET_VALUES: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}

export function setUIValue({ key, value }) {
  return {
    type: SET_VALUE,
    set: { key, value },
  };
}

export function* setUIValues(values) {
  yield all([...values.map((set) => put(setUIValue(set)))]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setValue(key: string, value: string | number, callback: (err?: any) => void = () => {}) {
  global.socket.emit(SETTINGS_SET_VALUE, { key, value }, callback);
  ToastsStore.info('Settings have been saved.');
  return setUIValue({ key, value });
}

export function resetUIValues() {
  return {
    type: RESET_VALUES,
  };
}

export function setSettings(settings) {
  return {
    type: SET_SETTINGS,
    settings,
  };
}
