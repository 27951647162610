import React from 'react';
import { Route, Switch } from 'react-router';
import { Slogan } from 'src/client/components/Slogan/Slogan';
import { MetArt } from 'src/client/constants/sites';
import { useSelector } from 'src/client/redux';
import styled from 'styled-components/macro';

export const FooterSeoSubHeadings = () => {
  const site = useSelector((state) => state.site);
  const slogan = site.abbreviation === MetArt.abbreviation ? 'WHERE FLAWLESS BEAUTY MEETS ART' : null;

  return (
    <Slogan slogan={slogan}>
      <Switch>
        <Route exact path="/" render={() => <H2>Sexy Erotic Photos and Videos</H2>} />
        <Route path="/erotic-films" render={() => <H2>{site.name} Videos of Nude Women</H2>} />
        <Route path="/galleries" render={() => <H2>{site.name} Galleries</H2>} />
        <Route path="/models" render={() => <H2>Erotic Models and Artists</H2>} />
        <Route exact path="/updates" render={() => <H2>{site.name} Updates</H2>} />
      </Switch>
    </Slogan>
  );
};

const H2 = styled.h2`
  font-size: inherit;
  margin: 0;
`;
