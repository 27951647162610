import React from 'react';
import styled from 'styled-components/macro';
import cx from 'classnames';
import { useSelector } from 'src/client/redux';
import { TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

type ImageLinkProps = {
  bannerDesktop: string;
  bannerMobile: string;
};

const ImageLink = styled.a<ImageLinkProps>`
  aspect-ratio: 1240/1136;
  background-image: url(${(props) => props.bannerDesktop});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin: auto;
  max-width: 1240px;

  @media ${TABLET_PORTRAIT} {
    background-image: url(${(props) => props.bannerMobile});
    aspect-ratio: 1170/658;
    max-width: 1170px;

    &.hero-slider {
      aspect-ratio: 2162/960;
      max-width: 2162px;
    }
  }
`;

export const PromoBanner = () => {
  const {
    cdnUrl,
    config: { splash },
  } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);
  const [joinHref] = useJoinUrl({ campaign: 'ad-zone-banner' });
  const showSfwSite = useShowSfwSite();

  const sliderType = splash?.components?.sliderComponent?.component ?? '';

  const showHeroSlider = ['HeroSlider', 'StraplezSlider'].includes(sliderType);

  const assetDirectoryPath = `${cdnUrl}/network-promo-banners/${site.abbreviation.toLowerCase()}`;
  const nonNudeText = showSfwSite ? '_nonnude' : '';

  const bannerDesktop = `${assetDirectoryPath}/banner_phone${nonNudeText}.jpg`;
  const bannerMobile = `${assetDirectoryPath}/banner${nonNudeText}.jpg`;

  return (
    <ImageLink
      bannerDesktop={bannerDesktop}
      bannerMobile={bannerMobile}
      className={cx({ 'hero-slider': showHeroSlider })}
      href={joinHref}
    />
  );
};
