import React, { ComponentProps } from 'react';
import styled from 'styled-components/macro';
import { SortDropdown } from '@tovia/man-ui';
import ConnectedViewOptions from '../ConnectedViewOptions';
import { GalleryCards } from '../Cards/Gallery/GalleryCards';
import ModelCards from '../Cards/Model/ModelCards';
import PhotographerCards from '../Cards/Photographer/PhotographerCards';
import { useWidePage } from 'src/client/helpers/useFeatureFlags';

const ContentListWrapper = styled.div`
  margin-bottom: 10px;
`;

const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
`;

const StyledDropdown = styled(SortDropdown)`
  margin-right: 20px;
`;

const Title = styled.h2`
  flex: auto;
  font-size: 2.15rem;
  margin: 10px 0;
`;

const StyledViewOptions = styled(ConnectedViewOptions)`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
`;

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  sortDropdownProps?: ComponentProps<typeof SortDropdown>;
  title?: string;
  type?: 'GALLERY' | 'PHOTOGRAPHER' | 'MODEL';
  viewPeriod?: string;
};

export const ContentListSection = (props: Props) => {
  const { title, sortDropdownProps, items, type = 'GALLERY', viewPeriod } = props;

  const widePage = useWidePage();

  return (
    <ContentListWrapper>
      {!widePage && (
        <Options>
          {title && <Title>{title}</Title>}
          {sortDropdownProps && <StyledDropdown {...sortDropdownProps} />}
          <StyledViewOptions />
        </Options>
      )}
      {type === 'GALLERY' && <GalleryCards galleries={items} viewPeriod={viewPeriod} />}
      {type === 'MODEL' && <ModelCards models={items} viewPeriod={viewPeriod} />}
      {type === 'PHOTOGRAPHER' && <PhotographerCards photographers={items} viewPeriod={viewPeriod} />}
    </ContentListWrapper>
  );
};
