import React from 'react';
import { useSelector } from 'src/client/redux';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

const SplashAboutTopMA = React.lazy(() => import('./SplashAboutTopMA').then((m) => ({ default: m.SplashAboutTopMA })));
const SplashAboutTopMX = React.lazy(() => import('./SplashAboutTopMX').then((m) => ({ default: m.SplashAboutTopMX })));
const SplashAboutTopSA = React.lazy(() => import('./SplashAboutTopSA').then((m) => ({ default: m.SplashAboutTopSA })));
const SplashAboutTopSL = React.lazy(() => import('./SplashAboutTopSL').then((m) => ({ default: m.SplashAboutTopSL })));
const SplashAboutTopTLE = React.lazy(() =>
  import('./SplashAboutTopTLE').then((m) => ({ default: m.SplashAboutTopTLE })),
);
const SplashAboutTopVT = React.lazy(() => import('./SplashAboutTopVT').then((m) => ({ default: m.SplashAboutTopVT })));

export const SplashAboutTop = () => {
  const showSfwSite = useShowSfwSite();
  const { abbreviation } = useSelector((state) => state.site);

  if (!showSfwSite) {
    return null;
  }

  switch (abbreviation.toLowerCase()) {
    case 'ma':
      return <SplashAboutTopMA />;
    case 'mx':
      return <SplashAboutTopMX />;
    case 'sa':
      return <SplashAboutTopSA />;
    case 'sl':
      return <SplashAboutTopSL />;
    case 'tle':
      return <SplashAboutTopTLE />;
    case 'vt':
      return <SplashAboutTopVT />;
    default:
      return null;
  }
};
