import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { chunk } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'src/client/redux';
import { useCategories } from 'src/client/requests/useCategories';
import { ExtendedFeaturedTag } from 'src/@types/ExtendedFeaturedTags';
import { UserTypes } from 'src/shared/constants/userTypes';
import { HeaderContext } from 'src/client/containers/HeaderBar/HeaderProvider';

export const SeoCategoriesPopover = () => {
  const { setActivePopover, setPopoverContentLoaded } = useContext(HeaderContext);
  const config = useSelector((state) => state.app.config);
  const userType = useSelector((state) => state.auth.userType);
  const { isLoading, topCategories } = useCategories();

  useEffect(() => {
    if (!isLoading && topCategories) {
      setPopoverContentLoaded(true);
    }
  }, [isLoading, setPopoverContentLoaded, topCategories]);

  if (isLoading) {
    return null;
  }

  const hideMenu = () => setActivePopover(null);

  const isGuest = userType === UserTypes.Guest;
  const [leftColumn, rightColumn] = chunk(topCategories, Math.ceil(topCategories.length / 2));

  return (
    <Popover>
      <PopoverTitle>Popular categories</PopoverTitle>
      <Columns>
        <Column data-testid="seo-categories-left-column" wide={isGuest}>
          {leftColumn.map((tag) => (
            <TagLink key={tag.UUID} tag={tag} onClick={hideMenu} title={tag.calculatedSeoTitle} />
          ))}
        </Column>
        <Column data-testid="seo-categories-right-column" wide={isGuest}>
          {rightColumn.map((tag) => (
            <TagLink key={tag.UUID} tag={tag} onClick={hideMenu} title={tag.calculatedSeoTitle} />
          ))}
        </Column>
      </Columns>
      {config.hasCategories && (
        <AllCategoryWrapper>
          <Link exact to={`/tags`} onClick={hideMenu}>
            <FontAwesomeIcon icon={faTags} />
            All categories
          </Link>
        </AllCategoryWrapper>
      )}
    </Popover>
  );
};

const TagLink = ({
  tag: { name, url },
  title,
  onClick,
}: {
  tag: ExtendedFeaturedTag;
  title: string;
  onClick: () => void;
}) => (
  <Link to={`/tags/${encodeURI(url || name)}`} onClick={onClick}>
    {title}
  </Link>
);

const Popover = styled.div`
  background: ${(props) => props.theme.primary1};
  width: 400px;
  border-color: ${(props) => props.theme.primary3};
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 0 0 15px;
`;

const PopoverTitle = styled.h2`
  font-size: 17px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  color: ${(props) => props.theme.primary4};
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Column = styled.div<{ wide: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: ${({ wide }) => (wide ? '150px' : '100px')};
`;

const Link = styled(NavLink)`
  margin-bottom: 14px;

  transition: 0.3s color;
  color: ${(props) => props.theme.headerBarPrimary2};

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.primary4};
  }

  &.active {
    color: ${(props) => props.theme.primary4};
  }
`;

const AllCategoryWrapper = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0.5rem;
  }
`;
