import React, { createContext, ReactNode, useEffect } from 'react';

import { useSelector } from '../../redux';
import { load as loadCams } from '../../redux/modules/cams';
import { useDispatch } from 'react-redux';
import { CamProviderSetup } from 'src/client/redux/modules/app';

export const CamsContext = createContext<{ loaded: boolean }>({
  loaded: false,
});

export const loadCamsFunction = (providers: CamProviderSetup[] = [], siteUUID: string) =>
  loadCams({ providers, siteUUID });

type Props = {
  children: ReactNode;
};

export const CamsContextProvider = (props: Props) => {
  const dispatch = useDispatch();
  const camProviders = useSelector((state) => state.app.config.camProviders);
  const siteUUID = useSelector((state) => state.site.UUID);
  const { user } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.cams);

  useEffect(() => {
    dispatch(loadCamsFunction(camProviders, siteUUID));
  }, [dispatch, camProviders, siteUUID]);

  const loaded = Boolean(user && !loading && !error);

  return <CamsContext.Provider value={{ loaded }}>{props.children}</CamsContext.Provider>;
};
