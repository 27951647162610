import React from 'react';
import { useSettingsSelector } from 'src/client/redux';
import { useDispatch } from 'react-redux';
import { setValue } from 'src/client/redux/modules/settings';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faThList } from '@fortawesome/pro-light-svg-icons';
import { StyledIcon } from 'src/client/components/ui/StyledIcon';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { DropdownButton, DropdownItem } from './styles';

export const IconOnlyViewDropdown = () => {
  const dispatch = useDispatch();
  const view = useSettingsSelector('listView');
  const onSelect = (value: string) => () => dispatch(setValue('listView', value));

  return (
    <DropdownButton
      title={
        <>
          <FontAwesomeIcon icon={view === 'detailed' ? faThList : faTh} />
          <StyledIcon $spaceLeft icon={faCaretDown} />
        </>
      }
    >
      <DropdownItem onClick={onSelect('detailed')} className={cx({ active: view === 'detailed' })}>
        <StyledIcon $spaceRight icon={faThList} />
        Detailed View
      </DropdownItem>
      <DropdownItem onClick={onSelect('grid')} className={cx({ active: view === 'grid' })}>
        <StyledIcon $spaceRight icon={faTh} />
        Grid View
      </DropdownItem>
    </DropdownButton>
  );
};
