import { MobileLink } from './MobileLink';
import { ActiveNestedItem, HeaderContext } from '../HeaderProvider';
import React, { useContext } from 'react';
import { faTags } from '@fortawesome/pro-regular-svg-icons';
import { MobileBar } from './MobileBar';
import { MobileExpandableMenuItem } from './MobileExpandableMenuItem';
import { useSelector } from 'src/client/redux';
import styled from 'styled-components/macro';
import { useCategories } from 'src/client/requests/useCategories';

export const MobileSeoCategoriesPicker = () => {
  const config = useSelector((state) => state.app.config);
  const { isLoading, topCategories } = useCategories();
  const { closeMobileMenu } = useContext(HeaderContext);

  if (isLoading) {
    return null;
  }

  return (
    <MobileExpandableMenuItem icon={faTags} menuLabel="Categories" activeNestedItem={ActiveNestedItem.SEO_CATEGORIES}>
      <MobileBar icon={faTags} label="Popular categories" />
      {topCategories.map((tag) => (
        <MobileLink
          key={tag.UUID}
          to={`/tags/${encodeURI(tag.url || tag.name)}`}
          label={tag.calculatedSeoTitle}
          handleClick={closeMobileMenu}
        />
      ))}
      {config.hasCategories && (
        <AllCategoriesLink exact handleClick={closeMobileMenu} label="All categories" to="/tags" />
      )}
    </MobileExpandableMenuItem>
  );
};

const AllCategoriesLink = styled(MobileLink)`
  font-weight: 700;
`;
