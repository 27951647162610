import * as icons from '../icons';

export const badgeMap = {
  1: {
    icon: icons.lifetime,
    title: 'Lifetime Member',
  },
  2: {
    icon: icons.staff,
    title: 'Community Staff',
  },
  3: {
    icon: icons.model,
    title: 'Verified Model',
  },
  4: {
    icon: icons.writer,
    title: 'Contributing Writer',
  },
  5: {
    icon: icons.photographer,
    title: 'Verified Photographer',
  },
  6: {
    icon: icons.director,
    title: 'Verified Director',
  },
};
