const redirects: Record<string, string> = {
  archive: '/updates/archive',
  blog: '/blog',
  films: '/movies',
  galleries: '/galleries',
  models: '/models',
  photographers: '/photographers',
  splash: '/',
  upcoming: '/updates/upcoming',
  updates: '/updates',
};
export default redirects;
