import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { capFirst } from '@tovia/man-app-utils/lib/helpers/converters';
import { useDispatch } from 'react-redux';
import { Modal } from 'src/client/components/modals/Modal/Modal';
import TagForm from './TagForm/TagForm';
import {
  addTags as addTagsFn,
  deleteTags as deleteTagsFn,
  hideNewTagsModal as hideNewTagsModalFn,
} from '../../redux/modules/tags';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';

const UserTagManagerModal = styled(Modal)`
  & .modal-body {
    padding: 12px 0 24px;
  }

  & .btn-close {
    top: 0;
    right: 10px;

    svg {
      font-size: 1.3rem;
    }
  }
`;

const Div = styled.div`
  & .user-tags-wrap {
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    border-radius: 5px;
    padding: 20px 10px;
    margin-bottom: 12px;
  }

  & .site-tags-container {
    margin: 10px 0;
  }

  & .user-tags {
    margin-top: 10px;
  }

  & .title {
    font-size: 1.3rem;
    color: #000;
    margin: 0 0 8px 0;
    font-weight: 600;
    display: block;
  }

  & .subtitle {
    font-size: 1.3rem;
    margin-bottom: 8px;
    margin: 0 0 10px 0;

    .galleryName {
      font-style: italic;
    }
  }

  & .instructions {
    font-size: 0.85rem;
    margin: 0 0 4px 0;
  }

  & .list-of-tags {
    max-height: 120px;
    overflow-y: auto;
    margin-bottom: 20px;
    margin-top: 10px;

    @media (max-width: 767px) {
      max-height: unset;
    }
  }

  & .tag {
    text-decoration: none;
    display: inline-block;
    padding: 2px 4px;
    font-size: 0.85rem;
    border-radius: 2px;
    margin: 4px 2px;
    background: ${(props) => props.theme.primaryBtn1Bg};
    color: #fff;
    cursor: default;

    &.site-tag {
      color: #000;
      background: #ccc;
      cursor: pointer;
      opacity: 0.9;
      transition: 0.3s opacity ease;

      :hover {
        opacity: 1;
      }
    }
  }
`;

export default function NewTagDialogClass() {
  const dispatch = useDispatch();
  const siteName = useSelector((state) => state.site.name);
  const tagsState = useSelector((state) => state.tags);
  const galleryTags = useSelector((state) => state.gallery.item && state.gallery.item.tags);
  const movieTags = useSelector((state) => state.movie.item && state.movie.item.tags);
  const addTags = (params) => dispatch(addTagsFn(params));
  const deleteTags = (params) => dispatch(deleteTagsFn(params));
  const hideNewTagsModal = () => dispatch(hideNewTagsModalFn());
  const intl = useIntl();

  const {
    newTagsModal: { show, name, objectUUID, objectType },
    addStatus,
    deleteStatus,
    tagsErrors,
    tags,
  } = tagsState;

  const setTags = (values) => {
    const { userTags } = values; // new user tags

    const deletedTags = tags[objectUUID].tags.filter((tag) => !userTags.includes(tag.name));

    if (deletedTags.length > 0) {
      deleteTags({
        objectUUID,
        type: objectType,
        UUIDs: deletedTags.map((tag) => tag.UUID),
      });
    }
    const addedTags = userTags.filter((userTag) => !tags[objectUUID].tags.map((tag) => tag.name).includes(userTag));
    if (addedTags.length > 0) {
      addTags({
        objectUUID,
        tags: addedTags,
        type: objectType,
      });
    }
  };

  const tagsLinks: ReactElement[] = [];
  const userTags: string[] = [];

  if (objectUUID && tags[objectUUID]) {
    tags[objectUUID].tags.forEach((tag) => {
      tagsLinks.push(
        <span className="tag user-tag" key={tag.UUID}>
          {tag.name}
        </span>,
      );
      userTags.push(tag.name);
    });
  }

  const siteTags: ReactElement[] = [...new Set(galleryTags || movieTags)].map((tag: string) => (
    <Link onClick={hideNewTagsModal} className="tag site-tag" to={`/tags/${tag.replace(/\s/g, '+')}`} key={tag}>
      {tag}
    </Link>
  ));

  return (
    <UserTagManagerModal show={show} onHide={hideNewTagsModal}>
      <Div>
        <div className="title">{intl.formatMessage({ id: 'tagDialog.title', defaultMessage: 'User Tag Manager' })}</div>
        <div className="user-tags-wrap">
          <span className="subtitle">
            {intl.formatMessage({ id: 'tagDialog.subtitle', defaultMessage: 'Add your Private tags for:' })}
            <span className="galleryName">{` ${name}`}</span>
          </span>
          <span className="instructions">
            {intl.formatMessage({
              id: 'tagDialog.instructions',
              defaultMessage: 'To add multiple tags separate each tag with a comma. Click update to add tags.',
            })}
          </span>
          <TagForm
            tagsErrors={tagsErrors}
            addStatus={addStatus}
            deleteStatus={deleteStatus}
            onSubmit={setTags}
            initialValues={{
              userTags, // initial tags to start with
            }}
          />
        </div>
        <div className="site-tags-wrap">
          <span className="subtitle">
            {intl.formatMessage(
              { id: 'tagDialog.siteTagsTitle', defaultMessage: `${siteName} Gallery Tags` },
              { siteName, galleryType: capFirst(objectType) },
            )}
          </span>
          <div className="site-tags-container">{siteTags}</div>
        </div>
      </Div>
    </UserTagManagerModal>
  );
}
