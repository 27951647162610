import React, { useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useFeatureFlags } from 'src/client/helpers/useFeatureFlags';
import { useSelectedLanguage } from 'src/client/helpers/useSelectedLanguage';
import { SupportedLanguage } from 'src/shared/constants/languages';
import en from 'src/shared/translations/en.json';

export const languagesCache = new Map<SupportedLanguage, Record<string, string>>([['en', en]]);

interface Context {
  language: SupportedLanguage;
  changeLanguage: (language: SupportedLanguage) => Promise<void>;
}

export const LanguageContext = React.createContext<Context>({
  language: 'en',
  changeLanguage: () => Promise.resolve(),
});

export const NewLanguageProvider: React.FC = (props) => {
  const [language, setLanguage] = useSelectedLanguage();
  const [messages, setMessages] = useState<Record<string, string>>(en);

  const changeLanguage = useCallback(
    async (newLanguage: SupportedLanguage | undefined) => {
      if (!newLanguage) return;

      setLanguage(newLanguage);

      if (languagesCache.has(newLanguage)) {
        // Even though typescript knows that the language is in the map,
        // it still thinks .get() could return undefined.
        setMessages(languagesCache.get(newLanguage) as Record<string, string>);
        return;
      }

      try {
        const newMessages = await import(`src/shared/translations/${newLanguage}.json`).then((m) => m.default);

        languagesCache.set(newLanguage, newMessages);

        setMessages(newMessages);
      } catch (error: unknown) {
        // There was a toast here in billing portal.
        // eslint-disable-next-line no-console
        console.error(`Failed to load language: ${newLanguage}`);
        setLanguage(language);
      }
    },
    [language, setLanguage],
  );

  const hasRunInitialLanguageChange = React.useRef(false);
  useEffect(() => {
    // not sure where to tap into this loading yet.
    if (!hasRunInitialLanguageChange.current /* && !appData.loading */) {
      changeLanguage(language);
      hasRunInitialLanguageChange.current = true;
    }
  }, [/* appData.loading,*/ changeLanguage, language]);

  // Wait for the initial language to load to return anymore of the app
  if (Object.keys(messages).length === 0) return null;

  return (
    <LanguageContext.Provider value={{ changeLanguage, language }}>
      <IntlProvider locale={language} messages={messages} defaultLocale="en">
        {props.children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

// @TODO - 2023-02-17
// When this doesn't need a feature flag, just remove this component and rename the one above.
export const LanguageProvider: React.FC = (props) => {
  const { enableInternationalizationManSite } = useFeatureFlags();

  if (!enableInternationalizationManSite) {
    return (
      <IntlProvider locale="en" messages={en}>
        {props.children}
      </IntlProvider>
    );
  }

  return <NewLanguageProvider>{props.children}</NewLanguageProvider>;
};
