import styled from 'styled-components';
import Image from '@tovia/man-ui/lib/components/Image/Image';

const DEFAULT_BLUR_VALUE = 20;

type Props = {
  $isBlurred: boolean;
  $blurValue?: number;
};

// The $ in the prop is only needed on the styled(Image) to stop from passing
// it to the underlying Image element. It's not needed on the styled.img, but
// we're just keeping the props consistent.
export const BlurrableImage = styled.img<Props>`
  ${({ $isBlurred, $blurValue = DEFAULT_BLUR_VALUE }) => {
    return $isBlurred && `filter: blur(${$blurValue}px);`;
  }}
  clip-path: inset(0);
`;

export const BlurrableLazyImage = styled(Image)<Props>`
  ${({ $isBlurred, $blurValue = DEFAULT_BLUR_VALUE }) => {
    return $isBlurred && `filter: blur(${$blurValue}px);`;
  }}
  clip-path: inset(0);
`;
