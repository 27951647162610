import React from 'react';
import styled from 'styled-components/macro';

type Props = {
  title: string;
};

const HeadingWrapper = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    min-height: 60px;
  }
  @media (max-width: 430px) {
    min-height: 50px;
  }
`;

const HeadingLabel = styled.span`
  display: block;
  text-align: center;
  color: #fff;
  font-family: Urbanist, 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  margin: 0 auto;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const Heading = (props: Props) => {
  return (
    <HeadingWrapper>
      <HeadingLabel>{props.title}</HeadingLabel>
    </HeadingWrapper>
  );
};
