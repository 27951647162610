import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FeedItem } from './FeedItem';
import { ActionProps, getActivityTypeInfo } from './ActivityFeed';

export const RatingAction = (props: ActionProps) => {
  const { activity } = props;
  const { objectType, rating, commentUUID } = activity;

  // user has favorited a comment
  if (objectType === 'COMMENT') {
    const {
      userDisplayName,
      object: { type },
    } = activity;
    const info = getActivityTypeInfo(activity)[type] || {};

    return (
      <FeedItem
        didWhat={
          <FormattedMessage
            id="activityFeed.actions.likedComment.action"
            defaultMessage="liked {userDisplayName}'s comment about"
            values={{ userDisplayName }}
          />
        }
        onWhat={{
          name: info.name,
          url: `${info.path}/#comment_${commentUUID?.substring(0, 6)}`,
        }}
      />
    );
  }

  const info = getActivityTypeInfo(activity)[objectType] || {};
  return (
    <FeedItem
      didWhat={
        <FormattedMessage
          id="activityFeed.actions.rating.action"
          defaultMessage="rated {label}"
          values={{ label: info.label }}
        />
      }
      onWhat={{
        name: info.name,
        url: info.path,
      }}
      moreInfo={
        <FormattedMessage id="activityFeed.actions.rating.moreInfo" defaultMessage="a {rating}" values={{ rating }} />
      }
    />
  );
};
