import React from 'react';
import { NavLink } from 'react-router-dom';
import { JoinButton } from 'src/client/components/buttons/JoinButton';
import styled from 'styled-components/macro';
import { useSelector } from '../../../redux/modules/helpers/useSelector';
import { logoUrl } from 'src/client/helpers/urls/logoUrl';

const Div = styled.div<{
  bottomMargin?: number;
}>`
  background: #000;
  padding-top: 62px;
  margin-bottom: ${(props) => props.bottomMargin};

  & .alternate-header {
    border-style: none;
    min-height: 64px;
    background: #000;
    top: 0;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;

    @media (max-width: 767px) {
      padding: 0 24px;
    }
  }

  & .logo {
    display: inline-block;
    vertical-align: middle;
    line-height: 60px;

    img {
      max-width: 205px;

      @media (max-width: 479px) {
        max-width: 110px;
        max-height: 45px;
        top: 7px;
      }
    }
  }

  & .offer-text {
    font-size: 1.15rem;
    height: 64px;
    text-align: right;

    div {
      span.sale-text {
        color: #fff;
        font-weight: bold;
      }

      :first-child {
        color: #aaa;
        border-right-color: ${(props) => props.theme.primaryBtn1Bg};
        border-right-width: 3px;
        border-right-style: solid;
        margin-left: 0;
        margin-right: 20px;
        padding-right: 20px;

        @media (max-width: 767px) {
          display: none;
        }
      }

      display: table-cell;
      height: 64px;
      padding-left: 20px;
      vertical-align: middle;

      a {
        color: #fff;
      }
    }
  }
`;

export default function ESHeaderBarComponent(props: {
  // eslint-disable-next-line react/require-default-props
  className?: string;
}) {
  const { className = '' } = props;
  const site = useSelector((state) => state.site);
  const {
    config: {
      esUpdates: { headerBar },
    },
    cdnUrl,
  } = useSelector((state) => state.app);

  return (
    <Div className={className} {...props}>
      <div className="alternate-header navbar navbar-default navbar-fixed-top">
        <div className="container no-pad">
          <NavLink to="/" className="logo">
            <img src={logoUrl(cdnUrl, site)} alt={site.name} />
          </NavLink>
          <div className="pull-right offer-text">
            <div>
              {headerBar.tagline}
              <br />
              <span className="sale-text">{headerBar.saleText}</span>
            </div>
            <div>
              <JoinButton campaign="es-header-bar">ENTRAR</JoinButton>
            </div>
          </div>
        </div>
      </div>
    </Div>
  );
}
