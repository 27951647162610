import React from 'react';
import styled from 'styled-components/macro';
import { ViewAllCamsCard } from '../cards/ViewAllCamsCard';
import { LiveGirlsContainer } from './LiveGirlsContainer';
import { LiveGirlsPopoverCamCard } from 'src/client/components/cards/CameraThumbnailCard';
import { CamTrackingPosition } from 'src/client/constants/camTracking';
import { CamsResponse } from 'src/@types/Cam';

const CamRowWrapper = styled.div`
  display: flex;
  gap: var(--gap);
`;

const SeparatorLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #979797;
`;

type Props = {
  camTrackingPosition: CamTrackingPosition;
  cams: CamsResponse[];
};

export const LiveGirlsContentTwoProvider = ({ camTrackingPosition, cams: [provider, secondProvider] }: Props) => {
  const popoverCams = provider.popoverCams;
  const secondPopoverCams = secondProvider.popoverCams;

  if (!popoverCams || !secondPopoverCams) return null;

  return (
    <LiveGirlsContainer>
      <CamRowWrapper>
        <ViewAllCamsCard camTrackingPosition={camTrackingPosition} provider={provider} />
        {popoverCams.slice(0, 4).map((cam) => (
          <LiveGirlsPopoverCamCard
            camTrackingPosition={camTrackingPosition}
            cam={cam}
            eventContentType="livecam"
            key={cam.id}
            provider={provider.provider}
          />
        ))}
      </CamRowWrapper>
      <SeparatorLine />
      <CamRowWrapper>
        <ViewAllCamsCard camTrackingPosition={camTrackingPosition} provider={secondProvider} />
        {secondPopoverCams.slice(0, 4).map((cam) => (
          <LiveGirlsPopoverCamCard
            camTrackingPosition={camTrackingPosition}
            cam={cam}
            eventContentType="livecam"
            key={cam.id}
            provider={secondProvider.provider}
          />
        ))}
      </CamRowWrapper>
    </LiveGirlsContainer>
  );
};
