import { TABLET_LANDSCAPE } from '@tovia/man-ui';
import React from 'react';
import styled from 'styled-components/macro';

type Props = {
  discountRibbonColor?: string;
  discountRibbonText?: string;
  ribbonTextColor?: string;
  showDiscountRibbon?: boolean;
};

export const DiscountRibbon = (props: Props) => {
  const { discountRibbonText } = props;
  if (!props.showDiscountRibbon || !discountRibbonText) return null;

  return (
    <Ribbon ribbonTextColor={props.ribbonTextColor} discountRibbonColor={props.discountRibbonColor}>
      {discountRibbonText}
    </Ribbon>
  );
};

const DEFAULT_BLUE = '#5D83D9';
const DEFAULT_WHITE = '#FFFFFF';

const Ribbon = styled.div<{ discountRibbonColor?: string; ribbonTextColor?: string }>`
  height: 20px;
  min-width: 70px;
  width: min-content;
  color: ${(props) => props.ribbonTextColor || DEFAULT_WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.discountRibbonColor || DEFAULT_BLUE};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: auto;
  white-space: nowrap;
  padding: 0 8px;

  @media ${TABLET_LANDSCAPE} {
    position: absolute;
    // 10px margin around the image
    left: 10px;
    // this includes the margin
    top: 101px;
    height: 30px;
    min-width: 100px;
    font-size: 18px;
  }
`;
