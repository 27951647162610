import { useQuery } from '@tanstack/react-query';
import { ResponseError } from 'superagent';
import { ApiClient } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { User } from 'src/@types/app';
import { EmblemState } from 'src/@types/Emblem';

const emblemTokenFetcher = (): Promise<EmblemState> =>
  new ApiClient().get(constructUrl(urls.get.emblem.getState)).then((res) => <EmblemState>res.body);

export const useEmblemToken = (user?: User) =>
  useQuery<EmblemState, ResponseError>({
    queryKey: ['emblemToken', user?.UUID],
    queryFn: emblemTokenFetcher,
    enabled: !!user?.accountNeedsAgeVerification,
    refetchOnWindowFocus: false,
  });
