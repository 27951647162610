import React, { ReactNode } from 'react';
import { MOBILE_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
import styled from 'styled-components/macro';
import { StandardGrid } from './StandardGrid';

type Props = {
  show?: boolean;
  content: ReactNode;
};

export const SplashMediaGrid = (props: Props) => {
  if (!props.show) return null;
  return <MediaGridWrapper as="div">{props.content}</MediaGridWrapper>;
};

const MediaGridWrapper = styled(StandardGrid)`
  padding: 0 15px;

  .update-stream-card {
    background-color: #171717 !important;
  }

  /* necessary to override the styles from the component library :( */
  &&& {
    .card-information-badges {
      padding: 2px 10px;
      position: static;
    }

    .badges {
      justify-content: space-between;

      @media ${MOBILE_LANDSCAPE} {
        justify-content: flex-start;
        gap: 10px;
      }
    }

    .badge {
      margin: 0;

      .text {
        font-size: 0.7rem;

        @media ${TABLET_PORTRAIT} {
          font-size: inherit;
        }
      }
    }
  }
`;
