import { CustomSuperAgentClient } from 'src/@types/superagent';
import { call, put } from 'redux-saga/effects';
import { ApiClient } from './ApiClient';

export { ApiClient };

let apiSaga: ApiClient;

export function* doRequest<T>(types: string[], apiFn: (client: CustomSuperAgentClient) => Promise<T>, props: object) {
  if (!apiSaga) {
    apiSaga = new ApiClient();
  }

  let REQ_LOAD = '';
  let REQ_SUCCESS = '';
  let REQ_FAILURE = '';

  if (types) {
    [REQ_LOAD, REQ_SUCCESS, REQ_FAILURE] = types;
    yield put({ type: REQ_LOAD, ...props });
  }

  try {
    const { body } = yield call(apiFn, apiSaga);
    if (REQ_SUCCESS) {
      return yield put({ type: REQ_SUCCESS, ...props, result: body as T });
    }
  } catch (error) {
    if (REQ_FAILURE) {
      return yield put({ type: REQ_FAILURE, ...props, error });
    }
  }
  return false;
}

export const getRequestFunc = <ReturnType, PropsType extends object>(
  types: [string, string, string],
  apiFn: (client: CustomSuperAgentClient) => Promise<ReturnType>,
  props: PropsType = {} as PropsType,
) => doRequest.bind(null, types, apiFn, props);
