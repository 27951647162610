import { take, call, fork, select, put } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { RESET_DURATION } from './helpers/config';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';

const LOAD = 'man-site/updates/LOAD';
const LOAD_SAGA = 'man-site/updates/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/updates/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/updates/LOAD_FAIL';
const LOAD_MORE = 'man-site/updates/LOAD_MORE';
const LOAD_MORE_SAGA = 'man-site/updates/LOAD_MORE_SAGA';
const LOAD_MORE_SUCCESS = 'man-site/updates/LOAD_MORE_SUCCESS';
const LOAD_MORE_FAIL = 'man-site/updates/LOAD_MORE_FAIL';
const LOAD_BANNER = 'man-site/updates/LOAD_BANNER';
const LOAD_BANNER_SAGA = 'man-site/updates/LOAD_BANNER_SAGA';
const LOAD_BANNER_SUCCESS = 'man-site/updates/LOAD_BANNER_SUCCESS';
const LOAD_BANNER_FAIL = 'man-site/updates/LOAD_BANNER_FAIL';
const SET_TITLE = 'man-site/updates/SET_TITLE';

const endpoint = constructUrl(urls.get.updates);

const modelPropTypes = PropTypes.shape({
  UUID: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
});

export const galleryPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  publishedAt: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['GALLERY', 'MOVIE']).isRequired,
  views: PropTypes.number.isRequired,
  ratingAverage: PropTypes.number.isRequired,
  models: PropTypes.arrayOf(modelPropTypes).isRequired,
  UUID: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  coverCleanImagePath: PropTypes.string.isRequired,
  favoriteCount: PropTypes.number.isRequired,
  imageCount: PropTypes.number.isRequired,
  runtime: PropTypes.number.isRequired,
});

export const galleriesPropTypes = PropTypes.arrayOf(galleryPropTypes);

const initialState = {
  loading: false,
  loaded: false,
  loadingMore: false,
  galleries: [],
  total: 0,
  galleriesSinceLastVisit: 0,
  banners: [],
  bannersTotal: 0,
  bannersError: false,
  bannersLoaded: false,
  loadingBanners: false,
  lastCallParams: {},
  lastBannerCallParams: {},
  error: false,
  title: 'Updates',
  timestamp: new Date().getTime(),
  pageSize: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      const { params } = action;
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        lastCallParams: params,
      };
    }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        galleries: action.result.galleries,
        total: action.result.total,
        galleriesSinceLastVisit: action.result.galleriesSinceLastVisit,
        pageSize: action.result.pageSize,
        timestamp: new Date().getTime(),
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case LOAD_MORE: {
      const { params } = action;
      return {
        ...state,
        loadingMore: true,
        lastCallParams: params,
      };
    }
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        galleries: [...state.galleries, ...action.result.galleries],
      };
    case LOAD_MORE_FAIL:
      return {
        ...state,
        loadingMore: false,
      };
    case LOAD_BANNER:
      return {
        ...state,
        loadingBanners: true,
        bannersError: false,
        bannersLoaded: false,
        lastBannerCallParams: action.params,
      };
    case LOAD_BANNER_SUCCESS:
      return {
        ...state,
        loadingBanners: false,
        banners: action.result.slideshow,
        bannersTotal: action.result.total,
        bannersLoaded: true,
      };
    case LOAD_BANNER_FAIL:
      return {
        ...state,
        loadingBanners: false,
        bannersError: action.error,
        bannersLoaded: true,
      };
    case SET_TITLE:
      return {
        ...state,
        title: action.title,
      };
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams, params) {
  return isEqual(lastCallParams, params);
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function loadMore(params) {
  return {
    type: LOAD_MORE_SAGA,
    params,
  };
}

export function loadBanner(params) {
  return {
    type: LOAD_BANNER_SAGA,
    params,
  };
}

export function setTitle(title) {
  return {
    type: SET_TITLE,
    title,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => state;
  const currentState = (yield select(getState)).updates;
  const { lastCallParams } = currentState;
  const requestParams = { ...params };
  const isOld = new Date().getTime() - currentState.timestamp > RESET_DURATION;

  if (
    ((!isLoaded(lastCallParams, params) || isOld) && !currentState.loading) ||
    (!currentState.loaded && !currentState.loading && !!currentState.error)
  ) {
    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client) =>
        client.get(endpoint, {
          params: requestParams,
        }),
      {
        params,
      },
    );
    yield call(loadFunc);
  }

  // Only load favorites/ratings for archive tab view
  if (params.tab === 'archive') {
    yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);
    const newState = yield select(getState);
    if (newState.auth.user && newState.updates.galleries.length > 0) {
      const objectUUIDs = newState.updates.galleries.map((item) => item.UUID);
      yield put(
        loadRatingInfo({
          objectUUIDs,
        }),
      );
      yield put(
        loadFavoriteInfo({
          objectUUIDs,
        }),
      );
    }
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

function* loadMoreGenerator(params) {
  const getState = (state) => state.updates;
  const currentState = yield select(getState);
  const { lastCallParams } = currentState;

  const requestParams = { ...params };

  if (!isLoaded(lastCallParams, params) && !currentState.loading) {
    const loadFunc = getRequestFunc(
      [LOAD_MORE, LOAD_MORE_SUCCESS, LOAD_MORE_FAIL],
      (client) =>
        client.get(endpoint, {
          params: requestParams,
        }),
      {
        params,
      },
    );
    yield call(loadFunc);
  }
}

// Trigger
function* watchLoadMore() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_MORE_SAGA);
    yield fork(loadMoreGenerator, params);
  }
}

function* loadBannerGenerator(params) {
  const getState = (state) => state.updates;
  const currentState = yield select(getState);
  const { lastBannerCallParams } = currentState;

  if (
    (!isLoaded(lastBannerCallParams, params) && !currentState.loadingBanners) ||
    (!currentState.bannersLoaded && !currentState.loadingBanners)
  ) {
    const loadFunc = getRequestFunc(
      [LOAD_BANNER, LOAD_BANNER_SUCCESS, LOAD_BANNER_FAIL],
      (client) =>
        client.get(constructUrl(urls.get.slideshow), {
          params,
        }),
      {
        params,
      },
    );
    yield call(loadFunc);
  }
}

function* watchLoadBanner() {
  while (true) {
    const { params } = yield take(LOAD_BANNER_SAGA);
    yield fork(loadBannerGenerator, params);
  }
}

export const watchers = [fork(watchLoad), fork(watchLoadMore), fork(watchLoadBanner)];
/* EOF SAGAS */
