import React from 'react';
import PropTypes from 'prop-types';
import RSSLider from 'better-react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Button } from '@tovia/man-ui/lib/components/Button/Button';

const Div = styled.div`
  & .slider {
    h3.title {
      font-weight: 300;
      margin-top: 0;
    }
  }

  & .previous {
    right: 30px;
  }

  & .next {
    right: 0;
  }

  & .slick-next,
  & .slick-prev {
    background-image: none;
    background-color: ${(props) => props.theme.primary6};

    :hover {
      background-image: none;
      background-color: ${(props) => props.theme.primary3};
    }
  }

  .slick-disabled {
    background-color: ${(props) => props.theme.primary3};
    cursor: default;
  }
  & .header {
    display: inline-block;
    width: calc(100% - 58px); // accomodate for navigation buttons
  }

  & .title {
    font-weight: 100;
    margin-top: 0;
    min-height: 28px;
    line-height: 28px;
    display: inline-block;
  }

  & .btn {
    line-height: 28px;
    padding: 0;
    top: 0;
    margin: 0 10px;
    display: inline-block;
    height: 28px;
    cursor: pointer;
  }

  & .btn:hover {
    text-decoration: underline;
  }

  & .btn:focus,
  & .btn:active {
    outline: none;
    box-shadow: none;
  }

  & .navigation-button {
    border: 0 none;
    border-radius: 4px;
    height: 24px;
    top: -36px;
    width: 24px;
    z-index: 1;
  }
`;

function NavigationButton(props) {
  const { direction, className, onClick } = props;
  return (
    <Button
      className={`navigation-button ${className} ${direction === 'left' ? 'previous' : 'next'}`}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={direction === 'left' ? faAngleLeft : faAngleRight} />
    </Button>
  );
}

NavigationButton.propTypes = {
  direction: PropTypes.oneOf(['right', 'left']).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

NavigationButton.defaultProps = {
  className: '',
  onClick: () => {},
};

export default function Slider(props) {
  const { title, buttons, children, className } = props;

  const settings = {
    prevArrow: <NavigationButton direction="left" />, // () => <Button className="previous"><FontAwesomeIcon icon={['fas', 'angle-left']} /></Button>,
    nextArrow: <NavigationButton direction="right" />,
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    ...(props.settings || {}),
  };

  const renderButtons = () =>
    buttons.map((button) =>
      React.isValidElement(button) ? (
        button
      ) : (
        <Link key={button.link} to={button.link} className="pull-right btn clickable">
          {button.label}
        </Link>
      ),
    );

  const renderTitle = () => (typeof title === 'string' ? <h3 className="title">{title}</h3> : title);
  const renderHeader = title && buttons;

  return (
    <Div className={`row container ${className}`}>
      <div className="slider">
        {renderHeader && (
          <div className="header">
            {title && renderTitle()}
            {buttons && renderButtons()}
          </div>
        )}
        <RSSLider {...settings}>{children}</RSSLider>
      </div>
    </Div>
  );
}

Slider.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttons: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.shape({
        link: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
  ),
  children: PropTypes.node.isRequired,
  settings: PropTypes.object,
  className: PropTypes.string,
  target: PropTypes.string,
};

Slider.defaultProps = {
  title: '',
  buttons: [],
  className: '',
  settings: {},
  target: '',
};
