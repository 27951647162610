import { useEffect } from 'react';
import { useLocation } from 'react-router';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ScrollTop({ children }: { children: any }) {
  const { pathname, state: restoreScroll } = useLocation();
  useEffect(() => {
    if (!restoreScroll) {
      window.scrollTo(0, 0);
    }
  }, [pathname, restoreScroll]);

  return children;
}
