import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { ReactNode } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { MobileMenuIcon } from '../styles';

type Props = {
  className?: string;
  exact?: boolean;
  handleClick?: () => void;
  href?: string;
  icon?: IconProp;
  label: ReactNode;
  openNewWindow?: boolean;
  to?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routes?: any[];
  extraContent?: ReactNode;
};

export const MobileLink = (props: Props) => {
  const linkContent = (
    <>
      {props.icon && <MobileMenuIcon fixedWidth icon={props.icon} aria-hidden="true" />}
      <span>{props.label}</span>
      {props.extraContent}
    </>
  );

  const classes = cx('mobile-link', props.className);

  if (props.href) {
    return (
      <a
        href={props.href}
        target={props.openNewWindow ? '_blank' : undefined}
        rel={props.openNewWindow ? 'noopener noreferrer' : undefined}
        className={classes}
      >
        {linkContent}
      </a>
    );
  }

  if (props.to) {
    return (
      <NavLink
        exact={props.exact}
        to={props.to}
        onClick={() => props.handleClick?.()}
        className={classes}
        activeClassName="active"
      >
        {linkContent}
      </NavLink>
    );
  }

  return null;
};
