import { call, fork, select, put, takeEvery } from 'redux-saga/effects';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';
import { SubscriptionPreviewResponse } from 'src/@types/app';
import { RootState } from 'src/client/redux/modules/reducer';
import { ResponseError } from 'superagent';

const ActionType = {
  FREE_GALLERIES_MOVIES_SIGNUP: 'man-site/marketing/FREE_GALLERIES_MOVIES_SIGNUP',
  FREE_GALLERIES_MOVIES_SIGNUP_SAGA: 'man-site/marketing/FREE_GALLERIES_MOVIES_SIGNUP_SAGA',
  FREE_GALLERIES_MOVIES_SIGNUP_SUCCESS: 'man-site/marketing/FREE_GALLERIES_MOVIES_SIGNUP_SUCCESS',
  FREE_GALLERIES_MOVIES_SIGNUP_FAIL: 'man-site/marketing/FREE_GALLERIES_MOVIES_SIGNUP_FAIL',
  SUBSCRIPTION_PREVIEW: 'man-site/marketing/SUBSCRIPTION_PREVIEW_SIGNUP',
  SUBSCRIPTION_PREVIEW_SAGA: 'man-site/marketing/SUBSCRIPTION_PREVIEW_SAGA',
  SUBSCRIPTION_PREVIEW_SUCCESS: 'man-site/marketing/SUBSCRIPTION_PREVIEW_SUCCESS',
  SUBSCRIPTION_PREVIEW_FAIL: 'man-site/marketing/SUBSCRIPTION_PREVIEW_FAIL',
  FREE_TRIAL_SIGNUP: 'man-site/marketing/FREE_TRIAL_SIGNUP',
  FREE_TRIAL_SIGNUP_SAGA: 'man-site/marketing/FREE_TRIAL_SIGNUP_SAGA',
  FREE_TRIAL_SIGNUP_SUCCESS: 'man-site/marketing/FREE_TRIAL_SIGNUP_SUCCESS',
  FREE_TRIAL_SIGNUP_FAIL: 'man-site/marketing/FREE_TRIAL_SIGNUP_FAIL',
  RESET: 'man-site/marketing/RESET',
  LOAD_UPGRADABLE_SITES_SAGA: 'man-site/marketing/LOAD_UPGRADABLE_SITES_SAGA',
  LOAD_UPGRADABLE_SITES_REQUEST: 'man-site/marketing/LOAD_UPGRADABLE_SITES_REQUEST',
  LOAD_UPGRADABLE_SITES_SUCCESS: 'man-site/marketing/LOAD_UPGRADABLE_SITES_SUCCESS',
  LOAD_UPGRADABLE_SITES_FAIL: 'man-site/marketing/LOAD_UPGRADABLE_SITES_FAIL',
  LOAD_TRUSTED_PARTNER_SITES_SAGA: 'man-site/marketing/LOAD_TRUSTED_PARTNER_SITES_SAGA',
  LOAD_TRUSTED_PARTNER_SITES_SUCCESS: 'man-site/marketing/LOAD_TRUSTED_PARTNER_SITES_SUCCESS',
  LOAD_TRUSTED_PARTNER_SITES_FAIL: 'man-site/marketing/LOAD_TRUSTED_PARTNER_SITES_FAIL',
  LOAD_TRUSTED_PARTNER_SITES_REQUEST: 'man-site/marketing/LOAD_TRUSTED_PARTNER_SITES_REQUEST',
} as const;

const endpoints = {
  freeGalleriesMovies: constructUrl(urls.post.signup.freeGalleriesMovies),
  freeTrial: constructUrl(urls.post.signup.freeTrial),
  subscriptionPreview: constructUrl(urls.get.subscriptionPreview),
  upgradableSites: constructUrl(urls.get.upgradableSites),
};

type UpgradableSite = {
  abbreviation: string;
  siteUUID: string;
  url: string;
};

export type TrustedPartnerSite = {
  siteUUID: string;
  name: string;
  prefix: string;
  price: number;
  discountPrice: number;
  percentSaved: number;
  tagline: string;
  description: string;
  URL: string;
  assetType: string;
};

type FetchError = ResponseError | null;

type ActionSubscriptionPreviewSuccess = {
  type: typeof ActionType.SUBSCRIPTION_PREVIEW_SUCCESS;
  result: SubscriptionPreviewResponse;
};

type ActionFreeTrialSignupSuccess = {
  type: typeof ActionType.FREE_TRIAL_SIGNUP_SUCCESS;
  email: string;
};

type ActionLoadTrustedPartnerSitesSuccess = {
  type: typeof ActionType.LOAD_TRUSTED_PARTNER_SITES_SUCCESS;
  result: {
    items: TrustedPartnerSite[];
  };
};

type ActionLoadUpgradableSitesSuccess = {
  type: typeof ActionType.LOAD_UPGRADABLE_SITES_SUCCESS;
  result: UpgradableSite[];
};

type EveryActionTypesExcludeCustomOnes = Exclude<
  typeof ActionType[keyof typeof ActionType],
  | ActionSubscriptionPreviewSuccess['type']
  | ActionFreeTrialSignupSuccess['type']
  | ActionLoadTrustedPartnerSitesSuccess['type']
  | ActionLoadUpgradableSitesSuccess['type']
>;

type Action =
  | {
      type: EveryActionTypesExcludeCustomOnes;
      error?: FetchError;
    }
  | ActionSubscriptionPreviewSuccess
  | ActionFreeTrialSignupSuccess
  | ActionLoadTrustedPartnerSitesSuccess
  | ActionLoadUpgradableSitesSuccess;

type State = {
  freeGalleriesMoviesStatus: 'initial' | 'loading' | 'success' | 'fail';
  freeGalleriesMoviesError?: FetchError;
  freeTrialSuccess: boolean;
  subscriptionPreviewError?: FetchError;
  subscriptionPreviewLoading: boolean;
  subscriptionPreview: SubscriptionPreviewResponse | null;
  loading: boolean;
  upgradableSites: UpgradableSite[];
  upgradableSitesError?: FetchError;
  UpgradableSitesLoading: boolean;
  upgradableSitesLoaded: boolean;
  trustedPartnerSitesLoading: boolean;
  trustedPartnerSites: TrustedPartnerSite[];
  trustedPartnerSitesError?: FetchError;
  error?: FetchError;
  email?: string;
};

const initialState: State = {
  freeGalleriesMoviesStatus: 'initial',
  freeTrialSuccess: false,
  subscriptionPreviewLoading: false,
  subscriptionPreview: null,
  loading: false,
  upgradableSites: [],
  UpgradableSitesLoading: false,
  upgradableSitesLoaded: false,
  trustedPartnerSitesLoading: false,
  trustedPartnerSites: [],
};

export default function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case ActionType.FREE_GALLERIES_MOVIES_SIGNUP:
      return {
        ...state,
        freeGalleriesMoviesStatus: 'loading',
        freeGalleriesMoviesError: null,
      };
    case ActionType.FREE_GALLERIES_MOVIES_SIGNUP_SUCCESS:
      return {
        ...state,
        freeGalleriesMoviesStatus: 'success',
      };
    case ActionType.FREE_GALLERIES_MOVIES_SIGNUP_FAIL:
      return {
        ...state,
        freeGalleriesMoviesStatus: 'fail',
        freeGalleriesMoviesError: action.error,
      };
    case ActionType.FREE_TRIAL_SIGNUP:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ActionType.FREE_TRIAL_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        freeTrialSuccess: true,
        email: action.email,
      };
    case ActionType.FREE_TRIAL_SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ActionType.RESET:
      return {
        ...initialState,
      };
    case ActionType.LOAD_UPGRADABLE_SITES_REQUEST:
      return {
        ...state,
        UpgradableSitesLoading: true,
        upgradableSites: [],
      };
    case ActionType.LOAD_UPGRADABLE_SITES_SUCCESS:
      return {
        ...state,
        upgradableSitesLoaded: true,
        UpgradableSitesLoading: false,
        upgradableSites: action.result,
      };
    case ActionType.LOAD_UPGRADABLE_SITES_FAIL:
      return {
        ...state,
        upgradableSitesLoaded: true,
        UpgradableSitesLoading: false,
        upgradableSitesError: action.error,
      };
    case ActionType.LOAD_TRUSTED_PARTNER_SITES_REQUEST:
      return {
        ...state,
        trustedPartnerSitesLoading: true,
        trustedPartnerSites: [],
      };
    case ActionType.LOAD_TRUSTED_PARTNER_SITES_SUCCESS:
      return {
        ...state,
        trustedPartnerSitesLoading: false,
        trustedPartnerSites: action.result.items,
      };
    case ActionType.LOAD_TRUSTED_PARTNER_SITES_FAIL:
      return {
        ...state,
        trustedPartnerSitesLoading: false,
        trustedPartnerSitesError: action.error,
      };
    case ActionType.SUBSCRIPTION_PREVIEW:
      return {
        ...state,
        subscriptionPreviewError: null,
        subscriptionPreviewLoading: true,
      };
    case ActionType.SUBSCRIPTION_PREVIEW_SUCCESS:
      return {
        ...state,
        subscriptionPreview: action.result,
        subscriptionPreviewLoading: false,
      };
    case ActionType.SUBSCRIPTION_PREVIEW_FAIL:
      return {
        ...state,
        subscriptionPreviewError: action.error,
        subscriptionPreviewLoading: false,
      };
    default: {
      return state;
    }
  }
}

export const freeGalleriesMoviesSignup = (params: { email: string; optin: boolean }) => ({
  type: ActionType.FREE_GALLERIES_MOVIES_SIGNUP_SAGA,
  params,
});

export const freeTrialSignup = (params: { email: string }) => ({
  type: ActionType.FREE_TRIAL_SIGNUP_SAGA,
  params,
});

export const reset = () => ({
  type: ActionType.RESET,
});

export const loadUpgradableSites = () => ({
  type: ActionType.LOAD_UPGRADABLE_SITES_SAGA,
});

export const loadSubscriptionPreview = (params: { token: string }) => ({
  type: ActionType.SUBSCRIPTION_PREVIEW_SAGA,
  params,
});

export const loadTrustedPartnerSites = () => ({
  type: ActionType.LOAD_TRUSTED_PARTNER_SITES_SAGA,
});

/* SAGAS */
function* submitFreeGalleriesMoviesSignup({
  params: data,
}: {
  type: string;
  params: { email: string; optin: boolean };
}) {
  const loadFunc = getRequestFunc(
    [
      ActionType.FREE_GALLERIES_MOVIES_SIGNUP,
      ActionType.FREE_GALLERIES_MOVIES_SIGNUP_SUCCESS,
      ActionType.FREE_GALLERIES_MOVIES_SIGNUP_FAIL,
    ],
    (client) =>
      client.post(endpoints.freeGalleriesMovies, {
        data,
      }),
    {
      ...data,
    },
  );
  yield call(loadFunc);
}

function* submitFreeTrialSignup({ params: data }: { type: string; params: { email: string } }) {
  window.dataLayer.push({
    event: 'gaEvent',
    eventCategory: 'free-trial',
    eventLabel: 'dialog',
    eventAction: 'email-collect-submit',
  });
  const loadFunc = getRequestFunc(
    [ActionType.FREE_TRIAL_SIGNUP, ActionType.FREE_TRIAL_SIGNUP_SUCCESS, ActionType.FREE_TRIAL_SIGNUP_FAIL],
    (client) =>
      client.post(endpoints.freeTrial, {
        data,
      }),
    {
      ...data,
    },
  );
  const { result } = yield call(loadFunc);
  if (result) {
    const { freeTrialURL, campaignId } = result;

    if (freeTrialURL) {
      if (campaignId) {
        window._vis_opt_queue = window._vis_opt_queue || [];
        window._vis_opt_queue.push(function () {
          // @ts-ignore
          _vis_opt_register_conversion(1, campaignId);
        });
      }

      window.dataLayer.push({
        event: 'gaEvent',
        eventCategory: 'free-trial',
        eventLabel: 'dialog',
        eventAction: 'email-collect-success',
      });
      window.location.assign(freeTrialURL);

      return true;
    }
  }

  // TODO: add error to UI when it's failed
  window.dataLayer.push({
    event: 'gaEvent',
    eventCategory: 'free-trial',
    eventLabel: 'dialog',
    eventAction: 'email-collect-error',
  });

  return false;
}

function* loadTrustedPartnerSitesGenerator() {
  const url = constructUrl(urls.get.trustedPartnerSites);
  const loadFunc = getRequestFunc(
    [
      ActionType.LOAD_TRUSTED_PARTNER_SITES_REQUEST,
      ActionType.LOAD_TRUSTED_PARTNER_SITES_SUCCESS,
      ActionType.LOAD_TRUSTED_PARTNER_SITES_FAIL,
    ],
    (client) => client.get(url),
  );
  yield call(loadFunc);
}

function* loadUpgradableSitesGenerator() {
  const loadFunc = getRequestFunc(
    [
      ActionType.LOAD_UPGRADABLE_SITES_REQUEST,
      ActionType.LOAD_UPGRADABLE_SITES_SUCCESS,
      ActionType.LOAD_UPGRADABLE_SITES_FAIL,
    ],
    (client) => client.get(endpoints.upgradableSites),
  );
  yield call(loadFunc);
}

function* subscriptionPreviewGenerator({ params: { token } }: { type: string; params: { token: string } }) {
  const currentState: RootState['marketing'] = yield select((state: RootState) => state.marketing);
  const { subscriptionPreview, subscriptionPreviewLoading, subscriptionPreviewError } = currentState;

  const objectUUIDs: string[] = [];

  if (!subscriptionPreviewLoading && !subscriptionPreview && !subscriptionPreviewError) {
    const loadFunc = getRequestFunc(
      [ActionType.SUBSCRIPTION_PREVIEW, ActionType.SUBSCRIPTION_PREVIEW_SUCCESS, ActionType.SUBSCRIPTION_PREVIEW_FAIL],
      (client) => client.get(endpoints.subscriptionPreview.replace(':token', token)),
    );

    const { result } = yield call(loadFunc);

    yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

    const userState: RootState['auth'] = yield select((state: RootState) => state.auth);
    if (userState.user) {
      result.featuredGalleries.forEach(({ UUID }) => objectUUIDs.push(UUID));
    }
  }

  if (objectUUIDs.length > 0) {
    yield put(
      loadRatingInfo({
        objectUUIDs,
      }),
    );
    yield put(
      loadFavoriteInfo({
        objectUUIDs,
      }),
    );
  }
}

// Trigger
function* watchFreeGalleriesMoviesSignup() {
  yield takeEvery(ActionType.FREE_GALLERIES_MOVIES_SIGNUP_SAGA, submitFreeGalleriesMoviesSignup);
}

function* watchFreeTrialSignup() {
  yield takeEvery(ActionType.FREE_TRIAL_SIGNUP_SAGA, submitFreeTrialSignup);
}

function* watchLoadUpgradableSites() {
  yield takeEvery(ActionType.LOAD_UPGRADABLE_SITES_SAGA, loadUpgradableSitesGenerator);
}

function* watchLoadTrustedPartnerSites() {
  yield takeEvery(ActionType.LOAD_TRUSTED_PARTNER_SITES_SAGA, loadTrustedPartnerSitesGenerator);
}

function* watchSubscriptionPreview() {
  yield takeEvery(ActionType.SUBSCRIPTION_PREVIEW_SAGA, subscriptionPreviewGenerator);
}

export const watchers = [
  fork(watchFreeGalleriesMoviesSignup),
  fork(watchFreeTrialSignup),
  fork(watchLoadUpgradableSites),
  fork(watchLoadTrustedPartnerSites),
  fork(watchSubscriptionPreview),
];
/* EOF SAGAS */
