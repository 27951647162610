import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import React from 'react';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';

const titles = {
  verify: 'Join Our Girls Today',
  vpn: 'Get IP Vanish',
};

// const subTexts = {
//   verify: 'It takes about a minute',
//   vpn: '30 day money-back guarantee, see partner site for details*',
// };

type Props = {
  type: 'verify' | 'vpn';
  url: string;
};

export const ButtonAndText = (props: Props) => {
  const target = props.type === 'verify' ? '_self' : '_blank';

  return (
    <Wrapper>
      <Button className={props.type} href={props.url} target={target}>
        {titles[props.type]}
        {props.type === 'verify' && <FontAwesomeIcon icon={faChevronRight} />}
      </Button>
      {/* <ButtonSubtext>{subTexts[props.type]} </ButtonSubtext> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-decoration: none;
  max-width: 240px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;

  height: 40px;
  font-size: 18px;

  @media ${TABLET_PORTRAIT} {
    height: 54px;
    font-size: 24px;
    max-width: 300px;
  }

  &.verify {
    background-color: #fbb91c;
    color: #000000;
  }

  &.vpn {
    background-color: #7ed420;
    color: #ffffff;
  }

  &.login {
    background-color: #333333;
    color: #dddddd;
    text-transform: uppercase;
  }

  svg {
    margin-left: 0.5em;
  }
`;

// const ButtonSubtext = styled.small`
//   color: #ffffff;
//   font-size: 12px;
//   margin: 10px auto 0;
//   display: block;
//   width: max-content;
// `;
