import React from 'react';
import styled from 'styled-components/macro';
import { JoinButton } from '../../buttons/JoinButton';

type Props = {
  title: string;
  campaign: string;
};

const SignupWrapper = styled.div`
  min-height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    min-height: 60px;
  }
  @media (max-width: 430px) {
    min-height: 30px;
  }
`;

const SignupButton = styled(JoinButton)`
  display: block;
  text-align: center;
  background: #f7bc74;
  box-shadow: 0 2px 10px #000;
  font-weight: 600;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-size: 28px;
  height: 80px;
  line-height: 80px;
  max-width: 480px;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 auto 70px;
  padding: 0 50px;
  :hover,
  :focus {
    text-decoration: none;
    color: #000;
  }
  @media (max-width: 1024px) {
    font-size: 18px;
    max-width: 340px;
    height: 60px;
    line-height: 60px;
    margin-bottom: 30px;
    box-shadow: 0 2px 5px #000;
  }
  @media (max-width: 768px) {
    padding: 0 30px;
    height: 48px;
    line-height: 48px;
  }
  @media (max-width: 430px) {
    font-size: 11px;
    max-width: 180px;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    margin-bottom: 20px;
  }
`;

export const SignupLink = (props: Props) => {
  return (
    <SignupWrapper>
      <SignupButton campaign={props.campaign}>{props.title}</SignupButton>
    </SignupWrapper>
  );
};
