import React from 'react';
import PropTypes from 'prop-types';
import ReactTagsInput from 'react-tagsinput';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';

const maxTags = 10;

export const ReactTagsWrapperClass = (props) => {
  const { className, inputClassName, tagClassName, removeClassName } = props;

  const intl = useIntl();

  const tagsAllowed = maxTags - (props.value.length || 0);
  const placeholder =
    tagsAllowed > 0
      ? intl.formatMessage(
          { id: 'tagForm.placeholder', defaultMessage: `Add up to ${tagsAllowed} separate tag(s)` },
          { count: tagsAllowed },
        )
      : '';

  return (
    <ReactTagsInput
      {...props}
      value={[].concat(props.value)}
      onlyUnique
      className={`wrapper  ${className}`}
      inputProps={{
        className: `input ${inputClassName}`,
        placeholder,
      }}
      tagProps={{
        className: `tag ${tagClassName}`,
        classNameRemove: `remove ${removeClassName}`,
      }}
      focusedClassName="focus"
      addOnBlur
      addKeys={[
        9, // Tab
        13, // Enter
        188, // Comma
      ]}
      maxTags={maxTags}
    />
  );
};

ReactTagsWrapperClass.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  tagClassName: PropTypes.string,
  removeClassName: PropTypes.string,
  theme: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

ReactTagsWrapperClass.defaultProps = {
  theme: {},
  className: '',
  inputClassName: '',
  tagClassName: '',
  removeClassName: '',
  value: [],
};

export default styled(ReactTagsWrapperClass)`
  overflow: hidden;
  border: 1px solid #ccc;
  padding: 2px;
  border-radius: 4px;
  background: #fff;
  transition: 0.3s border-color;
  cursor: text;

  & .tag {
    a {
      :hover,
      :focus {
        text-decoration: none;
      }

      ::before {
        content: ' ×';
      }
    }
  }

  & .remove {
    cursor: pointer;
    font-weight: bold;
    color: #fff;
  }

  & .input {
    background: transparent;
    border: 0;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 2px 5px;
    width: 220px;
    outline: 0;
  }

  &.focus {
    border-color: #888;
  }
`;
