import React from 'react';
import styled from 'styled-components/macro';
import { NavLink, Link } from 'react-router-dom';
import Slider from '@tovia/man-ui/lib/components/Slider/Slider';
import Button from '@tovia/man-ui/lib/components/Button/Button';
import Card from '@tovia/man-ui/lib/components/Card/Card';
import CardInformation from '@tovia/man-ui/lib/components/Card/CardInformation';
import CardMedia from 'src/client/components/ui/Card/CardMedia';
import { StyledIcon } from '../../ui/StyledIcon';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

const Wrap = styled.section`
  a {
    text-decoration: none;
  }
`;

const Div = styled.div`
  .card {
    background: none !important;

    img {
      min-height: 141px;
      object-fit: contain;
    }
  }
`;

const Header = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.splashPrimary4};
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 2.15rem;
  position: relative;
  float: left;

  @media (max-width: 639px) {
    font-size: 1.7rem;
    float: none;
    text-align: center;
  }
`;

const TextLink = styled(NavLink)``;

const IconLink = styled(NavLink)`
  border: 1px solid ${(props) => props.theme.splashPrimary5};
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  margin-left: 10px;
  color: ${(props) => props.theme.splashPrimary5};

  :hover {
    border-color: ${(props) => props.theme.splashPrimary3};
    color: ${(props) => props.theme.splashPrimary3};
  }
`;

const SliderCardWrap = styled.div`
  display: flex !important;
  justify-content: center !important; ;
`;

const SliderCard = styled(Card)`
  background: transparent;
  width: 195px;

  @media (max-width: 639px) {
    width: 225px;
  }
`;

const SliderCardInformation = styled(CardInformation)`
  font-size: 0.9rem;
  line-height: 1.2;
  display: block;
  margin-top: 6px;
  white-space: nowrap;
`;

const ButtonWrap = styled.div`
  text-align: center;
`;

const RoundButton = styled(Button)`
  display: inline-block;
  margin: 10px 0 30px;
  font-weight: 700;
  font-size: 1.07rem;
  padding: 8px 26px;
  border-radius: 28px;
  -webkit-transition: 0.3s opacity;
  transition: 0.3s opacity;
  opacity: 0.8;
  background: ${(props) => props.theme.splashPrimary1};
  color: ${(props) => props.theme.splashPrimary2};
  border: 2px solid ${(props) => props.theme.splashPrimary2};
  text-align: center;
  white-space: nowrap;
`;

const sliderSettings = {
  arrows: false,
  autoplay: false,
  slidesToScroll: 1,
  draggable: true,
  responsive: [
    {
      breakpoint: 515,
      settings: { slidesToShow: 1 },
    },
    {
      breakpoint: 740,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 986,
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: 1200,
      settings: { slidesToShow: 4 },
    },
    {
      breakpoint: 100000,
      settings: { slidesToShow: 5 },
    },
  ],
};

type Props = {
  title: string;
  items: Array<{
    UUID: string;
    imgSrc: string;
    name: string;
    url: string;
    timestamp: object;
    nameLink: {
      text: string;
      url: string;
    };
  }>;
  arrowLinkLabel: string;
  arrowLinkUrl: string;
  bottomLinkLabel: string;
  bottomLinkUrl: string;
};

export const CoverRow = (props: Props) => {
  const { title, arrowLinkLabel, arrowLinkUrl, bottomLinkLabel, bottomLinkUrl, items: coverRowItems } = props;

  const isBlurred = useShowSfwSite();

  const sliderCards = (
    <Slider settings={sliderSettings} className="text-center">
      {coverRowItems.map(({ imgSrc, name, url, nameLink: { text: linkText, url: linkUrl }, timestamp }) => (
        <SliderCardWrap key={name}>
          <SliderCard>
            <CardMedia altText={name} blurred={isBlurred} imgSrc={imgSrc} url={url} />
            <SliderCardInformation
              className="card-info-categories"
              title={
                <Link to={linkUrl} className="item-name">
                  {linkText}
                </Link>
              }
              subTitle={timestamp}
            />
          </SliderCard>
        </SliderCardWrap>
      ))}
    </Slider>
  );

  return (
    <Wrap>
      <Div className="container">
        <Header className="clearfix">
          <Title>{title}</Title>
          <Div className="pull-right">
            <TextLink className="va-m text-uppercase hidden-sm hidden-xs hidden-md" to={arrowLinkUrl}>
              {arrowLinkLabel}
            </TextLink>
            <IconLink to={arrowLinkUrl} className="hidden-xs va-m btn-slider-next">
              <StyledIcon icon={faChevronRight} />
            </IconLink>
          </Div>
        </Header>
        <Div className="list-group cover-list-group">{sliderCards}</Div>
        <ButtonWrap className="text-center">
          <RoundButton url={bottomLinkUrl}>{bottomLinkLabel}</RoundButton>
        </ButtonWrap>
      </Div>
    </Wrap>
  );
};
