// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.
const log = (...args) => {
  if (window.location.href.includes('www-local')) {
    if (args[0] === 'ERROR') {
      // console.log('[ServiceWorker]', ...(args.slice(1)));
    } else {
      // console.log('[ServiceWorker]', ...args);
    }
  }
};

export default function register(swUrl) {
  // Register the service worker
  if ('serviceWorker' in navigator && swUrl) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(swUrl).then(
        (registration) => {
          // eslint-disable-next-line no-param-reassign
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            log('update found', installingWorker);
            installingWorker.onstatechange = () => {
              const { state } = installingWorker;
              log('state change', state);
              if (state === 'installed') {
                log('installed', navigator.serviceWorker.controller);
              }
            };
          };
        },
        (error) => {
          log('ERROR', 'Error during service worker registration:', error);
        },
      );
    });
  }
}

export function unregisterServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(
      (registration) => {
        registration.unregister();
      },
      (error) => {
        log('ERROR', 'Error during service worker unregistration:', error);
      },
    );
  }
}
