import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faHeart, faStar } from '@fortawesome/pro-solid-svg-icons';
import { LiveGirlsContainer } from './LiveGirlsContainer';
import { CamTrackingPosition } from 'src/client/constants/camTracking';
import { LiveGirlsPopoverCamCard } from 'src/client/components/cards/CameraThumbnailCard';
import { useGenerateCamUrlWithParams } from 'src/client/helpers/urls/useGenerateCamUrlWithParams';
import { CamsResponse } from 'src/@types/Cam';

const LiveGirlsWrapper = styled(LiveGirlsContainer)<{
  showButtonGroup?: boolean;
}>`
  height: ${({ showButtonGroup }) => (showButtonGroup ? '540px' : '462px')};
  padding: 25px 30px 0 30px;
  flex-flow: row wrap;
  display: flex;
  gap: var(--gap);

  & > div {
    flex: 1 1 calc(20% - var(--gap));
  }
`;

const ButtonsWrapper = styled.div`
  margin: 20px 0 40px 0;
  text-align: center;
  flex-basis: 100%;
  width: 100%;
  align-items: center;
`;

const ButtonLink = styled.a`
  background-color: #ffffff;
  border: 2px solid rgb(206, 171, 88);
  border-radius: 6px;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin: 0 16px;
  padding: 10px 6px;
  width: 250px;
  cursor: pointer;

  :focus,
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  svg.icon {
    margin: 0 10px;
    width: 14px;

    &.gold {
      color: rgb(206, 171, 88);
    }

    &.green {
      color: #56c12b;
    }

    &.red {
      color: #ff0000;
    }
  }
`;

type Props = {
  camTrackingPosition: CamTrackingPosition;
  cam: CamsResponse;
};

export const LiveGirlsContentOneProvider = ({ camTrackingPosition, cam: { popoverCams, provider } }: Props) => {
  const intl = useIntl();
  const showButtonGroup = provider === 'streamate';

  const generateCamUrlWithParams = useGenerateCamUrlWithParams({ trackingPosition: camTrackingPosition, provider });

  if (!popoverCams) return null;

  const labels = [
    {
      label: intl.formatMessage({
        id: 'liveGirls.labels.all.modelsOnlineNow',
        defaultMessage: 'All Models Online Now',
      }),
      link: generateCamUrlWithParams('/cams-list'),
      icon: <FontAwesomeIcon icon={faCircle} className="icon green" />,
    },
    {
      label: intl.formatMessage({ id: 'liveGirls.labels.all.goldShow', defaultMessage: 'All Gold Show' }),
      link: generateCamUrlWithParams('/cams/category/gold'),
      icon: <FontAwesomeIcon icon={faStar} className="icon gold" />,
    },
    {
      label: intl.formatMessage({ id: 'liveGirls.labels.all.newModels', defaultMessage: 'All New Models' }),
      link: generateCamUrlWithParams('/cams/category/new'),
      icon: <FontAwesomeIcon icon={faHeart} className="icon red" />,
    },
  ];

  return (
    <LiveGirlsWrapper showButtonGroup={showButtonGroup}>
      {popoverCams.slice(0, 10).map((cam) => (
        <LiveGirlsPopoverCamCard
          camTrackingPosition={camTrackingPosition}
          cam={cam}
          eventContentType="livecam"
          key={cam.id}
          provider={provider}
        />
      ))}

      {showButtonGroup && (
        <ButtonsWrapper>
          {labels.map(({ icon, label, link }) => (
            <ButtonLink className="btn" href={link} target="_blank" rel="nofollow noopener noreferrer" key={link}>
              {icon}
              {label}
            </ButtonLink>
          ))}
        </ButtonsWrapper>
      )}
    </LiveGirlsWrapper>
  );
};
