import {
  faChevronCircleDown,
  faChevronCircleUp,
  faComments,
  faEye,
  faFilm,
  faGlobe,
  faImages,
  faTrophyAlt,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '@tovia/man-ui';
import React from 'react';
import { ModelResponse } from 'src/@types/app';
import { StyledIcon } from 'src/client/components/ui/StyledIcon';
import { modelHeadshotUrl } from 'src/client/helpers/urls';
import { useSelector } from 'src/client/redux';
import { formatViews } from 'src/client/utils/converters';
import { sanitizeLink } from 'src/shared/sanitize';
import FavoriteButton from '../../FavoriteButton/FavoriteButton';
import { scrollTo } from 'src/client/helpers';
import {
  BioLink,
  CoverImage,
  CoverImageWrapper,
  InfoPanelWrapper,
  ModelAge,
  ModelCountry,
  ModelInfo,
  ModelName,
  ModelRank,
  ModelSocial,
} from './styles';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

type Props = {
  className?: string;
  model: ModelResponse;
  onToggleBio: () => void;
  bioVisible: boolean;
};

export const ModelInfoPanel = (props: Props) => {
  const { model } = props;
  const { name, ranks, country, moviesCount, galleriesCount, UUID } = model;
  const { contentCdnUrl } = useSelector((state) => state.app);
  const {
    components: { modelDetailsPanel },
  } = useSelector((state) => state.app.config);
  const showSfwSite = useShowSfwSite();
  const { coverUrl, blurImage } = modelHeadshotUrl(contentCdnUrl, model, model.siteUUID, showSfwSite);
  const comments = useSelector((state) => state.comments.comments);

  const hasBioAttributes = !!((model.eyes && model.hair) || (model.height && model.weight));
  const countryName = country.name && country.name.toLowerCase() !== 'unknown' ? country.name : '';
  const rank = ranks.week || 'N/A';
  const totalComments = comments[UUID]?.commentsCount || 0;

  const hideRank = rank === 'N/A';

  const modelAge = model.publishAge >= 18 && !modelDetailsPanel.hideModelAge && (
    <span>Debut Age: {model.publishAge}</span>
  );

  const badges: React.ReactNode[] = [];
  if (modelDetailsPanel.showGalleryCounts) {
    if (moviesCount > 0) {
      badges.push(<Badge key="movies" text={moviesCount} icon={<FontAwesomeIcon icon={faFilm} />} />);
    }
    if (galleriesCount > 0) {
      badges.push(<Badge key="images" text={galleriesCount} icon={<FontAwesomeIcon icon={faImages} />} />);
    }
  }

  badges.push(<Badge key="views" text={formatViews(model.views)} icon={<FontAwesomeIcon icon={faEye} />} />);

  if (totalComments > 0) {
    badges.push(
      <Badge
        key="comments"
        text={formatViews(totalComments)}
        icon={<FontAwesomeIcon icon={faComments} />}
        onClick={() => scrollTo('comment-section')}
      />,
    );
  }

  return (
    <InfoPanelWrapper className={props.className}>
      <ModelName>{name}</ModelName>
      <CoverImageWrapper>
        <CoverImage src={coverUrl} alt={name} $isBlurred={blurImage} />
      </CoverImageWrapper>
      <ModelRank hidden={hideRank}>
        <StyledIcon $spaceRight icon={faTrophyAlt} />#{rank}
      </ModelRank>
      <ModelCountry to={`/models/country/${sanitizeLink(countryName)}`} hidden={!countryName}>
        <StyledIcon $spaceRight icon={faGlobe} />
        {countryName}
      </ModelCountry>
      <ModelAge>{modelAge}</ModelAge>
      <ModelSocial>
        <FavoriteButton favoriteCount={model.favoriteCount} objectType="MODEL" objectUUID={model.UUID} />
      </ModelSocial>
      <ModelInfo>{badges}</ModelInfo>
      <BioLink onClick={props.onToggleBio} hidden={!hasBioAttributes}>
        Read bio <StyledIcon $spaceLeft icon={props.bioVisible ? faChevronCircleUp : faChevronCircleDown} />
      </BioLink>
    </InfoPanelWrapper>
  );
};
