import { DeviceType, DeviceTypes } from 'src/shared/constants/userMeasurement';

export const getDeviceType = (): DeviceType => {
  const userAgent = navigator.userAgent;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
  const isTablet = /iPad|Android/i.test(userAgent) && !isMobile;

  if (isMobile) {
    return DeviceTypes.Mobile;
  } else if (isTablet) {
    return DeviceTypes.Tablet;
  } else {
    return DeviceTypes.Desktop;
  }
};

export const getDeviceTypeNoTablet = (): DeviceType => {
  const deviceType = getDeviceType();

  if (deviceType === DeviceTypes.Tablet) return DeviceTypes.Desktop;
  return deviceType;
};
