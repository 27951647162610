const MARK_READ = 'man-site/notifications/mark-read';
const MARK_READ_ID = 'man-site/notifications/mark-read/id';
const LOAD_NOTIFICATIONS = 'man-site/notifications/load-notifications';
const ADD_NOTIFICATION = 'man-site/notifications/add-notification';

const initialState = {
  unreadCount: 0,
  notifications: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MARK_READ: {
      return {
        ...state,
        unreadCount: 0,
      };
    }
    case MARK_READ_ID: {
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification.id === action.id) {
            return { ...notification, unread: false };
          }
          return notification;
        }),
      };
    }
    case LOAD_NOTIFICATIONS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case ADD_NOTIFICATION: {
      return {
        ...state,
        unreadCount: state.unreadCount + 1,
        notifications: [...state.notifications, action.notification],
      };
    }
    default: {
      return state;
    }
  }
}
/*
export function onMarkAsRead(id) {
  if (id) {
    return {
      type: MARK_READ_ID,
      id,
    };
  }

  return {
    type: MARK_READ,
  };
}

export function markAsRead(id) {
  global.socket.emit('notificationsMarkRead', id);
  return onMarkAsRead(id);
}

export function loadNotifications(data) {
  return {
    type: LOAD_NOTIFICATIONS,
    data,
  };
}

export function addNotification(notification) {
  return {
    type: ADD_NOTIFICATION,
    notification,
  };
}
*/
