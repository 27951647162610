import React, { Fragment, useState } from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import { faChevronRight, faVolume, faVolumeMute } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { ConstrainedContainer, TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
import { MediaRow } from './MediaRow';
import { MediaOverlay } from './MediaOverlay';
import { ButtonAndText, Button } from './Buttons';
import { HeroPanel } from './HeroPanel';
import { topNetworkFilms, topGalleries, topModels } from './mediaUrls';
import { useSelector } from 'src/client/redux';
import { Tooltip } from '@tovia/man-ui';
import cx from 'classnames';
import { AdultContentDisclaimerBanner } from '../../components/banners/AdultContentDisclaimerBanner';
import { logoUrl } from 'src/client/helpers/urls/logoUrl';

// const IPVANISH_AFFILIATE_URL = 'https://affiliate.ipvanish.com/aff_c?offer_id=1&aff_id=1719';

export const AgeVerifySplash = () => {
  const location = useLocation();
  const {
    // ageVerificationBlockedVPNStates,
    cdnUrl,
    contentCdnUrl,
    // location: userLocation,
    siteSubdomain,
    verifyMyAgeRedirectUrl,
  } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);
  const user = useSelector((state) => state.auth.user);
  const [muted, setMuted] = useState(true);
  const [overlayMediaUrl, setOverlayMediaUrl] = useState<string | undefined>(undefined);

  const preventFreeMemberClicks = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (user) return e.preventDefault();
  };

  // const userState = userLocation?.state || '';
  const logoSrc = logoUrl(cdnUrl, site);
  const loginUrl = `/login?r=${location.pathname.replace(/^\//, '')}`;

  const { name } = site;
  const copyright = new Date().getFullYear();

  const ToolTipComponent = user ? StyledTooltip : Fragment;

  return (
    <Wrapper>
      <HeaderBar>
        <Logo src={logoSrc} alt={name} />
        <HeaderButtonGroup>
          <ToolTipComponent
            placement="bottom"
            value={`You are not an active member of ${site.name}. Please subscribe to enter the site.`}
          >
            <HeaderButton
              href={loginUrl}
              className={cx('login', { disabled: !!user })}
              onClick={preventFreeMemberClicks}
            >
              Member Login
            </HeaderButton>
          </ToolTipComponent>
          <HeaderButton href={verifyMyAgeRedirectUrl} className="verify">
            Join Today <FontAwesomeIcon icon={faChevronRight} />
          </HeaderButton>
        </HeaderButtonGroup>
      </HeaderBar>
      <Banner>
        <BackgroundVideo autoPlay loop muted={muted}>
          <source src={`${contentCdnUrl}/nonnude/background.mp4`} type="video/mp4" />
        </BackgroundVideo>
        <BannerContainer>
          <HeroPanel
            buttonType="verify"
            buttonUrl={verifyMyAgeRedirectUrl}
            infoText="Membership brings you a world of unmatched sensual beauty and erotic sexuality. A virtually unlimited collection with endless updates of beautiful models."
            securityIcon={faLock}
            securityText="Fast, Safe & Private"
            title={`Enjoy the fully explicit version of ${name}`}
          />
          {/*<HeroPanel*/}
          {/*  hide={ageVerificationBlockedVPNStates.includes(userState)}*/}
          {/*  panelBackground={`${contentCdnUrl}/nonnude/map.png`}*/}
          {/*  title="Change Your Browsing Location"*/}
          {/*  buttonType="vpn"*/}
          {/*  buttonUrl={IPVANISH_AFFILIATE_URL}*/}
          {/*  infoText="Surf the web from any state leaving internet providers clueless about your true location."*/}
          {/*  securityIcon={faShieldCheck}*/}
          {/*  securityText="Protect Your Privacy"*/}
          {/*/>*/}
          <MuteButton onClick={() => setMuted(!muted)}>
            <FontAwesomeIcon icon={muted ? faVolumeMute : faVolume} />
          </MuteButton>
        </BannerContainer>
      </Banner>

      <MediaRow
        title="Our Top Network Films"
        mediaItems={topNetworkFilms}
        type="movie"
        setOverlayMediaUrl={setOverlayMediaUrl}
      />
      <MediaRow
        title="Our Top Network Models"
        mediaItems={topModels}
        type="gallery"
        setOverlayMediaUrl={setOverlayMediaUrl}
      />
      <MediaRow
        title="Our Top Photo Galleries"
        mediaItems={topGalleries}
        type="gallery"
        setOverlayMediaUrl={setOverlayMediaUrl}
      />

      <MediaOverlay overlayMediaUrl={overlayMediaUrl} closeOverlay={() => setOverlayMediaUrl(undefined)} />

      <ButtonAndText type="verify" url={verifyMyAgeRedirectUrl} />

      <StyledFooter>
        <Logo src={logoSrc} alt={name} />
        <SocialMediaIcons>
          <a href="https://www.instagram.com/metart.official/" target="_blank" rel="noopener noreferrer">
            <SocialMediaImage src={`${contentCdnUrl}/nonnude/instagram.svg`} alt="Instagram" />
          </a>
        </SocialMediaIcons>
        <RecordCompliance
          href={`https://${siteSubdomain}.${site.domain}/2257`}
          target="_blank"
          rel="noopener noreferrer"
        >
          18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement
        </RecordCompliance>
        <AgeCompliance>
          Warning - This site contains adult material of a sexual nature - you must be of legal age in your area to view
          this material. All materials on
          {name}, are copyright © ${copyright} {name}. All models were at least 18 years of age when photographed.
        </AgeCompliance>
      </StyledFooter>
      <AdultContentDisclaimerBanner />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background: black;
  color: white;
`;

const Logo = styled.img`
  width: 100%;
  max-height: 100%;
  max-width: 205px;
`;

const HeaderBar = styled(ConstrainedContainer).attrs({ as: 'header' })`
  height: 60px;
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 20px;
  align-items: center;
`;

const HeaderButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  column-gap: 10px;
  margin-left: auto;
`;

const HeaderButton = styled(Button)`
  height: 30px;
  max-width: auto;
  white-space: nowrap;

  font-size: 12px;
  padding: 0 8px;

  &.disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }

  @media ${TABLET_PORTRAIT} {
    font-size: 14px;
    padding: 0 20px;
  }
`;

const Banner = styled.section`
  position: relative;
  max-height: 1080px;
  background: #000;
`;

const BackgroundVideo = styled.video`
  aspect-ratio: 16 / 9;
  width: 100%;
  margin-bottom: 10px;
  object-fit: cover;
  object-position: center top;

  @media ${TABLET_PORTRAIT} {
    margin: 0;
    position: absolute;
    opacity: 0.8;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const MuteButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
  padding: 10px;

  background: transparent;
  border: 0;
  color: #ffffff;
  cursor: pointer;

  @media ${TABLET_PORTRAIT} {
    padding: 16px;
    font-size: 36px;
    margin-left: -16px;
    margin-bottom: -16px;
  }
`;

const BannerContainer = styled(ConstrainedContainer)`
  --spacing: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  row-gap: 20px;
  margin-bottom: 20px;

  @media ${TABLET_PORTRAIT} {
    position: relative;
    padding: var(--spacing) 0;

    ${MuteButton} {
      top: unset;
      bottom: var(--spacing);
      left: 0;
    }
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 56px;
  margin: 64px auto;
`;

const SocialMediaImage = styled.img`
  opacity: 0.8;
  width: 32px;
  height: 32px;

  &:hover {
    opacity: 1;
  }
`;
const StyledFooter = styled(ConstrainedContainer).attrs({ as: 'footer' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 30px;
  font-size: 12px;

  @media ${TABLET_PORTRAIT} {
    padding: 64px;
    font-size: 16px;
  }
`;

const RecordCompliance = styled.a`
  color: #ffffff;
  text-decoration: none;
`;

const AgeCompliance = styled.p`
  color: #b1b5bb;
  margin: 6px 0;
`;

const StyledTooltip = styled(Tooltip)`
  &.disabled {
    pointer-events: none;
    display: none;
  }
`;
