import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { SORT_TYPES } from 'src/shared/constants/search';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';
import { RootState } from 'src/client/redux/modules/reducer';
import { SearchResultItem, SearchResultResponse, Suggestion } from 'src/@types/SearchResultsTypes';
import { ResponseError } from 'superagent';

const LOAD = 'man-site/searchResults/LOAD';
const LOAD_SAGA = 'man-site/searchResults/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/searchResults/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/searchResults/LOAD_FAIL';

const endpoint = constructUrl(urls.get.searchResults);

type LoadParams = {
  page: string | number;
  pageSize: number;
  sortBy: string | undefined;
  query: Record<string, string>;
  searchPhrase?: string | undefined;
};

type ActionLoad = {
  type: typeof LOAD;
  lastCallParams: LoadParams;
};

type ActionLoadSuccess = {
  type: typeof LOAD_SUCCESS;
  result: SearchResultResponse;
};

type ActionLoadFail = {
  type: typeof LOAD_FAIL;
  error?: ResponseError;
};

type Action = ActionLoad | ActionLoadSuccess | ActionLoadFail;

type State = {
  displayName?: string;
  error?: ResponseError;
  fullTotal: number;
  itemCount: number;
  items: SearchResultItem[];
  lastCallParams?: LoadParams;
  loaded: boolean;
  loading: boolean;
  pageSize: number;
  redirectURL: string;
  suggestions: Suggestion[];
};

const initialState: State = {
  fullTotal: 0,
  itemCount: 0,
  items: [],
  loaded: false,
  loading: false,
  pageSize: 30,
  redirectURL: '',
  suggestions: [],
};

export default function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case LOAD:
      return {
        ...initialState,
        lastCallParams: action.lastCallParams,
        loaded: false,
        loading: true,
        error: undefined,
        displayName: undefined,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        items: action.result.items,
        suggestions: action.result.suggestions ?? [],
        itemCount: action.result.total,
        fullTotal: action.result.fullTotal ?? 0,
        redirectURL: action.result.redirectURL ?? '',
        displayName: action.result.displayName,
        loaded: true,
        loading: false,
        error: undefined,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        items: initialState.items,
        itemCount: initialState.itemCount,
        fullTotal: initialState.fullTotal,
        loaded: false,
        loading: false,
      };
    default: {
      return state;
    }
  }
}

const isLoaded = (lastCallParams: LoadParams | undefined, params: LoadParams) => isEqual(lastCallParams, params);

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator({ params }: { type: string; params: LoadParams }) {
  const moduleState: RootState['searchResults'] = yield select((state) => state.searchResults);
  const { lastCallParams } = moduleState;
  if ((!isLoaded(lastCallParams, params) && !moduleState.loading) || (!moduleState.loading && !moduleState.loaded)) {
    const sortType = SORT_TYPES.find(({ id }) => id === params.sortBy);
    const finalParams = {
      ...params,
      ...(sortType
        ? {
            sortBy: sortType.sortBy,
          }
        : {}),
      filters: params.query,
    };

    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client) =>
        client.get(endpoint, {
          params: finalParams,
        }),
      {
        lastCallParams: params,
      },
    );
    yield call(loadFunc);
  }

  yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

  const [auth, searchResults]: [
    RootState['auth'],
    RootState['searchResults'],
  ] = yield select(({ auth, searchResults }: RootState) => [auth, searchResults]);
  if (auth.user && searchResults.items.length > 0) {
    const objectUUIDs = searchResults.items.map((esItem) => esItem.item.UUID);
    yield put(
      loadRatingInfo({
        objectUUIDs,
      }),
    );
    yield put(
      loadFavoriteInfo({
        objectUUIDs,
      }),
    );
  }
}

// Trigger
function* watchLoad() {
  yield takeEvery(LOAD_SAGA, loadGenerator);
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
