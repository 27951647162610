import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt, faEye, faVideo } from '@fortawesome/pro-regular-svg-icons';
import Badge from '@tovia/man-ui/lib/components/Badge/Badge';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import CardInformation from '@tovia/man-ui/lib/components/Card/CardInformation';
import Card from '@tovia/man-ui/lib/components/Card/Card';
import { Link } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import cx from 'classnames';
import { formatDateDefaultOptions, secondsToRuntime } from 'src/client/utils/converters';
import GirdCardMenuOverlay from 'src/client/containers/Cards/GridCardMenuOverlay';
import ProgressBar from 'src/client/components/ProgressBar/ProgressBar';
import { RibbonCorner } from 'src/client/components/Ribbon/RibbonCorner';
import {
  DESKTOP,
  DESKTOP_WIDE,
  MOBILE_LANDSCAPE,
  TABLET_LANDSCAPE,
  TABLET_PORTRAIT,
  XX_LARGE,
} from 'src/client/constants/mediaQueries';
import { CardModelLinks } from 'src/client/containers/Cards/CardModelLinks';
import { GalleryModel } from '@tovia/man-protos/dist/types/content.types';
import { useWidePageContext } from 'src/client/containers/App/WidePageContextProvider';
import CardMedia from 'src/client/components/ui/Card/CardMedia';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

type Props = {
  url: string;
  imgSrc: string;
  imgAlt: string;
  title: string;
  timestamp?: string;
  views?: string | number;
  isBlurred: boolean;
  isStaffSelection?: boolean;
  models: Pick<GalleryModel, 'name' | 'path'>[];
  showModelName?: boolean;
  movieProgress?: number;
  isFavorited?: boolean;
  onFavorite?: () => void;
  spriteUrl?: string | null;
  ratingAverage?: string;
  showCategories?: boolean;
  showGalleryName?: boolean;
  categories?: string[];
  wideThumbnail?: boolean;
  isMovie?: boolean;
  isPromoGallery?: boolean;
  useExternalLinkForModelOrArtist?: boolean;
  runtime?: number;
  imageCount?: number;
};

export const GalleryGridCard = (props: Props) => {
  const {
    categories = [],
    imgAlt,
    imgSrc,
    isBlurred,
    isFavorited,
    isMovie,
    isPromoGallery,
    isStaffSelection,
    models,
    movieProgress = 0,
    onFavorite = () => false,
    runtime = 0,
    imageCount = 0,
    ratingAverage = 0,
    spriteUrl,
    timestamp,
    title,
    url,
    useExternalLinkForModelOrArtist,
    views,
  } = props;

  const ribbonContent = isMovie ? 'BONUS FILM' : 'BONUS SET';

  const [showMenu, setShowMenu] = useState(false);
  const [showSprite, setShowSprite] = useState(false);
  const [isSpriteLoaded, setSpriteLoaded] = useState(false);
  const widePage = useWidePageContext();
  const showSFWSite = useShowSfwSite();

  const subTitle = isStaffSelection
    ? 'Staff Selection'
    : timestamp && <FormattedDate value={new Date(timestamp)} {...formatDateDefaultOptions} month="short" />;

  const showPreview = () => {
    if (!spriteUrl) return;
    setShowSprite(true);
    setSpriteLoaded(false);
  };

  const hidePreview = () => {
    if (!spriteUrl) return;
    setShowSprite(false);
  };

  const showHoverPreview = spriteUrl && showSprite && !showMenu && !showSFWSite;

  const runtimeBadge =
    runtime > 0 ? (
      <Badge key="runtime" text={secondsToRuntime(runtime)} icon={<FontAwesomeIcon icon={faVideo} />} />
    ) : null;

  const imageCountBadge = imageCount ? (
    <Badge key="imageCount" text={imageCount} icon={<FontAwesomeIcon icon={faCameraAlt} />} />
  ) : null;

  // @TODO
  // the grid-card-wrapper class name is used for the unit tests, we should
  // get rid of that :/

  return (
    <Wrapper
      className={cx('grid-card-wrapper', { 'menu-opened': showMenu })}
      onMouseEnter={showPreview}
      onTouchStart={showPreview}
      onTouchEnd={hidePreview}
      onMouseLeave={hidePreview}
    >
      <Card>
        <RibbonCorner text={isPromoGallery ? ribbonContent : ''} ribbonType="grid" />
        <StyledCardMedia
          animate
          lazy
          url={url}
          imgSrc={imgSrc}
          altText={imgAlt}
          width="100%"
          height="100%"
          badges={[runtimeBadge, imageCountBadge].filter(Boolean)}
          blurred={isBlurred}
        >
          {showHoverPreview && (
            <HoverPreviewWrapper>
              <img src={spriteUrl} alt="preview" onLoad={() => setSpriteLoaded(true)} />
              <HoverPreview hidden={!isSpriteLoaded} to={url} $spriteUrl={spriteUrl} $isWidePage={widePage} />
            </HoverPreviewWrapper>
          )}
          <ProgressBar progress={movieProgress} />
          <GirdCardMenuOverlay
            onFavorite={onFavorite}
            isFavorited={isFavorited}
            setOpen={setShowMenu}
            isOpen={showMenu}
          />
        </StyledCardMedia>
        <CardInformation
          className="card-info-categories"
          title={
            props.showGalleryName ? (
              <Link to={url} className="item-name">
                {title}
              </Link>
            ) : undefined
          }
          subTitle={
            <CardModelLinks models={models} useExternalLinkForModelOrArtist={useExternalLinkForModelOrArtist} />
          }
        />
        {props.showCategories && categories.length > 0 && (
          <CardInformation
            className="card-info-1"
            subTitle={categories
              .map((cat) => (
                <Link to={`/search/${cat.replace(/\s+/g, '+')}`} key={`${title}_${cat}`}>
                  {cat}
                </Link>
              ))
              .reduce((result, value) => (
                <>
                  {result}, {value}
                </>
              ))}
          />
        )}
        <CardInformation
          className="card-info-2"
          // subTitle="Staff Selection"
          subTitle={subTitle}
          badges={[
            ...(ratingAverage
              ? [<Badge key="rating" text={ratingAverage} icon={<FontAwesomeIcon icon={faStar} />} />]
              : []),
            ...(views
              ? [<Badge className="hidden-xs" key="views" text={views} icon={<FontAwesomeIcon icon={faEye} />} />]
              : []),
          ]}
        />
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &:not(.menu-opened) {
    &:hover {
      .card-menu-overlay {
        opacity: 1;
      }
    }
  }

  .card-menu-overlay {
    transition: 0.3s opacity;
    opacity: 0;
    color: #fff;
  }

  .card-info-1 {
    padding-bottom: 0;
  }

  .card-info-2 {
    padding-top: 0;
    color: ${(props) => props.theme.primary7};

    path {
      fill: ${(props) => props.theme.primary7};
    }
  }

  .card-info-categories {
    padding: 0;
  }

  .card-information-sub-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
    overflow: hidden;
    display: block;
  }
`;

const StyledCardMedia = styled(CardMedia)`
  overflow: hidden;
  padding-top: 150%;
  user-select: none;

  > a {
    position: absolute;
    top: 0;
  }
`;

const HoverPreviewWrapper = styled.div`
  width: 100%;
  aspect-ratio: 214/120;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  img {
    display: none;
  }
`;

const HoverPreview = styled(Link)<{ $spriteUrl: string; $isWidePage: boolean }>`
  display: block;
  overflow: hidden;
  animation: play 12s steps(45) infinite;
  box-shadow: 0 -6px 12px 3px #00000060;
  background-image: url(${(props) => props.$spriteUrl});
  background-position-x: center;
  background-repeat: no-repeat;

  --height: 120px;
  --width: ${(props) => (props.$isWidePage ? '100%' : '214px')};

  // width + 1 because there is a 1px gap between the frames, so that needs to be off the screen.
  --background-width: calc(var(--width) + 1px);
  // height * 48 because there are 48 frames in the sprite
  --background-height: calc(var(--height) * 48);
  // skip the first 3 frames
  --background-start: calc(var(--height) * -3);
  --background-end: calc(var(--background-height) * -1);

  width: var(--width);
  height: var(--height);
  background-size: var(--background-width) var(--background-height);
  background-position-y: var(--background-start);

  @keyframes play {
    from {
      background-position-y: var(--background-start);
    }
    to {
      background-position-y: var(--background-end);
    }
  }

  @media ${MOBILE_LANDSCAPE} {
    --height: ${(props) => (props.$isWidePage ? '100px' : '120px')};
    --width: ${(props) => (props.$isWidePage ? '100%' : '214px')};
  }

  @media ${TABLET_PORTRAIT} {
    --height: ${(props) => (props.$isWidePage ? '130px' : '128px')};
    --width: ${(props) => (props.$isWidePage ? '100%' : '230px')};
  }

  @media ${TABLET_LANDSCAPE} {
    --height: ${(props) => (props.$isWidePage ? '135px' : '123px')};
    --width: ${(props) => (props.$isWidePage ? '100%' : '220px')};
  }

  @media ${DESKTOP} {
    --height: ${(props) => (props.$isWidePage ? '120px' : '120px')};
    --width: ${(props) => (props.$isWidePage ? '100%' : '214px')};
  }

  @media ${DESKTOP_WIDE} {
    --height: ${(props) => (props.$isWidePage ? '145px' : '120px')};
    --width: ${(props) => (props.$isWidePage ? '100%' : '214px')};
  }

  @media ${XX_LARGE} {
    --height: ${(props) => (props.$isWidePage ? '160px' : '120px')};
    --width: ${(props) => (props.$isWidePage ? '100%' : '214px')};
  }
`;
