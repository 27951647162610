import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';

type Props = {
  className?: string;
  subTitle?: ReactNode;
  tabs?: ReactNode;
  title?: ReactNode;
};

export const ContentHeader = (props: Props) => {
  const { tabs, title, subTitle, className } = props;

  return (
    <Wrapper className={className}>
      {title && <Title>{title}</Title>}
      <ClearLeft />
      {subTitle}
      <ClearLeft hidden={!subTitle} />
      {tabs}
      <ClearLeft />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 15px;
`;

const Title = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 24px;
  font-weight: 300;
  text-transform: capitalize;

  @media ${TABLET_PORTRAIT} {
    font-size: 2.15rem;
  }
`;

const ClearLeft = styled.div`
  clear: left;
`;
