import { take, call, fork, select, put, cancel } from 'redux-saga/effects';

import { ResponseError } from 'superagent';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { ACTIVITY_RATING } from 'src/shared/constants/activity';
import { addDashesToUUID } from 'src/shared/converters';
import { ActivityObjectType } from '@tovia/man-protos/dist/types/user.types';
import { ADD_ACTIVITY, REMOVE_ACTIVITY } from 'src/shared/constants/socketMessages';

const LOAD_SAGA = 'man-site/ratingInfo/LOAD_SAGA';
const ADD_LOADING_QUEUE = 'man-site/ratingInfo/ADD_LOADING_QUEUE';
const LOAD = 'man-site/ratingInfo/LOAD';
const LOAD_SUCCESS = 'man-site/ratingInfo/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/ratingInfo/LOAD_FAIL';

const RATE_SAGA = 'man-site/ratingInfo/RATE_SAGA';
const RATE = 'man-site/ratingInfo/RATE';
const RATE_SUCCESS = 'man-site/ratingInfo/RATE_SUCCESS';
const RATE_FAIL = 'man-site/ratingInfo/RATE_FAIL';

const SET_RATING = 'man-site/ratingInfo/SET_RATING';
const REMOVE_RATING = 'man-site/ratingInfo/REMOVE_RATING';

const endpoint = constructUrl(urls.post.ratingInfo);
const rateItemEndpoint = constructUrl(urls.post.rating);
const deleteRatingEndpoint = constructUrl(urls.delete.rating);

interface InitialState {
  loading?: boolean;
  loaded?: boolean;
  loadingObjectUUIDs: string[];
  loadedObjectUUIDs: string[];
  errorRatingObjectUUIDs: string[];
  ratingsInProcess: {
    [key: string]: number;
  };
  ratings: {
    [key: string]: number;
  };
}

export const initialState: InitialState = {
  loadingObjectUUIDs: [],
  loadedObjectUUIDs: [],
  errorRatingObjectUUIDs: [],
  ratingsInProcess: {},
  ratings: {},
};

interface ActionAddLoadingQueue {
  type: typeof ADD_LOADING_QUEUE;
  objectUUIDs: string[];
}

interface ActionLoad {
  type: typeof LOAD;
}

interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  result: {
    items: {
      UUID: string;
      rating: number;
    }[];
  };
  objectUUIDs: string[];
}

interface ActionLoadFail {
  type: typeof LOAD_FAIL;
  objectUUIDs: string[];
  error: ResponseError;
}

interface ActionRate {
  type: typeof RATE;
  UUID: string;
  rating: number;
}

interface ActionRateSuccess {
  type: typeof RATE_SUCCESS;
  UUID: string;
}

interface ActionRateFail {
  type: typeof RATE_FAIL;
  UUID: string;
}

interface ActionSetRating {
  type: typeof SET_RATING;
  UUID: string;
  rating: number;
}

interface ActionRemoveRating {
  type: typeof REMOVE_RATING;
  UUID: string;
}

export default function reducer(
  state = initialState,
  action:
    | ActionAddLoadingQueue
    | ActionLoad
    | ActionLoadSuccess
    | ActionLoadFail
    | ActionRate
    | ActionRateSuccess
    | ActionRateFail
    | ActionSetRating
    | ActionRemoveRating,
): InitialState {
  switch (action.type) {
    case ADD_LOADING_QUEUE: {
      return {
        ...state,
        loadingObjectUUIDs: [...state.loadingObjectUUIDs, ...action.objectUUIDs],
      };
    }
    case LOAD: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOAD_SUCCESS: {
      const newRatings = {};
      action.result.items.forEach(({ UUID, rating }) => {
        newRatings[UUID] = +rating;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        loadingObjectUUIDs: state.loadingObjectUUIDs.filter((UUID) => !action.objectUUIDs.includes(UUID)),
        ratings: {
          ...state.ratings,
          ...newRatings,
        },
        loadedObjectUUIDs: [...new Set([...state.loadedObjectUUIDs, ...action.objectUUIDs])],
      };
    }
    case LOAD_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        loadingObjectUUIDs: state.loadingObjectUUIDs.filter((UUID) => !action.objectUUIDs.includes(UUID)),
        loadedObjectUUIDs: [...new Set([...state.loadedObjectUUIDs, ...action.objectUUIDs])],
      };
    }
    case RATE: {
      const originalRating = {}; // rating before submission

      const keys = Object.keys(state.ratings);
      if (keys.includes(action.UUID)) {
        // check if there was a previous rating
        originalRating[action.UUID] = state.ratings[action.UUID];
      }

      return {
        ...state,
        ratings: {
          ...state.ratings,
          [action.UUID]: +action.rating,
        },
        ratingsInProcess: {
          ...state.ratingsInProcess,
          ...originalRating,
        },
      };
    }
    case RATE_SUCCESS: {
      // removing the ratingsInProcess
      const ratingsInProcess = { ...state.ratingsInProcess };
      delete ratingsInProcess[action.UUID];

      return {
        ...state,
        ratingsInProcess,
      };
    }
    case RATE_FAIL: {
      const ratingsInProcess = { ...state.ratingsInProcess };

      const ratings = { ...state.ratings };
      delete ratings[action.UUID];

      const keys = Object.keys(state.ratingsInProcess);
      if (keys.includes(action.UUID)) {
        // check if there was a previous rating
        ratings[action.UUID] = state.ratingsInProcess[action.UUID];
        // removing the ratingsInProcess
        delete ratingsInProcess[action.UUID];
      }

      return {
        ...state,
        ratingsInProcess,
        ratings,
      };
    }
    case SET_RATING: {
      return {
        ...state,
        ratings: {
          ...state.ratings,
          [action.UUID]: +action.rating,
        },
      };
    }
    case REMOVE_RATING: {
      return {
        ...state,
        ratings: {
          ...state.ratings,
          [action.UUID]: 0,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function setRating({ UUID, rating }) {
  return {
    type: SET_RATING,
    rating,
    UUID,
  };
}

export function removeRating(UUID) {
  return {
    type: REMOVE_RATING,
    UUID,
  };
}

export function rateItem(params) {
  return {
    type: RATE_SAGA,
    params,
  };
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

/* SAGAS */
function* loadGenerator({ params }) {
  const getState = (state) => state.ratingInfo;
  let currentState = yield select(getState);

  const finalObjectUUIDs: string[] = [];

  params.objectUUIDs.forEach((objectUUID) => {
    if (!currentState.loadingObjectUUIDs.includes(objectUUID) && !currentState.loadedObjectUUIDs.includes(objectUUID)) {
      finalObjectUUIDs.push(objectUUID);
    }
  });

  yield put({
    type: ADD_LOADING_QUEUE,
    objectUUIDs: finalObjectUUIDs,
  });

  yield delay(1000); // the time window we want to combine all the incoming rating requests

  currentState = yield select(getState);

  if (currentState.loadingObjectUUIDs.length === 0) {
    // all the items already exists
    return;
  }
  const loadFunc = getRequestFunc(
    [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    (client) =>
      client.post(endpoint, {
        data: {
          objectUUIDs: currentState.loadingObjectUUIDs,
        },
      }),
    { objectUUIDs: currentState.loadingObjectUUIDs },
  );
  yield call(loadFunc);
}

function* rateItemGenerator(data) {
  let ratingEndpoint;
  let method;
  let activityMethod: 'add' | 'remove';
  const { objectUUID, objectType } = data;
  const rating = parseInt(data.rating);

  if (Number.isNaN(rating)) {
    yield put({
      type: RATE_FAIL,
      UUID: objectUUID,
    });
    return;
  }

  const { ratingsInProcess, ratings } = yield select((state) => state.ratingInfo);
  const keys = Object.keys(ratingsInProcess);
  const sameRating = ratings[objectUUID] === rating;

  if (keys.includes(objectUUID) || sameRating) {
    return;
  }

  if (!data.rating || data.rating === 0) {
    ratingEndpoint = deleteRatingEndpoint;
    method = 'del';
    activityMethod = 'remove';
  } else {
    ratingEndpoint = rateItemEndpoint;
    method = 'post';
    activityMethod = 'add';
  }

  const rateFunc = getRequestFunc(
    [RATE, RATE_SUCCESS, RATE_FAIL],
    (client) =>
      client[method](ratingEndpoint, {
        data: {
          UUID: objectUUID,
          rating,
          type: objectType,
        },
      }),
    {
      UUID: objectUUID,
      rating,
    },
  );

  const { type } = yield call(rateFunc);

  if (type !== RATE_FAIL) {
    if (activityMethod === 'add') {
      const getState = (state) => state.activity.items;
      const currentItems = yield select(getState);

      const dashesUUID = addDashesToUUID(objectUUID);

      const item = currentItems.filter(
        (activity) => activity.objectUUID === dashesUUID && activity.actionType === ACTIVITY_RATING,
      );
      if (item.length > 0) {
        global.socket.emit(REMOVE_ACTIVITY, {
          actionType: ACTIVITY_RATING,
          objectUUID,
        });
      }
    }

    const socketMessage = activityMethod === 'add' ? ADD_ACTIVITY : REMOVE_ACTIVITY;

    global.socket.emit(socketMessage, {
      actionType: ACTIVITY_RATING,
      objectUUID,
      objectType:
        objectType === 'MOVIE'
          ? ActivityObjectType.GALLERY
          : ActivityObjectType[objectType as keyof typeof ActivityObjectType],
      rating,
    });
  }
}

// Trigger
function* watchLoad() {
  let currentSaga;
  while (true) {
    // eslint-disable-line  no-constant-condition
    // strategy of taking loads only when it's not loading, but also combine what hasn't sent
    const { params } = yield take(LOAD_SAGA);
    const isLoading = yield select((state) => state.ratingInfo.loading);
    if (!isLoading) {
      if (currentSaga) {
        yield cancel(currentSaga);
      }
      currentSaga = yield fork(loadGenerator, { params });
    }
  }
}

function* watchRateItem() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(RATE_SAGA);
    yield fork(rateItemGenerator, params);
  }
}

export const watchers = [fork(watchLoad), fork(watchRateItem)];
/* EOF SAGAS */
