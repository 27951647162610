import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';
import { TABLET_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
import { Button, ButtonAndText } from './Buttons';

type Props = {
  buttonType: 'verify' | 'vpn';
  buttonUrl: string;
  hide?: boolean;
  infoText: string;
  panelBackground?: string;
  securityIcon: IconDefinition;
  securityText: string;
  title: string;
};

export const HeroPanel = (props: Props) => {
  if (props.hide) return null;

  return (
    <Panel bg={props.panelBackground}>
      <Title>{props.title}</Title>
      {/* <SecurityLine>
        <FontAwesomeIcon icon={props.securityIcon} /> {props.securityText}
      </SecurityLine> */}
      <Info>{props.infoText}</Info>
      <ButtonAndText type={props.buttonType} url={props.buttonUrl} />
    </Panel>
  );
};

const Panel = styled.div<{ bg?: string }>`
  color: white;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url(${({ bg }) => bg});
  background-size: contain;
  border: 0.5px solid rgba(255, 255, 255, 0.8);

  ${Button} {
    margin-bottom: 10px;
  }

  @media ${TABLET_PORTRAIT} {
    border-width: 1px;
    padding: 10px 30px;
    max-width: 460px;
  }

  @media ${TABLET_LANDSCAPE} {
    max-width: 600px;
  }
`;

const Title = styled.h2`
  text-align: center;

  font-size: 24px;
  font-weight: bold;
  margin: 0 20px 5px;
  @media ${TABLET_PORTRAIT} {
    font-size: 28px;
    font-weight: normal;
    margin: 0 30px 10px;
  }

  @media ${TABLET_LANDSCAPE} {
    font-size: 38px;
    margin: 0 60px 10px;
  }
`;

// const SecurityLine = styled.span`
//   color: #546ce3;
//   text-transform: uppercase;
//   margin: 0 auto 10px;

//   font-size: 10px;
//   @media ${TABLET_PORTRAIT} {
//     font-size: 12px;
//   }

//   svg {
//     margin-right: 0.25em;
//   }
// `;

const Info = styled.p`
  margin: 0 0 20px;

  font-size: 14px;
  @media ${TABLET_PORTRAIT} {
    font-size: 18px;
  }

  @media ${TABLET_LANDSCAPE} {
    font-size: 24px;
  }
`;
