import styled, { css } from 'styled-components/macro';

// Media queries for mobile first design.
// Just override styles as the page gets larger.

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default

/**
 * Small devices (landscape phones, 576px and up)
 */
export const MOBILE_LANDSCAPE = '(min-width: 576px)';

/**
 * Medium devices (tablets (portrait), 768px and up)
 */
export const TABLET_PORTRAIT = '(min-width: 768px)';

/**
 * Large devices (tablets (landscape), small desktops, 992px and up)
 */
export const TABLET_LANDSCAPE = '(min-width: 992px)';

/**
 * Large devices (desktops, 992px and up)
 */
export const DESKTOP = '(min-width: 1200px)';

/**
 * Wide Screen devices (larger desktops, 1400px and up)
 */
export const DESKTOP_WIDE = '(min-width: 1400px)';

/**
 * X-Large devices (larger desktops, 1600px and up)
 */
export const X_LARGE = '(min-width: 1600px)';

/**
 * XX-Large devices (larger desktops, 1800px and up)
 */
export const XX_LARGE = '(min-width: 1800px)';

/**
 * XXX-Large devices (larger desktops, 2000px and up)
 */
export const XXX_LARGE = '(min-width: 2000px)';

/**
 * XXXX-Large devices (larger desktops, 2200px and up)
 */
export const XXXX_LARGE = '(min-width: 2200px)';

/**
 * XXXXX-Large devices (larger desktops, 2400px and up)
 */
export const XXXXX_LARGE = '(min-width: 2400px)';

export const maxWidthMediaQueries = css`
  margin: 0 10px;
  max-width: 100%;

  @media ${MOBILE_LANDSCAPE} {
    margin: 0 auto;
    max-width: 540px;
  }

  @media ${TABLET_PORTRAIT} {
    max-width: 720px;
  }

  @media ${TABLET_LANDSCAPE} {
    max-width: 970px;
  }

  @media ${DESKTOP} {
    max-width: 1140px;
  }

  @media ${DESKTOP_WIDE} {
    max-width: 1320px;
  }
`;

export const extendedMaxWidthMediaQueries = css`
  @media ${X_LARGE} {
    max-width: 1500px;
  }

  @media ${XX_LARGE} {
    max-width: 1700px;
  }

  @media ${XXX_LARGE} {
    max-width: 1900px;
  }

  @media ${XXXX_LARGE} {
    max-width: 2100px;
  }

  @media ${XXXXX_LARGE} {
    max-width: 2300px;
  }
`;

export const ConstrainedContainer = styled.section<{ extended?: boolean }>`
  ${maxWidthMediaQueries}
  ${(props) => props.extended && extendedMaxWidthMediaQueries}
`;

// TODO: find a better place for this
export const WidePageWrapper = styled.div`
  overflow: hidden;
  background-color: ${(props) => props.theme.primary1};
  margin-top: 10px;
`;
