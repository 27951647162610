import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FeedItem } from './FeedItem';
import { ActionProps, getActivityTypeInfo } from './ActivityFeed';

export const FollowAction = (props: ActionProps) => {
  const { activity } = props;
  const { objectType } = activity;
  const info = getActivityTypeInfo(activity)[objectType];

  return (
    <FeedItem
      didWhat={
        <FormattedMessage
          id="activityFeed.actions.follow.action"
          defaultMessage="followed {label}"
          values={{ label: info.label }}
        />
      }
      onWhat={{ name: info.name, url: info.path }}
    />
  );
};
