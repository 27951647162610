import { call, fork, select, put, takeEvery } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';
import { Leaderboard, Photographer } from '@tovia/man-protos/dist/types/content.types';
import { RootState } from './reducer';
import { GalleryResponseModel } from 'src/@types/app';
import { ResponseError } from 'superagent';

const ActionType = {
  LOAD: 'man-site/leaderboard/LOAD',
  LOAD_SAGA: 'man-site/leaderboard/LOAD_SAGA',
  LOAD_SUCCESS: 'man-site/leaderboard/LOAD_SUCCESS',
  LOAD_FAIL: 'man-site/leaderboard/LOAD_FAIL',
} as const;

export const defaultTab = 'galleries';
export const defaultTimePeriod = 'day';

const endpoints = {
  galleries: constructUrl(urls.get.galleries),
  models: constructUrl(urls.get.models),
  movies: constructUrl(urls.get.movies),
  photographers: constructUrl(urls.get.photographers),
};

type ActionType = typeof ActionType[keyof typeof ActionType];

type CallParams = {
  direction?: string;
  first?: number;
  galleryType?: string;
  order: string;
  tab: string;
  type?: string;
};

type State = {
  error?: ResponseError | boolean;
  items: ModelResponseType[] | GalleryResponseModel[] | Photographer[];
  lastCallParams: CallParams;
  loaded: boolean;
  loading: boolean;
};

type Load = {
  lastCallParams: CallParams;
  params: CallParams;
  type: typeof ActionType.LOAD;
};

type LoadSuccess = State & {
  result: Result;
  type: typeof ActionType.LOAD_SUCCESS;
};

type LoadFail = {
  error: ResponseError;
  type: typeof ActionType.LOAD_FAIL;
};

type GalleryModel = {
  globalUUID: string;
  name: string;
  path: string;
  UUID: string;
};

type ModelResponseType = Omit<GalleryModel, 'globalUUID'> & {
  galleriesCount: number;
  headshotImagePath: string;
  leaderboardViews: [Leaderboard];
  moviesCount: number;
  ratingAverage: number;
  siteUUID: string;
  totalGalleries: number;
  views: number;
};

type Result = {
  total: number;
  galleries: GalleryResponseModel[];
  models: ModelResponseType[];
  photographers: Photographer[];
};

type Action = Load | LoadSuccess | LoadFail;

const initialState = {
  items: [],
  loading: false,
  loaded: false,
  error: undefined,
  lastCallParams: { order: '', tab: '' },
} as State;

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case ActionType.LOAD: {
      return {
        ...state,
        loading: true,
        items: initialState.items,
        lastCallParams: action.lastCallParams,
      };
    }
    case ActionType.LOAD_SUCCESS:
      return {
        ...state,
        items: action.result.galleries || action.result.models || action.result.photographers,
        loading: false,
        loaded: true,
        error: action.error,
      };
    case ActionType.LOAD_FAIL:
      return {
        ...state,
        items: initialState.items,
        loading: false,
        loaded: true,
        error: action.error,
      };
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams: CallParams, params: CallParams) {
  return isEqual(lastCallParams, params);
}

export function load(params: CallParams) {
  return {
    type: ActionType.LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator({ params }: { type: string; params: CallParams }) {
  const currentState: RootState['leaderboard'] = yield select((state: RootState) => state.leaderboard);
  const { lastCallParams, loading } = currentState;
  const { order, tab } = params;
  const finalParams: CallParams = {
    order: `lb-${order}`,
    // Pulling 'top' 100 items:
    first: 20,
    direction: 'DESC',
    tab,
  };

  switch (tab) {
    case 'galleries':
      finalParams.galleryType = 'GALLERY';
      break;
    case 'movies':
      finalParams.galleryType = 'MOVIE';
      break;
    case 'photographers':
      finalParams.type = 'PHOTOGRAPHER';
      break;
    default:
      break;
  }

  if (!isLoaded(lastCallParams, finalParams) && !loading) {
    const loadFunc = getRequestFunc(
      [ActionType.LOAD, ActionType.LOAD_SUCCESS, ActionType.LOAD_FAIL],
      (client) =>
        client.get(endpoints[tab], {
          params: finalParams,
        }),
      {
        lastCallParams: { ...finalParams },
      },
    );
    yield call(loadFunc);
  }

  yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

  const newState: RootState = yield select();
  if (newState.auth.user && newState.leaderboard.items.length > 0) {
    let rateObjectType;

    if (!rateObjectType) {
      // one type
      switch (lastCallParams.tab) {
        case 'models':
          rateObjectType = 'model';
          break;
        case 'photographers':
          rateObjectType = 'photographer';
          break;
        case 'galleries':
        case 'movies':
        default:
          rateObjectType = 'gallery';
          break;
      }
    }
    const objectUUIDs = newState.leaderboard.items.map((item) => item.UUID);
    yield put(
      loadRatingInfo({
        objectUUIDs,
      }),
    );
    yield put(
      loadFavoriteInfo({
        objectUUIDs,
      }),
    );
  }
}

// Trigger
function* watchLoad() {
  yield takeEvery(ActionType.LOAD_SAGA, loadGenerator);
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
