import { useEffect } from 'react';
import { useHistory } from 'react-router';
import urls from 'src/shared/urls';

export const use404Redirect = (error?: SuperAgentError) => {
  const history = useHistory();
  useEffect(() => {
    if (error?.status === 404) {
      history.replace(urls.get.notFound);
    }
  }, [error, history]);
};
