import React from 'react';
import PropTypes from 'prop-types';
import ReactTagsWrapper from './ReactTagsWrapper';

// Redux Form rendering
export const RenderTagsInput = ({ input, className }) => (
  <ReactTagsWrapper {...input} className={className} onChange={input.onChange} />
);

RenderTagsInput.propTypes = {
  input: PropTypes.object,
  className: PropTypes.string,
};
