import React from 'react';
import rootSaga from 'src/client/sagas';

import configureStore from 'src/client/redux/store/configureStore';
import { initSocketIO } from 'src/client/sockets/client';
import { Provider } from 'react-redux';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState: any;
};

export const ReduxStoreProvider: React.FC<Props> = (props) => {
  const store = configureStore(props.initialState);
  global.socket = initSocketIO(store);
  store.runSaga(rootSaga);

  return <Provider store={store}>{props.children}</Provider>;
};
