import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  url: string;
};

const HeadingWrapper = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    min-height: 60px;
  }
  @media (max-width: 430px) {
    min-height: 50px;
  }
`;

const HeadingLabel = styled.span`
  display: block;
  text-align: left;
  color: #a07a4c;
  font-family: 'Open Sans', sans-serif;
  font-size: 28px;
  text-transform: uppercase;
  margin: 0 0 0 16px;
  @media (max-width: 1024px) {
    font-size: 16px;
    margin: 0 0 0 14px;
  }
  @media (max-width: 430px) {
    margin: 0 0 0 10px;
  }
`;

const HeadingLink = styled(Link)`
  display: block;
  text-decoration: none;
  text-align: center;
  color: #a07a4c;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  height: 40px;
  width: 160px;
  border: 1px solid #a07a4c;
  border-radius: 4px;
  text-transform: uppercase;
  margin: 0 32px 0 0;
  transition: 0.3s;
  @media (max-width: 1024px) {
    font-size: 13px;
    width: 100px;
    height: 26px;
    line-height: 26px;
    margin: 0 14px 0 0;
    background: #a07a4c;
    color: #fff;
  }
  @media (max-width: 430px) {
    width: 80px;
    height: 24px;
    line-height: 22px;
    margin: 0 10px 0 0;
  }
  :hover,
  :focus {
    background: #a07a4c;
    text-decoration: none;
    color: #fff;
  }
`;

export const Heading = (props: Props) => {
  return (
    <HeadingWrapper>
      <HeadingLabel>{props.title}</HeadingLabel>
      <HeadingLink to={props.url}>View All</HeadingLink>
    </HeadingWrapper>
  );
};
