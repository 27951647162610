import React from 'react';
import { useCookies } from 'react-cookie';
import ScrollToTop from '@tovia/man-ui/lib/components/ScrollToTop/ScrollToTop';
import { JoinButton } from 'src/client/components/buttons/JoinButton';
import styled from 'styled-components/macro';
import { useSelector } from 'src/client/redux';
import { usePromotions } from 'src/client/helpers/usePromotions';
import { MAM_COOKIE_NAME } from 'src/shared/constants/cookies';

const SignupButton = styled(JoinButton)`
  border-radius: 3px;
  font-size: 1.15rem;
  margin: 0 3px;
  padding: 15px 15px 16px;
  vertical-align: middle;
  background: #fc4718;
  color: #fff;
  opacity: 0.8;

  :hover,
  :focus {
    text-decoration: none;

    opacity: 1;
  }
`;

const MobileSignupButton = styled(JoinButton)`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background: black;
  text-align: center;
  color: white;
  padding: 15px;
  border-top: 1px solid rgb(49, 49, 49);
  font-weight: 600;
  display: block;
`;

const ScrollUpButton = styled.div<{ backgroundImage: string }>`
  width: 53px;
  height: 53px;
  display: inline-block;
  background: ${({ backgroundImage }) => `url(${backgroundImage})`} no-repeat center center;
  background-size: cover;
`;

type Props = {
  setFreeTrialDialogOpen: (state: boolean) => void;
};

const FreeTrialScrollToTop = (props: Props) => {
  const { user, loaded } = useSelector((state) => state.auth);
  const { showFreeTrialButton } = useSelector((state) => state.app.config);
  const { cdnUrl } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);
  const { isPromoActive } = usePromotions();

  const paCookieName = `${site.abbreviation}_PA`;
  const [cookies] = useCookies([MAM_COOKIE_NAME, paCookieName]);

  const { setFreeTrialDialogOpen } = props;

  if (!loaded) return null;

  const handleSignupButtonClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory: 'free-trial',
      eventLabel: 'dialog',
      eventAction: 'show',
    });
    setFreeTrialDialogOpen(true);
  };

  const isAffiliateUser = Boolean(cookies[MAM_COOKIE_NAME] || cookies[paCookieName]);

  const joinButtonText = () => {
    switch (true) {
      case !!(user && !user.validSubscription):
        return (
          <>
            <SignupButton campaign="free-trial-floater" className="hidden-xs">
              JOIN NOW!
            </SignupButton>
            <MobileSignupButton campaign="free-trial-floater" className="visible-xs">
              START NOW!
            </MobileSignupButton>
          </>
        );

      // in order to see this button you must:
      //   1. Not be loggged in
      //   2. Not have any affiliate codes in your cookies
      //   3. Not be accessing the site during a network wide promo
      //   4. Have the button enabled on the site
      case !user && !isAffiliateUser && !isPromoActive && showFreeTrialButton:
        return (
          <SignupButton campaign="free-trial-floater" onClick={handleSignupButtonClick} className="hidden-xs">
            FREE TRIAL
          </SignupButton>
        );

      default:
        return null;
    }
  };

  return (
    <ScrollToTop>
      {joinButtonText()}
      <ScrollUpButton
        className="va-m inline-block btn-scroll-up hidden-xs"
        backgroundImage={`${cdnUrl}/img/scroll@2x.png`}
      />
    </ScrollToTop>
  );
};

export default FreeTrialScrollToTop;
