import { take, call, fork, select } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

const CHANGE_TAB = 'man-site/activity/CHANGE_TAB';
const LOAD = 'man-site/activity/LOAD';
const LOAD_SAGA = 'man-site/activity/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/activity/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/activity/LOAD_FAIL';
const ADD_ACTIVITY = 'man-site/activity/ADD_ACTIVITY';
const REMOVE_ACTIVITY = 'man-site/activity/REMOVE_ACTIVITY';

const endpoint = constructUrl(urls.get.activity);

type State = {
  loaded: boolean;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  items: ClientActivity[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lastCallParams: any;
};

const initialState: State = {
  loaded: false,
  loading: false,
  error: false,
  items: [],
  lastCallParams: {},
};

type ActionsTypes =
  | typeof CHANGE_TAB
  | typeof LOAD
  | typeof LOAD_SAGA
  | typeof LOAD_SUCCESS
  | typeof LOAD_FAIL
  | typeof ADD_ACTIVITY
  | typeof REMOVE_ACTIVITY;

export default function reducer(state = initialState, action): State {
  switch (action.type as ActionsTypes) {
    case LOAD:
      return {
        ...state,
        loaded: false,
        loading: true,
        lastCallParams: action.lastCallParams,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case ADD_ACTIVITY:
      return {
        ...state,
        items: [action.activity, ...state.items],
      };
    case REMOVE_ACTIVITY: {
      return {
        ...state,
        items: [
          ...state.items.filter(
            (activity) =>
              activity.objectUUID !== action.activity.objectUUID || activity.actionType !== action.activity.actionType,
          ),
        ],
      };
    }
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams, params) {
  return isEqual(lastCallParams, params);
}

export function addActivity(activity) {
  return {
    type: ADD_ACTIVITY,
    activity,
  };
}

export function removeActivity({ actionType, objectUUID }) {
  return {
    type: REMOVE_ACTIVITY,
    activity: { actionType, objectUUID },
  };
}

export function changeTab(tab) {
  return {
    type: CHANGE_TAB,
    tab,
  };
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => state.activity;
  const currentState = yield select(getState);
  const { lastCallParams } = currentState;
  if ((!isLoaded(lastCallParams, params) && !currentState.loading) || (!currentState.loaded && !currentState.loading)) {
    // reload images after a failure
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint, { params }), {
      lastCallParams: { ...params },
    });
    yield call(loadFunc);
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
