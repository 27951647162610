import React from 'react';
import { useSelector } from 'src/client/redux';
import { MetArt } from 'src/client/constants/sites';
import { OnPageFooterMa } from 'src/client/components/OnPageSeo/OnPageFooter/OnPageFooterMa';
import { UserTypes } from 'src/shared/constants/userTypes';

export const OnPageFooter = () => {
  const { userType } = useSelector((state) => state.auth);
  const site = useSelector((state) => state.site);

  if (userType !== UserTypes.Guest) {
    return null;
  }

  switch (site?.abbreviation) {
    case MetArt.abbreviation:
      return <OnPageFooterMa />;
    default:
      return null;
  }
};
