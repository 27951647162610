import React, { useState, useEffect, useCallback, useRef, MouseEvent } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@tovia/man-ui/lib/components/Button/Button';
import {
  load as loadNotifications,
  SITE_NOTIFICATION_TYPES as NotificationTypes,
} from '../../redux/modules/siteNotifications';
import { useSelector, useSettingsSelector } from '../../redux/modules/helpers/useSelector';

const Wrap = styled.div<{
  template: 'dark' | 'light';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any;
}>`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  padding-top: 65px;
  z-index: 999;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);

  .notification-container {
    position: fixed;
    height: 100vh;
    padding: 0;
    overflow: hidden;
    margin: 16px 0 auto;

    @media (max-width: 997px) {
      width: 100%;
      margin-top: 0;
    }
  }

  .notification-panel {
    width: 300px;
    background: ${(props) => (props.template === 'dark' ? '#555555' : props.theme.primary1)};
    position: absolute;
    right: 0;
    transform: translateX(110%);
    margin-right: 10px;
    border-radius: 5px;
    transition: transform 0.3s ease;
    box-shadow: rgba(4, 4, 5, 0.15) 0 0 0 1px, rgba(0, 0, 0, 0.24) 0 2px 15px 0;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 0;
      height: 100vh;
      left: 0;
    }

    &.active {
      transform: translateX(0%);
    }

    .close-button {
      position: absolute;
      top: 0;
      height: 20px;
      z-index: 2;
      font-size: 1.2rem;
      right: 0;
      background: transparent;
      border-width: initial;
      border-style: none;
      border-color: initial;
      border-image: initial;
      outline: none;

      @media (max-width: 768px) {
        top: 8px;
        right: 5px;
      }
    }

    .cards-wrap {
      position: relative;
      overflow-y: auto;
      height: 100%;
      display: block;
      max-height: calc(100vh - 90px);
      min-height: 650px;
      padding-top: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 768px) {
        width: 90%;
        left: 5%;
      }
    }

    .notification-date {
      font-size: 0.9rem;
      color: #8c8c8c;
    }

    .notification-title {
      position: fixed;
      font-weight: 700;
      width: 100%;
      font-size: 1.3rem;
      display: block;
      text-align: center;
      padding: 20px 0;
      color: ${(props) => props.theme.primary4};
      z-index: 1;
      background: ${(props) => props.theme.primary2};
      border-radius: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .notification-text {
      font-size: 1rem;
      padding: 11px;
      width: 100%;
      display: block;
    }

    .notification-card {
      background: ${(props) => props.theme.primary2};
      min-height: 100px;
      width: 280px;
      position: relative;
      border-radius: 3px;
      padding: 16px 8px;
      margin-bottom: 10px;
      flex-shrink: 0;

      @media (max-width: 768px) {
        width: 90%;
        max-width: 450px;
      }

      a {
        text-decoration: underline;
      }
    }

    .notification-badge {
      padding: 2px 6px;
      border-radius: 3px;
      margin-left: 10px;
      font-size: 0.9rem;
      color: white;

      &.notification-newFeature {
        background: #06d6a0;
      }

      &.notification-bugFix {
        background: #ef476f;
      }

      &.notification-announcement {
        background: #ff9900;
      }
      &.notification-featureUpgrade {
        background: #0084ff;
      }
    }

    .notification-header {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const SiteNotificationsComponent = (props) => {
  const { active, onClose } = props;
  const [panelActive, setPanelActive] = useState(false);
  const siteName = useSelector((state) => state.site.name);
  const template = useSettingsSelector('template') as 'dark' | 'light';
  const { loaded, items } = useSelector((state) => state.siteNotifications);
  const dispatch = useDispatch();
  const load = useCallback((...args) => dispatch(loadNotifications(...args)), [dispatch]);
  const intl = useIntl();

  const wrapper = useRef();
  const container = useRef<HTMLInputElement>(null);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (active) {
      setTimeout(() => setPanelActive(true), 100);
    }
  }, [active]);

  if (!active || !loaded) {
    return null;
  }

  const getDate = (timestamp) => moment(parseInt(timestamp) * 1000).format('DD MMM, YYYY');

  const handleGAClick = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const { dataset } = target;

    if (target.tagName === 'A' && dataset.label && dataset.category) {
      window.dataLayer.push({
        event: 'gaEvent',
        eventCategory: dataset.category,
        eventLabel: dataset.label,
        eventAction: 'click',
      });
    }
  };

  const renderCards = () => {
    const { notifications } = items;

    const cards = notifications.map((notification) => {
      const { id, title, intlID } = NotificationTypes[notification.type];
      return (
        <div className="notification-card" key={notification.timestamp} onClick={handleGAClick}>
          <div className="notification-header">
            <span className={`notification-badge notification-${id}`}>
              {intl.formatMessage({ id: intlID, defaultMessage: title })}
            </span>
            <span className="notification-date">{getDate(notification.timestamp)}</span>
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <span className="notification-text" dangerouslySetInnerHTML={{ __html: notification.message }} />
        </div>
      );
    });
    return <div className="cards-wrap">{cards}</div>;
  };

  const renderTitle = () => (
    <span className="notification-title">
      {intl.formatMessage({ id: 'siteNotifications.title', defaultMessage: `What's new on ${siteName}` }, { siteName })}
    </span>
  );

  const closePanel = () => {
    setPanelActive(false);
    setTimeout(() => onClose(), 500);
  };

  const handleWrapClick = (e) => {
    if (e.target !== wrapper.current && e.target !== container.current) return;
    closePanel();
  };

  const notificationTitle = siteName ? renderTitle() : null;
  const notificationCards = loaded ? renderCards() : null;
  const notificationCloseButton = (
    <Button className="close-button" onClick={closePanel}>
      <FontAwesomeIcon icon={faTimes} />
    </Button>
  );

  return (
    <Wrap ref={wrapper} onClick={handleWrapClick} template={template}>
      <div className="notification-container container" ref={container}>
        <div className={`notification-panel ${panelActive ? 'active' : ''}`}>
          {notificationCloseButton}
          {notificationTitle}
          {notificationCards}
        </div>
      </div>
    </Wrap>
  );
};

SiteNotificationsComponent.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
};

SiteNotificationsComponent.defaultProps = {
  active: false,
  onClose: () => {},
};

export default SiteNotificationsComponent;
