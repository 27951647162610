import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { useSelector } from '../../redux/modules/helpers/useSelector';
import { HoverMenuCaret, HoverMenuLink } from './styles';
import { JoinButton } from 'src/client/components/buttons/JoinButton';

export const MoreGirls = () => {
  const intl = useIntl();
  const user = useSelector((state) => state.auth.user);
  const config = useSelector((state) => state.app.config);
  const [moreMenuOpened, setMoreMenuOpened] = useState(false);
  const timeout = useRef<number | undefined>(undefined);
  const site = useSelector((state) => state.site);

  const { moreGirls, isWhiteLabel, hasMoreGirls, shop } = config;
  const customText = !!moreGirls?.text;

  if (!user?.validSubscription || !hasMoreGirls) {
    return null;
  }

  const showMoreMenu = () => {
    clearTimeout(timeout.current);
    setMoreMenuOpened(true);
  };

  const hideMoreMenu = () => {
    timeout.current = setTimeout(setMoreMenuOpened, 333, false);
  };

  const moreGirlsText = (
    <FormattedMessage
      id={`header.links.moreGirls${customText ? '.custom' : ''}`}
      defaultMessage="More Girls"
      values={{ text: moreGirls?.text }}
    />
  );

  if (isWhiteLabel) {
    return (
      <JoinButton campaign="more-girls" target="_blank" rel="noopener noreferrer">
        {moreGirlsText}
      </JoinButton>
    );
  }

  return (
    <MoreGirlsWrapper className="hidden-xs hidden-sm">
      <div
        className="more-menu"
        onMouseOver={showMoreMenu}
        onFocus={showMoreMenu}
        onBlur={hideMoreMenu}
        onMouseLeave={hideMoreMenu}
      >
        <HoverMenuLink>
          {moreGirlsText}
          <HoverMenuCaret icon={faCaretDown} size="lg" />
        </HoverMenuLink>
      </div>

      <div
        hidden={!moreMenuOpened}
        className="more-menu-popover hidden-xs man-popover"
        onMouseEnter={showMoreMenu}
        onMouseLeave={hideMoreMenu}
      >
        <div className="man-arrow" />
        {moreGirls && moreGirls.list && moreGirls.list.length > 0 ? (
          <div className="cam-list-drop-down-wrap bg-white">
            {moreGirls.list.map((cam) => (
              <a key={cam.name} href={cam.url} target="_blank" rel="noreferrer">
                {cam.name}
              </a>
            ))}
          </div>
        ) : (
          <ul className="dropdown-menu dropdown-more-menu">
            <li>
              <JoinButton campaign="more-girls">
                <span className="dropdown-link">
                  {intl.formatMessage({ id: 'header.moreGirls.beautifulGirls', defaultMessage: 'Our Beautiful Girls' })}
                </span>
              </JoinButton>
            </li>
            <li>
              <NavLink to="/trusted-partners/">
                <span className="dropdown-link">
                  {intl.formatMessage({
                    id: 'header.moreGirls.trustedPartnerSites',
                    defaultMessage: 'Trusted Partner Sites',
                  })}
                </span>
              </NavLink>
            </li>
            {shop && (
              <li>
                <a href={shop.domain}>
                  <span className="dropdown-link">{shop.name}</span>
                </a>
              </li>
            )}
            <li>
              <a href={`https://goddess.me/?utm_medium=web&utm_source=${site.abbreviation}&utm_campaign=more-girls`}>
                <span className="dropdown-link">Intimate Toys</span>
              </a>
            </li>
          </ul>
        )}
      </div>
    </MoreGirlsWrapper>
  );
};

const MoreGirlsWrapper = styled.div`
  position: relative;
  @media (max-width: 1199px) {
    && {
      display: none;
    }
  }
`;
