import styled from 'styled-components/macro';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import cx from 'classnames';
import { TABLET_LANDSCAPE } from '@tovia/man-ui';

type Props = {
  children: ReactNode;
  mobileMenuOpened: boolean;
};

export const MobileMenuTransition = (props: Props) => {
  const [headerBarHeight, setHeaderBarHeight] = useState(64);
  const transitionElement = useRef<HTMLElement | null>(null);

  // Doing this to get accurate menu height since the header bar is now dynamic. Without it, the scroll is busted in
  // the menu. The whole mobile menu setup is pretty shit, but there is probably a better way to do this via CSS...
  useEffect(() => {
    const screenChangeListener = () => {
      setHeaderBarHeight(transitionElement?.current?.offsetTop || 64);
    };

    addEventListener('resize', screenChangeListener);

    return () => {
      removeEventListener('resize', screenChangeListener);
    };
  }, []);

  // You need something like this to access a prop of css, or the entering
  // state transition will happen too fast and css will process it in the same
  // tick, and the transition will not be seen.

  // https://github.com/reactjs/react-transition-group/issues/223#issuecomment-334748429
  const onEnterHandler = (node: HTMLElement) => {
    setHeaderBarHeight(node.offsetTop);
    return node.offsetHeight;
  };

  return (
    <Transition mountOnEnter unmountOnExit in={props.mobileMenuOpened} timeout={350} onEnter={onEnterHandler}>
      {(transitionClass) => (
        <TransitionWrapper
          ref={transitionElement}
          headerBarHeight={headerBarHeight}
          className={cx(transitionClass, 'mobile-menu-drawer')}
        >
          {props.children}
        </TransitionWrapper>
      )}
    </Transition>
  );
};

const TransitionWrapper = styled.section<{ headerBarHeight: number }>`
  background: ${(props) => props.theme.primary6};
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.3);
  // fallback in case device doesnt support 'dvh'
  height: calc(100vh - ${({ headerBarHeight }) => headerBarHeight}px);
  height: calc(100dvh - ${({ headerBarHeight }) => headerBarHeight}px);
  left: 0;
  overflow-y: scroll;
  position: absolute;
  transition: 0.3s ease transform;
  z-index: 10;

  &.exited,
  &.exiting {
    transform: translateX(-100%);
  }

  &.entered,
  &.entering {
    transform: translateX(0);
  }

  @media ${TABLET_LANDSCAPE} {
    display: none;
  }
`;
