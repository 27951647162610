import React from 'react';
import { FormattedMessage } from 'react-intl';
import { moreGirls as moreGirlsIcon } from 'src/client/icons';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { MobileLink } from './MobileLink';
import { ActiveNestedItem } from '../HeaderProvider';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';
import { MobileExpandableMenuItem } from './MobileExpandableMenuItem';

type Props = {
  isSplash: boolean;
};

export const MobileMoreGirls = (props: Props) => {
  const config = useSelector((state) => state.app.config);
  const user = useSelector((state) => state.auth.user);
  const [joinUrl] = useJoinUrl({ campaign: 'mobile-more-girls' });
  const site = useSelector((state) => state.site);

  const { moreGirls, isWhiteLabel, shop } = config;

  if (props.isSplash || !config.hasMoreGirls || isWhiteLabel || !user?.validSubscription) {
    return null;
  }

  return (
    <MobileExpandableMenuItem
      activeNestedItem={ActiveNestedItem.MOREGIRLS}
      icon={moreGirlsIcon}
      menuLabel={moreGirls?.text || 'More Girls'}
    >
      {moreGirls?.list?.length ? (
        <>
          {moreGirls?.list?.map((item) => (
            <MobileLink key={item.name} href={item.url} openNewWindow label={item.name} />
          ))}
        </>
      ) : (
        <>
          <MobileLink
            href={joinUrl}
            label={<FormattedMessage id="header.links.beautifulGirls" defaultMessage="Our Beautiful Girls" />}
          />

          <MobileLink
            href="/trusted-partners"
            label={<FormattedMessage id="header.links.trustedPartnerSites" defaultMessage="Trusted Partner Sites" />}
          />

          <MobileLink href={shop?.domain} label={shop?.name} />

          <MobileLink
            href={`https://goddess.me/?utm_medium=web&utm_source=${site.abbreviation}&utm_campaign=more-girls`}
            label="Intimate Toys"
          />
        </>
      )}
    </MobileExpandableMenuItem>
  );
};
