import React from 'react';
import { Field, reduxForm, SubmitHandler } from 'redux-form';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import GoButton from '@tovia/man-ui/lib/components/Button/Button';
import { useSelector } from '../../redux/modules/helpers/useSelector';
import { validateEmail } from 'src/client/utils/validators';

const formName = 'freeTrialForm';

type Props = {
  loading: boolean;
  success: boolean;
  className?: string;
  theme: object; // eslint-disable-line react/no-unused-prop-types
  submissionError?: boolean;
  handleSubmit: SubmitHandler<Props>;
};

const renderInputField = ({
  // eslint-disable-next-line react/prop-types
  meta: { error, touched },
  input,
  ...rest
}) => (
  <div>
    <input {...input} {...rest} disabled={rest.disabled} />
    {touched && error && <span className="submission-error">{error}</span>}
  </div>
);

const FreeTrialFormComponent = ({ submissionError = false, className = '', ...props }: Props) => {
  const { loading, handleSubmit, success } = props;
  const intl = useIntl();
  const cdnUrl = useSelector((state) => state.app.cdnUrl);

  return (
    <fieldset disabled={loading || success} className={className}>
      <form onSubmit={handleSubmit}>
        <Field
          component={renderInputField}
          name="email"
          id="email-input"
          className="email-input"
          type="email"
          placeholder={intl.formatMessage({
            id: 'freeTrialDialog.form.placeholder',
            defaultMessage: 'Enter your email to start your free account',
          })}
          disabled={loading || success}
          validate={validateEmail}
        />
        <GoButton
          className="submit"
          loading={loading || success}
          disabled={loading || success}
          type="submit"
          spinnerClass="go-spinner"
        >
          {intl.formatMessage({ id: 'freeTrialDialog.form.button', defaultMessage: 'GO!' })}
        </GoButton>
        {submissionError && (
          <span className="submission-error">An error occurred on submission, please try again later.</span>
        )}
        <div className="safety">
          <img
            src={`${cdnUrl}/freeTrialSignup/freeTrialDialogNotice.png`}
            alt={intl.formatMessage({ id: 'freeTrialDialog.form.notice', defaultMessage: 'No credit card required' })}
          />
        </div>
      </form>
    </fieldset>
  );
};

const StyledComp = styled(FreeTrialFormComponent)`
  .email-input {
    position: absolute;
    top: 78px;
    left: 62px;
    width: 422px;
    height: 43px;
    font-size: 12pt;

    > input {
      border: 0;
      height: inherit;
      padding: 0 16px;
      width: inherit;
    }

    > span {
      font-size: 10pt;
      padding: 0 16px;
    }
  }

  .submit {
    position: absolute;
    cursor: pointer;
    top: 74px;
    left: 520px;
    width: 83px;
    height: 51px;
    margin: 0;
    padding: 6px 9px;
    text-align: center;
    line-height: 33px;
    font-size: 18pt;
    border: 0;
    border-radius: 6px;
    color: #fff;
    background: linear-gradient(
      to bottom,
      rgba(81, 206, 18, 1) 0%,
      rgba(64, 163, 15, 1) 74%,
      rgba(64, 163, 15, 1) 100%
    );
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.3);
    opacity: 0.8;

    &.loading {
      background-color: #9d9d9d;
      color: transparent !important;
    }

    .go-spinner {
      display: inline-block;
      height: 32px;
      position: relative;

      .spinner {
        position: absolute;
        width: 40px;
        height: 40px;

        path {
          fill: rgba(0, 0, 0, 0.2);
        }
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  .submission-error {
    color: red;
    left: 64px;
    position: absolute;
    text-align: center;
    top: 122px;
  }

  .safety {
    position: absolute;
    top: 140px;
    text-align: center;
    width: 616px;
    height: 24px;
  }
`;

export default reduxForm<Props>({
  form: formName, // a unique name for this form
})(StyledComp);
