import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';
import { SearchBoxComponent } from '../../components/SearchBox/SearchBoxComponent';
import useAutoComplete from '../HeaderBar/helpers/useAutoComplete';

const formName = 'searchBar';

interface Props {
  searchTerm: string;
  isOpened: boolean;
  onClose: () => void;
}

interface FormInputs {
  searchTerm: string;
}

type AllProps = Props & InjectedFormProps<FormInputs, Props>;

export function SearchBox(props: AllProps) {
  const [autoCompleteValues, setAutoComplete] = useAutoComplete();

  const { searchTerm = '' } = props;

  useEffect(() => {
    setAutoComplete(searchTerm);
  }, [searchTerm, setAutoComplete]);

  const onClick = () => {
    setAutoComplete('');
  };

  return <SearchBoxComponent {...props} autoCompleteValues={autoCompleteValues} handleClick={onClick} />;
}

export default connect((state) => ({
  searchTerm: formValueSelector(formName)(state, 'searchTerm'),
}))(
  reduxForm<FormInputs, Props>({
    form: formName,
  })(SearchBox),
);
