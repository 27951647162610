import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import Badge from '@tovia/man-ui/lib/components/Badge/Badge';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import CardInformation from '@tovia/man-ui/lib/components/Card/CardInformation';
import Card from '@tovia/man-ui/lib/components/Card/Card';
import { Link } from 'react-router-dom';
import GirdCardMenuOverlay from '../../GridCardMenuOverlay';
import ModelSubscription from '../../../../components/modals/ModelSubscriptionModal';
import CardMedia from 'src/client/components/ui/Card/CardMedia';

const Wrapper = styled.div`
  &:not(.menu-opened) {
    &:hover {
      .card-menu-overlay {
        opacity: 1;
      }
    }
  }

  .card-menu-overlay {
    transition: 0.3s opacity;
    opacity: 0;
    color: #fff;
  }

  .card-media {
    overflow: hidden;
    padding-top: 150%;

    > a {
      position: absolute;
      top: 0;

      img {
        object-fit: contain;
      }
    }
  }
`;

type Props = {
  name: string;
  url: string;
  isBlurred: boolean;
  imgSrc: string;
  imgAlt: string;
  ratingAverage: string | number;
  views: string | number;
  isFavorited: boolean;
  onFavorite: () => void;
};
export default function ModelGridCard(props: Props) {
  const { name, url, imgSrc, imgAlt, ratingAverage, isBlurred, isFavorited, views, onFavorite } = props;

  const [isOpen, setOpen] = useState(false);
  const [showFollowModal, setFollowModalVisibility] = useState(false);

  return (
    <Wrapper className={`grid-card-wrapper ${isOpen ? 'menu-opened' : ''}`}>
      <Card>
        <CardMedia
          url={url}
          imgSrc={imgSrc}
          lazy
          animate
          altText={imgAlt}
          badges={[
            ...(views ? [<Badge key="views" text={views} icon={<FontAwesomeIcon icon={faEye} />} />] : []),
            ...(ratingAverage
              ? [<Badge key="rating" text={ratingAverage} icon={<FontAwesomeIcon icon={faStar} />} />]
              : []),
          ]}
          blurred={isBlurred}
        >
          <GirdCardMenuOverlay
            onFavorite={() => (!isFavorited ? setFollowModalVisibility(true) : onFavorite())}
            isFavorited={isFavorited}
            setOpen={setOpen}
            isOpen={isOpen}
          />
        </CardMedia>
        <CardInformation className="card-info-1" title={<Link to={url}>{name}</Link>} />
      </Card>
      {isOpen && (
        <ModelSubscription
          showModal={showFollowModal}
          onToggleFavor={onFavorite}
          hideModal={() => setFollowModalVisibility(false)}
        />
      )}
    </Wrapper>
  );
}

ModelGridCard.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  ratingAverage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  views: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFavorited: PropTypes.bool,
  onFavorite: PropTypes.func.isRequired,
};

ModelGridCard.defaultProps = {
  isFavorited: false,
  ratingAverage: 0,
  views: 0,
};
