import superagent from 'superagent';
import { logoUrl } from 'src/client/helpers/urls/logoUrl';

declare global {
  interface Window {
    cdnUrl: string;
    version: string;
  }
}

export const loadAppData = async () => {
  // Production is quick and easy, get in and get out.
  if (process.env.NODE_ENV !== 'development') {
    const appData = window.__INITIAL_STATE__;

    window.cdnUrl = appData.initialState.app.cdnUrl;
    window.version = appData.initialState.app.version;

    return appData;
  }

  // ipAddress (or any other query params) should be passed along to the
  // app-data request to simulate what loading the app would do in staging
  // or production
  const appDataUrl = `/api/app-data${window.location.search}`;

  try {
    const appData = (await superagent.get(appDataUrl)).body;

    window.cdnUrl = appData.initialState.app.cdnUrl;
    window.version = appData.initialState.app.version;
    document
      .querySelector('#loader img')
      ?.setAttribute('src', logoUrl(appData.initialState.app.cdnUrl, appData.initialState.site));
    document.querySelector('#preconnect-cdn-url')?.setAttribute('href', appData.initialState.app.cdnUrl);
    document.querySelector('#preconnect-content-cdn-url')?.setAttribute('href', appData.initialState.app.contentCdnUrl);
    document
      .querySelector('#preconnect-pconetent-cdn-url')
      ?.setAttribute('href', appData.initialState.app.contentProtectedCdnUrl);

    // return Promise.all([appData, (api as any).get('/api/user-data'), fetchTranslations('en')]);
    return appData;
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.warn('fetch app data error', error);
  }

  return null;
};
