import React, { useState } from 'react';
import cx from 'classnames';
import { ModelResponse } from 'src/@types/app';
import { useSelector, useSettingsSelector } from 'src/client/redux';
import { displayModelStat } from 'src/client/utils/converters';
import { getModelAttributes } from '../modelAttributes';
import { ModelInfoPanel } from './ModelInfoPanel';
import {
  Attributes,
  Biography,
  WideModelPageContent,
  ModelBio,
  ModelBioTitle,
  StyledActivityPanel,
  StyledRankingDetailsPanel,
  WideModelLayout,
  WideModelWrapper,
  ANIMATION_NAME,
  ANIMATION_DURATION,
  BioComingSoon,
  ModelBioWrapper,
} from './styles';

type Props = {
  model: ModelResponse;
  children: React.ReactNode;
};

export const WideModelPage = (props: Props) => {
  const { model } = props;
  const blogUrl = 'blogs' in model && model.blogs[0] ? model.blogs[0].path : null;

  const user = useSelector((state) => state.auth.user);
  const ratingsScale = useSettingsSelector<number>('ratingsScale');
  const measurements = useSettingsSelector('measurements');
  const config = useSelector((state) => state.app.config);

  const [isBioExpanded, setIsBioExpanded] = useState<boolean>(false);
  const [isBioMounted, setIsBioMounted] = useState<boolean>(false);

  const onToggleBio = () => setIsBioExpanded(!isBioExpanded);

  const showRanking = Boolean(config.leaderboard.active && model.ranks.week);

  const attributes = getModelAttributes(model);
  const showBio = Boolean(config.components.modelDetailsPanel.showModelBiography) && model.biography !== '0';

  return (
    <WideModelWrapper className="model-page">
      <WideModelLayout
        extended
        className={cx({ 'bio-toggled': isBioMounted, 'is-member': !!user })}
        showRanking={showRanking}
      >
        <ModelInfoPanel model={props.model} bioVisible={isBioMounted} onToggleBio={onToggleBio} />

        <StyledActivityPanel
          blogUrl={blogUrl}
          condensedView
          fullMode={user?.validSubscription}
          itemUrl={model.path}
          objectType="model"
          objectUUID={model.UUID}
          ratingAverage={parseFloat(model.ratingAverage.toFixed(ratingsScale))}
          ratings={model.ratingCount}
          views={model.views}
        />

        <ModelBioWrapper
          animationName={ANIMATION_NAME}
          animationDuration={ANIMATION_DURATION}
          isExpanded={isBioExpanded}
          mountCb={(mounted) => setIsBioMounted(mounted)}
          mountOnEnter
          unmountOnExit
        >
          <ModelBio showBio={showBio}>
            <Attributes>
              <ModelBioTitle>{model.name}</ModelBioTitle>
              {attributes
                .filter((attr) => !!attr.value)
                .map(({ label, statType, value }) => (
                  <div key={statType}>
                    <strong>{label}: </strong>
                    <span>{displayModelStat({ statType, value, measurements })}</span>
                  </div>
                ))}
            </Attributes>
            {showBio && (
              <Biography>
                <ModelBioTitle>Biography</ModelBioTitle>
                {model.biography ? model.biography : <BioComingSoon>Coming Soon</BioComingSoon>}
              </Biography>
            )}
          </ModelBio>
        </ModelBioWrapper>

        <WideModelPageContent>{props.children}</WideModelPageContent>

        {showRanking && <StyledRankingDetailsPanel ranks={model.ranks} type="model" className="model-ranking" />}
      </WideModelLayout>
    </WideModelWrapper>
  );
};
