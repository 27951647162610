import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TabsComponent = (props) => {
  const { className, tabs, toggleTab, activeTab } = props;

  const renderedTabs = tabs.map((tab) => (
    <li
      key={tab.title}
      className={`${activeTab === tab.value ? 'active' : ''}`}
      onClick={() => {
        toggleTab(tab.value);
      }}
    >
      <div className="bg-helper" />
      <span className="tab-title">
        {tab.title}
        {activeTab === tab.title && <FontAwesomeIcon icon={faSortDown} className="active-caret" />}
      </span>
      <div className="tab-overlay" />
      <div className="tab-bg" style={{ backgroundImage: `url(${tab.image})` }} />
    </li>
  ));

  return (
    <div className={`${className} tabs-wrap`}>
      <ul>{renderedTabs}</ul>
    </div>
  );
};

TabsComponent.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  toggleTab: PropTypes.func.isRequired,
  className: PropTypes.string,
};

// Same approach for defaultProps too
TabsComponent.defaultProps = {
  className: '',
};

export default withTheme(styled(TabsComponent)`
  &.tabs-wrap {
    max-width: 2130px;
    margin: 0 auto;
    padding: 0 15px 0;

    ul {
      width: 100%;
      margin: 20px 0;
      padding-left: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    li {
      .bg-helper {
        padding-top: 40%; // 231px/579px (0.399)
      }
      position: relative;
      display: flex;
      float: left;
      width: 33%;
      overflow: hidden;
      cursor: pointer;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    li:hover,
    li.active {
      .tab-overlay {
        opacity: 0;
      }
      .tab-title {
        text-shadow: 1px 2px 1px #000;
      }
      .active-caret {
        filter: drop-shadow(1px 2px 1px #000);
      }
    }

    li.active {
      .tab-bg {
        outline: 2px solid ${(props) => props.theme.primaryBtn1Bg};
        outline-offset: -2px;
      }
    }

    .tab-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text-align: center;
      display: inline-block;
      background: #000;
      opacity: 0.5;
      transition: opacity 0.75s ease;
      z-index: 1;
    }

    .tab-bg {
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    span.tab-title {
      font-size: 2.6rem;
      color: #fff;
      text-transform: uppercase;
      position: absolute;
      top: 40%;
      display: inline-block;
      width: 100%;
      text-align: center;
      text-shadow: none;
      z-index: 1;

      @media (max-width: 768px) {
        font-size: 1.7rem;
      }

      .active-caret {
        text-shadow: none;
        line-height: 10px;
        display: block;
        width: 100%;
        margin-top: -0.35em;
      }
    }
  }
`);
