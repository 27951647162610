import React from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'src/client/redux';
import { MOBILE_LANDSCAPE, TABLET_LANDSCAPE } from 'src/client/constants/mediaQueries';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

const Background = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1032;
`;

const CenteredBox = styled.div`
  max-width: 1240px;
  position: relative;
  width: 94%;

  @media ${MOBILE_LANDSCAPE} {
    @media (orientation: landscape) {
      max-width: 1000px;
      width: 75%; // doing this because landscape with the mobile popup is too large, switching to regular popup below
    }
  }

  @media ${TABLET_LANDSCAPE} {
    max-width: 1000px;
  }
`;

const CloseButton = styled.div`
  align-items: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  height: 30px;
  justify-content: center;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 30px;
`;

type ImageLinkProps = {
  popupDesktop: string;
  popupMobile: string;
};

const ImageLink = styled.a<ImageLinkProps>`
  aspect-ratio: 1240/1136;
  background-image: url(${(props) => props.popupMobile});
  background-size: contain;
  display: block;

  // switching to regular popup here for mobile landscape mode
  @media ${MOBILE_LANDSCAPE} {
    @media (orientation: landscape) {
      aspect-ratio: 1000/562;
      background-image: url(${(props) => props.popupDesktop});
    }
  }

  @media ${TABLET_LANDSCAPE} {
    aspect-ratio: 1000/562;
    background-image: url(${(props) => props.popupDesktop});
  }
`;

type Props = {
  onClose: () => void;
};

export const PromoPopup = (props: Props) => {
  const { onClose } = props;
  const { cdnUrl } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);
  const [joinHref] = useJoinUrl({ campaign: 'ad-zone-popup' });
  const showSfwSite = useShowSfwSite();

  const siteAbbreviation = site.abbreviation.toLowerCase();
  const assetDirectoryPath = `${cdnUrl}/network-promo-banners/${siteAbbreviation}`;
  const nonNudeText = showSfwSite ? '_nonnude' : '';

  const popupDesktop = `${assetDirectoryPath}/popup${nonNudeText}.jpg`;
  const popupMobile = `${assetDirectoryPath}/banner_phone${nonNudeText}.jpg`; // reusing mobile banner as mobile popup

  return (
    <Background>
      <CenteredBox>
        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <ImageLink href={joinHref} popupDesktop={popupDesktop} popupMobile={popupMobile} />
      </CenteredBox>
    </Background>
  );
};
