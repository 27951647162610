import styled from 'styled-components/macro';

export const SkinnyPage = styled.div.attrs({
  // bootstrap styles
  className: 'content container',
})`
  max-width: 950px;
  padding-left: 30px;
  padding-right: 30px;
`;
