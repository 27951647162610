import React, { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { Panel, AdvancedModal, DESKTOP, MOBILE_LANDSCAPE } from '@tovia/man-ui';
import { ModalDialog } from '@tovia/man-ui/lib/components/Modal/AdvancedModal';
import { BlurrableImage } from 'src/client/components/ui/BlurredImage';

type Props = {
  isBlurred: boolean;
  imgSrc: string;
  imgAlt: string;
  children: ReactNode;
  className?: string;
  largeImageSrc?: string;
};

export const ImageDetailsPanel = (props: Props) => {
  const { className, imgAlt, imgSrc, largeImageSrc, isBlurred } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const modalImageRef = useRef<HTMLImageElement>(null);

  return (
    <PanelWrapper className={className}>
      <StyledPanel>
        <PosterImage src={imgSrc} alt={imgAlt} onClick={() => setModalOpen(true)} $isBlurred={isBlurred} />
        <InfoContainer className="info-container">{props.children}</InfoContainer>
      </StyledPanel>
      <StyledModal show={modalOpen} onClose={() => setModalOpen(false)} zIndex={2000} DialogComponent={MyDialog}>
        <ModalImage src={largeImageSrc || imgSrc} alt={imgAlt} ref={modalImageRef} />
      </StyledModal>
    </PanelWrapper>
  );
};

const MyDialog = styled(ModalDialog)`
  max-height: 80vh;

  @media ${DESKTOP} {
    max-height: 90vh;
  }
`;

const StyledModal = styled(AdvancedModal)`
  padding: 0;
  display: flex;

  & > div {
    display: flex;
  }
`;

const InfoContainer = styled.div`
  flex: 1 1 100%;

  @media ${MOBILE_LANDSCAPE} {
    flex: 1 1 50%;
  }
`;

const ModalImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  border-radius: 6px;
  object-fit: contain;
  flex: 1 1 auto;
`;

const PanelWrapper = styled.div`
  .panel-content {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;
    padding: 0 !important;
    flex-wrap: wrap;

    @media ${MOBILE_LANDSCAPE} {
      flex-wrap: nowrap;
    }
  }
`;

const PosterImage = styled(BlurrableImage)<{ $isBlurred: boolean }>`
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 0;
  flex: 0 0 auto;
  max-width: 100%;

  // Disable clicking the poster-image for mobile
  pointer-events: none;

  @media ${MOBILE_LANDSCAPE} {
    margin-bottom: 0;
    margin-right: 20px;
    pointer-events: ${(props) => (props.$isBlurred ? 'none' : 'auto')};
  }
`;

const StyledPanel = styled(Panel)`
  .panel-content {
    position: relative;
  }
`;
