import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { ReduxStoreProvider } from './ReduxStoreProvider';
import { DebugProvider } from './DebugProvider';
import { ThemeProvider } from './ThemeProvider';
import { RootState } from 'src/client/redux/modules/reducer';

type Props = {
  initialState: RootState;
  children: React.ReactNode;
};

const queryClient = new QueryClient();

export const ExternalProviders = (props: Props) => {
  const ReduxProvider = process.env.NODE_ENV === 'production' ? ReduxStoreProvider : DebugProvider;

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <ReduxProvider initialState={props.initialState}>
          <BrowserRouter>
            <ThemeProvider colorThemes={props.initialState.theme.colors}>{props.children}</ThemeProvider>
          </BrowserRouter>
        </ReduxProvider>
      </CookiesProvider>
    </QueryClientProvider>
  );
};
