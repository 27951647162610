import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from '../../redux/modules/helpers/useSelector';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';

interface PromoFooterBannerContainerProps {
  isVisible: boolean;
}

interface PromoFooterBannerImageProps {
  desktopImage: string;
  tabletImage: string;
  mobileImage: string;
}

export const PromoFooterBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const { cdnUrl } = useSelector((state) => state.app);
  const [joinHref] = useJoinUrl({ campaign: 'ad-zone-footer-popup' });

  const desktopImage = `${cdnUrl}/network-promo-banners/footer.jpg`;
  const tabletImage = `${cdnUrl}/network-promo-banners/footer_tablet.jpg`;
  const mobileImage = `${cdnUrl}/network-promo-banners/footer_iphone.jpg`;

  return (
    <PromoFooterBannerContainer isVisible={isVisible}>
      <PromoFooterBannerImage
        href={joinHref}
        desktopImage={desktopImage}
        tabletImage={tabletImage}
        mobileImage={mobileImage}
      />
      <PromoFooterBannerClose onClick={() => setIsVisible(false)}>
        <FontAwesomeIcon icon={faTimes} />
      </PromoFooterBannerClose>
    </PromoFooterBannerContainer>
  );
};

const PromoFooterBannerContainer = styled.div<PromoFooterBannerContainerProps>`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  z-index: 1032;
`;

const PromoFooterBannerImage = styled.a<PromoFooterBannerImageProps>`
  display: block;
  width: 300px;
  height: 80px;
  background-image: url(${({ mobileImage }) => mobileImage});

  @media (min-width: 768px) and (max-width: 1280px) {
    width: 720px;
    height: 130px;
    background-image: url(${({ tabletImage }) => tabletImage});
  }

  @media (min-width: 1280px) {
    width: 980px;
    height: 180px;
    background-image: url(${({ desktopImage }) => desktopImage});
  }
`;

const PromoFooterBannerClose = styled.div`
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
  display: flex;
  background: white;
  border-radius: 50%;
  color: black;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
`;
