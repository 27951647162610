/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker, { unregisterServiceWorker } from './client/registerServiceWorker';
import { App } from './client/containers/App';
import { loadAppData } from './client/helpers';
import { setupInitialReduxState } from './client/helpers';
import { ExternalProviders, ManSiteProviders } from './client/components/Providers';

const loadApp = (initialState) => {
  const reactRoot = document.getElementById('content');

  ReactDOM.render(
    <ExternalProviders initialState={initialState}>
      <ManSiteProviders>
        <App />
      </ManSiteProviders>
    </ExternalProviders>,
    reactRoot,
  );

  if (process.env.NODE_ENV !== 'production') {
    window.React = React; // enable debugger
    unregisterServiceWorker();
  } else {
    registerServiceWorker('/sw.js');
  }
};

loadAppData()
  .then(setupInitialReduxState)
  .then(loadApp)
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.error('Cant get start application', error);
  });
