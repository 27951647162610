import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';

export const SecurityIconsSectionComponent = (props) => {
  const { show, className, items } = props;

  if (!show) return null;

  return (
    <section className={`${className} security-icons-wrapper`}>
      <div className="container security-icons-title">
        <span>Guaranteed Safe & Secure Checkout</span>
      </div>
      <div className="container text-center">
        {items.map((item) => (
          <a
            href={item.linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            key={item.imageUrl}
            className="security-icon"
          >
            <img src={item.imageUrl} alt={item.imageAlt} />
          </a>
        ))}
      </div>
    </section>
  );
};

SecurityIconsSectionComponent.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      imageAlt: PropTypes.string.isRequired,
      linkUrl: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

// Same approach for defaultProps too
SecurityIconsSectionComponent.defaultProps = {
  className: '',
  show: true,
};

export default withTheme(styled(SecurityIconsSectionComponent)`
  &.security-icons-wrapper {
    background-color: ${(props) => props.theme.splashOptional2 || props.theme.splashPrimary2};
    padding: 30px 0;

    .security-icons-title {
      overflow: hidden;
      text-align: center;

      span {
        color: black;
        display: inline-block;
        margin: 12px;
        position: relative;
        text-transform: uppercase;

        :before,
        :after {
          border-bottom: 1px solid #333;
          content: '';
          display: inline-block;
          position: absolute;
          top: 50%;
          width: 0;

          @media (min-width: 640px) {
            width: 150px;
          }

          @media (min-width: 768px) {
            width: 450px;
          }
        }

        :before {
          margin-right: 12px;
          right: 100%;
        }

        :after {
          left: 100%;
          margin-left: 12px;
        }
      }
    }

    .security-icon {
      margin: 0 12px;

      > img {
        width: 125px;

        @media (min-width: 992px) {
          width: 105px;
        }
      }
    }
  }
`);
