import { DESKTOP, MOBILE_LANDSCAPE, TABLET_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
import CountTabs from 'src/client/containers/CountTabs/CountTabs';
import styled from 'styled-components/macro';

export const GalleriesTabsGrid = styled.div`
  display: grid;
  width: 100%;

  &.list-view-grid {
    column-gap: 15px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media ${MOBILE_LANDSCAPE} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media ${TABLET_LANDSCAPE} {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media ${DESKTOP} {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .cams-title {
      display: none;

      @media ${TABLET_PORTRAIT} {
        display: block;
        grid-column: span 1;
      }

      @media ${TABLET_LANDSCAPE} {
        grid-column: span 2;
      }
    }
  }

  &.list-view-detailed {
    column-gap: 10px;

    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media ${TABLET_PORTRAIT} {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media ${TABLET_LANDSCAPE} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .cams-title {
      display: none;

      @media ${TABLET_PORTRAIT} {
        display: block;
        grid-column: span 1;
      }
    }
  }
`;

// If the GalleriesTabs div is not the only child, then it means that the
// CamsTabs are visible so we need to take up less grid space
export const GalleriesTabs = styled(CountTabs)`
  grid-column-start: 1;
  grid-column-end: -1;

  .list-view-grid & {
    @media ${TABLET_PORTRAIT} {
      &:not(:only-child) {
        grid-column-end: -2;
      }
    }

    @media ${TABLET_LANDSCAPE} {
      &:not(:only-child) {
        grid-column-end: -3;
      }
    }
  }

  .list-view-detailed & {
    @media ${TABLET_PORTRAIT} {
      &:not(:only-child) {
        grid-column-end: -2;
      }
    }
  }
`;
