import React, { ReactNode, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { ensure } from 'src/client/utils/type-utils';
import { setValue } from 'src/client/redux/modules/settings';

import Select from 'react-select';
import { SettingData, SettingDataOption } from './settingsData';
import { StyledIcon } from 'src/client/components/ui/StyledIcon';

export const SettingsSelectBox = (props: SettingData) => {
  const { label, settingKey, iconDefinition, options } = props;

  const config = useSelector((state) => state.app.config);
  const { values, enums } = useSelector((state) => state.settings);
  const setting = ensure(values.find((value) => value.key === settingKey));
  const currentEnum = ensure(enums.find((e) => e.key === settingKey));

  const optionFilter = useCallback(
    ({ filter, inverted }: SettingDataOption) => {
      const value = ensure(filter)
        .split('.')
        .reduce((prev, curr) => (prev ? prev[curr] : null), config);
      return inverted ? value : !value;
    },
    [config],
  );

  const settingOptions = useMemo(() => {
    return currentEnum.options.reduce((prev, current) => {
      const option = options.find((cOption) => cOption.value === current.value);

      if (option?.filter && optionFilter(option)) return prev;

      prev.push({
        key: settingKey,
        value: current.value,
        label: option ? <SettingsOption {...option} /> : current.value,
      });

      return prev;
    }, [] as Array<{ key: string; value: string | number; label: ReactNode }>);
  }, [options, settingKey, currentEnum, optionFilter]);

  return (
    <div className="col-md-3 col-sm-4 col-xs-6 v-offset-15">
      <div>
        <StyledIcon icon={iconDefinition} $spaceRight />
        <FormattedMessage id={label.id} defaultMessage={label.defaultMessage} tagName="strong" />
      </div>

      <div className="v-offset-10">
        <StyledSelect
          name={settingKey}
          instanceId={settingKey}
          value={setting.value}
          searchable={false}
          clearable={false}
          options={settingOptions}
          onChange={(option) => setValue(option.key, option.value)}
        />
      </div>
    </div>
  );
};

const SettingsOption = (props: SettingDataOption) => {
  if (!props.id) return <>{props.label}</>;
  return <FormattedMessage id={props.id} defaultMessage={props.defaultMessage} />;
};

const StyledSelect = styled(Select)`
  .Select-menu-outer {
    max-height: inherit;
  }
  .Select-menu {
    max-height: inherit;
  }
`;
