import React from 'react';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';
import styled from 'styled-components/macro';

type Props = {
  galleryCoverUrlHover: string;
  galleryVideoUrl: string;
};

const GalleryVideoBackground = styled.video<{ visible: boolean }>`
  max-width: 100%;
  display: block;

  // Using visibility instead of display:none or returning null because the
  // layout is based on the video element being present
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  @media (max-width: 1024px) {
    vertical-align: baseline;
  }
`;

export const GalleryVideo = (props: Props) => {
  const showSfwSite = useShowSfwSite();
  const shouldAutoplay = window.innerWidth >= 768 && !showSfwSite;
  const preloadValue = shouldAutoplay ? 'auto' : 'none';

  return (
    <GalleryVideoBackground
      visible={!showSfwSite}
      autoPlay={shouldAutoplay}
      preload={preloadValue}
      poster={props.galleryCoverUrlHover}
      muted
      loop
    >
      <source src={props.galleryVideoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </GalleryVideoBackground>
  );
};
