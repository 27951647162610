import React from 'react';
import styled from 'styled-components/macro';
import Placeholder from '@tovia/man-ui/lib/components/Placeholder/Placeholder';

export const PaginationPlaceholder = () => {
  const { Line } = Placeholder;

  return (
    <PaginationWrap>
      <Line medium thick />
    </PaginationWrap>
  );
};

const PaginationWrap = styled(Placeholder)`
  display: flex;
  justify-content: center;
  margin: 15px 0 !important;
`;
