import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { secondsToRuntime } from 'src/client/utils/converters';

import { faCameraAlt } from '@fortawesome/pro-light-svg-icons';
import { faVideo } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GalleryVideo } from 'src/client/components/splash/Network/GalleryVideo';
import { useGetGalleryCoverUrl } from 'src/client/helpers/urls';
import { CoverAttributes } from 'src/client/helpers/urls/useGetGalleryCoverUrl';
import { BlurrableImage } from '../../ui/BlurredImage';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

type Props = {
  coverAttributes: CoverAttributes;
  coverAttributesHover: CoverAttributes;
  gallerySite?: string;
  galleryUrl: string;
  galleryVideoUrl: string;
  imageCount?: number;
  models?: string;
  publishedAt?: string;
  runtime?: number;
  siteUUID: string;
  title: string;
  type: string;
  UUID: string;
};

const GalleryWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const GalleryInnerWrapper = styled.div`
  max-width: 100%;
`;

const GalleryOverlay = styled.div`
  color: transparent;
  position: absolute;
  flex-basis: 100%;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  z-index: 3;
  img {
    transition: 0.3s;
    opacity: 0;
  }
  :hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    img {
      opacity: 1;
    }
  }
  @media (max-width: 1024px) {
    font-size: 48px;
    img {
      max-width: 44px;
    }
  }
`;

const GalleryInformation = styled.div`
  padding: 0 0 20px 10px;
  width: 100%;
  max-width: 97%;

  @media (max-width: 1024px) {
    margin-bottom: 10px;
    padding: 0 0 0 9px;
  }
`;

const GalleryTitle = styled.span`
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 24px;
  margin: 5px 0 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const GalleryModels = styled.span`
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #f7bc74;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0 3px;
  @media (max-width: 1024px) {
    font-size: 15px;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

const GalleryDate = styled.span`
  display: block;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #9d9d9d;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const GalleryCount = styled.span`
  display: block;
  width: 76px;
  height: 38px;
  line-height: 38px;
  border-radius: 10px;
  background: #151419;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 10px;
  @media (max-width: 1024px) {
    width: 60px;
    height: 21px;
    line-height: 21px;
    font-size: 12px;
    bottom: 10px;
  }
  @media (max-width: 768px) {
    bottom: 7px;
  }
`;

const GalleryLink = styled(Link)<{ $showSfwSite: boolean }>`
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  position: relative;
  display: block;
  overflow: hidden;
  text-decoration: none;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  img:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img:nth-child(3) {
    transition: transform 0.2s ease-in-out;
    transform-origin: center center;
  }
  ${(props) =>
    !props.$showSfwSite &&
    css`
      :hover,
      :focus {
        text-decoration: none;
        color: #fff;
        img:nth-child(2) {
          opacity: 0;
        }
        img:nth-child(3) {
          opacity: 1;
          transform: scale(1.1);
          transform-origin: center center;
        }
      }
    `}
`;

const GalleryImage = styled(BlurrableImage)`
  max-width: 100%;
  display: block;
  @media (max-width: 1024px) {
    vertical-align: baseline;
  }
`;

export const Gallery = (props: Props) => {
  const { cdnUrl, contentCdnUrl } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);
  const getGalleryCoverUrl = useGetGalleryCoverUrl();
  const showSfwSite = useShowSfwSite();

  const item = {
    thumbnailCoverPath: '',
    UUID: props.UUID,
    siteUUID: props.siteUUID,
  };

  const galleryCover = getGalleryCoverUrl(contentCdnUrl, item, props.coverAttributes);
  const galleryCoverHover = getGalleryCoverUrl(contentCdnUrl, item, props.coverAttributesHover);

  return (
    <GalleryWrapper className={props.type}>
      <GalleryInnerWrapper>
        <GalleryLink to={props.galleryUrl} $showSfwSite={showSfwSite}>
          <GalleryOverlay>
            {(props.type == 'GALLERY' && <img src={`${cdnUrl}/splash/${site.networkUUID}/view.png`} />) || (
              <img src={`${cdnUrl}/splash/${site.networkUUID}/play.png`} alt="View" />
            )}
          </GalleryOverlay>
          <GalleryImage src={galleryCover.url} alt={props.title} $isBlurred={galleryCover.blurImage} />
          {props.type == 'GALLERY' && (
            <GalleryImage src={galleryCoverHover.url} alt={props.title} $isBlurred={galleryCoverHover.blurImage} />
          )}
          {props.type == 'MOVIE' && (
            <GalleryVideo galleryVideoUrl={props.galleryVideoUrl} galleryCoverUrlHover={galleryCoverHover.url} />
          )}
        </GalleryLink>
        <GalleryInformation>
          <GalleryTitle>{props.title}</GalleryTitle>
          <GalleryModels>{props.models}</GalleryModels>
          <GalleryDate>
            {props.gallerySite} | {props.publishedAt}
          </GalleryDate>
          {props.type == 'GALLERY' && (
            <GalleryCount>
              <FontAwesomeIcon icon={faCameraAlt} /> {props.imageCount}
            </GalleryCount>
          )}
          {props.type == 'MOVIE' && (
            <GalleryCount>
              <FontAwesomeIcon icon={faVideo} /> {secondsToRuntime(props.runtime)}
            </GalleryCount>
          )}
        </GalleryInformation>
      </GalleryInnerWrapper>
    </GalleryWrapper>
  );
};
