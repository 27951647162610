import React, { ReactElement, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { destructUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import { GalleryDetailedCard } from 'src/client/containers/Cards/Detailed/Gallery/GalleryDetailedCard';
import { GalleryGridCard } from 'src/client/containers/Cards/Grid/Gallery/GalleryGridCard';
import { rateItem } from 'src/client/redux/modules/ratingInfo';
import { addFavor, removeFavor } from 'src/client/redux/modules/favoriteInfo';
import { spriteUrl } from 'src/client/helpers/urls';
import comingSoonImg from 'src/client/utils/images/coming-soon.png';
import { CamsContext } from '../../Cams/CamsContextProvider';
import { formatViews } from 'src/client/utils/converters';
import { useSelector, useSettingsSelector } from 'src/client/redux';
import { CamsSection } from 'src/client/containers/Cams/CamsSection/CamsSection';
import { Dispatch } from 'redux';
import { GridCardsWrapper } from 'src/client/containers/Cards/GridCardsWrapper';
import { Gallery } from 'src/@types/Gallery';
import { redirectToJoin, useJoinUrlGenerator } from 'src/client/components/buttons/JoinButton';
import cx from 'classnames';
import { generateGalleryAlt } from 'src/client/utils/generateGalleryAlt';
import { SearchResultGallery } from '../../../../@types/SearchResultsTypes';
import { XOR } from 'src/shared/xor';
import { useGetGalleryCoverUrl } from 'src/client/helpers/urls/useGetGalleryCoverUrl';

const networkDomain = 'metartnetwork.com';

type HandleFavoriteChangeFn = (params: {
  gallery: XOR<SearchResultGallery, Gallery>;
  isUser?: boolean;
  isFavorited: boolean;
  dispatch: Dispatch;
  showUpgradeDialog: (target: string) => {};
}) => () => void;

const handleFavoriteChange: HandleFavoriteChangeFn = (params) => {
  if (params.isUser) {
    return () => {
      const favorParams = {
        objectUUID: params.gallery.UUID,
        objectType: params.gallery.type?.toUpperCase(),
      };

      const dispatchAction = params.isFavorited ? removeFavor : addFavor;
      params.dispatch(dispatchAction(favorParams));
    };
  }

  return () => params.showUpgradeDialog('favorite-gallery-card');
};

type HandleRatingSubmitFn = (params: {
  isUser?: boolean;
  gallery: XOR<SearchResultGallery, Gallery>;
  dispatch: Dispatch;
  showUpgradeDialog: (target: string) => {};
}) => (rating?: number) => void;

const handleRatingSubmit: HandleRatingSubmitFn = (params) => {
  if (params.isUser) {
    return (rating) => {
      params.dispatch(
        rateItem({
          objectUUID: params.gallery.UUID,
          objectType: 'GALLERY',
          rating,
        }),
      );
    };
  }

  return () => params.showUpgradeDialog('rate-gallery-card');
};

const getPlayedCookie = (resumableCookie?: Record<string, string>) => {
  if (!resumableCookie) {
    return {};
  }

  return Object.keys(resumableCookie).reduce((acc, val) => {
    const [resumeAt, duration] = resumableCookie[val].split(':');
    let percentage = (parseInt(resumeAt) / parseInt(duration)) * 100;
    percentage = Math.ceil(percentage / 10) * 10;
    return Object.assign(acc, { [val]: percentage });
  }, {});
};

type Props = {
  galleries: XOR<SearchResultGallery, Gallery>[];
  viewPeriod?: string;
  camsCount?: number;
  showCams?: boolean;
  showFullCamThumbnails?: boolean;
  seoInjectedComponent?: ReactElement;
  tag?: string;
};

export function GalleryCards(props: Props) {
  const { galleries, viewPeriod, camsCount, seoInjectedComponent = null, tag } = props;

  const dispatch = useDispatch();
  const site = useSelector((state) => state.site);
  const user = useSelector((state) => state.auth.user);
  const config = useSelector((state) => state.app.config);
  const { hasWideMovieCovers, imgPlaceholderPath } = config;
  const view = useSettingsSelector<string>('listView');
  const ratingsScale = useSettingsSelector<number>('ratingsScale');
  const { contentCdnUrl, defaultCdnUrl, siteSubdomain } = useSelector((state) => state.app);
  const ratingInfo = useSelector((state) => state.ratingInfo.ratings);
  const favoriteInfo = useSelector((state) => state.favoriteInfo.favoritingObjectUUIDs);
  const playedMovies = useSelector((state) => state.playedMovies.items);
  const [cookies] = useCookies(['resumableVideos']);
  const cams = useContext(CamsContext);
  const generateJoinUrl = useJoinUrlGenerator();
  const getGalleryCover = useGetGalleryCoverUrl();

  const resumableCookie = cookies.resumableVideos || false;

  const played = playedMovies.reduce((acc, val) => Object.assign(acc, { [destructUUID(val)]: 10 }), {});
  const galleryPlaceholderImgSrc = `${contentCdnUrl}/${imgPlaceholderPath.gallery}`;
  const moviePlaceholderImgSrc = `${contentCdnUrl}/${imgPlaceholderPath.movie}`;

  const playedCookies = getPlayedCookie(resumableCookie);

  const playedMoviesInfo = Object.assign({}, played, playedCookies);
  const GalleryCardComponent = view === 'detailed' ? GalleryDetailedCard : GalleryGridCard;
  const isUser = user && user.validSubscription;

  const cards = galleries.map((gallery) => {
    const isMovie = ((gallery.type as unknown) as string).toLowerCase() === 'movie';
    const isNetworkWideGallery = gallery?.isStaffSelection || gallery?.isIntimateSelection;
    const isFavorited = favoriteInfo.includes(gallery.UUID);
    const wideThumbnail = !!(hasWideMovieCovers && isMovie);
    const placeholderImgSrc = isMovie ? moviePlaceholderImgSrc : galleryPlaceholderImgSrc;
    let movieProgress = 0;
    if (playedMoviesInfo) {
      movieProgress = playedMoviesInfo[gallery.UUID] || 0;
    }

    let { views } = gallery;
    if (viewPeriod) {
      views = gallery?.leaderboardViews?.[viewPeriod];
    }

    const itemSpriteUrl = isMovie && !isNetworkWideGallery ? spriteUrl(gallery, contentCdnUrl, gallery.siteUUID) : null;

    const useExternalLinkForModelOrArtist = isNetworkWideGallery && site.UUID !== site.networkUUID;
    const galleryCover = getGalleryCover(isNetworkWideGallery ? defaultCdnUrl : contentCdnUrl, gallery, {
      clean: isNetworkWideGallery ? false : config.cleanGalleryCovers,
      thumbnail: true,
      wide: wideThumbnail,
    });

    const itemData = {
      isNetworkWideGallery,
      useExternalLinkForModelOrArtist,
      wideThumbnail,
      url: gallery.path,
      imgSrc: gallery.hasCover ? galleryCover.url || comingSoonImg : placeholderImgSrc,
      isBlurred: gallery.hasCover && galleryCover.blurImage,
      imgAlt: generateGalleryAlt(gallery.name, gallery.models, tag),
      title: gallery.name,
      timestamp: gallery.publishedAt,
      views: formatViews(views),
      isIntimateSelection: gallery.isIntimateSelection,
      isStaffSelection: gallery.isStaffSelection,
      models: gallery.models,
      movieProgress,
      isFavorited,
      onFavorite: handleFavoriteChange({
        isUser,
        gallery,
        isFavorited,
        dispatch,
        showUpgradeDialog: () => redirectToJoin(generateJoinUrl, 'gallery-favorite'),
      }),
      onUpgrade: () => redirectToJoin(generateJoinUrl, 'gallery-upgrade'), // TODO: add to menu overlay if not user
      spriteUrl: itemSpriteUrl,
      ratingAverage: gallery.ratingAverage.toFixed(ratingsScale),
      imageCount: gallery.imageCount,
      runtime: gallery.runtime,
      photographer: {
        text: gallery.photographers[0] && gallery.photographers[0].name,
        url: useExternalLinkForModelOrArtist
          ? `//${siteSubdomain}.${networkDomain}${gallery.photographers[0].path}`
          : gallery.photographers[0].path,
      },
      ratingStars: {
        userRating: ratingInfo[gallery.UUID] && ratingInfo[gallery.UUID],
        submitRating: handleRatingSubmit({
          isUser,
          gallery,
          dispatch,
          showUpgradeDialog: () => redirectToJoin(generateJoinUrl, 'gallery-rating'),
        }),
        hideLabel: true,
        rating: gallery.ratingAverage.toFixed(ratingsScale),
      },
      isMovie,
      isPromoGallery: gallery.isPromoGallery,
      favoriteCount: gallery.favoriteCount,
      showRating: !gallery.isStaffSelection,
      categories: gallery.categories,
      showPhotographer: config.components.contentList.showPhotographer,
      showCategories: config.components.contentList.showCategories,
      showGalleryName: config.components.contentList.showGalleryName,
    };

    if (
      gallery.isPromoGallery &&
      user?.subscriptions?.some((subscription) => destructUUID(subscription.siteUUID) === gallery.siteUUID)
    ) {
      return null;
    }

    return <GalleryCardComponent key={gallery.UUID} {...itemData} />;
  });

  if (seoInjectedComponent) {
    cards.push(seoInjectedComponent);
  }

  const showCamsSection = props.showCams && cams.loaded;

  return (
    <GridCardsWrapper className={cx({ 'injected-seo-text': seoInjectedComponent })}>
      {cards}
      {showCamsSection ? (
        <CamsSection itemCount={camsCount} showFullCamThumbnails={props.showFullCamThumbnails} />
      ) : null}
    </GridCardsWrapper>
  );
}
