import { take, call, fork, select, put } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { SORT_TYPES } from 'src/shared/constants/movies';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';

const LOAD = 'man-site/photographer/LOAD';
const LOAD_SAGA = 'man-site/photographer/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/photographer/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/photographer/LOAD_FAIL';

const endpoint = constructUrl(urls.get.photographer);

const initialState = {
  loading: false,
  loaded: false,
  item: false,
  lastCallParams: {},
  error: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      const { lastCallParams } = action;
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        item: false,
        lastCallParams,
      };
    }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        item: initialState.item,
      };
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams, params) {
  return isEqual(lastCallParams, params);
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => ({ ...state.photographer, siteUUID: state.site.UUID });
  const photographerState = yield select(getState);
  const { lastCallParams } = photographerState;
  if (
    (!isLoaded(lastCallParams, params) && !photographerState.loading) ||
    (!photographerState.loading && !photographerState.loaded)
  ) {
    const sortType = SORT_TYPES.find((ele) => ele.id === params.sortBy);
    const finalParams = {
      ...params,
      ...(sortType
        ? {
            order: sortType.order,
            direction: sortType.direction,
          }
        : {}),
    };

    delete finalParams.sortBy;

    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client) => client.get(endpoint, { params: finalParams }),
      {
        lastCallParams: { ...params },
        siteUUID: photographerState.siteUUID,
      },
    );

    yield call(loadFunc);
  }

  yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

  const newState = yield select();
  if (newState.auth.user && newState.photographer.item) {
    const objectUUIDs = [
      newState.photographer.item.UUID,
      ...newState.photographer.item.galleries.map((gallery) => gallery.UUID),
      ...newState.photographer.item.movies.map((movie) => movie.UUID),
    ];
    yield put(
      loadRatingInfo({
        objectUUIDs,
      }),
    );
    yield put(
      loadFavoriteInfo({
        objectUUIDs,
      }),
    );
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
