import { ApiClient } from 'src/client/helpers';
import { AnalyticsDataPayload, ConversionType } from 'src/shared/constants/userMeasurement';

export const signalAnalyticsData = async (data: AnalyticsDataPayload, uniqueUUID: string, type: ConversionType) => {
  if (!uniqueUUID) {
    return;
  }
  const api = new ApiClient();
  try {
    await api.post('/api/analytics', {
      data: { data, uniqueUUID, type: type },
    });
  } catch (err) {
    return err as Error;
  }
};
