import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { setLastViewedNotification } from '../../redux/modules/siteNotifications';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FlatPopover } from './styles';
import { useSelector } from 'src/client/redux';

type Props = {
  username: string;
  onRecentsClick: () => void;
  closeNotificationPanel: () => void;
  className?: string;
  siteNotificationBadgeCount: number;
  toggleProfileMenu: (next: boolean) => void;
};

export const UserMenu = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    username,
    siteNotificationBadgeCount,
    closeNotificationPanel,
    onRecentsClick,
    toggleProfileMenu,
    ...rest
  } = props;

  const intl = useIntl();
  const { ssoUrl, config } = useSelector((state) => state.app);

  const { hasSiteNotifications, hasAccountCenter, isWhiteLabel } = config;
  const dispatch = useDispatch();

  const handleNewsClick = useCallback(() => {
    closeNotificationPanel();
    const timestamp = moment().unix();
    dispatch(setLastViewedNotification({ timestamp }));
  }, [dispatch, closeNotificationPanel]);

  return (
    <>
      <UserPopover ref={ref} {...rest}>
        <Dropdown className="dropdown-menu" onClick={() => toggleProfileMenu(false)}>
          <Header>
            <Avatar className="avatar">{username[0]}</Avatar>
            <UserLabel className="user-label">{username}</UserLabel>
          </Header>
          <Divider />
          {!isWhiteLabel && hasAccountCenter && (
            <li>
              <a href={ssoUrl} target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'header.userMenu.manageAccount', defaultMessage: 'Manage Account' })}
              </a>
            </li>
          )}
          {!isWhiteLabel && (
            <li className="hidden-xs">
              <a id="recents" className="recents-link" onClick={onRecentsClick}>
                {intl.formatMessage({ id: 'header.userMenu.recents', defaultMessage: 'Recents' })}
              </a>
            </li>
          )}
          {!isWhiteLabel && (
            <li>
              <NavLink to="/favorites">
                {intl.formatMessage({ id: 'header.userMenu.favorites', defaultMessage: 'Favorites' })}
              </NavLink>
            </li>
          )}
          {!isWhiteLabel && (
            <li>
              <NavLink to="/ratings">
                {intl.formatMessage({ id: 'header.userMenu.ratings', defaultMessage: 'Ratings' })}
              </NavLink>
            </li>
          )}
          {hasSiteNotifications && (
            <li>
              <span className="dropdown-item new-releases" onClick={handleNewsClick}>
                {intl.formatMessage({ id: 'header.userMenu.news', defaultMessage: 'News' })}
                {!!siteNotificationBadgeCount && <div className="notification-badge">{siteNotificationBadgeCount}</div>}
              </span>
            </li>
          )}
          <li>
            <a href="/logout">{intl.formatMessage({ id: 'header.userMenu.logout', defaultMessage: 'Logout' })}</a>
          </li>
        </Dropdown>
      </UserPopover>
    </>
  );
});

const UserPopover = styled(FlatPopover)`
  max-width: none;

  .bottom {
    margin-left: -10px;
    margin-top: 22px;

    .arrow {
      margin-left: 20px;
      margin-top: 1px;
    }
  }
`;

const Header = styled.div`
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  white-space: nowrap;
  height: 40px;
  margin-top: 5px;
`;

const Avatar = styled.span`
  text-align: center;
  border-radius: 2px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 1.15rem;
  display: inline-block;
  vertical-align: middle;
  background: ${(props) => props.theme.primary3};
  color: ${(props) => props.theme.primary4};
`;

const UserLabel = styled.span`
  margin-left: 4px;
  font-size: 1.15rem;
  display: inline-block;
  vertical-align: middle;

  @media (max-width: 767px) {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Divider = styled.li`
  margin: 9px 13px 3px 20px;
  height: 1px;
  overflow: hidden;
`;

const Dropdown = styled.ul`
  list-style: none;
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  position: static;
  display: block;
  border: 0;
  box-shadow: none;

  li:not(${Divider}) {
    padding: 3px;
    cursor: pointer;
  }

  li > span.new-releases {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    display: flex;
    width: 100%;

    > div {
      font-size: 0.75rem;
      border-radius: 4px;
      width: 24px;
      height: 16px;
      background-color: ${(props) => props.theme.headerBarPrimary3};
      text-align: center;
      line-height: 16px;
      font-weight: 700;
      margin-left: 8px;
      color: white;
    }
  }
`;
