import { FeatureFlags, SitePickerItem } from 'src/@types/SiteConfig';
import { SplashConfig } from 'src/@types/DefaultConfig';
import { Site } from './site';
import { ThemeConfig } from '../../../@types/DefaultConfig';
import { CamProvider } from 'src/shared/constants/cams';
import { GeoIPResponse } from '@tovia/man-app-utils/lib/@types/geoip';
import { defaultIPLocation } from '@tovia/man-app-utils/lib/helpers/defaultIPLocation';

const SET_TITLE = 'man-site/app/SET_TITLE';

type ConfigMoviesTab = {
  label: string;
  link: string;
  requiresAuth?: boolean;
  requiresPPSMovies?: boolean;
  value: string;
};

type ConfigMoreGirls = {
  list?: Array<{ name: string; url: string }>;
  text: string;
};

type ConfigHeaderBar = {
  hideSitePicker: boolean;
  showSplashSitePicker: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type ExternalScripts = Record<
  string,
  Partial<{
    appendCodeAfterUrlLoads: boolean;
    code: string;
    mamWhitelist: number[];
    url: string;
  }>
>;

type Shop = {
  abbreviation: string;
  domain: string;
  name: string;
};

type Promotion = {
  bannerEnabled: boolean;
  enabled: boolean;
  footerEnabled: boolean;
  gatewayEnabled: boolean;
  inlineBannerEnabled: boolean;
  popupClickCount: number;
  popupDisabledPaths: string[];
  popupEnabled: boolean;
  promoCode: string;
  promoCodeEnd: string;
  promoCodeStart: string;
  ribbonEnabled: boolean;
  splashFooterEnabled: boolean;
  upgradePromoCode: string;
};

type ModelsTab = {
  label: string;
  link: string;
  value: string;
};

type CrossSitePopupBanner = {
  bannerUrl: string;
  siteUrl: string;
};

type MetaTag = {
  title?: string;
  description?: string;
};

export type TabSeoHeaders = Record<string, { header: string }>;

export type CamProviderSetup = {
  provider: CamProvider;
  thumbUrl?: string;
};

export type AppConfig = {
  canRequestMultipleCams: boolean;
  camProviders: CamProviderSetup[];
  cams: CamProviderSetup;
  crossSitePopupBanner?: CrossSitePopupBanner;
  disableMetartIntimate: boolean;
  externalScripts?: ExternalScripts;
  featureFlags?: FeatureFlags;
  has4kResolution: boolean;
  hasAccountCenter: boolean;
  hasAllSites: boolean;
  hasBlog: boolean;
  hasCamsSlider: boolean;
  hasCategories: boolean;
  hasDirectors: boolean;
  hasMemberUpgrades: boolean;
  hasMoreGirls: boolean;
  hasMovies: boolean;
  hasPhotographers: boolean;
  hasPPS: boolean;
  hasSiteNotifications: boolean;
  hasStaffSelection: boolean;
  hasUpdates: boolean;
  hasUpgradableSites: boolean;
  hasViewOptions: boolean;
  hasWideMovieCovers: boolean;
  headerBar: Partial<ConfigHeaderBar>;
  isWhiteLabel: boolean;
  livetv: Site;
  metaTags: Record<string, MetaTag>;
  modelsTabs: ModelsTab[];
  moreGirls: ConfigMoreGirls;
  moviesTabs: ConfigMoviesTab[];
  playboyLanderMAMCodes: string[];
  promoCategoryUUIDs: string[];
  promotions: Promotion;
  shop: Shop;
  show: Site;
  splash: SplashConfig;
  tabSeoHeaders: TabSeoHeaders;
  theme: ThemeConfig;

  // TODO: add here configs available
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type InitialState = {
  accessSubdomain: string;
  ataBundleUrl: string;
  ageVerificationBlockedVPNStates: string[];
  cdnUrl: string;
  config: Partial<AppConfig>;
  contentCdnUrl: string;
  contentProtectedCdnUrl: string;
  defaultCdnUrl: string;
  emblem: {
    postbackUrl: string;
    projectKey: string;
    url: string;
  };
  googleAnalytics?: {
    UATrackingID: string;
    '4TrackingID': string;
  };
  googleOptimize?: {
    containerID: string;
  };
  googleTagManager?: {
    auth: string;
    id: string;
    preview: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icons?: any;
  ipLocation: GeoIPResponse;
  joinSubdomain: string;
  jwPlayerKey: string;
  oneTrustDomainID?: string;
  pageTitle: string;
  pixelApiUrl: string;
  showSFWSite: boolean;
  showLegaLRestrictionSplash: boolean;
  sitePickerList: SitePickerItem[];
  siteSubdomain: string;
  ssoUrl: string;
  test?: {
    platform: string;
    testId: string | number;
    pps?: object;
  };
  timezone: string;
  verifyMyAgeRedirectUrl: string;
  version: string;
};

export const initialState: InitialState = {
  accessSubdomain: '',
  ageVerificationBlockedVPNStates: [],
  ataBundleUrl: '',
  cdnUrl: '',
  config: {},
  contentCdnUrl: '',
  contentProtectedCdnUrl: '',
  defaultCdnUrl: '',
  emblem: {
    postbackUrl: '',
    projectKey: '',
    url: '',
  },
  joinSubdomain: '',
  jwPlayerKey: '',
  ipLocation: defaultIPLocation,
  pageTitle: '',
  pixelApiUrl: '',
  showSFWSite: false,
  showLegaLRestrictionSplash: false,
  sitePickerList: [],
  siteSubdomain: '',
  ssoUrl: '',
  timezone: 'GMT',
  verifyMyAgeRedirectUrl: '',
  version: '',
};

interface ActionSetTitle {
  type: typeof SET_TITLE;
  title: string;
}

interface ActionSetTitle {
  type: typeof SET_TITLE;
  title: string;
}

export default function reducer(state = initialState, action: ActionSetTitle): InitialState {
  switch (action.type) {
    case SET_TITLE: {
      return {
        ...state,
        pageTitle: action.title,
      };
    }
    default:
      return state;
  }
}

export function setTitle(title) {
  return {
    type: SET_TITLE,
    title,
  };
}
