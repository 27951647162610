export const modelHeadshotUrl = (
  cdnUrl: string,
  item: { headshotImagePath: string; headshotImagePathSfw?: string },
  siteUUID: string,
  showSfwSite?: boolean,
) => {
  const basePath = `${cdnUrl}/${siteUUID}`;
  const hasSfwHeadshot = !!item.headshotImagePathSfw;
  const imagePath = hasSfwHeadshot && showSfwSite ? item.headshotImagePathSfw : item.headshotImagePath;

  return {
    coverUrl: `${basePath}${imagePath}`,
    blurImage: Boolean(showSfwSite && !hasSfwHeadshot),
  };
};
