import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router';
import NotFound from '../NotFound/NotFound';
import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs';
import Pages from '../Pages/Pages';
import urls from 'src/shared/urls';
import { Page2257 } from '../Static';
import { ReportPiracy } from '../Piracy/ReportPiracy';
import { TrustedPartners } from '../TrustedPartners';
import { AdvancedSearch, SearchResults } from '../Search';
import { UserComments } from '../UserComments';
import { Cover } from '../Cover';
import { Leaderboard } from '../Leaderboard';
import { SubscriptionPreview } from '../SubscriptionPreview';
import NewsletterSuccessPage from '../NewsletterForm/NewsletterSuccessPage';
import {
  validateDate,
  validatePageNumber,
  validateSorting,
  validateTab,
  validateTimePeriod,
  validateCategory,
  validateMediaType,
} from 'src/client/helpers';
import { SettingsPage } from '../SettingsPage/SettingsPage';
import {
  Movies,
  Updates,
  ModelsAndArtists,
  Galleries,
  Blog,
  FavoritesAndRatings,
  AllSites,
  ReportAbuse,
} from '../index';
import { Photographer } from '../Photographer';
import { getGallerySortTypes, getMovieSortTypes, routes } from '../../utils/router';
import { Gallery } from '../Gallery';
import { Movie } from '../Movie';
import { ImageGrid } from '../ImageGrid';
import { Image } from '../Image';
import { SpecialFeatures } from '../SpecialFeatures';
import Loading from '../Loading';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { PopupBanners } from '../PopupBanners/PopupBanners';
import FooterBar from '../FooterBar/FooterBar';
import { About } from '../About';
import { OptOut } from '../OptOut';
import { PrivateRoute } from 'src/client/utils/PrivateRoute';
import { VwoTest } from '../VwoTest';
import { ModelRoute } from '../Model/ModelRoute';
import { usePages } from 'src/client/helpers/usePages';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';
import { RedirectWithSameParams } from 'src/client/components/Redirect/RedirectWithSameParams';
import { Categories } from 'src/client/pages';

const Div = styled.div`
  min-height: calc(100vh - 64px);
  overflow: hidden;
  position: relative;
  padding-top: 10px;

  &.not-found {
    padding: 0;
  }

  @media only screen and (max-width: 992px) {
    padding-top: 0;
  }

  & .page-inner {
    min-height: calc(100vh - 421px);
    //overflow: hidden;

    @media only screen and (max-width: 1200px) {
      min-height: calc(100vh - 596px);
    }

    @media only screen and (max-width: 992px) {
      min-height: calc(100vh - 620px);
    }

    @media only screen and (max-width: 768px) {
      min-height: calc(100vh - 617px);
    }

    @media only screen and (max-width: 480px) {
      min-height: calc(100vh - 622px);
    }
  }
`;

export default function Internal() {
  const { user, loaded: userLoaded } = useSelector((state) => state.auth);
  const config = useSelector((state) => state.app.config);
  const path2257 = usePages('/2257');
  const location = useLocation();
  const gallerySortTypes = useMemo(() => getGallerySortTypes(config), [config]);
  const movieSortTypes = useMemo(() => getMovieSortTypes(config), [config]);
  const [blogJoinUrl] = useJoinUrl({ campaign: 'blog-redirect' });

  const isNotFound = location.pathname === urls.get.notFound;

  const authenticatedBlogRoute = () => {
    if (config.blog.requiresAuth) {
      if (!userLoaded) {
        return null;
      }
      if (!user) {
        return window.location.replace(blogJoinUrl);
      }
    }

    return <Blog />;
  };

  return (
    <Div className={`page-content ${isNotFound ? 'not-found' : ''}`}>
      {isNotFound ? (
        <div className="page-inner">
          <Route component={NotFound} />
        </div>
      ) : (
        <>
          <BreadCrumbs />
          <div className="page-inner">
            <React.Suspense fallback={<Loading />}>
              <Switch>
                <Route exact path="/vwo-test/:testId" component={VwoTest} />
                {config.hasUpdates && <Route path={routes.updates.path} component={Updates} />}
                <Route exact path={routes.pages.path} component={Pages} />
                <Redirect exact from={routes.updates.routes.oldArchive2.path} to={routes.updates.routes.archive.path} />
                {config.movies.active && <Route path={routes.movies.path} component={Movies} />}
                {config.movies.active && (
                  <RedirectWithSameParams from={routes.movies.oldPath} to={routes.movies.path} />
                )}
                {config.blog.active && <Route path={routes.blog.path} render={authenticatedBlogRoute} />}
                <Route path={routes.galleries.path} component={Galleries} />
                <Route path={routes.modelsAndArtists.path} component={ModelsAndArtists} />
                {config.hasCategories && <Route exact path={routes.categories.path} component={Categories} />}
                {!config.isWhiteLabel && <Route exact path={routes.allSites.path} component={AllSites} />}
                <Route
                  exact
                  path={routes.galleries.routes.gallery.path}
                  render={({ match }) => {
                    if (validateDate(match.params.date) && validatePageNumber(match.params.page)) {
                      return <Gallery />;
                    }

                    return <Redirect to={`/model/${match.params.modelName}`} />;
                  }}
                />
                <Route
                  exact
                  path={routes.movies.routes.movie.path}
                  render={({ match }) => {
                    if (validateDate(match.params.date)) {
                      return <Movie />;
                    }
                    return <Redirect to={`/model/${match.params.modelName}`} />;
                  }}
                />
                <Route
                  exact
                  path={[
                    routes.modelsAndArtists.routes.modelLatest.path,
                    routes.modelsAndArtists.routes.modelMovies.path,
                    routes.modelsAndArtists.routes.modelPhotos.path,
                  ]}
                  component={ModelRoute}
                />
                <Route
                  exact
                  path={routes.images.routes.grid.path}
                  render={({ match }) => {
                    if (validateDate(match.params.date) && validateMediaType(match.params.mediaType)) {
                      return <ImageGrid />;
                    }

                    return <Redirect to={`/model/${match.params.modelName}`} />;
                  }}
                />
                <Route
                  exact
                  path={routes.images.routes.image.path}
                  render={({ match }) => {
                    if (validateDate(match.params.date) && validateMediaType(match.params.mediaType)) {
                      return <Image />;
                    }

                    return <Redirect to={`/model/${match.params.modelName}/${gallerySortTypes[0].id}`} />;
                  }}
                />
                <Route
                  exact
                  path={routes.modelsAndArtists.routes.photographer.path}
                  render={({ match }) => {
                    if (match.params.sortBy && match.params.tab) {
                      if (
                        validateSorting(match.params.sortBy, [...gallerySortTypes, ...movieSortTypes]) &&
                        validateTab(match.params.tab, config.photographerPageTabs)
                      ) {
                        return <Photographer />;
                      }
                      return <Redirect to={`/photographer/${match.params.photographerName}`} />;
                    }
                    return <Photographer />;
                  }}
                />
                <Route
                  exact
                  path={routes.modelsAndArtists.routes.photographerLatest.path}
                  render={({ match }) => {
                    if (validateTab(match.params.tab, config.photographerPageTabs)) {
                      return <Photographer />;
                    }
                    return <Redirect to={`/photographer/${match.params.photographerName}`} />;
                  }}
                />

                {config.splash?.components.specialFeaturesSection && (
                  <Route
                    exact
                    path={routes.specialFeatures.path}
                    render={(props) => {
                      const { specialFeatures } = config;
                      // eslint-disable-next-line react/prop-types
                      const {
                        match: {
                          params: { category = '', subCategory = '', extraCategory = '' },
                        },
                      } = props;

                      if (!subCategory && Object.keys(specialFeatures.subCategories).includes(category)) {
                        const keys = specialFeatures.subCategories[category];
                        return <Redirect to={`/category/${category}/${keys[0]}`} />;
                      }

                      if (
                        subCategory &&
                        !extraCategory &&
                        Object.keys(specialFeatures.additionalCategories).includes(subCategory)
                      ) {
                        const keys = specialFeatures.additionalCategories[subCategory];
                        return <Redirect to={`/category/${category}/${subCategory}/${keys[0]}`} />;
                      }

                      let isValid = true;
                      if (extraCategory && Number.isNaN(extraCategory)) {
                        isValid = validateCategory(specialFeatures.additionalCategories[subCategory], extraCategory);
                      }

                      if (subCategory && Number.isNaN(subCategory) && isValid) {
                        isValid = isValid && validateCategory(specialFeatures.subCategories[category], subCategory);
                      }

                      if (category && isValid) {
                        isValid = isValid && validateCategory(specialFeatures.categories, category);
                      }

                      return isValid ? <SpecialFeatures /> : <NotFound />;
                    }}
                  />
                )}

                {!config.isWhiteLabel && (
                  <Route exact path={routes.subscriptionPreview.path} component={SubscriptionPreview} />
                )}

                <Route exact path={routes.search.routes.advancedSearch.path} component={AdvancedSearch} />
                <Route exact path={routes.search.routes.searchResults.path} component={SearchResults} />
                <Route exact path={routes.categories.routes.categoriesResults.path} component={SearchResults} />
                {!config.isWhiteLabel && (
                  <Redirect exact from={routes.user.path} to={routes.user.routes.comments.path} />
                )}
                {!config.isWhiteLabel && (
                  <Route exact path={routes.user.routes.comments.path} component={UserComments} />
                )}
                <Route
                  exact
                  path={routes.cover.path}
                  render={({ match }) => (match.params.galleryUUID?.length === 32 ? <Cover /> : <NotFound />)}
                />
                {!config.isWhiteLabel && (
                  <Route
                    exact
                    path={routes.leaderboard.path}
                    render={({ match }) => {
                      const leaderboardTabs =
                        config.leaderboardTabs && config.leaderboardTabs.map(({ value }) => value);

                      if (
                        config.leaderboard.active &&
                        validateTab(match.params.tab, leaderboardTabs) &&
                        validateTimePeriod(match.params.timePeriod)
                      ) {
                        return <Leaderboard />;
                      }

                      return <NotFound />;
                    }}
                  />
                )}
                {!config.isWhiteLabel && (
                  <PrivateRoute
                    userLoaded={!!userLoaded}
                    isUser={!!user}
                    path={routes.favoritesAndRatings.path}
                    component={FavoritesAndRatings}
                  />
                )}
                {!config.isWhiteLabel && (
                  <PrivateRoute
                    userLoaded={!!userLoaded}
                    exact
                    isUser={!!user}
                    path={routes.settings.path as string}
                    component={SettingsPage}
                  />
                )}

                <Route
                  exact
                  path={routes['2257'].path}
                  render={() => {
                    if (path2257.includes('pages')) {
                      return <Redirect to={path2257} />;
                    }
                    return <Page2257 />;
                  }}
                />
                <Route exact path={routes.piracy.path} component={ReportPiracy} />
                {!config.isWhiteLabel && (
                  <PrivateRoute
                    userLoaded={!!userLoaded}
                    exact
                    isUser={!!user}
                    path={routes.trustedPartners.path as string}
                    component={TrustedPartners}
                  />
                )}

                <Route
                  exact
                  path={routes.login.path}
                  render={() => {
                    // Express redirects will handle it
                    window.location.assign(routes.login.path as string);
                    return <section>Redirecting...</section>;
                  }}
                />
                <Route
                  exact
                  path={routes.signup.path}
                  render={() => {
                    // Express redirects will handle it
                    window.location.assign(routes.signup.path as string);
                    return <section>Redirecting...</section>;
                  }}
                />
                <Route exact path={routes.newsletterConfirmed.path} component={NewsletterSuccessPage} />
                <Route exact path={routes.report.path} component={ReportAbuse} />
                <Route exact path="/opt-out" component={OptOut} />

                {config.footer.aboutUsUrl && <Route exact path={config.footer.aboutUsUrl} component={About} />}

                <Route component={NotFound} />
              </Switch>
            </React.Suspense>
          </div>
        </>
      )}
      {!config.isWhiteLabel && userLoaded && (
        <>
          <FooterBar />
          <PopupBanners />
        </>
      )}
    </Div>
  );
}
