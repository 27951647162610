import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import styled from 'styled-components/macro';
import isEmail from 'validator/lib/isEmail';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { validatorOptions } from 'src/shared/constants/signup';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { useInfinityTagEvent } from '../../helpers/useInfinityTagEvents';

const validate = (values) => {
  const errors: {
    [key: string]: string;
  } = {};
  if (!values.email || !isEmail(values.email, validatorOptions)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const formName = 'freeGalleriesMoviesForm';

const renderInputField = ({ meta, input, ...rest }) => <input {...input} {...rest} disabled={rest.disabled} />;

interface Props {
  callStatus: string;
  // eslint-disable-next-line react/require-default-props
  className?: string;
  // eslint-disable-next-line react/require-default-props
  invalid?: boolean;
  // eslint-disable-next-line react/require-default-props
  submitError?: string;
  isEU: boolean;
  isStraplez?: boolean;
  // eslint-disable-next-line react/require-default-props
  showOptInDialog?: boolean;
}

interface FormInputs {
  email: string;
  optin: boolean;
}

type AllProps = Props & InjectedFormProps<Props, FormInputs>;

function FreeGalleriesMoviesForm(props: AllProps) {
  const {
    handleSubmit,
    callStatus,
    className = '',
    invalid,
    submitError,
    isEU,
    isStraplez = false,
    showOptInDialog,
  } = props;
  const {
    config: {
      footer: { privacyUrl },
    },
  } = useSelector((state) => state.app);

  const sendStartFreeTrialEventOnClick = useInfinityTagEvent({
    name: 'Start your free trial membership “button”',
    type: 'Acquisition',
    value: '0.001',
  });

  return (
    <form onSubmit={handleSubmit} className={className}>
      <fieldset disabled={callStatus === 'loading'}>
        <div className="email-signup-form-box">
          <Field
            component={renderInputField}
            className="email-signup-input text-center form-control"
            id="email-signup-input"
            name="email"
            type="email"
            placeholder={isStraplez ? 'Your e-mail address' : 'Enter email here'}
          />
          <div className={`form-inline eu-opt-in ${!isEU ? 'hidden' : ''}`}>
            <Field
              component={renderInputField}
              className="eu-opt-in-checkbox"
              id="optin"
              name="optin"
              type="checkbox"
            />
            <label
              style={{
                display: 'none',
              }}
              htmlFor="eu-optin-input"
            >
              Optin for more
            </label>
            <span className="eu-opt-in-text">
              Yes I want more free stuff. Tick the box and join us to get occasional information about our products,
              services, events, news, and offers. Read our <a href={privacyUrl}>Privacy Policy</a> for more info.
            </span>
          </div>
          <button
            className="email-signup-submit-btn btn btn-primary"
            type="submit"
            disabled={invalid}
            onClick={sendStartFreeTrialEventOnClick}
          >
            {(isStraplez && 'Start your free trial') || (callStatus === 'loading' && 'Sending...') || (
              <div>
                START YOUR <strong>FREE</strong> TRIAL MEMBERSHIP
              </div>
            )}
          </button>
          {submitError && <div className="error">{submitError}</div>}
          {showOptInDialog && (
            <ModalDialog className="signup-modal">
              {/* @ts-ignore */}
              <ModalHeader>
                <ModalTitle>Last Chance!</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <p>
                  Would you like to get free stuff? Click Yes to join us to get occasional information about our
                  products, services, events, news and offers.
                </p>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={(e) => {
                    e.preventDefault();
                    props.change('optin', true);

                    setTimeout(() => {
                      handleSubmit(e);
                    }, 300);
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    props.change('optin', false);
                    setTimeout(() => {
                      handleSubmit(e);
                    }, 300);
                  }}
                >
                  No
                </button>
              </ModalFooter>
            </ModalDialog>
          )}
        </div>
      </fieldset>
    </form>
  );
}

const StyledComp = styled(FreeGalleriesMoviesForm)`
  & .eu-opt-in {
    text-align: left;
    margin-top: 0;
    margin-bottom: -20px;
    padding: 15px;
    background: rgba(0, 0, 0, 0.2);
  }

  & .eu-opt-in-text {
    display: inline-block;
    font-weight: 400;
    font-size: 0.9rem;
    width: calc(100% - 25px);
    vertical-align: top;
    margin: 0;
    padding-left: 15px;

    > a {
      text-decoration: underline !important;
      color: #fff;
    }
  }

  & .eu-opt-in-checkbox {
    position: relative;
    top: 0px;
    margin-top: 2px;

    @media (max-width: 767px) {
      width: 20px;
      height: 20px;
    }
  }

  .signup-modal {
    margin-top: 10px;
    width: fit-content;
    h4,
    p {
      color: #000;
    }
  }

  & .email-signup-submit-btn {
    height: 60px;
    margin-top: 20px;
    font-size: 1.65rem;
    border-radius: 3px;
    border: 1px solid transparent;
    width: 100%;
    opacity: 1;

    @media (max-width: 1199px) {
      font-size: 1.43rem;
    }

    @media (max-width: 991px) {
      font-size: 1.15rem;
    }

    @media (max-width: 767px) {
      font-size: 1.07rem;
    }

    @media (max-width: 639px) {
      font-size: 0.85rem;
    }

    :hover,
    :active,
    :focus {
      cursor: pointer;
    }

    :disabled {
      background: ${(props) => props.theme.primaryBtn1Bg};
      height: 60px;
      margin-top: 20px;
      font-size: 1.65rem;
      border-radius: 3px;
      border: 1px solid transparent;
      width: 100%;
      opacity: 1;

      @media (max-width: 1199px) {
        font-size: 1.43rem;
      }

      @media (max-width: 991px) {
        font-size: 1.15rem;
      }

      @media (max-width: 767px) {
        font-size: 1.07rem;
      }

      @media (max-width: 639px) {
        font-size: 0.85rem;
      }
    }
  }

  & .email-signup-form-box {
    margin: 0 auto;
    position: relative;
    padding: 4%;
    background: transparent;
  }

  & .email-signup-input {
    width: 100%;
    border-radius: 3px;
    margin: 0 auto;
    height: 45px;
    line-height: 45px;
    font-size: 1.85rem;

    @media (max-width: 639px) {
      font-size: 1.15rem;
    }
  }

  & .error {
    font-size: 13pt;
    text-align: center;
    margin: 10px -100px -15px;
    font-weight: bold;
    color: red;
  }
`;

export default reduxForm<FormInputs, Props>({
  form: formName, // a unique name for this form
  validate,
  //@ts-ignore // TODO: Fix redux form with TS
})(StyledComp);
