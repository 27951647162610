import React from 'react';
import styled from 'styled-components/macro';
import AdvancedModal from '@tovia/man-ui/lib/components/Modal/AdvancedModal';
import { displayModelStat } from '../../utils/converters';
import { useSelector, useSettingsSelector } from '../../redux/modules/helpers/useSelector';
import { getModelAttributes, ModelAttributes } from 'src/client/containers/Model/modelAttributes';

type Props = {
  attributes: ModelAttributes & {
    name: string;
    biography?: string | boolean;
  };
  coverImageUrl: string;
  show: boolean;
  onHide: () => void;
};

export default function ModelStatsModal(props: Props) {
  const measurements = useSettingsSelector('measurements');
  const config = useSelector((state) => state.app.config);

  const { attributes, coverImageUrl } = props;
  const { biography, name } = attributes;

  const showModelBiography = config.components.modelDetailsPanel.showModelBiography as boolean;
  const showBio = showModelBiography && biography !== '0';

  const attrs = getModelAttributes(attributes);

  return (
    <StyledModal show={props.show} onClose={props.onHide}>
      <ModelImage data-testid="model-image" src={coverImageUrl} alt={name} className="pull-left" />
      <ModelName>{name}</ModelName>
      <div>
        {attrs
          .filter((attr) => !!attr.value)
          .map(({ label, statType, value }) => (
            <div key={statType}>
              <strong>{label}: </strong>
              <span>{displayModelStat({ statType, value, measurements })}</span>
            </div>
          ))}
      </div>
      {showBio && !!biography && (
        <>
          <h3>Bio</h3>
          <p>{biography}</p>
        </>
      )}
    </StyledModal>
  );
}

const ModelImage = styled.img`
  width: 100%;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    max-width: 230px;
    margin-right: 30px;
  }
`;

const StyledModal = styled(AdvancedModal)`
  padding: 30px;
  width: 600px;

  /* @TODO remove after fixed in man-ui */
  max-width: 100%;
`;

const ModelName = styled.h3`
  margin-top: 0;
`;
