import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import avnAward2016NomineeImg from 'src/client/containers/Splash/images/avnAward2016Nominee.png';
import riseAward2016NomineeImg from 'src/client/containers/Splash/images/riseAward2016Nominee.png';
import xbizAward2018Img from 'src/client/containers/Splash/images/xbizAward2018.png';
import xbizAward2019Img from 'src/client/containers/Splash/images/xbizAward2019.png';
import ynotAward2019Img from 'src/client/containers/Splash/images/ynotAward2019.png';
import { TABLET_PORTRAIT } from '@tovia/man-ui';

const awards = [
  {
    imageUrl: ynotAward2019Img,
    text: <FormattedMessage id="splash.awards.ynotNudeArtSiteOfTheYear" defaultMessage="Nude Art Site Of The Year" />,
  },
  {
    imageUrl: avnAward2016NomineeImg,
    text: <FormattedMessage id="splash.awardsSection.bestWebsite" defaultMessage="Best Website" />,
  },
  {
    imageUrl: riseAward2016NomineeImg,
    text: <FormattedMessage id="splash.awardsSection.siteOfTheYear" defaultMessage="Site of the Year" />,
  },
  {
    imageUrl: xbizAward2018Img,
    text: <FormattedMessage id="splash.awardsSection.adultSiteOfTheYear" defaultMessage="Adult Site Of The Year" />,
  },
  {
    imageUrl: xbizAward2019Img,
    text: <FormattedMessage id="splash.awardsSection.adultSiteOfTheYear" defaultMessage="Adult Site Of The Year" />,
  },
];

type Props = {
  show: boolean;
};

export const AwardsSection = (props: Props) => {
  const { show = false } = props;
  if (!show) return null;

  return (
    <SectionWrapper>
      <div className="container">
        <Headline>
          <FormattedMessage
            tagName="span"
            id="splash.awardsSection.title"
            defaultMessage="The most awarded nude site in the world"
          />
        </Headline>
        <List>
          {awards.map((award) => (
            <Award key={`${award.imageUrl}-${award.text}`}>
              <Image src={award.imageUrl} alt="" />
              <Title>{award.text}</Title>
            </Award>
          ))}
        </List>
      </div>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  width: 100vw;
  background: #191919;
  padding-bottom: 40px;
`;

const Headline = styled.div`
  height: 1px;
  background: #b5954f;
  position: relative;
  text-align: center;
  margin: 60px 0 35px;

  @media (max-width: 639px) {
    background: none;
    height: auto;
    margin: 60px 0 0;
  }

  span {
    color: #fff;
    font-size: 1.85rem;
    text-transform: uppercase;
    text-align: center;
    background: #191919;
    padding: 0 15px;
    position: relative;
    top: -20px;
    display: inline-block;
  }
`;

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Award = styled.li`
  display: block;
  width: 124px;
  flex: 0 0 auto;
  text-align: center;
  margin-bottom: 10px;

  ${TABLET_PORTRAIT} {
    margin-bottom: 0;
  }
`;

const Image = styled.img`
  max-width: 100%;
  width: 124px;
`;

const Title = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.splashPrimary3};
`;
