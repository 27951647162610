import { io, Socket } from 'socket.io-client';
import { Store } from 'redux';
import { setUIValue } from '../redux/modules/settings';
import { addActivity, removeActivity } from '../redux/modules/activity';
import { addFavorites, removeFavorites } from '../redux/modules/favoriteInfo';
import { setRating, removeRating } from '../redux/modules/ratingInfo';
import { ClientToServerEvents, ServerToClientEvents } from 'src/@types/socket.io';
import {
  ADD_ACTIVITY,
  ADD_FAVORITE,
  REMOVE_ACTIVITY,
  REMOVE_FAVORITE,
  REMOVE_RATING,
  SETTINGS_SET_VALUE,
  SET_RATING,
} from 'src/shared/constants/socketMessages';

export type SocketIOClient = Socket<ServerToClientEvents, ClientToServerEvents>;

export const initSocketIO = (store: Store) => {
  const socket: SocketIOClient = io('', {
    path: '/ws',
    secure: true,
    transports: ['websocket'],
  });
  socket.on(SETTINGS_SET_VALUE, ({ key, value }) => {
    store.dispatch(setUIValue({ key, value }));
  });

  socket.on(ADD_ACTIVITY, (activity) => {
    store.dispatch(addActivity(activity));
  });

  socket.on(REMOVE_ACTIVITY, (activity) => {
    store.dispatch(removeActivity(activity));
  });

  socket.on(ADD_FAVORITE, (UUID) => {
    store.dispatch(addFavorites([UUID]));
  });

  socket.on(REMOVE_FAVORITE, (UUID) => {
    store.dispatch(removeFavorites([UUID]));
  });

  socket.on(SET_RATING, ({ objectUUID, rating }) => {
    store.dispatch(setRating({ UUID: objectUUID, rating }));
  });

  socket.on(REMOVE_RATING, (UUID) => {
    store.dispatch(removeRating(UUID));
  });

  return socket;
};
