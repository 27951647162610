/**
 User tags only
 */
import { call, fork, select, take } from 'redux-saga/effects';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import actionError from './helpers/actionError';
import { getTagsResponse, Tag } from '@tovia/man-protos/dist/types/content.types';

const LOAD = 'man-site/tags/LOAD';
const LOAD_SAGA = 'man-site/tags/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/tags/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/tags/LOAD_FAIL';
const ADD = 'man-site/tags/ADD';
const ADD_SAGA = 'man-site/tags/ADD_SAGA';
const ADD_SUCCESS = 'man-site/tags/ADD_SUCCESS';
const ADD_FAIL = 'man-site/tags/ADD_FAIL';
const EDIT = 'man-site/tags/EDIT';
const EDIT_SUCCESS = 'man-site/tags/EDIT_SUCCESS';
const EDIT_FAIL = 'man-site/tags/EDIT_FAIL';
const DELETE = 'man-site/tags/DELETE';
const DELETE_SAGA = 'man-site/tags/DELETE_SAGA';
const DELETE_SUCCESS = 'man-site/tags/DELETE_SUCCESS';
const DELETE_FAIL = 'man-site/tags/DELETE_FAIL';
const SHOW_NEW_TAG_MODAL = 'man-site/tags/SHOW_NEW_TAG_MODAL';
const HIDE_NEW_TAG_MODAL = 'man-site/tags/HIDE_NEW_TAG_MODAL';

// Edit user tag endpoints:
const loadEndpoint = constructUrl(urls.get.tags);
const addEndpoint = constructUrl(urls.post.tags);
const deleteEndpoint = constructUrl(urls.delete.tags);

interface InitialState {
  loadingObjectUUIDs: string[];
  errorObjectUUIDs: string[];
  tags: {
    [key: string]: {
      tags: Tag[];
      total: number;
    };
  };
  tagsErrors: string[];
  addStatus: string;
  editStatus: string;
  editError: null;
  deleteStatus: string;
  newTagsModal: {
    show?: boolean;
    name: string;
    objectUUID: string;
    objectType: string;
  };
}

export const initialState: InitialState = {
  loadingObjectUUIDs: [],
  errorObjectUUIDs: [],
  tags: {},
  tagsErrors: [],
  addStatus: '',
  editStatus: '',
  editError: null,
  deleteStatus: '',
  newTagsModal: {
    show: false,
    name: '',
    objectUUID: '',
    objectType: '',
  },
};

interface ActionLoad {
  type: typeof LOAD;
  objectUUID: string;
}

interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  objectUUID: string;
  result: getTagsResponse;
}

interface ActionLoadFail {
  type: typeof LOAD_FAIL;
  objectUUID: string;
}

interface ActionAdd {
  type: typeof ADD;
}

interface ActionAddSuccess {
  type: typeof ADD_SUCCESS;
  objectUUID: string;
  result: {
    tags: Tag[];
    total: number;
  };
}

interface ActionAddFail {
  type: typeof ADD_FAIL;
}

interface ActionEdit {
  type: typeof EDIT;
}

interface ActionEditSuccess {
  type: typeof EDIT_SUCCESS;
}

interface ActionEditFail {
  type: typeof EDIT_FAIL;
}

interface ActionDelete {
  type: typeof DELETE;
}

interface ActionDeleteSuccess {
  type: typeof DELETE_SUCCESS;
  objectUUID: string;
  deletedUUIDs: string[];
}

interface ActionDeleteFail {
  type: typeof DELETE_FAIL;
}

interface ActionShowNewTagDialog {
  type: typeof SHOW_NEW_TAG_MODAL;
  params: {
    name: string;
    objectUUID: string;
    objectType: string;
  };
}

interface ActionHideNewTagDialog {
  type: typeof HIDE_NEW_TAG_MODAL;
}

export default function reducer(
  state = initialState,
  action:
    | ActionLoad
    | ActionLoadSuccess
    | ActionLoadFail
    | ActionAdd
    | ActionAddSuccess
    | ActionAddFail
    | ActionEdit
    | ActionEditSuccess
    | ActionEditFail
    | ActionDelete
    | ActionDeleteSuccess
    | ActionDeleteFail
    | ActionShowNewTagDialog
    | ActionHideNewTagDialog,
): InitialState {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loadingObjectUUIDs: [...state.loadingObjectUUIDs, action.objectUUID],
        errorObjectUUIDs: state.errorObjectUUIDs.filter((UUID) => UUID !== action.objectUUID),
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loadingObjectUUIDs: [...state.loadingObjectUUIDs.filter((UUID) => UUID !== action.objectUUID)],
        tags: {
          ...state.tags,
          [action.objectUUID]: {
            tags: action.result.tags,
            total: action.result.total,
          },
        },
      };
    case LOAD_FAIL:
      return {
        ...state,
        loadingObjectUUIDs: state.loadingObjectUUIDs.filter((UUID) => UUID !== action.objectUUID),
        errorObjectUUIDs: [...state.errorObjectUUIDs, action.objectUUID],
      };
    case ADD:
      return {
        ...state,
        addStatus: 'loading',
        tagsErrors: state.tagsErrors.filter((error) => error !== 'add'),
      };
    case ADD_SUCCESS:
      return {
        ...state,
        addStatus: 'success',
        tags: {
          ...state.tags,
          [action.objectUUID]: {
            tags: state.tags[action.objectUUID].tags.concat(action.result.tags),
            total: state.tags[action.objectUUID].total + action.result.total,
          },
        },
      };
    case ADD_FAIL:
      return {
        ...state,
        addStatus: 'fail',
        tagsErrors: [...state.tagsErrors, 'add'],
      };
    case EDIT:
      return {
        ...state,
        editStatus: 'loading',
        editError: initialState.editError,
      };
    case EDIT_SUCCESS:
      return {
        ...state,
        editStatus: 'success',
      };
    case EDIT_FAIL:
      return {
        ...state,
        editError: actionError(action),
        editStatus: 'fail',
      };
    case DELETE:
      return {
        ...state,
        deleteStatus: 'loading',
        tagsErrors: state.tagsErrors.filter((error) => error !== 'delete'),
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        deleteStatus: 'success',
        tags: {
          ...state.tags,
          [action.objectUUID]: {
            tags: state.tags[action.objectUUID].tags.filter((tag) => !action.deletedUUIDs.includes(tag.UUID)),
            total: state.tags[action.objectUUID].total - action.deletedUUIDs.length,
          },
        },
      };
    case DELETE_FAIL:
      return {
        ...state,
        tagsErrors: [...state.tagsErrors, 'delete'],
        deleteStatus: 'fail',
      };
    case SHOW_NEW_TAG_MODAL:
      return {
        ...state,
        newTagsModal: {
          ...initialState.newTagsModal,
          name: action.params.name,
          objectUUID: action.params.objectUUID,
          objectType: action.params.objectType,
          show: true,
        },
      };
    case HIDE_NEW_TAG_MODAL:
      return {
        ...state,
        newTagsModal: {
          ...initialState.newTagsModal,
          show: false,
        },
      };
    default: {
      return state;
    }
  }
}

export function showNewTagsModal(params) {
  return {
    type: SHOW_NEW_TAG_MODAL,
    params,
  };
}

export function hideNewTagsModal() {
  return {
    type: HIDE_NEW_TAG_MODAL,
  };
}

export function loadTags(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function addTags(params) {
  return {
    type: ADD_SAGA,
    params,
  };
}

export function deleteTags(params) {
  return {
    type: DELETE_SAGA,
    params,
  };
}

/* SAGAS */
function* loadTagGenerator(params) {
  const getState = (state) => state.tags;
  const currentState = yield select(getState);

  if (!currentState.tags[params.objectUUID] && !currentState.loadingObjectUUIDs.includes(params.objectUUID)) {
    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client) =>
        client.get(loadEndpoint, {
          params,
        }),
      {
        objectUUID: params.objectUUID,
      },
    );
    yield call(loadFunc);
  }
}

function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadTagGenerator, params);
  }
}

function* addTagGenerator(data) {
  const loadFunc = getRequestFunc(
    [ADD, ADD_SUCCESS, ADD_FAIL],
    (client) =>
      client.post(addEndpoint, {
        data,
      }),
    {
      objectUUID: data.objectUUID,
    },
  );
  yield call(loadFunc);
}

function* watchAdd() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(ADD_SAGA);
    yield fork(addTagGenerator, params);
  }
}

function* deleteTagGenerator(data) {
  const deleteFunc = getRequestFunc(
    [DELETE, DELETE_SUCCESS, DELETE_FAIL],
    (client) =>
      client.del(deleteEndpoint, {
        data,
      }),
    {
      objectUUID: data.objectUUID,
      deletedUUIDs: data.UUIDs,
    },
  );
  yield call(deleteFunc);
}

function* watchDelete() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(DELETE_SAGA);
    yield fork(deleteTagGenerator, params);
  }
}

export const watchers = [fork(watchLoad), fork(watchAdd), fork(watchDelete)];
/* EOF SAGAS */
