import React from 'react';
import Helmet from 'react-helmet';
import head from '../../utils/head';
import { addDashesToUUID } from 'src/shared/converters';
import { isPrerender } from '../../utils/prerenderDetect';
import { useSelector } from 'src/client/redux';

export const HTMLHead = () => {
  const site = useSelector((state) => state.site);
  const { ataBundleUrl, cdnUrl, config, googleTagManager, googleOptimize, test } = useSelector((state) => state.app);

  return (
    <Helmet {...head(config, site, cdnUrl)}>
      <link rel="apple-touch-icon" href={`${cdnUrl}/icons/${site.abbreviation}_512.png`} />
      <link rel="shortcut icon" href={`${cdnUrl}/favicon/${site.abbreviation}.ico`} />
      <meta name="theme-color" content={`${config.pwaPrimaryColor || '#000000'}`} />

      {!isPrerender && <script>{getATAScript(ataBundleUrl, addDashesToUUID(site.networkUUID))}</script>}

      {!isPrerender && googleTagManager && googleOptimize && (
        <script>{getGoogleOptimizeScript(googleTagManager.id)}</script>
      )}

      {!isPrerender && test && <script>{getVWOScript(test.testId)}</script>}
    </Helmet>
  );
};

const getGoogleOptimizeScript = (containerID: string) => `
  (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
  h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
  (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
  })(window,document.documentElement,'async-hide','dataLayer',4000,
  {'${containerID}':true});
`;

const getATAScript = (ataBundleUrl: string, networkUUID: string) => `
  !function(t,r){window.ata=r,r.init=function(t,a){r._token=t,a&&(r._initParams=a)},r._calls=[];for(var a=["track"],c=0;c<a.length;c+=1){var e=a[c];r[e]=function(t){return function(){r._calls.push([t,Array.prototype.slice.call(arguments,0)])}}(e)}script=t.createElement("script"),script.type="text/javascript",script.async=!0,script.src="${ataBundleUrl}",scriptTag=t.getElementsByTagName("script")[0],scriptTag.parentNode.insertBefore(script,scriptTag)}(document,window.ata||[]);
  ata.init('${networkUUID}');
`;

const getVWOScript = (testId: string | number) => `
  var testId = ${testId};
  window._vwo_code = window._vwo_code || (function(){
  var account_id=555654,
  settings_tolerance=4000,
  library_tolerance=10000,
  use_existing_jquery=false,
  is_spa=1,
  hide_element='',

  /* DO NOT EDIT BELOW THIS LINE */
  f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
  window.settings_timer=setTimeout(function () {_vwo_code.finish() },settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());
`;
