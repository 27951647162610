import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import CommentActions from './CommentActions';
import { StyledRef } from 'src/@types/app';

export type SharedProps = {
  commentForm?: ReactElement;
  disallowRate?: boolean;
  isRated?: boolean;
  isUser?: boolean;
  link?: ReactNode;
  onRate: () => void;
  parentUrl?: string;
  ratesCount?: number;
  replyText?: string;
  UUID: string;
};

type Props = {
  badge?: { icon: FontAwesomeIconProps['icon']; title: string };
  childComments?: Props[];
  className?: string;
  displayName: string;
  encodeDisplayName?: (value: string) => string;
  onCommentSubmit: (data: unknown) => void;
  parentUrl?: string;
  parentUUID?: string;
  postStatus?: string;
  renderRelativeDate?: (date: string) => string;
  setDisplayName?: () => void;
  showActions?: boolean;
  showRelativeDate?: boolean;
  showUpgradeDialog: () => void;
  subText?: ReactNode;
  text: string;
  timestamp?: string;
  user?: {
    displayname: string;
  };
} & SharedProps;

const usePrevious = <T,>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const defaultStatus = {
  isReplyFormVisible: false,
  isLoading: false,
};

export const getCommentId = (uuid: string): string => `comment_${uuid.substring(0, 6)}`;

export const Comment = React.forwardRef((props: Props, ref: StyledRef<HTMLInputElement>) => {
  const {
    badge,
    commentForm,
    disallowRate,
    displayName,
    encodeDisplayName = (displayname) => displayname.replace(/\s/g, '+'),
    isRated,
    isUser,
    link,
    onCommentSubmit,
    onRate,
    parentUrl,
    postStatus,
    ratesCount,
    renderRelativeDate,
    replyText,
    showActions = true,
    showRelativeDate = true,
    showUpgradeDialog,
    subText,
    text,
    timestamp = '',
    user,
    UUID,
  } = props;

  const [status, setStatus] = useState(defaultStatus);
  const prevPostStatus = usePrevious(postStatus);

  useEffect(() => {
    if (postStatus === 'success' && postStatus !== prevPostStatus && status.isLoading) {
      setStatus(defaultStatus);
    }
  }, [postStatus, prevPostStatus, status.isLoading]);

  const handleCommentSubmit = (value: unknown) => {
    onCommentSubmit(value);
    setStatus((s) => ({ ...s, isLoading: true }));
  };

  const toggleReplyForm = () => {
    setStatus((s) => ({ ...s, isReplyFormVisible: !s.isReplyFormVisible }));
  };

  const commentActionProps = {
    commentForm,
    disallowRate,
    isRated,
    isReplyFormVisible: status.isReplyFormVisible,
    isUser,
    link,
    onRate,
    parentUrl,
    ratesCount,
    replyText,
    toggleReplyForm,
    onCommentSubmit: handleCommentSubmit,
    UUID,
  };

  return (
    <UserComment className="comment" id={getCommentId(UUID)} ref={ref}>
      <AvatarContainer className="pull-left">
        <Avatar>{displayName && displayName.slice(0, 1).toUpperCase()}</Avatar>
      </AvatarContainer>
      <div className="wrapper">
        {badge?.icon && (
          <BadgeWrapper>
            <FontAwesomeIcon icon={badge.icon} className="comment-badge" />
          </BadgeWrapper>
        )}
        <UserLink to={`/user/${encodeDisplayName(displayName)}/comments/`}>{displayName}</UserLink>
        {showRelativeDate && props.renderRelativeDate && (
          <RelativeTime>{props?.renderRelativeDate(timestamp)}</RelativeTime>
        )}
        {badge?.title && <BadgeTitle>{badge.title}</BadgeTitle>}
        <div className="v-offset-10" />
        {text ? <p dangerouslySetInnerHTML={{ __html: text }} /> : null}
        {subText}
        {link}
        {showActions && <CommentActions {...commentActionProps} />}

        {props.childComments?.map((commentProps: Props) => {
          return (
            <Comment
              badge={commentProps.badge}
              childComments={commentProps.childComments}
              commentForm={commentForm}
              displayName={commentProps.displayName}
              isRated={commentProps.isRated}
              isUser={isUser}
              key={commentProps.UUID}
              onCommentSubmit={commentProps.onCommentSubmit}
              onRate={commentProps.onRate}
              parentUrl={commentProps.parentUrl}
              parentUUID={commentProps.parentUUID}
              postStatus={commentProps.postStatus}
              ratesCount={commentProps.ratesCount}
              renderRelativeDate={renderRelativeDate}
              replyText={replyText}
              setDisplayName={props.setDisplayName}
              showRelativeDate={commentProps.showRelativeDate}
              showUpgradeDialog={showUpgradeDialog}
              text={commentProps.text}
              timestamp={commentProps.timestamp}
              user={user}
              UUID={commentProps.UUID}
            />
          );
        }) ?? null}
      </div>
      <div className="clear" />
    </UserComment>
  );
});

Comment.displayName = 'Comment';

const UserComment = styled.div`
  margin: 20px 0 0;
  padding: 10px;
  border: 0 none;
  max-width: 900px;
  background-color: ${(props) => props.theme.primary6};

  .comment {
    padding: 0;
    margin: 0;

    + .comment {
      margin-top: 10px;
    }

    @media (max-width: 990px) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .actions + & {
    margin-top: 10px;
  }

  p {
    font-size: 0.9rem;
    max-width: 635px;
  }

  .wrapper {
    overflow: hidden;
  }
`;

const AvatarContainer = styled.div`
  width: 64px;

  @media (max-width: 990px) {
    display: none;
  }
`;

const Avatar = styled.div`
  width: 52px;
  height: 52px;
  line-height: 52px;
  border-radius: 2px;
  font-size: 2.6rem;
  text-align: center;
  background: ${(props) => props.theme.primary3};
  color: ${(props) => props.theme.primary6};
`;

const UserLink = styled(NavLink)`
  font-size: 1.07rem;
`;

const BadgeWrapper = styled.div`
  position: relative;
  width: 35px;
  height: 40px;
  display: flex;

  float: left;
  justify-content: center;
  align-items: center;

  .comment-badge {
    display: flex;
    font-size: 1.6rem;
    margin-right: 10px;
    width: 30px;
    height: 25px;
    align-items: center;

    path {
      width: 22px;
      fill: #ceab58;
    }
  }
`;

const BadgeTitle = styled.small`
  display: block;
  margin-left: 30px;
  font-size: 0.78rem;
  color: ${(props) => props.theme.primary7};
`;

const RelativeTime = styled.small`
  margin-left: 7px;
  color: ${(props) => props.theme.primary7};
`;

export default Comment;
