import { take, call, fork, select } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

const LOAD = 'man-site/itemCounts/LOAD';
const LOAD_SAGA = 'man-site/itemCounts/LOAD_SAGA';
const LOAD_FAIL = 'man-site/itemCounts/LOAD_FAIL';
const LOAD_SUCCESS = 'man-site/itemCounts/LOAD_SUCCESS';

const endpoint = constructUrl(urls.get.itemCounts);

const initialState = {
  lastCallParams: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        lastCallParams: action.lastCallParams,
      };
    }
    case LOAD_FAIL: {
      return {
        ...state,
      };
    }
    case LOAD_SUCCESS: {
      return {
        ...state,
        ...action.result,
      };
    }
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams, params) {
  return isEqual(lastCallParams, params);
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => state.itemCounts;
  const currentState = yield select(getState);
  const { lastCallParams } = currentState;
  if (!isLoaded(lastCallParams, params) && !currentState.loading) {
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint, { params }), {
      lastCallParams: { ...params },
    });
    yield call(loadFunc);
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
