import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModelDetailsPanel } from './ModelDetailsPanel';
import { ActivityPanel } from './ActivityPanel';
import RankingDetailsPanel from 'src/client/components/panels/RankingDetailsPanel';
import { loadTags, showNewTagsModal } from 'src/client/redux/modules/tags';
import { TagsPanel } from 'src/client/components/panels/TagsPanel/TagsPanel';
import { useSelector, useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';
import cx from 'classnames';
import { GalleryResponseModel, ModelResponse } from 'src/@types/app';
import { PanelsGrid } from './PanelsGrid';

type Props = {
  className?: string;
  model: ModelResponse | GalleryResponseModel;
  isModelPage?: boolean;
};

export const ModelPanels = (props: Props) => {
  const { model, isModelPage = false, className } = props;
  const dispatch = useDispatch();
  const config = useSelector((state) => state.app.config);
  const tags = useSelector((state) => state.tags.tags);
  const showTags = useSettingsSelector('tags');
  const ratingsScale = useSettingsSelector<number>('ratingsScale');
  const user = useSelector((state) => state.auth.user);

  const userTags = tags[model.UUID] ? tags[model.UUID].tags : [];
  const combinedTags = [...userTags, ...model.uniqueTagsWithUrl];

  const [fullMode, setFullMode] = useState(false);

  useEffect(() => {
    if (!user) return;

    setFullMode(user.validSubscription);

    dispatch(loadTags({ objectUUID: model.UUID, type: 'MODEL' }));
  }, [dispatch, model.UUID, user]);

  const addNewTag = useCallback(() => {
    if (!user || config.isWhiteLabel) return;

    dispatch(showNewTagsModal({ name: model.name, objectUUID: model.UUID, objectType: 'model' }));
  }, [config.isWhiteLabel, dispatch, model.name, model.UUID, user]);

  const blogUrl = 'blogs' in model && model.blogs[0] ? model.blogs[0].path : null;
  const showRanking = !config.isWhiteLabel && config.leaderboard.active;

  return (
    <PanelsGrid className={cx('model-panels', className)}>
      <ModelDetailsPanel model={model} isModelPage={isModelPage} className="model-details" />

      {!config.isWhiteLabel && (
        <ActivityPanel
          ratingAverage={parseFloat(model.ratingAverage.toFixed(ratingsScale))}
          objectUUID={model.UUID}
          objectType="model"
          ratings={model.ratingCount}
          views={model.views}
          itemUrl={model.path}
          blogUrl={blogUrl}
          fullMode={fullMode}
          className="model-activity"
        />
      )}

      {showRanking && <RankingDetailsPanel ranks={model.ranks} type="model" className="model-ranking" />}

      {!!showTags && <TagsPanel tags={combinedTags} userTags={userTags} onAddNewTag={addNewTag} />}
    </PanelsGrid>
  );
};
