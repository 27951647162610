export type ModelAttributes = {
  eyes?: string;
  hair?: string;
  height?: number;
  weight?: number;
  breasts?: string;
  size?: string;
  pubicHair?: string;
  ethnicity: string;
};

export const getModelAttributes = (attrs: ModelAttributes) => [
  { label: 'Eye color', statType: 'eye', value: attrs.eyes },
  { label: 'Hair color', statType: 'hair', value: attrs.hair },
  { label: 'Height', statType: 'height', value: attrs.height },
  { label: 'Weight', statType: 'weight', value: attrs.weight },
  { label: 'Breasts', statType: 'breast', value: attrs.breasts },
  { label: 'Measurements', statType: 'measurements', value: attrs.size },
  { label: 'Shaved', statType: 'shaved', value: attrs.pubicHair },
  { label: 'Ethnicity', statType: 'ethnicity', value: attrs.ethnicity.replace(/(\w)/, (match) => match.toUpperCase()) },
];
