import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useSelector } from 'src/client/redux';

type Props = {
  title: string;
  url: string;
};

const ButtonWrapper = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    min-height: 90px;
  }
`;

// using transient props so they don't get passed down to the Link component
const ButtonLink = styled(Link)<{
  $buttonColor?: string;
  $buttonHoverColor?: string;
}>`
  display: block;
  text-decoration: none;
  text-align: center;
  background: ${({ $buttonColor }) => $buttonColor || '#272137'};
  color: #fff;
  font-family: Urbanist, 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 80px;
  height: 80px;
  width: 380px;
  border-radius: 40px;
  text-transform: uppercase;
  margin: 0 auto;
  transition: 0.3s;

  @media (max-width: 1024px) {
    font-size: 14px;
    width: 180px;
    height: 40px;
    line-height: 40px;
  }

  @media (max-width: 430px) {
    font-size: 16px;
    width: 180px;
    height: 45px;
    line-height: 45px;
  }

  :hover,
  :focus {
    background: ${({ $buttonHoverColor }) => $buttonHoverColor || '#322b46'};
    text-decoration: none;
    color: #fff;
  }
`;

export const Button = (props: Props) => {
  const {
    config: { splash },
  } = useSelector((state) => state.app);

  return (
    <ButtonWrapper>
      <ButtonLink $buttonColor={splash?.buttonColor} $buttonHoverColor={splash?.buttonHoverColor} to={props.url}>
        {props.title}
      </ButtonLink>
    </ButtonWrapper>
  );
};
