import React from 'react';
import { useCookies } from 'react-cookie';
import { getPartnerTrackingData } from '../../utils/affiliateTracking';
import { useSelector } from 'src/client/redux';
import { MAM_COOKIE_NAME } from 'src/shared/constants/cookies';

export default function AffiliateHeader() {
  const affiliateHeader = useSelector((state) => state.app.config.affiliateHeader);
  const [cookies] = useCookies([MAM_COOKIE_NAME]);

  const partnerTrackingData = getPartnerTrackingData(cookies[MAM_COOKIE_NAME]);

  if (!partnerTrackingData) {
    return null;
  }

  const affiliateHeaderHTML = affiliateHeader && affiliateHeader[partnerTrackingData.partnerID];

  return affiliateHeaderHTML ? <div dangerouslySetInnerHTML={{ __html: affiliateHeaderHTML }} /> : null; // eslint-disable-line react/no-danger
}
