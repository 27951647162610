import { DESKTOP, TABLET_LANDSCAPE, TABLET_PORTRAIT } from '@tovia/man-ui';
import styled from 'styled-components/macro';

export const PanelsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;

  .panel {
    margin: 0;
    height: 100%;
  }

  .panel-content {
    padding: 0;
  }

  & > div {
    grid-column: span 12;
  }

  .gallery-details,
  .gallery-activity,
  .model-details,
  .model-activity,
  .photographer-details,
  .photographer-activity {
    @media ${TABLET_PORTRAIT} {
      grid-column: span 6;
    }

    @media ${TABLET_LANDSCAPE} {
      grid-column: span 4;
    }
  }

  .gallery-purchase,
  .gallery-ranking,
  .model-ranking {
    @media ${TABLET_LANDSCAPE} {
      grid-column: span 4;
    }
  }

  .movie-details {
    @media ${TABLET_LANDSCAPE} {
      grid-column: span 7;
    }

    @media ${DESKTOP} {
      grid-column: span 6;
    }
  }

  .movie-purchase {
    @media ${TABLET_LANDSCAPE} {
      grid-column: span 5;
    }

    @media ${DESKTOP} {
      grid-column: span 6;
    }
  }
`;
