import { fork, call, takeEvery } from 'redux-saga/effects';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

// PAYLOADS //
type PostParams = {
  email: string;
  message: string;
};

type PostGeneratorData = {
  type: string;
  data: PostGeneratorData;
};

// ACTIONS //
const ActionType = {
  POST: 'man-site/piracyReport/POST',
  POST_SAGA: 'man-site/piracyReport/POST_SAGA',
  POST_SUCCESS: 'man-site/piracyReport/POST_SUCCESS',
  POST_FAIL: 'man-site/piracyReport/POST_FAIL',
} as const;

const endpoint = constructUrl(urls.post.piracyReport);

type ActionType = typeof ActionType[keyof typeof ActionType];

type PostAction = {
  type: typeof ActionType.POST;
};

type PostSuccessAction = {
  type: typeof ActionType.POST_SUCCESS;
};

type FetchError = SuperAgentError | null;

type PostFailAction = {
  type: typeof ActionType.POST_FAIL;
  error: FetchError;
};

type PostSagaAction = {
  type: typeof ActionType.POST_SAGA;
  params: PostParams;
};

type Action = PostAction | PostSuccessAction | PostFailAction | PostSagaAction;

// STATE //
type State = {
  status: 'submitting' | 'success' | 'fail' | null;
  error: FetchError;
};

const initialState: State = {
  status: null,
  error: null,
};

export default function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case ActionType.POST:
      return {
        ...state,
        status: 'submitting',
      };
    case ActionType.POST_SUCCESS:
      return {
        ...state,
        status: 'success',
      };
    case ActionType.POST_FAIL:
      return {
        ...state,
        status: 'fail',
        error: action.error,
      };
    default:
      return state;
  }
}

export function post(params: PostParams): PostSagaAction {
  return {
    type: ActionType.POST_SAGA,
    params,
  };
}

/* SAGAS */
function* postGenerator(data: PostGeneratorData) {
  const postFunc = getRequestFunc([ActionType.POST, ActionType.POST_SUCCESS, ActionType.POST_FAIL], (client) =>
    client.post(endpoint, { data }),
  );
  yield call(postFunc);
}

function* watchPost() {
  yield takeEvery(ActionType.POST_SAGA, postGenerator);
}

export const watchers = [fork(watchPost)];
/* EOF SAGAS */
