import React from 'react';
import styled from 'styled-components/macro';

import { destructUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';

const SitesWrapper = styled.div`
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    min-height: 80px;
  }
  @media (max-width: 430px) {
    min-height: 50px;
  }
`;

const SiteLogos = styled.div<{ desktop: string; tablet: string; mobile: string }>`
  background-image: url(${(props) => props.desktop});
  background-repeat: no-repeat;
  background-position: center center;
  width: 1866px;
  min-height: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1866px) {
    max-width: 100%;
    width: 95%;
    position: static;
    left: 0;
    transform: none;
    background-size: contain;
  }

  @media (max-width: 1024px) {
    width: 758px;
    min-height: 76px;
    left: 52%;
    background-image: url(${(props) => props.tablet});
  }

  @media (max-width: 758px) {
    max-width: 95%;
    position: static;
    left: 0;
    transform: none;
    background-size: contain;
  }

  @media (max-width: 430px) {
    width: 100%;
    min-height: 76px;
    left: 52%;
    background-image: url(${(props) => props.mobile});
  }

  @media (max-width: 320px) {
    max-width: 98%;
    position: static;
    left: 0;
    transform: none;
    background-size: contain;
  }
`;

export const Sites = () => {
  const site = useSelector((state) => state.site);
  const { cdnUrl } = useSelector((state) => state.app);

  return (
    <SitesWrapper>
      <SiteLogos
        desktop={`${cdnUrl}/splash/${destructUUID(site.networkUUID)}/network_logos_desktop.png`}
        tablet={`${cdnUrl}/splash/${destructUUID(site.networkUUID)}/network_logos_tablet.png`}
        mobile={`${cdnUrl}/splash/${destructUUID(site.networkUUID)}/network_logos_mobile.svg`}
      />
    </SitesWrapper>
  );
};
