import React from 'react';
import styled from 'styled-components/macro';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';
import { TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';

export const PromoHeaderRibbon = () => {
  const [joinHref] = useJoinUrl({ campaign: 'ad-zone-header-ribbon' });

  return (
    <PromoHeaderRibbonContainer>
      <PromoHeaderRibbonLink href={joinHref}>
        HOLIDAY SALE, 50% OFF!!!! - Limited time special - HURRY!!!! Sale ends January 06
      </PromoHeaderRibbonLink>
    </PromoHeaderRibbonContainer>
  );
};

const PromoHeaderRibbonContainer = styled.div`
  align-items: center;
  background-color: #ceab58;
  display: flex;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  min-height: 40px;
  padding: 4px 0;
  text-align: center;

  @media ${TABLET_PORTRAIT} {
    font-size: 16px;
  }
`;

const PromoHeaderRibbonLink = styled.a`
  color: #000;
  display: block;
  margin: 0 2px;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: #e6e6e6;
    text-decoration: underline;
  }
`;
