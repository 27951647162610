import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import styled, { keyframes } from 'styled-components/macro';
import { useSelector } from '../../redux/modules/helpers/useSelector';

const animation = keyframes`
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.3);
  }

  45% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.15);
  }

  75% {
    transform: scale(1);
  }

  90% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
`;

const Div = styled.div`
  align-items: center;
  background-color: #eff0f2;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  height: calc(100vh - 64px);

  & .success-wrapper {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;

    .success-image {
      animation: ${animation} 1s forwards;
      animation-delay: 0.15s;
    }

    .success-text {
      font-size: 2.25rem;
      font-weight: 700;
      margin: 12px 0;
    }

    .loading-text {
      color: #999;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
`;

export default function NewsletterSuccessPageContainer(props: {
  // eslint-disable-next-line react/require-default-props
  className?: string;
}) {
  const { className = '' } = props;
  const cdnUrl = useSelector((state) => state.app.cdnUrl);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push('/updates');
    }, 2500);
  }, [history]);

  return (
    <Div className={className}>
      <div className="success-wrapper">
        <img className="success-image" src={`${cdnUrl}/img/success_checkmark.svg`} alt="success" />
        <h1 className="success-text">You&apos;re all set!</h1>
        <p className="loading-text">Thank you for activating your newsletter! Redirecting you to the site...</p>
      </div>
    </Div>
  );
}
