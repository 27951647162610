import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import LineBehind from '@tovia/man-ui/lib/components/LineBehind/LineBehind';
import RoundButton from '@tovia/man-ui/lib/components/Button/RoundButton';
import PanelInfoItem from '@tovia/man-ui/lib/components/Panel/PanelInfoItem';
import { displayModelStat } from './displayModelStat';
import { faCamera, faCamcorder, faMapMarkerAlt, faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import { StyledIcon } from 'src/client/components/ui/StyledIcon';
import { modelHeadshotUrl } from 'src/client/helpers/urls/modelHeadshotUrl';
import { useSelector } from 'src/client/redux';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';
import { BlurrableImage } from 'src/client/components/ui/BlurredImage';
import { useGetGalleryCoverUrl } from 'src/client/helpers/urls';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  runnersUp: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  topModel: any;
  galleryCount: number;
  movieCount: number;
  measurements: 'imperial' | 'metric';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recentContentQuery: any[];
  className?: string;
  show: boolean;
};

export const TopModelsSection = (props: Props) => {
  const { runnersUp, topModel, galleryCount, movieCount, measurements, recentContentQuery } = props;
  const topModelContentCount = 3;
  const { contentCdnUrl } = useSelector((state) => state.app);
  const showSfwSite = useShowSfwSite();
  const getGalleryCoverUrl = useGetGalleryCoverUrl();

  const renderEthnicity = (ethnicity) => {
    if (!ethnicity) {
      return 'N/A';
    }
    return ethnicity.replace(/(\w)/, (match) => match.toUpperCase());
  };

  const renderRunnerUpList = () => (
    <div className="model-list row">
      {runnersUp.map((runnerUp) => {
        const { coverUrl, blurImage } = modelHeadshotUrl(contentCdnUrl, runnerUp, runnerUp.siteUUID, showSfwSite);
        return (
          <div className="model-list-portrait-col col-xs-4 col-md-2" key={runnerUp.UUID}>
            <NavLink className="model-link nowrap" to={runnerUp.path}>
              <BlurrableImage
                className="model-list-portrait-img img-responsive"
                src={coverUrl}
                alt={runnerUp.name}
                $isBlurred={blurImage}
              />
              {runnerUp.name}
            </NavLink>
          </div>
        );
      })}
    </div>
  );

  const renderTopModelSection = () => {
    const renderHighlights = (highlights) =>
      highlights.map(({ label, key, converter }) => (
        <li key={key}>
          <PanelInfoItem
            label={label}
            value={displayModelStat({
              statType: key,
              value: (converter && converter(topModel[key])) || topModel[key],
              measurements,
            })}
            labelClassName
          />
        </li>
      ));

    const hasStats = (!!topModel.eyes && !!topModel.hair) || (!!topModel.weight && !!topModel.height);
    const showAge = !!(topModel.age && topModel.age !== 0);

    const { coverUrl, blurImage } = modelHeadshotUrl(contentCdnUrl, topModel, topModel.siteUUID, showSfwSite);

    return (
      <div className="row details">
        <div className="col-md-12 col-lg-6 main-model">
          <div className="model-stats-wrapper">
            <div className="model-portrait pull-left">
              <NavLink to={topModel.path}>
                <BlurrableImage src={coverUrl} alt={topModel.name} className="img-responsive" $isBlurred={blurImage} />
              </NavLink>
            </div>
            <div className="model-stats pull-left">
              <div className="model-name">
                <NavLink to={topModel.path}>{topModel.name}</NavLink>
              </div>
              <ul className="pull-left">
                {topModel.country && (
                  <li>
                    <StyledIcon $spaceRight icon={faMapMarkerAlt} />
                    <span className="highlight">{topModel.country.name}</span>
                  </li>
                )}
                {showAge && (
                  <li>
                    Age Published:&nbsp;
                    <span className="highlight">{topModel.age}</span>
                  </li>
                )}
              </ul>
              <div className="clearfix" />
              <ul className="pull-left stat-highlights">
                {hasStats &&
                  renderHighlights([
                    ...(topModel.eyes ? [{ label: 'Eye color', key: 'eyes' }] : []),
                    ...(topModel.hair ? [{ label: 'Hair color', key: 'hair' }] : []),
                    ...(topModel.height ? [{ label: 'Height', key: 'height' }] : []),
                    ...(topModel.weight ? [{ label: 'Weight', key: 'weight' }] : []),
                  ])}
              </ul>
              <ul className="pull-right stat-highlights hidden-xxxs">
                {hasStats &&
                  renderHighlights([
                    ...(topModel.breasts ? [{ label: 'Breasts', key: 'breasts' }] : []),
                    ...(topModel.size ? [{ label: 'Size', key: 'size' }] : []),
                    ...(topModel.pubicHair ? [{ label: 'Shaved', key: 'pubicHair' }] : []),
                    ...(topModel.ethnicity
                      ? [{ label: 'Ethnicity', key: 'ethnicity', converter: (ethnicity) => renderEthnicity(ethnicity) }]
                      : []),
                  ])}
              </ul>
              <div className="clearfix" />
              <ul className="list-inline model-stats-icons">
                {movieCount > 0 && (
                  <li className="model-stats-icon">
                    <StyledIcon $spaceRight icon={faCamcorder} />
                    {movieCount}
                  </li>
                )}
                {galleryCount > 0 && (
                  <li className=" model-stats-icon">
                    <StyledIcon $spaceRight icon={faCamera} />
                    {galleryCount}
                  </li>
                )}
                {topModel.ratingAverage && (
                  <>
                    <li className="model-stats-separator">|</li>
                    <li className="model-stats-icon">
                      <StyledIcon $spaceRight icon={faThumbsUp} />
                      {Math.round(parseFloat(topModel.ratingAverage) * 10)}%
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        {recentContentQuery
          .map((gallery) => {
            const { url, blurImage } = getGalleryCoverUrl(contentCdnUrl, gallery, {});
            return (
              <div key={gallery.UUID} className="col-lg-2 visible-lg custom-spacing-models">
                <div className="custom-img-container">
                  <NavLink to={gallery.path}>
                    <BlurrableImage
                      src={url}
                      alt={gallery.name}
                      className="img-responsive custom-img-default"
                      $isBlurred={blurImage}
                    />
                  </NavLink>
                </div>
              </div>
            );
          })
          .slice(0, topModelContentCount)}
      </div>
    );
  };

  if (!props.show) return null;

  return (
    <Section>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <LineBehind label="GLOBAL TOP MODELS" />
          </div>
        </div>
        {renderTopModelSection()}
        {renderRunnerUpList()}
        <div className="text-center">
          <div className="v-offset-10" />
          <RoundButton to="/models/top/">VIEW ALL MODELS</RoundButton>
          <div className="v-offset-30" />
        </div>
      </div>
    </Section>
  );
};

const Section = styled.section`
  & .details {
    margin-top: 30px;
  }

  & .main-model {
    padding-left: 2px;
    padding-right: 2px;
  }

  & .model-list {
    margin-top: 4px;
    margin-bottom: 30px;
    text-align: center;
  }

  & .model-list-portrait-col,
  & .custom-spacing-models {
    padding-left: 2px;
    padding-right: 2px;
  }

  & .model-list-portrait-col {
    margin-bottom: 40px;
  }

  & .model-list-portrait-col a {
    text-decoration: none;
    color: ${(props) => props.theme.splashPrimary2};
    height: 0;
    padding-top: 150%;
    display: block;
    justify-content: center;
    align-items: center;
    background-color: #2b2b2b;

    img {
      position: absolute;
      top: 0;
      height: 100%;
      object-fit: contain;
    }
  }

  & .model-stats-wrapper {
    height: 287px;
    font-size: 0.85rem;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    background-color: ${(props) => props.theme.splashOptional1 || props.theme.splashPrimary4};
    color: ${(props) => props.theme.splashPrimary3};

    @media (max-width: 767px) {
      height: auto;
    }

    @media (max-width: 639px) {
      height: auto;
      min-width: 355px;
      font-size: 0.7rem;
      padding: 0;
    }
  }

  & .model-portrait {
    width: 146px;

    @media (max-width: 639px) {
      width: 120px;
    }
  }

  & .model-name {
    width: 300px;
    margin-left: 16px;
    font-size: 1.7rem;
    font-weight: 100;
    line-height: 1.1;
    margin-bottom: 10px;

    @media (max-width: 639px) {
      font-size: 1.3rem;
      margin-left: 0;
    }

    @media (max-width: 479px) {
      width: auto;
    }

    a {
      text-decoration: none;
    }
  }

  & .model-stats {
    @media (max-width: 639px) {
      margin-left: 0;
      width: 235px;
      padding-left: 10px;
      padding-top: 10px;
    }

    @media (max-width: 479px) {
      width: 160px;
    }

    ul {
      width: 150px;
      margin-left: 16px;
      padding-left: 0;
      list-style: none;

      @media (max-width: 639px) {
        width: 200px;
        margin-top: 0;
        margin-left: 0;
      }

      @media (max-width: 479px) {
        width: 150px;
      }
    }

    ul.list-inline {
      li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    ul.model-stats-icons {
      width: 300px;
      margin-top: 30px;

      @media (max-width: 639px) {
        width: 200px;
        margin-top: 0;
      }

      @media (max-width: 479px) {
        width: 150px;
      }
    }

    .stat-highlights {
      min-height: 72px;
    }
  }

  & .model-stats-icon {
    color: ${(props) => props.theme.splashPrimary2};
    opacity: 0.5;
    padding: 0;
    font-size: 0.85rem;
  }

  & .model-stats-separator {
    padding-left: 0;
    font-size: 1rem;
  }

  & .highlight,
  & .top-model-section,
  & .man-label {
    color: ${(props) => props.theme.splashPrimary2};
  }

  & .custom-img-container {
    height: 287px;
    display: flex;

    a {
      display: flex;
      align-items: center;
      background-color: #2b2b2b;
    }
  }
`;
