import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Div = styled.div`
  background-color: #f5f5f5;
  bottom: 0;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
  width: inherit;

  .current-progress {
    background-color: #ceab58;
    height: inherit;
    width: ${(props) => props.progress}%;
  }
`;

export default function ProgressBar(props) {
  const { className, progress } = props;

  if (!progress) {
    return null;
  }

  return (
    <Div className={`progress-bar ${className}`} progress={progress}>
      <div className="current-progress" />
    </Div>
  );
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number,
};

ProgressBar.defaultProps = {
  className: '',
  progress: 0,
};
