import { isEqual } from 'lodash/fp';
import { take, call, fork, select } from 'redux-saga/effects';
import { CustomSuperAgentClient } from '../../../@types/superagent';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

const LOAD = 'man-site/galleryPermissions/LOAD';
const LOAD_FAIL = 'man-site/galleryPermissions/LOAD_FAIL';
const LOAD_SAGA = 'man-site/galleryPermissions/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/galleryPermissions/LOAD_SUCCESS';
const RESET = 'man-site/galleryPermissions/RESET';

interface Store {
  galleryPermissions: GalleryPermissionsState;
}

interface GalleryPermissionsState {
  allowedStreamIds: string[];
  loading: boolean;
  loaded: boolean;
  downloadCounts: {
    allDownloadsCount: number;
    currentGalleryCount: number;
    limitDownloads: number;
    allowedToDownload: boolean;
  };
  lastCallParams: {
    token?: string;
    galleryUUID?: string;
  };
}

interface ActionLoad {
  type: typeof LOAD;
  lastCallParams: GalleryPermissionsState['lastCallParams'];
}

interface ActionLoadFail {
  type: typeof LOAD_FAIL;
}

interface ActionLoadSaga {
  type: typeof LOAD_SAGA;
}

interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  result: {
    allowedStreamIds: string[];
    downloadCounts: GalleryPermissionsState['downloadCounts'];
  };
}

interface ActionReset {
  type: typeof RESET;
}

type GalleryPermissionActionTypes = ActionLoad | ActionLoadFail | ActionLoadSaga | ActionLoadSuccess | ActionReset;

interface LoadParams {
  token?: string;
  gallery: string;
}

const endpoint = constructUrl(urls.get.galleryPermissions);

const initialState: GalleryPermissionsState = {
  allowedStreamIds: [],
  loading: false,
  loaded: false,
  lastCallParams: {},
  downloadCounts: {
    allDownloadsCount: 0,
    currentGalleryCount: 0,
    limitDownloads: 0,
    allowedToDownload: false,
  },
};

export default function reducer(state = initialState, action: GalleryPermissionActionTypes) {
  switch (action.type) {
    case LOAD: {
      const { lastCallParams } = action;
      return {
        ...initialState,
        loading: true,
        loaded: false,
        lastCallParams,
      };
    }

    case LOAD_SUCCESS: {
      return {
        ...state,
        ...action.result,
        loading: false,
        loaded: true,
      };
    }

    case LOAD_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case RESET:
      return { ...initialState };

    default: {
      return state;
    }
  }
}

export function load(params: LoadParams) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}

/* SAGAS */
export function* loadGenerator(params: LoadParams) {
  const getState = (state: Store) => state.galleryPermissions;
  const currentState = yield select(getState);

  if (!currentState.loading && !isEqual(currentState.lastCallParams, params)) {
    const options = {
      params: {
        token: params.token || '',
        galleryUUID: params.gallery,
      },
    };

    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client: CustomSuperAgentClient) => client.get(endpoint, options),
      { lastCallParams: { ...params } },
    );

    yield call(loadFunc);
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    const { params } = yield take(LOAD_SAGA);
    yield call(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
