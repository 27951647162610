import PropTypes from 'prop-types';
import { ResponseError } from 'superagent';
import { AnyAction } from 'redux';
import { Site } from './site';

export const staffSelectionUUID = '466BA5C62FD1D8C409C5634696D9605C';
export const loverHairyUUID = '605315CFA26369142D715EE4E9ED534E';

export const sitePropType = PropTypes.shape({
  UUID: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  abbreviation: PropTypes.string.isRequired,
});

export const sitesPropType = PropTypes.arrayOf(sitePropType);

interface InitialState {
  loaded?: boolean;
  loading?: boolean;
  error?: ResponseError;
  items: Site[];
  siteUUID?: string;
  picker?: {
    user?: string[];
    guest?: string[];
  };
}

export const initialState: InitialState = {
  items: [],
};

export default function reducer(state = initialState, action: AnyAction): InitialState {
  switch (action.type) {
    default: {
      return state;
    }
  }
}
