import React from 'react';
import styled from 'styled-components/macro';
import { SkinnyPage } from '../components/layouts/SkinnyPage';
import { MetArt } from '../constants/sites';
import { useSelector } from 'src/client/redux';
import { MainPageTitle } from 'src/client/components/layouts/MainPageTitle';
import { useShowSfwSite } from '../helpers/useFeatureFlags';

const AboutText = styled.div`
  white-space: pre-line;
`;

export const About = () => {
  const config = useSelector((state) => state.app.config);
  const site = useSelector((state) => state.site);
  const showSfwSite = useShowSfwSite();

  const aboutText = showSfwSite ? config.aboutUsTextSfw : config.aboutUsText;

  // SEO_CLEANUP
  const title = site.abbreviation === MetArt.abbreviation ? 'About MetArt Erotic art' : `About ${site.name}`;

  return (
    <SkinnyPage>
      <MainPageTitle>{title}</MainPageTitle>
      <AboutText>{aboutText}</AboutText>
    </SkinnyPage>
  );
};
