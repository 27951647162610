import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import {
  faBars,
  faCloudDownloadAlt,
  faComment,
  faComments,
  faDesktop,
  faExpandArrowsAlt,
  faExternalLink,
  faFilmAlt,
  faGift,
  faHistory,
  faHome,
  faImages,
  faInfoCircle,
  faLocationCircle,
  faPlayCircle,
  faSearch,
  faStar,
  faStars,
  faTags,
  faTh,
  faThLarge,
  faThList,
} from '@fortawesome/pro-regular-svg-icons';
import { SettingsKey } from 'src/client/redux/modules/settings';

export type SettingData = {
  label: {
    id: string;
    defaultMessage: string;
  };
  settingKey: SettingsKey;
  iconDefinition: IconDefinition;
  options: SettingDataOption[];
};

export type SettingDataOption = {
  label: string | number;
  value: string | number;
  id?: string;
  defaultMessage?: string;
  filter?: string;
  inverted?: boolean;
};

export const formData: SettingData[] = [
  {
    label: {
      id: 'settings.selects.resolution',
      defaultMessage: 'Resolution',
    },
    settingKey: 'resolution',
    options: [
      {
        label: 'Low',
        value: 'low',
        id: 'settings.options.resolution.low',
        defaultMessage: 'low',
      },
      {
        label: 'Medium',
        value: 'medium',
        id: 'settings.options.resolution.medium',
        defaultMessage: 'medium',
      },
      {
        label: 'High',
        value: 'high',
        id: 'settings.options.resolution.high',
        defaultMessage: 'high',
      },
    ],
    iconDefinition: faDesktop,
  },
  {
    label: {
      id: 'settings.selects.thumbnails',
      defaultMessage: 'Thumbnails',
    },
    settingKey: 'thumbnailsPerPage',
    options: [
      { label: '20', value: 20 },
      { label: '40', value: 40 },
      { label: '60', value: 60 },
      { label: '80', value: 80 },
      { label: '100', value: 100 },
      { label: '1000', value: 1000 },
    ],
    iconDefinition: faTh,
  },
  {
    label: {
      id: 'settings.selects.imageWindow',
      defaultMessage: 'Open images',
    },
    settingKey: 'imageWindow',
    options: [
      {
        label: 'Same Window',
        value: 'same',
        id: 'settings.options.imageWindow.sameWindow',
        defaultMessage: 'Same Window',
      },
      {
        label: 'New Window',
        value: 'new',
        id: 'settings.options.imageWindow.newWindow',
        defaultMessage: 'New Window',
      },
    ],
    iconDefinition: faExternalLink,
  },
  {
    label: {
      id: 'settings.selects.listView',
      defaultMessage: 'List View',
    },
    settingKey: 'listView',
    options: [
      {
        label: 'Grid',
        value: 'grid',
        id: 'settings.options.listView.grid',
        defaultMessage: 'Grid',
      },
      {
        label: 'Detailed',
        value: 'detailed',
        id: 'settings.options.listView.detailed',
        defaultMessage: 'Detailed',
      },
    ],
    iconDefinition: faThList,
  },
  {
    label: {
      id: 'settings.selects.comments',
      defaultMessage: 'Comments',
    },
    settingKey: 'comments',
    options: [
      {
        label: 'Show',
        value: 1,
        id: 'settings.options.comments.show',
        defaultMessage: 'Show',
      },
      {
        label: 'Hide',
        value: 0,
        id: 'settings.options.comments.hide',
        defaultMessage: 'Hide',
      },
    ],
    iconDefinition: faComment,
  },
  {
    label: {
      id: 'settings.selects.commentSorting',
      defaultMessage: 'Comments sorted by',
    },
    settingKey: 'commentSorting',
    iconDefinition: faComments,
    options: [
      {
        label: 'Newsest',
        value: 'newest',
        id: 'settings.options.commentSorting.newest',
        defaultMessage: 'Newest',
      },
      {
        label: 'Oldest',
        value: 'oldest',
        id: 'settings.options.commentSorting.oldest',
        defaultMessage: 'Oldest',
      },
      {
        label: 'Thumbs Up',
        value: 'thumbsup',
        id: 'settings.options.commentSorting.thumbsUp',
        defaultMessage: 'Thumbs Up',
      },
    ],
  },
  {
    label: {
      id: 'settings.selects.filmsSorting',
      defaultMessage: 'Films sorted by',
    },
    settingKey: 'filmsSorting',
    iconDefinition: faFilmAlt,
    options: [
      {
        label: 'Latest',
        value: 'latest',
        id: 'settings.options.filmsSorting.latest',
        defaultMessage: 'Latest',
      },
      {
        label: 'Top Rated',
        value: 'top',
        id: 'settings.options.filmsSorting.topRated',
        defaultMessage: 'Top Rated',
      },
      {
        label: 'Most Viewed',
        value: 'viewed',
        id: 'settings.options.filmsSorting.mostViewed',
        defaultMessage: 'Most Viewed',
      },
      {
        label: 'Most Favorited',
        value: 'favorited',
        id: 'settings.options.filmsSorting.mostFavorited',
        defaultMessage: 'Most Favorited',
      },
      {
        label: '4K Resolution',
        value: 'resolution',
        id: 'settings.options.filmsSorting.resolution4K',
        defaultMessage: '4k Resolution',
        filter: 'has4kResolution',
      },
    ],
  },
  {
    label: {
      id: 'settings.selects.galleriesSorting',
      defaultMessage: 'Galleries sorted by',
    },
    settingKey: 'galleriesSorting',
    iconDefinition: faImages,
    options: [
      {
        label: 'Latest',
        value: 'latest',
        id: 'settings.options.galleriesSorting.latest',
        defaultMessage: 'Latest',
      },
      {
        label: 'Top Rated',
        value: 'top',
        id: 'settings.options.galleriesSorting.topRated',
        defaultMessage: 'Top Rated',
      },
      {
        label: 'Most Viewed',
        value: 'viewed',
        id: 'settings.options.galleriesSorting.mostViewed',
        defaultMessage: 'Most Viewed',
      },
      {
        label: 'Most Favorited',
        value: 'favorited',
        id: 'settings.options.galleriesSorting.mostFavorited',
        defaultMessage: 'Most Favorited',
      },
    ],
  },
  {
    label: {
      id: 'settings.selects.modelsSorting',
      defaultMessage: 'Models sorted by',
    },
    settingKey: 'modelsSorting',
    iconDefinition: faStars,
    options: [
      {
        label: 'Top Rated',
        value: 'top',
        id: 'settings.options.modelsSorting.topRated',
        defaultMessage: 'Top Rated',
      },
      {
        label: 'Newest',
        value: 'new',
        id: 'settings.options.modelsSorting.newest',
        defaultMessage: 'Newest',
      },
      {
        label: 'Alphabetical',
        value: 'all',
        id: 'settings.options.modelsSorting.alphabetical',
        defaultMessage: 'Alphabetical',
      },
      {
        label: 'Most Viewed',
        value: 'viewed',
        id: 'settings.options.modelsSorting.mostViewed',
        defaultMessage: 'Most Viewed',
      },
      {
        label: 'Most Favorited',
        value: 'favorited',
        id: 'settings.options.modelsSorting.mostFavorited',
        defaultMessage: 'Most Favorited',
      },
      {
        label: 'My Top Rated',
        value: 'my-top',
        id: 'settings.options.modelsSorting.myTopRated',
        defaultMessage: 'My Top Rated',
      },
      {
        label: 'Most Appearances',
        value: 'most-appearances',
        id: 'settings.options.modelsSorting.mostAppearances',
        defaultMessage: 'Most Appearances',
      },
    ],
  },
  {
    label: {
      id: 'settings.selects.navigation',
      defaultMessage: 'Navigation',
    },
    settingKey: 'navigation',
    options: [
      {
        label: 'Simple',
        value: 'simple',
        id: 'settings.options.navigation.simple',
        defaultMessage: 'Simple',
      },
      {
        label: 'Enhanced',
        value: 'enhanced',
        id: 'settings.options.navigation.enhanced',
        defaultMessage: 'Enhanced',
      },
    ],
    iconDefinition: faLocationCircle,
  },
  {
    label: {
      id: 'settings.selects.searchResults',
      defaultMessage: 'Search Results',
    },
    settingKey: 'resultsPerPage',
    options: [
      { label: 30, value: 30 },
      { label: 90, value: 90 },
      { label: 180, value: 180 },
      { label: 720, value: 720 },
      { label: 'All', value: 10000 },
    ],
    iconDefinition: faSearch,
  },
  {
    label: {
      id: 'settings.selects.thumbnailView',
      defaultMessage: 'Thumbnail View',
    },
    settingKey: 'thumbnail',
    options: [
      {
        label: 'Square',
        value: 0,
        id: 'settings.options.thumbnailView.square',
        defaultMessage: 'Square',
      },
      {
        label: 'Full',
        value: 1,
        id: 'settings.options.thumbnailView.full',
        defaultMessage: 'Full',
      },
    ],
    iconDefinition: faThLarge,
  },
  {
    label: {
      id: 'settings.selects.descriptions',
      defaultMessage: 'Descriptions',
    },
    settingKey: 'descriptions',
    options: [
      {
        label: 'Show',
        value: 1,
        id: 'settings.options.descriptions.show',
        defaultMessage: 'Show',
      },
      {
        label: 'Hide',
        value: 0,
        id: 'settings.options.descriptions.hide',
        defaultMessage: 'Hide',
      },
    ],
    iconDefinition: faBars,
  },
  {
    label: {
      id: 'settings.selects.recents',
      defaultMessage: 'Recents',
    },
    settingKey: 'recents',
    options: [
      {
        label: 'Show',
        value: 1,
        id: 'settings.options.recents.show',
        defaultMessage: 'Show',
      },
      {
        label: 'Hide',
        value: 0,
        id: 'settings.options.recents.hide',
        defaultMessage: 'Hide',
      },
    ],
    iconDefinition: faHistory,
  },
  {
    label: {
      id: 'settings.selects.tags',
      defaultMessage: 'Tags',
    },
    settingKey: 'tags',
    options: [
      {
        label: 'Show',
        value: 1,
        id: 'settings.options.tags.show',
        defaultMessage: 'Show',
      },
      {
        label: 'Hide',
        value: 0,
        id: 'settings.options.tags.hide',
        defaultMessage: 'Hide',
      },
    ],
    iconDefinition: faTags,
  },
  {
    label: {
      id: 'settings.selects.slideshow',
      defaultMessage: 'Slideshow',
    },
    settingKey: 'slideshowDelay',
    options: [
      { label: '2 seconds', value: 2 },
      { label: '4 seconds', value: 4 },
      { label: '6 seconds', value: 6 },
      { label: '8 seconds', value: 8 },
      { label: '10 seconds', value: 10 },
      { label: '15 seconds', value: 15 },
      { label: '20 seconds', value: 20 },
    ],
    iconDefinition: faPlayCircle,
  },
  {
    label: {
      id: 'settings.selects.measurements',
      defaultMessage: 'Measurements',
    },
    settingKey: 'measurements',
    options: [
      {
        label: 'Metric',
        value: 'metric',
        id: 'settings.options.measurements.metric',
        defaultMessage: 'Metric',
      },
      {
        label: 'Imperial',
        value: 'imperial',
        id: 'settings.options.measurements.imperial',
        defaultMessage: 'Imperial',
      },
    ],
    iconDefinition: faInfoCircle,
  },
  {
    label: {
      id: 'settings.selects.download',
      defaultMessage: 'Download',
    },
    settingKey: 'downloadMirror',
    options: [
      {
        label: 'Standard Mirror',
        value: 1,
        id: 'settings.options.download.standardMirror',
        defaultMessage: 'Standard Mirror',
      },
      {
        label: 'Alternate Mirror',
        value: 0,
        id: 'settings.options.download.alternateMirror',
        defaultMessage: 'Alternate Mirror',
      },
    ],
    iconDefinition: faCloudDownloadAlt,
  },
  {
    label: {
      id: 'settings.selects.template',
      defaultMessage: 'Template',
    },
    settingKey: 'template',
    options: [
      {
        label: 'Light',
        value: 'light',
        id: 'settings.options.template.light',
        defaultMessage: 'Light',
      },
      {
        label: 'Dark',
        value: 'dark',
        id: 'settings.options.template.dark',
        defaultMessage: 'Dark',
      },
    ],
    iconDefinition: faGift,
  },
  {
    label: {
      id: 'settings.selects.imageAutoSize',
      defaultMessage: 'Image Autosize',
    },
    settingKey: 'imageAutoSize',
    options: [
      {
        label: 'On',
        value: 1,
        id: 'settings.options.imageAutoSize.on',
        defaultMessage: 'On',
      },
      {
        label: 'Off',
        value: 0,
        id: 'settings.options.imageAutoSize.off',
        defaultMessage: 'Off',
      },
    ],
    iconDefinition: faExpandArrowsAlt,
  },
  {
    label: {
      id: 'settings.selects.homepage',
      defaultMessage: 'Home Page',
    },
    settingKey: 'homepage',
    iconDefinition: faHome,
    options: [
      {
        label: 'Updates',
        value: 'updates',
        id: 'settings.options.homepage.updates',
        defaultMessage: 'Updates',
        filter: 'hasUpdates',
      },
      {
        label: 'Archive',
        value: 'archive',
        id: 'settings.options.homepage.archive',
        defaultMessage: 'Archive',
        filter: 'hasUpdates',
      },
      {
        label: 'Blog',
        value: 'blog',
        id: 'settings.options.homepage.blog',
        defaultMessage: 'Blog',
        filter: 'blog.active',
      },
      {
        label: 'Films',
        value: 'films',
        id: 'settings.options.homepage.films',
        defaultMessage: 'Films',
        filter: 'movies.active',
      },
      {
        label: 'Galleries',
        value: 'galleries',
        id: 'settings.options.homepage.archive',
        defaultMessage: 'Galleries',
      },
      {
        label: 'Models',
        value: 'models',
        id: 'settings.options.homepage.models',
        defaultMessage: 'Models',
      },
      {
        label: 'Photographers',
        value: 'photographers',
        id: 'settings.options.homepage.photographers',
        defaultMessage: 'photographers',
        filter: 'hasPhotographers',
      },
      {
        label: 'Splash',
        value: 'splash',
        id: 'settings.options.homepage.splash',
        defaultMessage: 'Splash',
        filter: 'splash.guestsOnly',
        inverted: true,
      },
      {
        label: 'Upcoming',
        value: 'upcoming',
        id: 'settings.options.homepage.upcoming',
        defaultMessage: 'Upcoming',
        filter: 'hasUpdates',
      },
    ],
  },
  {
    label: {
      id: 'settings.selects.ratingsScale',
      defaultMessage: 'Rating Decimal Place Value',
    },
    settingKey: 'ratingsScale',
    options: [
      {
        label: "Decimal value of 1/10's",
        value: 1,
        id: 'settings.options.ratingsScale.one',
        defaultMessage: "Decimal value of 1/10's",
      },
      {
        label: "Decimal value of 1/100's",
        value: 2,
        id: 'settings.options.ratingsScale.two',
        defaultMessage: "Decimal value of 1/100's",
      },
    ],
    iconDefinition: faStar,
  },
];
