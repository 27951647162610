import React from 'react';
import { useIntl } from 'react-intl';
import { faRedo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  reload: (...args: unknown[]) => unknown;
  className?: string;
  error?: boolean | string | object;
  content?: string;
};

export const ErrorMessage = ({ className = '', error = false, content = '', ...props }: Props) => {
  const intl = useIntl();
  const { reload } = props;

  const message = () => {
    if (error) {
      return intl.formatMessage({ id: 'loader.errors.defaultError', defaultMessage: 'An error has occured' });
    }
    return `${intl.formatMessage({ id: 'loader.errors.failedToLoad', defaultMessage: 'Failed to load' })}${
      content ? ' ' + content : ''
    }`;
  };

  return (
    <div className={`${className} error-message`}>
      <div className="message-text">{`${message()}.`}</div>
      <div className="retry" onClick={() => reload()}>
        {intl.formatMessage({ id: 'loader.retry', defaultMessage: 'Click to retry' })}
        <FontAwesomeIcon icon={faRedo} className="retry-icon" aria-hidden="true" />
      </div>
    </div>
  );
};
