import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { SORT_TYPES } from 'src/shared/constants/galleries';
import { load } from 'src/client/redux/modules/activity';
import { FavoriteAction } from './FavoriteAction';
import { FollowAction } from './FollowAction';
import { RatingAction } from './RatingAction';
import { CommentAction } from './CommentAction';
import { FeedItemWrapper } from './FeedItem';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import LoaderWrapper from 'src/client/components/Loader/LoaderWrapper';
import { FlatPopover } from '../styles';

export const getActivityTypeInfo = (activity: ClientActivity) => {
  const {
    object: { name, path, type },
  } = activity;

  const params = { name, path };
  return {
    MODEL: {
      label: 'model',
      ...params,
      path: `${path}/${SORT_TYPES[0].id}`,
    },
    GALLERY: {
      label: type?.toLowerCase(),
      ...params,
    },
    MOVIE: {
      label: type?.toLowerCase(),
      ...params,
    },
    PHOTOGRAPHER: {
      label: 'photographer',
      ...params,
    },
    MEDIA: {
      label: '',
      ...params,
      name: `${name}, image #${activity.order}`,
      path: `${path}/image/${activity.order}`,
    },
    BLOG: {
      label: 'blog',
      ...params,
      path: `${path}`,
    },
  };
};

export type ActionProps = {
  activity: ClientActivity;
};

const componentMap: Record<ClientActivity['actionType'], (props: ActionProps) => JSX.Element | null> = {
  FAVORITE: FavoriteAction,
  FOLLOW: FollowAction,
  RATE: RatingAction,
  COMMENT: CommentAction,
  UNRECOGNIZED: () => null,
};

export const ActivityFeed = React.forwardRef<HTMLDivElement>((props, ref) => {
  const dispatch = useDispatch();
  const { items, loaded, loading, error } = useSelector((state) => state.activity);

  const loadData = useCallback(() => {
    dispatch(load({ first: 12, orderBy: 'TIMESTAMP', direction: 'DESC' }));
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const activities = items.map((activity, i) => {
    const ActivityComponent = componentMap[activity.actionType];
    return <ActivityComponent activity={activity} key={i} />;
  });

  const showLoader = (loading && activities.length === 0) || error;

  return (
    <ActivityPopover id="ActivityFeed" placement="bottom" {...props} ref={ref}>
      <ActivityContainer>
        <FormattedMessage id="activityFeed.title" defaultMessage="Activity Feed" tagName={ActivityFeedTitle} />

        <Feeds>
          {showLoader && (
            <LoaderWrapper
              reloadFunction={loadData}
              loadingStatus={loading}
              reduxErrorStatus={!loaded && error}
              contentType="user activity"
            />
          )}
          {!loading && activities.length === 0 && (
            <NoActivity className="no-activity">
              <FeedEmpty as="p">
                <FormattedMessage
                  id="activityFeed.emptyMessage"
                  defaultMessage="Try rating or commenting on your favourite galleries to fill up your activity feed."
                />
              </FeedEmpty>
            </NoActivity>
          )}
          {!showLoader && activities.length > 0 && activities}
        </Feeds>
      </ActivityContainer>
    </ActivityPopover>
  );
});

const ActivityPopover = styled(FlatPopover)`
  max-width: none;

  .bottom {
    .arrow {
      margin-left: 93px;
    }
  }
`;

const ActivityContainer = styled.div`
  width: 450px;
`;

const ActivityFeedTitle = styled.p`
  font-size: 2.15rem;
  font-weight: normal;
  width: 456px;
  height: 50px;
  padding: 10px 0 0 18px;
  margin-bottom: 12px;
`;

const Feeds = styled.div`
  padding: 0 10px;
  width: 435px;
  height: 300px;
  overflow-y: auto;
  margin: 0 auto;

  & .loader {
    margin: 110px auto;
  }
`;

const NoActivity = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const FeedEmpty = styled(FeedItemWrapper)`
  padding: 10px;
  text-align: center;
  border-bottom: 0;
`;
