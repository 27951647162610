const SORT_TYPE_TOP_RATED = {
  id: 'top',
  title: 'Top Rated',
  order: 'RATING',
  direction: 'DESC',
  intlID: 'dropdown.topRated',
};
const SORT_TYPE_ALPHABETICAL = {
  id: 'all',
  title: 'Alphabetical',
  order: 'NAME',
  direction: 'ASC',
  intlID: 'dropdown.alphabetical',
};

export const SORT_TYPES = [SORT_TYPE_TOP_RATED, SORT_TYPE_ALPHABETICAL];

export const PhotographerUUIDS: Record<string, string> = {
  Arkisi: 'D6E437EE3F632465F00B61D2BF5D7F52',
  Rylsky: 'AB8332188975A2BAF75B3C59FBB0631F',
} as const;
