import Tooltip from '@tovia/man-ui/lib/components/Tooltip/Tooltip';
import React, { RefObject, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { useSelector, useSettingsSelector } from '../../redux/modules/helpers/useSelector';
import { ImageButton, NavbarIcon } from './styles';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import { ActivityFeed } from './ActivityFeed/ActivityFeed';
import styled, { keyframes } from 'styled-components/macro';
import { UserMenu } from './UserMenu';
import { HeaderContext, HeaderPopovers } from './HeaderProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUserCircle } from '@fortawesome/pro-light-svg-icons';
import { activity } from 'src/client/icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

type Props = {
  closeNotificationPanel: () => void;
  mainHeader: RefObject<HTMLDivElement>;
  toggleRecentsBox: () => void;
};

export const UserMenuItems = (props: Props) => {
  const intl = useIntl();
  const history = useHistory();
  const homepage = useSettingsSelector('homepage');
  const location = useLocation<{ canGoBack?: boolean }>();
  const user = useSelector((state) => state.auth.user);
  const { isWhiteLabel } = useSelector((state) => state.app.config);

  const siteNotificationBadgeCount = useSelector((state) => state.siteNotifications.items?.newNotificationsCount);

  const [bouncingBadge, setBounceingBadge] = useState(false);
  useEffect(() => {
    const toggleBouncingBadge = () => setBounceingBadge((bouncing) => !bouncing);
    let timeout: NodeJS.Timeout | undefined = undefined;

    setTimeout(() => {
      toggleBouncingBadge();
      timeout = setTimeout(toggleBouncingBadge, 20000);
    }, 2000);

    return () => timeout && clearTimeout(timeout);
  }, []);

  const { activePopover, setActivePopover } = useContext(HeaderContext);

  if (!user || isWhiteLabel) {
    return null;
  }

  const isSettings = location.pathname.startsWith('/settings');

  const handleSettingsClick = () => {
    if (location?.state?.canGoBack) {
      return history.goBack();
    }

    return history.replace(`/${homepage}`);
  };

  const handleActivityFeedToggle = (next: boolean) => {
    if (next === false) {
      if (activePopover !== HeaderPopovers.ACTIVITY) return;
      return setActivePopover(null);
    }
    setActivePopover(HeaderPopovers.ACTIVITY);
  };

  const handleProfileMenuToggle = (next: boolean) => {
    if (next === false) {
      if (activePopover !== HeaderPopovers.PROFILE) return;
      return setActivePopover(null);
    }
    setActivePopover(HeaderPopovers.PROFILE);
  };

  return (
    <>
      <Tooltip
        value={intl.formatMessage({ id: 'header.tooltip.activity', defaultMessage: 'Activity' })}
        placement="bottom"
      >
        <div>
          {/* This div is for the Tooltip to bind to, it can't directly attach to the overlay trigger */}
          <OverlayTrigger
            container={props.mainHeader}
            trigger="click"
            placement="bottom"
            show={activePopover === HeaderPopovers.ACTIVITY}
            overlay={<ActivityFeed />}
            rootClose
            onToggle={handleActivityFeedToggle}
          >
            <ImageButton className={cx('hidden-xs', 'clickable', { 'hidden-md': !user.validSubscription })}>
              <NavbarIcon icon={activity} />
            </ImageButton>
          </OverlayTrigger>
        </div>
      </Tooltip>

      <Tooltip
        value={intl.formatMessage({ id: 'header.tooltip.settings', defaultMessage: 'Settings' })}
        placement="bottom"
      >
        <ImageButton className="clickable">
          {isSettings ? (
            <a onClick={handleSettingsClick}>
              <NavbarIcon icon={faCog} />
            </a>
          ) : (
            <NavLink to={{ pathname: '/settings', state: { canGoBack: true } }}>
              <NavbarIcon icon={faCog} />
            </NavLink>
          )}
        </ImageButton>
      </Tooltip>

      <OverlayTrigger
        container={props.mainHeader}
        trigger="click"
        placement="bottom"
        show={activePopover === HeaderPopovers.PROFILE}
        overlay={
          <UserMenu
            toggleProfileMenu={handleProfileMenuToggle}
            onRecentsClick={props.toggleRecentsBox}
            closeNotificationPanel={props.closeNotificationPanel}
            username={user.email}
            siteNotificationBadgeCount={siteNotificationBadgeCount}
          />
        }
        rootClose
        onToggle={handleProfileMenuToggle}
      >
        <UserMenuButton className={cx('clickable', { active: activePopover === HeaderPopovers.PROFILE })}>
          <NavbarIcon icon={faUserCircle} />
          <FontAwesomeIcon icon={faCaretDown} />
          {siteNotificationBadgeCount > 0 && (
            <NotificationBadge className={cx({ bouncing: bouncingBadge })}>
              {siteNotificationBadgeCount}
            </NotificationBadge>
          )}
        </UserMenuButton>
      </OverlayTrigger>
    </>
  );
};

const UserMenuButton = styled(ImageButton)`
  display: flex;
  position: relative;
  align-items: center;
  column-gap: 0.25rem;
`;

const bounceAnimation = keyframes`
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: translateY(0);
  }
  
  50% {
    transform: translateY(-5px);
  }
`;

const NotificationBadge = styled.div`
  top: -2px;
  color: white;
  font-size: 0.8rem;
  left: 50%;
  top: 5px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: ${(props) => props.theme.headerBarPrimary3};
  position: absolute;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  opacity: 1;

  &.bouncing {
    animation: ${bounceAnimation} 1.5s linear infinite;
  }
`;
