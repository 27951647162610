import { faWebcam } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { useSelector } from '../../../redux';
import { ActiveNestedItem } from '../HeaderProvider';
import { MobileExpandableMenuItem } from './MobileExpandableMenuItem';

export const MobileLiveGirls = () => {
  const config = useSelector((state) => state.app.config);

  if (config.isWhiteLabel || !config.camsList) {
    return null;
  }

  return (
    <MobileExpandableMenuItem activeNestedItem={ActiveNestedItem.LIVEGIRLS} icon={faWebcam} menuLabel="Live Girls">
      {config.camsList.map((cam) => (
        <a className="mobile-link" key={cam.name} href={cam.url} target="_blank" rel="noreferrer">
          {cam.name}
        </a>
      ))}
    </MobileExpandableMenuItem>
  );
};
