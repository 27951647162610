import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';
import { MobileLink } from './MobileLink';

type Props = {
  isSplash: boolean;
  closeMobileMenu: () => void;
};

export const MobileSplashLinks = (props: Props) => {
  const [joinUrl] = useJoinUrl({ campaign: 'mobile-splash' });

  if (!props.isSplash) {
    return null;
  }

  return (
    <>
      <a href="/login">
        <FormattedMessage id="header.links.signIn" defaultMessage="sign in" />
      </a>
      <MobileLink
        handleClick={props.closeMobileMenu}
        label={<FormattedMessage id="header.links.updates" defaultMessage="Updates" />}
        routes={[]}
        to="/updates"
      />
      <MobileLink
        handleClick={props.closeMobileMenu}
        label={<FormattedMessage id="header.links.joinNow" defaultMessage="'Join Now" />}
        routes={[]}
        href={joinUrl}
      />
    </>
  );
};
