import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { BackButton, MobileMenuIcon, NestedMenuLink, MobileMenuCaretWrapper } from '../styles';
import cx from 'classnames';

import { ActiveNestedMobileItem, HeaderContext } from '../HeaderProvider';

type Props = {
  activeNestedItem: ActiveNestedMobileItem;
  className?: string;
  icon: IconDefinition;
  menuLabel: string;
};
export const MobileExpandableMenuItem = (props: PropsWithChildren<Props>) => {
  const { activeNestedItem, children, className, icon, menuLabel } = props;
  const { activeNestedMobileMenu, setActiveNestedMobileMenu } = useContext(HeaderContext);

  return (
    <NestedMenuLink className={className}>
      <span className="clickable" onClick={() => setActiveNestedMobileMenu(activeNestedItem)}>
        <MobileMenuIcon icon={icon} width={14} />
        {menuLabel}
        <MobileMenuCaretWrapper>
          <FontAwesomeIcon icon={faChevronRight} />
        </MobileMenuCaretWrapper>
      </span>

      <div className={cx('level', { active: activeNestedMobileMenu === activeNestedItem })}>
        <BackButton onClick={() => setActiveNestedMobileMenu(null)}>
          <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
          <FormattedMessage id="header.links.back" defaultMessage="Back" tagName="span" />
        </BackButton>
        {children}
      </div>
    </NestedMenuLink>
  );
};
