export type MediaItem = {
  thumbnailUrl: string;
  mediaUrl: string;
};

export const topGalleries: MediaItem[] = [
  { thumbnailUrl: '/nonnude/gallery_1.jpg', mediaUrl: '/nonnude/gallery_full_1.jpg' },
  { thumbnailUrl: '/nonnude/gallery_2.jpg', mediaUrl: '/nonnude/gallery_full_2.jpg' },
  { thumbnailUrl: '/nonnude/gallery_3.jpg', mediaUrl: '/nonnude/gallery_full_3.jpg' },
  { thumbnailUrl: '/nonnude/gallery_4.jpg', mediaUrl: '/nonnude/gallery_full_4.jpg' },
];

export const topModels: MediaItem[] = [
  { thumbnailUrl: '/nonnude/model_1.jpg', mediaUrl: '/nonnude/model_full_1.jpg' },
  { thumbnailUrl: '/nonnude/model_2.jpg', mediaUrl: '/nonnude/model_full_2.jpg' },
  { thumbnailUrl: '/nonnude/model_3.jpg', mediaUrl: '/nonnude/model_full_3.jpg' },
  { thumbnailUrl: '/nonnude/model_4.jpg', mediaUrl: '/nonnude/model_full_4.jpg' },
];

export const topNetworkFilms: MediaItem[] = [
  { thumbnailUrl: '/nonnude/movie_1.png', mediaUrl: '/nonnude/promo_1.mp4' },
  { thumbnailUrl: '/nonnude/movie_2.png', mediaUrl: '/nonnude/promo_2.mp4' },
  { thumbnailUrl: '/nonnude/movie_3.png', mediaUrl: '/nonnude/promo_4.mp4' },
  { thumbnailUrl: '/nonnude/movie_4.png', mediaUrl: '/nonnude/promo_5.mp4' },
];
