import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { useSelector } from 'src/client/redux';
import { EMBLEM_SESSION_COOKIE } from 'src/shared/constants/cookies';
import { EMBLEM_AGE_VERIFICATION } from 'src/shared/constants/socketMessages';

type SFWSiteContextType = {
  showSfwSite: boolean;
  error: string | null;
  setError: (error: string | null) => void;
};

const ONE_HOUR = 60 * 60;

export const SFWSiteContext = React.createContext<SFWSiteContextType>({
  showSfwSite: false,
  error: null,
  setError: () => {},
});

export const SFWSiteProvider: React.FC = ({ children }) => {
  const showSFWSiteConfig = useSelector((state) => state.app.showSFWSite);
  const { location, user } = useSelector((state) => state.auth);
  const [cookies, setCookie] = useCookies([EMBLEM_SESSION_COOKIE]);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();

  const emblemSessionCookie = cookies[EMBLEM_SESSION_COOKIE];
  const showSfwSite = useMemo(() => {
    if (emblemSessionCookie) {
      return false;
    }

    return showSFWSiteConfig;
  }, [showSFWSiteConfig, emblemSessionCookie]);

  const setEmblemCookie = useCallback(
    (maxAge: number) => {
      setCookie(EMBLEM_SESSION_COOKIE, true, {
        maxAge,
        path: '/',
      });
    },
    [setCookie],
  );

  useEffect(() => {
    global.socket.on(EMBLEM_AGE_VERIFICATION, ({ error, countryCode, stateprovCode }) => {
      if (error) {
        setError(error);
        return;
      }

      handleSocketMessageByCountry({ countryCode, stateprovCode, setEmblemCookie });
    });

    return () => {
      global.socket.off(EMBLEM_AGE_VERIFICATION);
    };
  });

  useEffect(() => {
    if (!emblemSessionCookie || !user?.accountNeedsAgeVerification) {
      return;
    }

    const unregisterHistoryListener = history.listen(() => {
      handlePageChangeByCountry({ countryCode: location.country, stateprovCode: location.state, setEmblemCookie });
    });

    return () => {
      unregisterHistoryListener();
    };
  }, [
    location.country,
    location.state,
    user?.accountNeedsAgeVerification,
    emblemSessionCookie,
    setEmblemCookie,
    history,
  ]);

  return <SFWSiteContext.Provider value={{ showSfwSite, error, setError }}>{children}</SFWSiteContext.Provider>;
};

export type HandleSocketMessageParams = {
  countryCode?: string;
  stateprovCode?: string;
  setEmblemCookie: (maxAge: number) => void;
};

const handleSocketMessageByCountry = (params: HandleSocketMessageParams) => {
  switch (params.countryCode) {
    case 'US':
      handleSocketMessageByUSState(params);
      break;
    case 'FR':
      params.setEmblemCookie(ONE_HOUR);
      break;
    default:
      window.location.reload();
      break;
  }
};

const handleSocketMessageByUSState = (params: HandleSocketMessageParams) => {
  switch (params.stateprovCode) {
    case 'TN':
      params.setEmblemCookie(ONE_HOUR);
      break;
    default:
      window.location.reload();
      break;
  }
};

const handlePageChangeByCountry = (params: HandleSocketMessageParams) => {
  switch (params.countryCode) {
    case 'FR':
      params.setEmblemCookie(ONE_HOUR);
      break;
    default:
      break;
  }
};
