import { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { loadUpgradableSites } from 'src/client/redux/modules/marketing';
import { HIDE_FREE_ACCESS_GATEWAY_COOKIE, HIDE_UPGRADE_STRIP_COOKIE } from 'src/shared/constants/cookies';

export const useUpgradeStrip = () => {
  const [cookies, setCookie] = useCookies([HIDE_FREE_ACCESS_GATEWAY_COOKIE, HIDE_UPGRADE_STRIP_COOKIE]);
  const { showUpgradeStrip, hasUpgradableSites } = useSelector((state) => state.app.config);
  const upgradableSites = useSelector((state) => state.marketing.upgradableSites);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const upgradeStripOpen = Boolean(
    showUpgradeStrip &&
      upgradableSites.length &&
      user?.validSubscription &&
      !user.showMembersPortal &&
      !cookies.hideUpgradeStrip,
  );

  // cookies as a whole changes when all the cookies change, so we save this
  // to a variable to prevent unnecessary dispatches.
  const hideUpgradeStripCookie = cookies[HIDE_UPGRADE_STRIP_COOKIE];
  useEffect(() => {
    if (hasUpgradableSites && showUpgradeStrip && !hideUpgradeStripCookie && user) {
      dispatch(loadUpgradableSites());
    }
  }, [hasUpgradableSites, hideUpgradeStripCookie, showUpgradeStrip, user, dispatch]);

  const closeUpgradeStrip = useCallback(() => {
    const expires = new Date(new Date().getTime() + 86400 * 1000); // 24 Hrs
    setCookie(HIDE_UPGRADE_STRIP_COOKIE, true, { path: '/', expires });
  }, [setCookie]);

  return [upgradeStripOpen, closeUpgradeStrip] as const;
};
