import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useCookies } from 'react-cookie';

export const ChatWidget = (props: { 'jira-id': string; 'service-desk-id': string; validSubscription: boolean }) => {
  const [cookies, setCookie] = useCookies(['hideChatWidget']);
  const [isChatWidgetWrapperVisible, setIsChatWidgetWrapperVisible] = useState(false);

  const handleClose = () => {
    setCookie('hideChatWidget', 'true', { path: '/', expires: new Date(Date.now() + 86400000 * 15) });
    setIsChatWidgetWrapperVisible(false);
    const chatWidget = document.querySelector('chat-widget') as HTMLElement;
    if (chatWidget) {
      chatWidget.style.display = 'none';
    }
  };

  useEffect(() => {
    if (props.validSubscription && !cookies.hideChatWidget) {
      const script = document.createElement('script');
      script.src = 'https://chat-api.spartez-software.com/chat-widget.js';
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        customElements.whenDefined('chat-widget').then(() => {
          const chatWidget = document.querySelector('chat-widget');
          if (chatWidget && chatWidget.shadowRoot) {
            const observer = new MutationObserver(() => {
              const chatWidgetWrapper = chatWidget.shadowRoot?.querySelector('.chat-widget-wrapper');
              if (chatWidgetWrapper) {
                setIsChatWidgetWrapperVisible(true);
                observer.disconnect();
              }
            });
            observer.observe(chatWidget.shadowRoot, { childList: true, subtree: true });

            const style = document.createElement('style');
            style.textContent = `
              .chat-widget-wrapper {
                bottom:80px!important;
                right:15px!important;
              }
              .chat-icon-button {
                background:url('https://assets.metartnetwork.com/livechat/chat@2x.png') no-repeat!important;
                background-size:contain!important;
                border-radius:0!important;
                width:53px!important;
                height:52px!important;
              }
              .chat-icon-button:hover {
                width:56px!important;
                height:55px!important;
              }
              .knowledge-base-wrapper {
                display:none!important;
              }
              .chat-close-icon,.chat-offline,.chat-icon {
                background-image:none!important;
              }
              .chat-popup-wrapper {
                margin-bottom:10px!important;
              }
              .chat-button[data-spacing=default] {
                background:#587BCE!important;
              }
              .chat-button[data-spacing=default]:hover {
                background:#34549E!important;
              }
              .container-header {
                background-color:#8F9196!important;
              }
              div {
                --main-accent-color:#587BCE!important;
              }
              .pre-chat-form-field-label .required {
                color:#587BCE!important;
              }
              @media(max-width:767px) {
                .chat-widget-wrapper {
                  bottom:5px!important;
                  right:5px!important;
                }
              }
            `;
            chatWidget.shadowRoot.appendChild(style);
          }
        });
      };
    }
  }, [props.validSubscription, cookies.hideChatWidget, setCookie]);

  return (
    <>
      {props.validSubscription ? React.createElement('chat-widget', props) : null}
      {isChatWidgetWrapperVisible && (
        <CloseButton onClick={handleClose} style={{ visibility: 'visible' }}>
          X
        </CloseButton>
      )}
    </>
  );
};

const CloseButton = styled.button`
  position: fixed;
  bottom: 127px;
  right: 62px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #fff;
  color: #000;
  font-size: 11px;
  font-weight: 700;
  cursor: pointer;
  z-index: 999999999;
  opacity: 0.7;
  visibility: hidden;
  @media (max-width: 767px) {
    bottom: 53px;
    right: 53px;
  }
`;
