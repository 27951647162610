import React from 'react';
import styled from 'styled-components/macro';
import { DESKTOP } from '@tovia/man-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt, faPlayCircle } from '@fortawesome/pro-light-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { faEye, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { SplashGalleryResponse } from 'src/@types/Splash';

type Props = {
  gallery: SplashGalleryResponse;
  thumbnailUrl: string;
  details: string;
};

export const SplashGalleryCard = (props: Props) => {
  const { gallery, thumbnailUrl } = props;

  const intl = useIntl();

  const smallImage = thumbnailUrl.replace('clean_', 't_clean_').replace('cover_', 't_cover_');
  const director = `By ${gallery.photographers.map((photographer) => photographer.name).join(', ')}`;
  const detailsIcon = gallery.type === 'GALLERY' ? faCameraAlt : faPlayCircle;

  return (
    <Wrapper to={gallery.path}>
      <Figure>
        <picture>
          <source srcSet={thumbnailUrl} media={DESKTOP} />
          <Image src={smallImage} alt={gallery.name} loading="lazy" />
        </picture>
        <Caption>
          <Title>{gallery.name}</Title>
          <Director>{director}</Director>
        </Caption>
        <Details>
          <Icon icon={detailsIcon} /> {props.details}
        </Details>
      </Figure>
      <Info>
        <Models>{gallery.models.map((model) => model.name).join(',')}</Models>
        <PublishedDate>
          {intl.formatDate(new Date(gallery.publishedAt), { year: 'numeric', month: 'long', day: '2-digit' })}
        </PublishedDate>
        <Badges>
          <li>
            <Icon icon={faStar} /> {gallery.ratingAverage.toFixed(2)}
          </li>
          <li>
            <Icon icon={faEye} /> {gallery.views}
          </li>
          <li>
            <Icon icon={faThumbsUp} /> {gallery.favoriteCount}
          </li>
        </Badges>
      </Info>
    </Wrapper>
  );
};

const Wrapper = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: #171717;
`;

const Figure = styled.figure`
  position: relative;
`;

const Title = styled.h2`
  font-size: 2rem;
  line-height: 1.4;
  margin: 0;
  text-transform: uppercase;
`;

const Director = styled.h3`
  font-size: 1.15rem;
  font-style: italic;
  line-height: 1.4;
  margin: 0;
`;

// Maybe we need an update to get the loading property to work in TS?
const Image = styled.img<{ loading: string }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 525 / 790;
`;

const Caption = styled.figcaption`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  transition: opacity 0.3s;
  width: 100%;

  ${Figure}:hover & {
    opacity: 1;
  }
`;

const Details = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  line-height: 1;
  padding: 5px;
  position: absolute;
  right: 0;
  transition: background-color 0.3s;

  ${Figure}:hover & {
    background: rgba(0, 0, 0, 0);
  }
`;

const Info = styled.div`
  padding: 5px 10px;
`;

const Models = styled.h4`
  font-size: 1rem;
  line-height: 20px;
  margin: 0;
  overflow: hidden;
  padding: 2px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PublishedDate = styled.span`
  color: #888888;
  font-size: 0.85rem;
  padding: 2px 0;
`;

const Badges = styled.ul`
  align-items: baseline;
  color: #adadad;
  column-gap: 10px;
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 2px 0;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.25em;
`;
