import PropTypes from 'prop-types';
import { AnyAction } from 'redux';

export type Site = {
  abbreviation: string;
  birthdate: number;
  domain: string;
  domainCanonical: string;
  name: string;
  networkName: string;
  networkUUID: string;
  UUID: string;
};

const initialState: Site = {
  abbreviation: '',
  birthdate: 0,
  domain: '',
  domainCanonical: '',
  name: '',
  networkName: '',
  networkUUID: '',
  UUID: '',
};

export default function reducer(state = initialState, action: AnyAction): Site {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

export const sitePropType = PropTypes.shape({
  abbreviation: PropTypes.string.isRequired,
  birthdate: PropTypes.number,
  domain: PropTypes.string.isRequired,
  domainCanonical: PropTypes.string,
  name: PropTypes.string.isRequired,
  SSL: PropTypes.bool,
  UUID: PropTypes.string.isRequired,
});
