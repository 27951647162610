export const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -84;
    window.scrollTo({
      top: y + yOffset,
      behavior: 'smooth',
    });
  }
};
