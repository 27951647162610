import React from 'react';
import { HeaderPopovers } from '../../containers/HeaderBar/HeaderProvider';
import { SeoCategoriesPopover } from './SeoCategoriesPopover';
import { useSelector } from 'src/client/redux';
import { HeaderItemWithPopover } from 'src/client/containers/HeaderBar/HeaderItemWithPopover';

export const SeoCategoriesMenu = () => {
  const { hasCategories } = useSelector((state) => state.app.config);

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!hasCategories) {
      e.preventDefault();
    }
  };

  return (
    <HeaderItemWithPopover
      headerPopover={HeaderPopovers.SEO_CATEGORIES}
      menuLink="/tags"
      menuText="CATEGORIES"
      onClick={onClick}
    >
      <SeoCategoriesPopover />
    </HeaderItemWithPopover>
  );
};
