import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';

import { RenderTagsInput } from './TagsInput/TagsInput';

const Fieldset = styled.fieldset`
  & .error-msg {
    text-align: center;
    color: red;
  }

  & .submit-wrap {
    display: flex;
    align-items: flex-end;

    .btn {
      height: 35px;
    }
  }

  & .tags-input {
    display: inline-block;
    margin-right: 10px;
    overflow: hidden;

    @media (max-width: 767px) {
      width: 80%;
    }

    @media (min-width: 768px) {
      width: 470px;
    }

    @media (max-width: 480px) {
      width: 70%;
    }
  }

  & .instructions {
    font-size: 91%;
    color: #000;
  }

  & .update {
    @media (max-width: 767px) {
      width: 17%;
    }
    @media (max-width: 480px) {
      width: 25%;
    }
  }
`;

interface Props {
  tagsErrors: string[];
  addStatus: string;
  deleteStatus: string;
  // eslint-disable-next-line react/require-default-props
  className?: string;
}

interface FormInputs {
  userTags: string[];
  tagsErrors: string[];
}

type AllProps = Props & InjectedFormProps<FormInputs, Props>;

export function TagFormComponent(props: AllProps) {
  const { addStatus, deleteStatus, tagsErrors = [], handleSubmit, className = '' } = props;

  const intl = useIntl();

  return (
    <Fieldset disabled={addStatus === 'loading' || deleteStatus === 'loading'} className={className}>
      <form className="handle-submit" onSubmit={handleSubmit}>
        <div className="submit-wrap">
          <Field component={RenderTagsInput} name="userTags" id="tag-textarea" className="va-m tags-input" value={[]} />
          <a className="btn btn-primary va-m update" onClick={handleSubmit}>
            {intl.formatMessage({ id: 'tagForm.button', defaultMessage: 'Update' })}
          </a>
        </div>
        {(addStatus === 'fail' || deleteStatus === 'fail') && tagsErrors.length > 0 && (
          <div className="error-msg">{`Unable to ${tagsErrors.map((error) => error).join(' and ')} tags`}</div>
        )}
      </form>
    </Fieldset>
  );
}

export default reduxForm<FormInputs, Props>({
  form: 'TagsDialog',
})(TagFormComponent);
