import { useMemo, useState } from 'react';
import { useSelector } from 'src/client/redux';
import { useFeatureFlags } from 'src/client/helpers/useFeatureFlags';
import { usePromotions } from 'src/client/helpers/usePromotions';
import {
  AlsScan,
  Domai,
  EroticBeauty,
  ErroticaArchives,
  EternalDesire,
  GoddesNudes,
  LoveHairy,
  MetArt,
  MetArtX,
  PlayBoyPlus,
  RylskyArt,
  SexArt,
  Site,
  Straplez,
  Stunning18,
  TheLifeErotic,
  VivThomas,
  Vixen,
} from 'src/client/constants/sites';

const activeSites: Site[] = [
  MetArt,
  SexArt,
  AlsScan,
  Domai,
  ErroticaArchives,
  EroticBeauty,
  GoddesNudes,
  LoveHairy,
  MetArtX,
  TheLifeErotic,
  VivThomas,
  Straplez,
];

const optionalSites: Site[] = [EternalDesire, RylskyArt, Stunning18, Vixen, PlayBoyPlus];

// @TODO This will be removed when all the sites come from man-site.
// Randomize the sites, with the addition of any optional sites, and slice to length.
const shuffle = (sites: Site[], length: number) => {
  const shuffleSort = () => Math.random() - 0.5;
  return sites.sort(shuffleSort).slice(0, length);
};

export const useGateway = () => {
  const { user } = useSelector((state) => state.auth);
  const { cdnUrl } = useSelector((state) => state.app);
  const featureFlags = useFeatureFlags();
  const { isPromoGatewayActive } = usePromotions();

  const [selectedSites, setSelectedSites] = useState<string[]>([]);
  const [backgroundImage, setBackgroundImage] = useState('');

  const backgroundImagePath = `${cdnUrl}/gateway/${isPromoGatewayActive ? 'promo-backgrounds' : 'backgrounds'}`;

  const sitesToShow = useMemo(() => {
    const sites: string[] = [];
    if (featureFlags.showVixenInGateway && featureFlags.showVixenInUpgrades) {
      sites.push('vixen');
    }

    if (featureFlags.showPlayboyInGateway && featureFlags.showPlayboyInUpgrades) {
      sites.push('playboyplus');
    }
    const activeSubs = user?.subscriptions.map((sub) => sub.abbreviation) || [];
    const availableSites = activeSites.filter((site) => !activeSubs.includes(site.abbreviation));
    const additionalSites = sites
      .map((site) => optionalSites.find((s) => s.abbreviation === site))
      .filter(Boolean) as Site[];
    const shuffled = shuffle([...availableSites, ...additionalSites], 3);

    setSelectedSites(shuffled.map((site) => site.abbreviation));
    setBackgroundImage(`${backgroundImagePath}/${shuffled[0].abbreviation}_bg.jpg`);
    return shuffled;
  }, [backgroundImagePath, featureFlags, user?.subscriptions]);

  return {
    backgroundImage,
    backgroundImagePath,
    selectedSites,
    setBackgroundImage,
    setSelectedSites,
    sitesToShow,
  };
};
