import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const RecentBlogSectionComponent = (props) => {
  const { blog, className, title } = props;
  const intl = useIntl();

  const sliceIndex = blog.content.length > 420 ? blog.content.lastIndexOf(' ', 420) : blog.content.length;
  const blogContent = blog.content.slice(0, sliceIndex);

  return (
    <div className={`${className} recent-blog`}>
      <section className="container recent-blog-wrapper">
        {title && <div className="recent-blog-title">{title}</div>}
        <div className="recent-blog-post">
          <NavLink to={blog.path} className="recent-blog-post-title">
            {blog.title}
          </NavLink>
          <div className="recent-blog-message">
            {blog.image && (
              <NavLink to={blog.path}>
                <img src={blog.image} alt="" />
              </NavLink>
            )}
            <p>
              <span
                dangerouslySetInnerHTML={{ __html: blogContent }} // eslint-disable-line react/no-danger
              />
              ...
              <NavLink to={blog.path} className="recent-blog-more">
                {intl.formatMessage({ id: 'splash.recentBlogSection.buttons.more', defaultMessage: 'more...' })}
              </NavLink>
            </p>
            <div className="clear" />
          </div>
          <NavLink to={blog.path} className="btn btn-primary recent-blog-btn">
            {intl.formatMessage({ id: 'splash.recentBlogSection.buttons.readMore', defaultMessage: 'Read full post' })}
            <FontAwesomeIcon icon={faCaretRight} />
          </NavLink>
        </div>
      </section>
    </div>
  );
};

RecentBlogSectionComponent.propTypes = {
  blog: PropTypes.shape({
    content: PropTypes.string,
    image: PropTypes.string,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
  className: PropTypes.string,
  theme: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  title: PropTypes.string,
};

// Same approach for defaultProps too
RecentBlogSectionComponent.defaultProps = {
  blog: {},
  className: '',
  title: '',
};

export default withTheme(styled(RecentBlogSectionComponent)`
  &.recent-blog {
    background-color: ${(props) => props.theme.splashOptional1 || props.theme.splashPrimary2};
    padding: 12px;

    .recent-blog-wrapper {
      background-color: unset;
    }

    .recent-blog-title {
      color: ${(props) => props.theme.splashPrimary1};
      background-color: ${(props) => props.theme.primary9};
      display: inline-block;
      font-size: 1.7rem;
      padding: 12px 24px;
      text-transform: uppercase;
    }

    .recent-blog-post {
      border: 1px solid ${(props) => props.theme.primary9};
      background-color: ${(props) => props.theme.splashPrimary1};
      padding: 0 16px 16px;
      position: relative;
    }

    .recent-blog-post-title {
      color: ${(props) => props.theme.primary10};
      font-size: 1.7rem;
      padding: 12px 0;
      text-transform: uppercase;
      display: block;
    }

    .recent-blog-message {
      display: block;

      img {
        float: left;
        margin-right: 12px;
        max-width: 200px;
      }

      p {
        padding-bottom: 36px;
      }
    }

    .recent-blog-more {
      color: ${(props) => props.theme.primary9};
      margin-left: 8px;
    }

    .recent-blog-btn {
      bottom: 8px;
      color: ${(props) => props.theme.splashPrimary1};
      display: inline-block;
      margin: 0 24px;
      padding: 12px;
      position: absolute;
      right: 0;

      & .fa-caret-right {
        margin: 0 8px;
      }
    }
  }
`);
