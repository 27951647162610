import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components/macro';
import cx from 'classnames';
import { useSelector } from 'src/client/redux';
import { MetArt } from 'src/client/constants/sites';
import { OnPageTextContentMa } from 'src/client/components/OnPageSeo/OnPageText/OnPageTextContentMa';
import { UserTypes } from 'src/shared/constants/userTypes';

export type OnPageTextProps = {
  className?: string;
  contentName: OnPageContentName;
};

export type OnPageContentName = 'text1' | 'text2' | 'text3' | 'text4';
export type OnPageContentBlock = {
  [contentName in OnPageContentName]?: ReactNode;
};

export const OnPageText = (props: OnPageTextProps) => {
  const { className = '', contentName } = props;
  const { userType } = useSelector((state) => state.auth);
  const site = useSelector((state) => state.site);

  const textContent = useMemo(() => {
    switch (site.abbreviation) {
      case MetArt.abbreviation:
        return <OnPageTextContentMa contentName={contentName} />;
      default:
        return null;
    }
  }, [contentName, site.abbreviation]);

  if (textContent === null || userType !== UserTypes.Guest) {
    return null;
  }

  return <OnPageTextWrapper className={cx(className)}>{textContent}</OnPageTextWrapper>;
};

export const OnPageTextWrapper = styled.span`
  * {
    color: ${(props) => props.theme.primary4};
    opacity: 0.6;
    font-size: inherit;
    margin: 0;
  }

  h2 {
    padding-bottom: 1rem;
  }
`;
