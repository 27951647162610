import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Tabs from '@tovia/man-ui/lib/components/Tab/Tabs';
import { CountTabProps } from './CountTab';

import { load } from '../../redux/modules/itemCounts';
import { useSelector } from '../../redux/modules/helpers/useSelector';

export default function CountTabs({
  countParams,
  children,
  className = '',
}: {
  // List of Tab Names: see itemCounts.ts
  countParams?: string[];
  children: ReactElement<CountTabProps>[];
  className?: string;
}) {
  // static propTypes = {
  //   children: PropTypes.node.isRequired,
  //   className: PropTypes.string,
  //   counts: PropTypes.object.isRequired,
  //   countParams: PropTypes.array, // eslint-disable-line
  //   load: PropTypes.func.isRequired, // eslint-disable-line
  // };
  //
  // static defaultProps = {
  //   className: '',
  //   countParams: null,
  // };

  // componentWillMount() {
  //   this.loadData(this.props);
  // }
  //
  // componentWillUpdate(nextProps) {
  //   this.loadData(nextProps);
  // }

  const dispatch = useDispatch();
  const counts = useSelector((state) => state.itemCounts);

  useEffect(() => {
    const loadParams = {
      types: countParams,
    };

    if (countParams) {
      dispatch(load(loadParams));
    }
  }, [countParams, dispatch]);

  return (
    <Tabs className={className}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          // onClick: onChange,
          count: counts[child?.props.value]?.total || 0,
        }),
      )}
    </Tabs>
  );
}
