import { useEffect } from 'react';
import { v4 as UUID } from 'uuid';
import { ApiClient } from 'src/client/helpers';
import { ANALYTICS_COOKIE_NAME, CSRF_TOKEN } from 'src/shared/constants/cookies';
import { AnalyticsDataPayload, UseAnalyticsProps } from 'src/shared/constants/userMeasurement';
// New version by default parses cookie values when getting them
// which changes some conditions through the code
// TODO: Fix such conditions, so we can update this package and not use it twice.
import { useCookies } from 'react-cookie';

export const useAnalytics = (props: UseAnalyticsProps) => {
  const [cookies, setCookie] = useCookies([ANALYTICS_COOKIE_NAME, CSRF_TOKEN]);
  const analyticsUUID: string = cookies[ANALYTICS_COOKIE_NAME];
  const csrfToken: string = cookies[CSRF_TOKEN];
  const { deviceType, type, categoryId, conversionType } = props;

  useEffect(() => {
    if (!csrfToken || analyticsUUID) {
      return;
    }

    async function signalAnalyticsData(uuid: string) {
      const api = new ApiClient();
      try {
        const data: AnalyticsDataPayload = {
          categoryId,
          deviceType,
        };

        if (conversionType) {
          data.conversionType = conversionType;
        }

        await api.post('/api/analytics', {
          data: { data, uniqueUUID: uuid, type },
        });
      } catch (err) {
        return err as Error;
      }
    }

    const uuid = UUID();
    setCookie(ANALYTICS_COOKIE_NAME, uuid, { path: '/' });
    signalAnalyticsData(uuid);
  }, [analyticsUUID, setCookie, categoryId, deviceType, type, conversionType, csrfToken]);
};
