import React, { ReactEventHandler } from 'react';
import styled from 'styled-components/macro';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JoinButton } from 'src/client/components/buttons/JoinButton';
import { useUpgradeStrip } from './helpers/useUpgradeStrip';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';
import { NonNudeInfoStrip } from './NonNudeInfoStrip';

export const UpgradeStrip = () => {
  const [upgradeStripOpen, closeUpgradeStrip] = useUpgradeStrip();
  const showSfwSite = useShowSfwSite();

  // This _might_ cause the non-nude strip to show up at an ueexpected time in
  // the future if we offer the ability to dismiss the age verify modal and a
  // member is browsing the blurred site.
  if (showSfwSite) {
    return <NonNudeInfoStrip />;
  }

  if (!upgradeStripOpen) return null;

  const handleClose: ReactEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    closeUpgradeStrip();
  };

  return (
    <UpgradeStripWrapper>
      <JoinButton campaign="upgrade-strip">
        <p>
          <span className="access-text">FREE ACCESS</span>&nbsp;
          <span className="access-text-xs">Members Only!</span>
          <span className="access-text-sm">
            to any Metart Network site. Visit our discounts area to find great "Members Only" upgrades!
          </span>
          <span className="access-text-lg">
            to any Metart Network site. Visit our discounts area to find great "Members Only" specials and upgrades!
          </span>
          <FontAwesomeIcon onClick={handleClose} icon={faTimesCircle} className="upgrade-close" />
        </p>
      </JoinButton>
    </UpgradeStripWrapper>
  );
};

export const UpgradeStripWrapper = styled.div`
  background-color: #f1cc1d;
  height: 28px;
  padding: 4px 0;
  text-align: center;
  color: #000;
  text-decoration: none;
  font-weight: 700;

  a {
    color: #000;
    &:hover,
    &:active {
      color: #333;
      text-decoration: none;
    }
  }

  .access-text {
    text-decoration: underline;
    vertical-align: top;
  }

  .access-text-xs {
    display: none;
    vertical-align: top;

    @media (max-width: 767px) {
      display: inline;
    }
  }
  .access-text-sm {
    display: none;
    vertical-align: top;

    @media (min-width: 768px) and (max-width: 991px) {
      display: inline;
    }
  }
  .access-text-lg {
    display: none;
    vertical-align: top;

    @media (min-width: 992px) {
      display: inline;
    }
  }

  .upgrade-close {
    cursor: pointer;
    font-size: 1.3rem;
    margin-left: 8px;

    &:hover {
      color: #fff;
    }
  }
`;
