import { take, call, fork, select, put } from 'redux-saga/effects';

import { ResponseError } from 'superagent';
import { ApiClient, getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { load as loadRatingInfo } from './ratingInfo';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';
import { sanitizeComment } from 'src/shared/sanitize';
import { SortType } from 'src/shared/constants/sortType';

const ADD_COMMENT = 'man-site/comments/ADD_COMMENT';
const LOAD = 'man-site/comments/LOAD';
const LOAD_SAGA = 'man-site/comments/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/comments/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/comments/LOAD_FAIL';
const LOAD_LATEST = 'man-site/comments/LOAD_LATEST';
const LOAD_LATEST_SAGA = 'man-site/comments/LOAD_LATEST_SAGA';
const LOAD_LATEST_SUCCESS = 'man-site/comments/LOAD_LATEST_SUCCESS';
const LOAD_LATEST_FAIL = 'man-site/comments/LOAD_LATEST_FAIL';
const POST = 'man-site/comments/POST';
const POST_SAGA = 'man-site/comments/POST_SAGA';
const POST_SUCCESS = 'man-site/comments/POST_SUCCESS';
const POST_FAIL = 'man-site/comments/POST_FAIL';

const displayNameMinLength = 4;

const SORT_TYPE_OLDEST: SortType = {
  id: 'oldest',
  title: 'Oldest',
  order: 'TIMESTAMP',
  direction: 'ASC',
  intlID: 'dropdown.oldest',
};
const SORT_TYPE_NEWEST: SortType = {
  id: 'newest',
  title: 'Newest',
  order: 'TIMESTAMP',
  direction: 'DESC',
  intlID: 'dropdown.newest',
};
const SORT_TYPE_THUMBS_UP: SortType = {
  id: 'thumbsUp',
  title: 'Thumbs Up',
  order: 'RATING',
  direction: 'DESC',
  intlID: 'dropdown.thumbsUp',
};

export const COMMENT_SORT_TYPES: SortType[] = [SORT_TYPE_NEWEST, SORT_TYPE_OLDEST, SORT_TYPE_THUMBS_UP];

interface Comment {
  UUID: string;
  badges: number;
  isEdited: boolean;
  isSilenced: boolean;
  networkUserUUID: string;
  object: {
    name: string;
    path: string;
  };
  parentUUID: string;
  rating: number;
  siteUUID: string;
  text: string;
  timestamp: string;
  userDisplayName: string;
  visible: boolean;
}

interface InitialState {
  loadingObjectUUIDs: string[];
  errorObjectUUIDs: string[];
  comments: {
    [key: string]: {
      comments: Comment[];
      commentsCount: number;
    };
  };
  sortBy: string;
  latest: Comment[];
  latestError?: ResponseError;
  latestExpanded?: boolean;
  latestLoading?: boolean;
  postStatus: string;
  postError?: ResponseError;
}

export const initialState: InitialState = {
  loadingObjectUUIDs: [],
  errorObjectUUIDs: [],
  comments: {},
  sortBy: 'newest',
  latest: [],
  postStatus: 'none',
};

interface ActionLoad {
  type: typeof LOAD;
  objectUUID: string;
}

interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  objectUUID: string;
  result: {
    comments: Comment[];
    total: number;
  };
  sortBy: string;
}

interface ActionLoadFail {
  type: typeof LOAD_FAIL;
  objectUUID: string;
}

interface ActionLoadLatest {
  type: typeof LOAD_LATEST;
  latestExpanded: boolean;
}

interface ActionLoadLatestSuccess {
  type: typeof LOAD_LATEST_SUCCESS;
  result: {
    comments: Comment[];
  };
}

interface ActionLoadLatestFail {
  type: typeof LOAD_LATEST_FAIL;
  error: ResponseError;
}

interface ActionPost {
  type: typeof POST;
}

interface ActionPostSuccess {
  type: typeof POST_SUCCESS;
}

interface ActionPostFail {
  type: typeof POST_FAIL;
  error: ResponseError;
}

interface ActionAddComment {
  type: typeof ADD_COMMENT;
  objectUUID: string;
  comment: Comment;
}

export default function reducer(
  state = initialState,
  action:
    | ActionLoad
    | ActionLoadSuccess
    | ActionLoadFail
    | ActionLoadLatest
    | ActionLoadLatestSuccess
    | ActionLoadLatestFail
    | ActionPost
    | ActionPostSuccess
    | ActionPostFail
    | ActionAddComment,
): InitialState {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        loadingObjectUUIDs: [...state.loadingObjectUUIDs, action.objectUUID],
        errorObjectUUIDs: state.errorObjectUUIDs.filter((UUID) => UUID !== action.objectUUID),
      };
    }
    case LOAD_SUCCESS:
      return {
        ...state,
        loadingObjectUUIDs: state.loadingObjectUUIDs.filter((UUID) => UUID !== action.objectUUID),
        comments: {
          ...state.comments,
          [action.objectUUID]: {
            comments: action.result.comments,
            commentsCount: action.result.total,
          },
        },
        sortBy: action.sortBy,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loadingObjectUUIDs: state.loadingObjectUUIDs.filter((UUID) => UUID !== action.objectUUID),
        errorObjectUUIDs: [...state.errorObjectUUIDs, action.objectUUID],
      };
    case LOAD_LATEST:
      return {
        ...state,
        latestError: undefined,
        latestExpanded: action.latestExpanded,
        latestLoading: true,
      };
    case LOAD_LATEST_SUCCESS:
      return {
        ...state,
        latest: [...state.latest, ...action.result.comments],
        latestLoading: false,
      };
    case LOAD_LATEST_FAIL:
      return {
        ...state,
        latestError: action.error,
        latestLoading: false,
      };
    case POST:
      return {
        ...state,
        postStatus: 'submitting',
        postError: undefined,
      };
    case POST_SUCCESS:
      return {
        ...state,
        postStatus: 'success',
        postError: undefined,
      };
    case POST_FAIL:
      return {
        ...state,
        postStatus: 'fail',
        postError: action.error,
      };
    case ADD_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.objectUUID]: {
            comments: [action.comment, ...state.comments[action.objectUUID].comments],
            commentsCount: state.comments[action.objectUUID].commentsCount + 1,
          },
        },
      };
    default: {
      return state;
    }
  }
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function loadLatest(params) {
  return {
    type: LOAD_LATEST_SAGA,
    params,
  };
}

export function post(params) {
  return {
    type: POST_SAGA,
    params,
  };
}

export function add({ badges, objectUUID, parentUUID, text, userDisplayName, UUID }) {
  return {
    type: ADD_COMMENT,
    objectUUID,
    comment: {
      isEdited: false,
      badges,
      parentUUID,
      rating: 0,
      text,
      timestamp: new Date().toISOString(),
      userDisplayName,
      UUID,
    },
  };
}

export const validateDisplayName = async (name) => {
  let response;
  if (name && name.length >= displayNameMinLength) {
    const api = new ApiClient();
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response = await (api as any).get(constructUrl(urls.get.validateDisplayName).replace(':name', name));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      ({ response } = err);
    }
  }

  if (response && response.body) {
    return response.body;
  }
  return false;
};

export const setDisplayName = async (name) => {
  if (!name || name.length < displayNameMinLength) {
    return false;
  }
  let response;

  const api = new ApiClient();
  const data = {
    displayName: name,
  };
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response = await (api as any).post(constructUrl(urls.post.setDisplayName), { data });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    ({ response } = err);
  }

  return response && response.body;
};

/* SAGAS */
function* loadGenerator(params) {
  const finalParams = {
    objectUUID: params.objectUUID,
    order: params.order || 'TIMESTAMP',
    direction: params.direction || 'DESC',
  };
  const getState = (state) => state.comments;
  const currentState = yield select(getState);
  if (
    !(currentState.comments[finalParams.objectUUID] && currentState.sortBy === params.id) &&
    !currentState.loadingObjectUUIDs.includes(finalParams.objectUUID)
  ) {
    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client) => client.get(constructUrl(urls.get.comments), { params: finalParams }),
      {
        objectUUID: finalParams.objectUUID,
        sortBy: params.id,
      },
    );

    yield call(loadFunc);

    yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

    const newState = yield select();
    if (newState.auth.user && newState.comments.comments[finalParams.objectUUID]) {
      yield put(
        loadRatingInfo({
          objectUUIDs: [...newState.comments.comments[finalParams.objectUUID].comments.map((item) => item.UUID)],
        }),
      );
    }
  }
}

function* loadLatestGenerator(params) {
  const getState = (state) => state.comments;
  const currentState = yield select(getState);

  const comments: string[] = [];

  if (
    (currentState.latestError || currentState.latest.length === 0 || params.after !== 0) &&
    !currentState.latestExpanded
  ) {
    const loadFunc = getRequestFunc(
      [LOAD_LATEST, LOAD_LATEST_SUCCESS, LOAD_LATEST_FAIL],
      (client) => client.get(constructUrl(urls.get.comments), { params }),
      {
        latestExpanded: !!params.after,
      },
    );

    const { result } = yield call(loadFunc);

    yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

    const userState = yield select((state) => state.auth);
    if (userState.user) {
      result.comments.forEach(({ UUID }) => comments.push(UUID));
    }
  }

  if (comments.length > 0) {
    yield put(
      loadRatingInfo({
        objectUUIDs: comments,
      }),
    );
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

function* watchLoadLatest() {
  while (true) {
    const { params } = yield take(LOAD_LATEST_SAGA);
    yield fork(loadLatestGenerator, params);
  }
}

function* postGenerator(data) {
  const loadFunc = getRequestFunc([POST, POST_SUCCESS, POST_FAIL], (client) =>
    client.post(constructUrl(urls.post.comment), { data }),
  );
  const { result, type } = yield call(loadFunc);

  if (type === POST_SUCCESS) {
    const { UUID } = result;
    const user = yield select((state) => state.auth.user);
    yield put(
      add({
        badges: user.badges,
        objectUUID: data.objectUUID,
        parentUUID: data.parentUUID,
        text: sanitizeComment(data.text),
        userDisplayName: user.displayname,
        UUID,
      }),
    );
  }
}

// Trigger
function* watchPost() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(POST_SAGA);
    yield fork(postGenerator, params);
  }
}

export const watchers = [fork(watchLoad), fork(watchLoadLatest), fork(watchPost)];
/* EOF SAGAS */
