import React from 'react';
import { HeaderPopovers } from '../../containers/HeaderBar/HeaderProvider';
import { useSelector } from '../../redux';
import { LiveGirlsPopover } from './LiveGirlsPopover';
import { useGenerateCamUrlWithParams } from 'src/client/helpers/urls/useGenerateCamUrlWithParams';
import { CamTrackingPosition } from 'src/client/constants/camTracking';
import { HeaderItemWithPopover } from 'src/client/containers/HeaderBar/HeaderItemWithPopover';

type Props = {
  shouldRender: boolean;
};

export const LiveGirlsMenu = (props: Props) => {
  const config = useSelector((state) => state.app.config);

  const camTrackingPosition: CamTrackingPosition = 'header';
  const generateCamUrlWithParams = useGenerateCamUrlWithParams({
    trackingPosition: camTrackingPosition,
    provider: config?.camProviders?.[0].provider ?? 'streamate',
  });

  if (!props.shouldRender) {
    return null;
  }

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (config.camsList?.length > 0) {
      return;
    }

    window.open(generateCamUrlWithParams('/cams-list'), '_blank', 'noopener noreferrer');
  };

  return (
    <HeaderItemWithPopover
      arrowStyle="white"
      headerPopover={HeaderPopovers.LIVEGIRLS}
      menuLink="/cams-list"
      menuText="LIVE GIRLS"
      onClick={onClick}
    >
      <LiveGirlsPopover />
    </HeaderItemWithPopover>
  );
};
