import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

export const StyledIcon = styled(FontAwesomeIcon)<{
  $spaceRight?: boolean;
  $spaceLeft?: boolean;
}>`
  ${(props) => props.$spaceRight && 'margin-right: 0.25em;'}
  ${(props) => props.$spaceLeft && 'margin-left: 0.25em;'}
`;
