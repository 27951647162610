import React, { ComponentProps } from 'react';
import { CoverRow } from './CoverRow';

type Props = ComponentProps<typeof CoverRow> & {
  show: boolean;
};

export const MediaUpdatesSection = (props: Props) => {
  const { show = true, items, title, arrowLinkLabel, arrowLinkUrl, bottomLinkLabel, bottomLinkUrl } = props;

  if (!show) return null;

  return (
    <CoverRow
      title={title}
      items={items}
      arrowLinkLabel={arrowLinkLabel}
      arrowLinkUrl={arrowLinkUrl}
      bottomLinkLabel={bottomLinkLabel}
      bottomLinkUrl={bottomLinkUrl}
    />
  );
};
