import React, { useMemo } from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { validateSorting, validateTab } from 'src/client/helpers';
import { useSelector } from 'src/client/redux';
import { getGallerySortTypes, getMovieSortTypes } from 'src/client/utils/router';
import Model from './Model';

export const ModelRoute = () => {
  const config = useSelector((state) => state.app.config);

  const match = useRouteMatch<{ sortBy?: string; tab?: string; modelName: string }>();
  const { sortBy, tab = 'latest', modelName } = match.params;

  const gallerySortTypes = useMemo(() => getGallerySortTypes(config), [config]);
  const movieSortTypes = useMemo(() => getMovieSortTypes(config), [config]);

  if (!validateTab(tab, config.modelPageTabs)) {
    return <Redirect to={`/model/${modelName}`} />;
  }

  if (sortBy && !validateSorting(sortBy, [...gallerySortTypes, ...movieSortTypes])) {
    return <Redirect to={`/model/${modelName}`} />;
  }

  return <Model />;
};
