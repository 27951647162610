import { useEffect } from 'react';
import { isPartner } from '../../utils/affiliateTracking';
import { useCookies } from 'react-cookie';
import { MAM_COOKIE_NAME } from 'src/shared/constants/cookies';

export const useExternalScript = (
  url?: string,
  code?: string,
  appendCodeAfterUrlLoads = false,
  mamWhitelist?: number[],
) => {
  const [cookies] = useCookies([MAM_COOKIE_NAME]);

  useEffect(() => {
    // initialize this to true in case there are no whitelist checks to be made
    const passedWhitelistChecks = [true];

    // if there is a mam whitelist, verify that the user has the appropriate cookie to load the script
    if (mamWhitelist) {
      passedWhitelistChecks.push(isPartner(cookies[MAM_COOKIE_NAME], mamWhitelist));
    }

    // every value in the array has to be true or else we stop here
    if (!passedWhitelistChecks.every((value) => value)) {
      return;
    }

    const body = document.querySelector('body');
    let urlScript: HTMLScriptElement | undefined;
    let customCodeScript: HTMLScriptElement | undefined;

    // append the url as a script to the end of the body tag
    if (body && url) {
      urlScript = document.createElement('script');
      urlScript.setAttribute('src', url);
      urlScript.setAttribute('defer', '');
      body.appendChild(urlScript);
    }

    // create code script, which can be appended in two ways down below
    if (code) {
      customCodeScript = document.createElement('script');
      customCodeScript.textContent = code;
    }

    // append code script after url script loads
    if (appendCodeAfterUrlLoads && urlScript) {
      urlScript.onload = () => {
        if (customCodeScript && body) {
          body.appendChild(customCodeScript);
        }
      };
    }

    // append code script independent of the url
    if (!appendCodeAfterUrlLoads && customCodeScript && body) {
      body.appendChild(customCodeScript);
    }

    // cleanup, remove scripts from body
    return () => {
      if (body) {
        if (urlScript) {
          body.removeChild(urlScript);
        }

        if (customCodeScript) {
          body.removeChild(customCodeScript);
        }
      }
    };
  }, [appendCodeAfterUrlLoads, code, cookies, mamWhitelist, url]);
};
