export type UserType = typeof UserTypes[keyof typeof UserTypes];

/**
 * @see getUserType
 */
export const UserTypes = {
  Unknown: 0,
  Guest: 1,
  Free: 2,
  Paid: 3,
  Expired: 4,
} as const;

export const isValidUserType = (userType: UserType): boolean => {
  return Object.values(UserTypes).includes(userType);
};

export const SFW_DATE_THRESHOLD = '2025-02-01';
