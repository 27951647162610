import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import { useSelector } from '../../redux/modules/helpers/useSelector';
import { SitePickerDropdown } from './SitePickerDropdown';
import Popover from 'react-bootstrap/esm/Popover';
import { ImageButton } from './styles';
import { useSitePickerList } from './helpers/useSitePickerList';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

export const PopoverSitePicker = () => {
  const { headerBar, isWhiteLabel } = useSelector((state) => state.app.config);
  const sitePickerIcon = useSelector((state) => state.app.icons?.sitePicker);
  const location = useLocation();
  const [sitePickerOpened, setSitePickerOpened] = useState(false);
  const sitePickerList = useSitePickerList();

  const showSplashSitePicker = headerBar?.showSplashSitePicker ? true : location.pathname !== '/';

  if (headerBar?.hideSitePicker || !showSplashSitePicker || isWhiteLabel || !sitePickerList.length) {
    return null;
  }

  return (
    <SitePickerButton className={cx('pull-left', { active: sitePickerOpened }, 'clickable', 'hidden-xs', 'hidden-sm')}>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={
          <SitePickerPopover placement="bottom">
            <SitePickerDropdown sitePickerList={sitePickerList} />
          </SitePickerPopover>
        }
        rootClose
        onEntered={() => setSitePickerOpened(true)}
        onExited={() => setSitePickerOpened(false)}
      >
        <SitePickerIconWrap>
          <StyledSitePickerIcon icon={sitePickerIcon} />
          <FontAwesomeIcon icon={faCaretDown} />
        </SitePickerIconWrap>
      </OverlayTrigger>
    </SitePickerButton>
  );
};

const SitePickerIconWrap = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const StyledSitePickerIcon = styled(FontAwesomeIcon)`
  font-size: 3.6rem;
  margin-right: 4px;
`;

const SitePickerPopover = styled(Popover)`
  max-width: none;
  position: fixed;
  top: 8px !important;
  background: ${(props) => props.theme.primary6};

  .arrow {
    border-bottom-color: ${(props) => props.theme.primary3};
    margin-top: -24px;

    ::after {
      border-bottom-color: ${(props) => props.theme.primary6};
      margin-top: -8px;
      margin-left: -10px;
    }
  }
`;

const SitePickerButton = styled(ImageButton)`
  position: relative;
  padding: 0;
  margin-right: 5px;
`;
