const inToFt = (n: number) => {
  const realFeet = n / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return `${feet}'${inches}''`;
};

const cmToIn = (n: number) => Math.round(n * 0.393701);

const mToFeet = (n: number) => inToFt(cmToIn(n));

const kgToLbs = (n: number) => Math.round(n * 2.20462);

const sizeInInches = (cmSize: string) =>
  cmSize.replace(/(\d+)/g, (match) => Math.round(parseInt(match, 10) * 0.3937).toString());

const metricConverter = {
  height: (height: number) => `${height} cm`,
  weight: (weight: number) => `${weight} kg`,
};

const imperialConverters = {
  height: (height: number) => mToFeet(height),
  weight: (weight: number) => `${kgToLbs(weight)} lbs`,
  size: (size: string) => sizeInInches(size),
};

export const displayModelStat = ({
  statType,
  value,
  measurements,
}: {
  statType: string;
  value: number | string;
  measurements: 'imperial' | 'metric';
}) => {
  const converter = measurements === 'imperial' ? imperialConverters[statType] : metricConverter[statType];
  return converter?.(value) ?? value;
};
