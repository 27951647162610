import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Slider from '@tovia/man-ui/lib/components/Slider/Slider';
import RoundButton from '@tovia/man-ui/lib/components/Button/RoundButton';
import { useInfinityTagEvent } from '../../../helpers/useInfinityTagEvents';

const SkipButton = styled(RoundButton)`
  position: absolute;
  bottom: 20px;
  z-index: 3;
`;

const StyledSlider = styled(Slider)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: ${(props) => props.theme.splashPrimary1}; */
  z-index: 2;
  margin: 0;
`;

const HeroSliderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  &::before {
    content: '';
    padding-top: 44%;
    height: 0;
  }

  .slider-wrap {
    .awards {
      position: absolute;
      width: 640px;
      height: 90px;
      top: 30px;
      left: 10px;
      z-index: 2;
    }

    .slogan {
      position: absolute;
      width: 90%;
      height: 17px;
      left: 5%;
      right: 5%;
      bottom: 20px;
      z-index: 2;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    .title-wrap {
      position: absolute;
      text-transform: uppercase;
      color: #ffffff;
      left: 8%;
      top: 130px;
      text-align: center;
      z-index: 2;

      fieldset {
        position: relative;
        max-height: 250px;
        padding: 15px;
        border-radius: 2px;
        border: 1px solid #fff;
        text-align: center;
      }

      legend {
        color: white;
        border: none;
        font-size: 1.07rem;
        text-transform: uppercase;
        text-align: center;
        border-bottom: 0;
        width: auto;
        display: inline;
        margin-bottom: 0;
        padding: 0 20px;
      }

      @media (max-width: 768px) {
        width: 100vw;
        left: 0;
        color: ${(props) => props.theme.primaryBtn1Text};
        background-color: rgba(0, 0, 0, 0.36);
        bottom: 30px;
      }

      h1 {
        font-weight: 400;
        font-size: 3rem;
        @media (max-width: 768px) {
          display: none;
        }
      }

      span {
        @media (max-width: 768px) {
          display: none;
        }
      }

      .mobile-Title {
        display: none;
        @media (max-width: 768px) {
          display: inline-block;
          font-size: 0.85rem;
          text-transform: capitalize;
          line-height: 34px;
        }
      }
    }

    .mobile-title-wrap {
      display: none;
      span {
        display: block;
        width: 100%;
        line-height: 33px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.36);
      }
    }

    .title-wrap.dark {
      fieldset {
        border: 1px solid #000;
      }

      legend {
        color: #000;
      }
    }

    .slider {
      position: relative;
      display: block;
      height: 100%;
      padding: 0 !important;
      max-width: 2130px;
      margin: 0 auto;

      &:hover .slick-arrow {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .hero {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-size: cover;
      background-position: center center;
    }

    .hide-btn {
      background: ${(props) => props.theme.primaryBtn1Bg};
      color: ${(props) => props.theme.splashPrimary1};
      width: 320px;
      height: 52px;
      border: 0;
      border-radius: 3px;
      top: auto;
      bottom: 200px;
      outline: none;
      display: inline-block;
      text-transform: uppercase;
      font-size: 1.6rem;
      font-weight: 700;
      & :hover {
        background: ${(props) => props.theme.primaryBtn1HoverBg};
      }

      @media (max-width: 990px) {
        left: 0;
        width: 100vw;
        bottom: 0;
        font-size: 0.85rem;
        height: 30px;
      }

      @media (max-width: 768px) {
        left: 0;
        width: 100vw;
        bottom: 0;
      }
    }

    .slick-slider,
    .slick-track,
    .slick-list {
      height: 100%;
    }

    .slick-arrow {
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 70px;
      border: 0 none;
      border-radius: 4px;
      background-color: #1c1c1c;
      color: transparent;
      transition: 0.5s opacity ease;
      opacity: 0;
      pointer-events: none;
      z-index: 3;

      :hover {
        background-color: #000000;
      }
    }

    .slick-prev {
      left: 15px;
    }

    .slick-next {
      right: 15px;
    }

    .slick-dots {
      list-style: none;
      display: flex !important;
      position: absolute;
      bottom: 16px;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0;

      @media (max-width: 768px) {
        display: none !important;
      }

      li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 10px;
        padding: 0;
        cursor: pointer;
        background: white;
        border-radius: 50%;
        opacity: 0.25;
      }

      li.slick-active {
        opacity: 0.75;
      }

      button {
        color: transparent;
        background: transparent;
        border: 0;
        outline: none;
      }
    }
  }

  // Hero Slider is hidden on mobile devices.
  @media (max-width: 768px) {
    display: none;
  }
`;

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  teasers: any[];
  className?: string;
  hideSlider: () => void;
  awardsBlackImageUrl: string;
  awardsWhiteImageUrl: string;
  sloganUrl: string;
  hasAwards?: boolean;
  hasFreeGallery?: boolean;
  mainPageUrl: string;
  showButton: boolean;
};

export default function HeroSlider(props: Props) {
  const intl = useIntl();
  const {
    className = '',
    settings,
    teasers,
    hideSlider,
    hasAwards = true,
    hasFreeGallery = true,
    awardsBlackImageUrl,
    awardsWhiteImageUrl,
    sloganUrl,
    mainPageUrl,
    showButton,
  } = props;

  const sendSkipButtonEventOnClick = useInfinityTagEvent({
    name: 'Skip to the main page button',
    type: 'Inquiry',
    value: '0.001',
  });

  const renderTeaser = (teaser) => {
    const { models, imgSrc, isLight, name } = teaser;

    const slideProps = {
      className: 'hero',
      style: { backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 70px), url(${imgSrc})` },
    };
    const slide = teaser.path ? <NavLink to={teaser.path} {...slideProps} /> : <div {...slideProps} />;

    return (
      <div key={imgSrc}>
        {hasAwards && (
          <div
            className="awards hidden-sm hidden-xs"
            style={{ backgroundImage: `url(${isLight ? awardsBlackImageUrl : awardsWhiteImageUrl})` }}
          />
        )}
        {hasFreeGallery && (
          <>
            <div className="slogan" style={{ backgroundImage: `url(${sloganUrl})` }} />
            <div className={`${isLight ? 'dark ' : ''} title-wrap hidden-sm hidden-xs`}>
              <fieldset>
                <legend>
                  Starring:
                  <strong>{models.length ? ` ${models.join(' and ')}` : models}</strong>
                </legend>
                <button type="button" onClick={hideSlider} className="hide-btn btn btn-primary">
                  View Free Gallery
                </button>
              </fieldset>
            </div>
            <div className="mobile-title-wrap visible-sm visible-xs">
              <span>{`${name} - ${models.length ? `${models.join(' and ')}` : models}`}</span>
              <button type="button" onClick={hideSlider} className="hide-btn btn btn-primary">
                View Free Gallery
              </button>
            </div>
          </>
        )}
        {slide}
      </div>
    );
  };

  return (
    <HeroSliderContainer>
      {teasers.length > 0 && (
        <>
          <StyledSlider settings={settings} className={`${className} slider-wrap`}>
            {teasers.map(renderTeaser)}
          </StyledSlider>
          {showButton && (
            <div onClick={sendSkipButtonEventOnClick}>
              <SkipButton to={mainPageUrl} className="skip-button">
                {intl.formatMessage({ id: 'splash.emailSignupSection.button', defaultMessage: 'SKIP TO MAIN PAGE' })}
              </SkipButton>
            </div>
          )}
        </>
      )}
    </HeroSliderContainer>
  );
}
