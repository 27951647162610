import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import NormalSlider from '../../../components/splash/NormalSlider/NormalSlider';
import HeroSlider from '../../../components/splash/HeroSlider/HeroSlider';
import StraplezSlider from '../../../components/splash/NormalSlider/StraplezSlider';
import redirectHomepages from 'src/shared/constants/redirectHomepages';
import awardsBlackImg from '../images/awardsBlack.png';
import awardsWhiteImg from '../images/awardsWhite.png';
import sloganImg from '../images/slogan.png';
import { loadBanner } from '../../../redux/modules/updates';
import { useSelector, useSettingsSelector } from '../../../redux/modules/helpers/useSelector';

const sliderSettings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  variableWidth: false,
};

const Div = styled.div`
  &.slider-container {
    position: relative;
    background: ${(props) => props.theme.splashPrimary4};
    width: 100%;
    max-width: 1170px;

    &.hero {
      max-width: 2130px;
    }

    &.straplez {
      max-width: 100%;
    }

    &.network {
      max-width: 100%;
    }

    margin: 0 auto;
    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  teasers: any[];
  hideSlider: () => void;
  show?: boolean;
};

export const SplashSlider = (props: Props) => {
  const dispatch = useDispatch();
  const { teasers } = props;
  const [showSlider, setSliderVisibility] = useState<boolean>(false);

  const contentCdnUrl = useSelector((state) => state.app.contentCdnUrl);
  const site = useSelector((state) => state.site);
  const banners = useSelector((state) => state.updates.banners || []);
  const config = useSelector((state) => state.app.config);
  const mainPageUrl = useSettingsSelector('homepage');
  const components = config.splash?.components;

  const hasFreeGallery = useMemo(() => components?.sliderComponent?.hasFreeGallery !== false, [components]);
  const hasAwards = useMemo(() => components?.sliderComponent?.hasAwards !== false, [components]);
  const showHeroSlider = useMemo(() => components?.sliderComponent?.component === 'HeroSlider', [components]);
  const showStraplezSlider = useMemo(() => components?.sliderComponent?.component === 'StraplezSlider', [components]);
  const showNetworkSlider = useMemo(() => components?.sliderComponent?.component === 'NetworkSlider', [components]);
  const slideshowName = useMemo(() => components?.sliderComponent?.slideshowName, [components]);
  const showButton = !hasFreeGallery && banners.length;

  const getImgSrc = useCallback(
    (teaser) => {
      if (slideshowName) {
        return `${contentCdnUrl}/${site.UUID}${teaser.coverImagePath}`;
      }

      return `${contentCdnUrl}/${teaser.siteUUID}/media/${teaser.galleryUUID}/splash-slide.jpg`;
    },
    [site.UUID, slideshowName, contentCdnUrl],
  );

  const hideSlider = useCallback(() => {
    setSliderVisibility(false);
    props.hideSlider();
  }, [props, setSliderVisibility]);

  useEffect(() => {
    if (slideshowName) {
      dispatch(loadBanner({ name: slideshowName, timestamp: 1 }));
    }
  }, [dispatch, slideshowName]);

  useEffect(() => {
    if (props.show && teasers.length) {
      setSliderVisibility(true);
    }
  }, [props.show, teasers]);

  const finalTeasers = useMemo(() => {
    if (slideshowName) {
      return banners.map((banner) => ({
        path: banner.path,
        imgSrc: getImgSrc(banner),
        models: banner.models,
      }));
    }

    if (hasFreeGallery) {
      return teasers.map((teaser) => ({
        ...teaser,
        imgSrc: `${getImgSrc(teaser)}`,
      }));
    }

    return [];
  }, [hasFreeGallery, banners, teasers, slideshowName, getImgSrc]);

  if (!showSlider || (!teasers.length && !banners.length)) {
    return null;
  }

  return (
    <Div
      className={`${showHeroSlider ? 'hero' : ''} ${showStraplezSlider ? 'straplez' : ''} ${
        showNetworkSlider ? 'network' : ''
      }  slider-container`}
    >
      {(showHeroSlider && (
        <HeroSlider
          awardsBlackImageUrl={awardsBlackImg}
          awardsWhiteImageUrl={awardsWhiteImg}
          sloganUrl={sloganImg}
          teasers={finalTeasers}
          hideSlider={hideSlider}
          settings={{ ...sliderSettings, dots: false }}
          hasAwards={hasAwards}
          hasFreeGallery={hasFreeGallery}
          mainPageUrl={redirectHomepages[mainPageUrl]}
          showButton={showButton}
        />
      )) ||
        (showStraplezSlider && (
          <StraplezSlider teasers={finalTeasers} hideSlider={hideSlider} settings={sliderSettings} />
        )) || <NormalSlider teasers={finalTeasers} hideSlider={hideSlider} settings={sliderSettings} />}
    </Div>
  );
};
