import React, { ReactElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useJoinUrl } from '../components/buttons/JoinButton';

type Props = {
  campaign?: string;
  isUser?: boolean;
  userLoaded: boolean;
  exact?: boolean;
  path: string | string[];
} & (
  | {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component: ReactElement | React.LazyExoticComponent<any> | React.FC;
      render?: never;
    }
  | {
      render: Function;
      component?: never;
    }
);

export const PrivateRoute = (props: Props) => {
  const { campaign = 'unauthorized-route', render, component, isUser, userLoaded, ...rest } = props;
  const Component = component as React.ElementType;

  const [joinUrl] = useJoinUrl({ campaign });

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!userLoaded) return null;

        if (isUser) {
          return Component ? <Component {...routeProps} /> : render && render(routeProps);
        }

        return <Redirect to={joinUrl} />;
      }}
    />
  );
};
