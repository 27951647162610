import React from 'react';
import styled from 'styled-components/macro';
import Placeholder from '@tovia/man-ui/lib/components/Placeholder/Placeholder';

type Props = {
  detailed?: boolean;
};

export const GalleriesCardPlaceholder = (props: Props) => {
  const { detailed } = props;

  return detailed ? <DetailedGridCard /> : <SimpleGridCard />;
};

const SimpleGridCard = () => {
  const { Line, Image } = Placeholder;

  return (
    <GridWrap data-testid="grid-card" role="progressbar">
      <GridCard column>
        <Image />
        <Placeholder column>
          <Line />
          <Line thin />
          <Line medium thin />
        </Placeholder>
      </GridCard>
    </GridWrap>
  );
};

const DetailedGridCard = () => {
  const { Line, Image } = Placeholder;

  return (
    <DetailedCardWrap data-testid="detailed-card" role="progressbar">
      <DetailedCard>
        <Placeholder>
          <Image />
        </Placeholder>
        <Placeholder className="info-wrap" column>
          <Placeholder className="header" column>
            <Line thick />
            <Line thin medium />
            <Line thin />
          </Placeholder>
          <Placeholder className="footer">
            <Line />
          </Placeholder>
        </Placeholder>
      </DetailedCard>
    </DetailedCardWrap>
  );
};

const GridWrap = styled.div`
  @media (max-width: 479px) {
    width: 46%;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    padding: 0;
    width: 31%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 230px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 225px;
  }

  @media (min-width: 1200px) {
    width: 218px;
  }
`;

const DetailedCardWrap = styled.div`
  width: 33%;

  @media (max-width: 991px) and (min-width: 768px) {
    width: 49.5%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const GridCard = styled(Placeholder)`
  width: 100%;
  margin: 10px 5px;

  .image {
    padding-top: 148%;
    height: 0;
  }

  > .placeholder {
    padding-top: 4px;
  }
`;

const DetailedCard = styled(Placeholder)`
  width: 100%;
  display: flex;
  margin: 0 0 3px 3px;
  padding: 5px;

  .image {
    height: 0;
    width: 140px;
    padding-top: 105%;
  }

  .info-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
