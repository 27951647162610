import './Gateway.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { useSelector } from 'src/client/redux';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';
import styled, { css } from 'styled-components/macro';
import { useGateway } from 'src/client/containers/PopupBanners/useGateway';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { usePromotions } from 'src/client/helpers/usePromotions';
import { signalAnalyticsData } from 'src/client/utils/sendAnalyticsData';
import { CategoryIds, ConversionTypes, MeasurementTypes } from 'src/shared/constants/userMeasurement';
import { getDeviceType } from 'src/client/utils/getDeviceType';
import { useCookies } from 'react-cookie';
import { ANALYTICS_COOKIE_NAME } from 'src/shared/constants/cookies';
import { logoUrl } from 'src/client/helpers/urls/logoUrl';

type Props = {
  onClose: () => void;
};

export const FreeAccessGateway = (props: Props) => {
  const { onClose } = props;
  const { cdnUrl, siteSubdomain } = useSelector((state) => state.app);
  const {
    backgroundImage,
    backgroundImagePath,
    selectedSites,
    setBackgroundImage,
    setSelectedSites,
    sitesToShow,
  } = useGateway();
  const [joinUrl] = useJoinUrl({ campaign: 'ad-zone-gateway-desktop' });
  const { isPromoGatewayActive } = usePromotions();
  const [cookies] = useCookies([ANALYTICS_COOKIE_NAME]);

  const analyticsUUID: string = cookies[ANALYTICS_COOKIE_NAME];

  const gatewayTitle = isPromoGatewayActive ? 'BIG HOLIDAY SAVINGS' : 'FREE ACCESS!';
  const gatewayText = isPromoGatewayActive ? (
    <>
      We appreciate your membership and would like to
      <br />
      wish you a <span className="free-day">happy holiday season</span> with this amazing offer.
    </>
  ) : (
    <>
      We appreciate your membership and would like to
      <br />
      thank you with <span className="free-day">1 free day</span> on three great sites.
    </>
  );

  const sendAnalyticsData = () => {
    signalAnalyticsData(
      {
        categoryId: CategoryIds.MembersPortal,
        conversionType: ConversionTypes.Click,
        deviceType: getDeviceType(),
      },
      analyticsUUID,
      MeasurementTypes.Conversion,
    );
    window.open(joinUrl, '_blank', 'noreferrer noopener');
  };

  return (
    <GatewayWrapper>
      <GatewayApp style={{ backgroundImage: `url(${backgroundImage})` }}>
        <CloseButton>
          <FontAwesomeIcon icon={faTimes} onClick={onClose} />
        </CloseButton>
        <GatewayRightBox>
          <GatewayTitle>{gatewayTitle}</GatewayTitle>
          <GatewayText>{gatewayText}</GatewayText>
          <GatewaySites>
            {sitesToShow.map((site) => {
              const siteActive = selectedSites.includes(site.abbreviation);

              const toggleSelectedSite = () => {
                const newActiveSites = siteActive
                  ? selectedSites.filter((s) => s !== site.abbreviation)
                  : [...selectedSites, site.abbreviation];
                setSelectedSites(newActiveSites);
              };

              return (
                <div
                  key={site.abbreviation}
                  className="site-wrapper"
                  onMouseEnter={() => setBackgroundImage(`${backgroundImagePath}/${site.abbreviation}_bg.jpg`)}
                  onClick={toggleSelectedSite}
                >
                  <div className="logo-box">
                    <img alt={site.name} className="logo" src={logoUrl(cdnUrl, site)} />
                    <link rel="preload" href={`${backgroundImagePath}/${site.abbreviation}_bg.jpg`} as="image" />
                    <a
                      className="site-link"
                      onClick={(e) => e.stopPropagation()} // prevents unchecking site
                      href={`https://${siteSubdomain}.${site.domain}/?utm_source=gateway_popup`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faExternalLink} />
                      <span>Visit</span>
                    </a>
                  </div>
                  <div className={`check-circle ${siteActive ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                  </div>
                </div>
              );
            })}
          </GatewaySites>
          <GatewayClaimButton isPromoGatewayActive={isPromoGatewayActive} onClick={sendAnalyticsData}>
            claim your discount
          </GatewayClaimButton>
        </GatewayRightBox>
      </GatewayApp>
    </GatewayWrapper>
  );
};

const GatewayWrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1031;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const GatewayApp = styled.div`
  width: 1170px;
  height: 400px;
  position: relative;
  color: rgb(var(--white-rgb));
  background-repeat: no-repeat;
  background-color: rgb(var(--black-rgb));
`;

const CloseButton = styled.div`
  align-items: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  height: 30px;
  justify-content: center;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 30px;
`;

const GatewayRightBox = styled.div`
  padding: 15px;
  position: absolute;
  right: 20px;
  width: 440px;
  box-sizing: border-box;
  background: rgba(var(--white-rgb), 0.2);
  top: 20px;
  text-align: center;
`;

const GatewayTitle = styled.h1`
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: normal;
`;

const GatewayText = styled.div`
  .free-day {
    color: var(--free-day-text);
  }
`;

const GatewaySites = styled.div`
  margin-top: 10px;

  .site-wrapper {
    display: flex;
    justify-content: space-between;
    background: rgba(var(--black-rgb), 0.3);
    padding: 10px;
    margin: 2px 0;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .site-wrapper:hover {
    background: rgba(var(--black-rgb), 0.5);
  }

  .logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: 100px;
    margin-right: 10px;
  }

  .site-link {
    color: gray;
    font-size: 80%;
    text-decoration: none;
  }

  .site-link svg {
    margin-right: 5px;
  }

  .check-circle {
    color: var(--checkbox-color);
  }

  .check-circle.active {
    color: var(--active-checkbox-color);
  }
`;

const GatewayClaimButton = styled.div<{ isPromoGatewayActive: boolean }>`
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 400;
  display: block;
  color: rgb(var(--white-rgb));
  ${({ isPromoGatewayActive }) =>
    isPromoGatewayActive
      ? css`
          background: var(--gold-claim-button-background);
        `
      : css`
          background: var(--orange-red-claim-button-background);
          background: var(--orange-red-claim-button-background-gradient);
        `}
  border: var(--gold-claim-button-border);
  padding: 15px;
  border-radius: 3px;
  opacity: 0.8;
  cursor: pointer;
  margin-top: 15px;
  box-shadow: 0 2px 3px rgba(var(--black-rgb), 0.5);

  &:hover {
    opacity: 1;
  }
`;
