import React from 'react';
import styled from 'styled-components/macro';
import { useJoinUrl } from '../buttons/JoinButton';
import { DESKTOP, DESKTOP_WIDE, MOBILE_LANDSCAPE, TABLET_LANDSCAPE, TABLET_PORTRAIT } from '@tovia/man-ui';
import { useSelector } from 'src/client/redux';
import { ConstrainedContainer } from 'src/client/constants/mediaQueries';
import { signalAnalyticsData } from 'src/client/utils/sendAnalyticsData';
import { CategoryIds, MeasurementTypes, ConversionTypes } from 'src/shared/constants/userMeasurement';
import { getDeviceType } from 'src/client/utils/getDeviceType';
import { useCookies } from 'react-cookie';
import { ANALYTICS_COOKIE_NAME, HIDE_FREE_SITES_BUTTON } from 'src/shared/constants/cookies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

export const MembersPortalFloater = () => {
  const [cookies, setCookie] = useCookies([ANALYTICS_COOKIE_NAME, HIDE_FREE_SITES_BUTTON]);
  const analyticsUUID: string = cookies[ANALYTICS_COOKIE_NAME];
  const hideFreeSites: string = cookies[HIDE_FREE_SITES_BUTTON];

  const [joinUrl] = useJoinUrl({
    campaign: 'gateway-replacement-floater',
    overrideSite: false,
  });
  const { user } = useSelector((state) => state.auth);

  const onClose = () => {
    const expires = new Date(new Date().getTime() + 3600 * 1000 * 24); // 1 day
    setCookie(HIDE_FREE_SITES_BUTTON, true, { expires, path: '/' });
  };

  const showMembersPortalFloater = Boolean(!hideFreeSites && user?.showMembersPortal);

  return showMembersPortalFloater ? (
    <LinkWrapper>
      <CloseButton onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseButton>
      <PortalLink
        href={joinUrl}
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => {
          signalAnalyticsData(
            {
              categoryId: CategoryIds.MembersPortalFloaty,
              conversionType: ConversionTypes.Click,
              deviceType: getDeviceType(),
            },
            analyticsUUID,
            MeasurementTypes.Conversion,
          );
        }}
      >
        Free Sites
      </PortalLink>
    </LinkWrapper>
  ) : null;
};

const LinkWrapper = styled(ConstrainedContainer)`
  position: fixed;
  text-align: center;
  top: 50%;
  right: 0;
  width: 40px;
  height: 150px;
  z-index: 1000;
  background-color: #ceab58;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const CloseButton = styled.div`
  visibility: visible;
  position: absolute;
  align-items: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  height: 20px;
  justify-content: center;
  left: -10px;
  top: -10px;
  width: 20px;
  z-index: 2000;
  @media ${MOBILE_LANDSCAPE} {
    visibility: hidden;
  }
  &:before {
    // to avoid misclicking
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0px;
    left: 0px;
    z-index: 2000;
    pointer-events: none;
  }
`;

const PortalLink = styled.a`
  position: relative;
  text-decoration: none;
  writing-mode: vertical-rl;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-family: 'Open Sans', sans-serif;
  font-size: min(25px, 6vw);
  rotate: 180deg;

  @media ${MOBILE_LANDSCAPE} {
    font-size: 3vw;
  }

  @media ${TABLET_PORTRAIT} {
    font-size: 2.3vw;
  }

  @media ${TABLET_LANDSCAPE} {
    font-size: 2vw;
  }

  @media ${DESKTOP} {
    font-size: 2vw;
  }

  @media ${DESKTOP_WIDE} {
    font-size: min(25px, 1.5vw);
  }
`;
