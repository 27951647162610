import { takeEvery, call, fork, select } from 'redux-saga/effects';

import { ResponseError } from 'superagent';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { CamProviderSetup } from 'src/client/redux/modules/app';
import { CamsResponse, GetCamsRequest, GetCamsResponse, ProviderRequest } from 'src/@types/Cam';
import { addDashesToUUID } from '@tovia/man-app-utils/lib/helpers/uuids';

const LOAD = 'man-site/cams/LOAD';
const LOAD_SAGA = 'man-site/cams/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/cams/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/cams/LOAD_FAIL';

const camsEndpoint = constructUrl(urls.get.cams);

interface State {
  cams?: CamsResponse[];
  loading?: boolean;
  loaded?: boolean;
  error?: ResponseError;
  tryCount: number;
  lastRequestTimestamp: number;
}

const initialState: State = {
  tryCount: 0,
  lastRequestTimestamp: 0,
};

type ActionLoad = {
  type: typeof LOAD;
} & GetCamsRequest;

interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  result: GetCamsResponse;
}

interface ActionLoadFail {
  type: typeof LOAD_FAIL;
  error: ResponseError;
}

export default function reducer(state = initialState, action: ActionLoad | ActionLoadSuccess | ActionLoadFail): State {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        loading: true,
        tryCount: state.tryCount + 1,
        lastRequestTimestamp: new Date().getTime(),
      };
    }

    case LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        cams: action.result.cams.filter(({ total }) => total > 0),
        tryCount: 0,
        error: undefined,
      };
    }
    case LOAD_FAIL:
      return {
        ...initialState,
        error: action.error,
        tryCount: state.tryCount,
        lastRequestTimestamp: state.lastRequestTimestamp,
      };
    default: {
      return state;
    }
  }
}

type LoadCamsParams = { providers: CamProviderSetup[]; siteUUID: string };

export function load(params: LoadCamsParams) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
export function* loadGenerator({ params: { providers, siteUUID } }: { type: string; params: LoadCamsParams }) {
  const getState = (state) => state.cams;
  const currentState = yield select(getState);
  const isValidRequestTime = new Date().getTime() - currentState.lastRequestTimestamp >= 5000;
  const isValidRequestTry = currentState.tryCount <= 5;
  if (!currentState.loading && isValidRequestTry && isValidRequestTime) {
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) =>
      client.get(camsEndpoint, {
        params: { ...generateExpressCompatibleUrlParams(providers), siteUUID: addDashesToUUID(siteUUID) },
      }),
    );
    yield call(loadFunc);
  }
}

// Generate object that will be converted to a express compatible url param structure
// ?providers[0][provider]=streamate&providers[1][provider]=stripchat
// https://nodejs.org/api/querystring.html#querystring_querystring_parse_str_sep_eq_options
const generateExpressCompatibleUrlParams = (providers: ProviderRequest[]) =>
  providers.reduce((providers, provider, index) => {
    return { ...providers, [`providers[${index}]`]: provider };
  }, {});

// Trigger
function* watchLoad() {
  yield takeEvery(LOAD_SAGA, loadGenerator);
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
