import { useEffect, useState } from 'react';

const isMobileWidth = (): boolean => {
  return window.innerWidth < 768;
};

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(isMobileWidth);

  useEffect(() => {
    const onWindowResize = () => {
      setIsMobile(isMobileWidth());
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return isMobile;
};
