import React from 'react';
import styled from 'styled-components/macro';

type RibbonProps = {
  text: string;
  ribbonType: keyof typeof styleMap;
};

const styleMap: Record<string, { fontSize: string; translateY: string; width: string; height: string }> = {
  grid: { fontSize: '14px', translateY: '227%', width: '130px', height: '28px' },
  detail: { fontSize: '8px', translateY: '200%', width: '85px', height: '20px' },
};

export function RibbonCorner({ text, ribbonType }: RibbonProps) {
  const ribbonStyle = styleMap[ribbonType];

  return (
    <Container
      fontSize={ribbonStyle.fontSize}
      translateY={ribbonStyle.translateY}
      width={ribbonStyle.width}
      height={ribbonStyle.height}
      {...(text ? { 'data-ribbon': text } : {})}
    />
  );
}

const Container = styled.div<{ fontSize: string; translateY: string; width: string; height: string }>`
  --d: 6px;

  ::before {
    display: none;
  }
  &[data-ribbon]::before {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    font-weight: 600;
    display: block;
    content: attr(data-ribbon);
    z-index: 2;
    text-align: center;
    font-size: ${(props) => props.fontSize};
    position: absolute;
    color: #000000;
    top: 0;
    left: 0;
    transform: translate(0%, ${(props) => props.translateY}) rotate(-45deg);
    transform-origin: bottom left;
    background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d) no-repeat #f7ce46;
    clip-path: polygon(
      0 0,
      100% 0,
      100% 100%,
      calc(100% - var(--d)) calc(100% - var(--d)),
      var(--d) calc(100% - var(--d)),
      0 100%
    );
    mask: linear-gradient(135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom left,
      linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom right;
    mask-size: 300vmax 300vmax;
    -webkit-mask: linear-gradient(135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom left,
      linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom right;
    -webkit-mask-size: 300vmax 300vmax;
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
  }
`;
