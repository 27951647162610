import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import useAutoComplete from '../helpers/useAutoComplete';

const validate = (values) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errors: any = {};
  if (!values.searchTerm || values.searchTerm.length < 1) {
    errors.searchTerm = 'Invalid search term';
  }
  return errors;
};

const formName = 'mobileSearchBox';

type Props = {
  isSplash: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose: any;
  searchTerm?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  change: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSearch: any;
};

const MobileSearchBox = (props: Props) => {
  const { handleSubmit, onClose, searchTerm = '' } = props;
  const intl = useIntl();
  const [autoCompleteValues, setAutoComplete] = useAutoComplete();

  useEffect(() => {
    setAutoComplete(searchTerm);
  }, [searchTerm, setAutoComplete]);

  const clearAndClose = () => {
    setAutoComplete('');
    props.change('searchTerm', null);
    onClose();
  };

  const boldValue = (value) => {
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return value.replace(regex, '<b>$1</b>');
  };

  if (props.isSplash) {
    return null;
  }

  return (
    <SearchForm onSubmit={handleSubmit} className="form-nav-search form-inline">
      <MobileInputGroup>
        <FontAwesomeIcon icon={faSearch} />
        <Field
          component="input"
          name="searchTerm"
          type="text"
          placeholder={intl.formatMessage({ id: 'search.search', defaultMessage: 'Search' })}
          className="form-control search-term"
          autoComplete="off"
        />
        {autoCompleteValues.length > 0 && (
          <div className="mobile-search-autocomplete">
            {autoCompleteValues.map((value) => (
              <NavLink
                key={value}
                to={`/search/${value.replace(/\s+/g, '+')}`}
                onClick={clearAndClose}
                className="autocomplete-item"
              >
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: boldValue(value) }} />
              </NavLink>
            ))}
          </div>
        )}
      </MobileInputGroup>
      <NavLink to="/search/" className="search-link" rel="nofollow" onClick={clearAndClose}>
        {intl.formatMessage({ id: 'search.advancedSearch.advancedSearch', defaultMessage: 'Advanced Search' })}
      </NavLink>
    </SearchForm>
  );
};

export default connect((state) => ({
  searchTerm: formValueSelector(formName)(state, 'searchTerm'),
}))(
  reduxForm({
    form: formName,
    validate,
    onSubmit: (values, context, props) => {
      props.onClose();
      props.change('searchTerm', null);
      props.onSearch(values.searchTerm);
    },
  })(MobileSearchBox),
);

const MobileInputGroup = styled.div`
  position: relative;

  svg {
    position: absolute;
    right: 10px;
    top: 11px;
    opacity: 0.5;
  }

  .search-term {
    padding-right: 30px;
  }

  .mobile-search-autocomplete {
    background-color: ${(props) => props.theme.primary1};
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    left: 0;
    line-height: 2;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;

    .autocomplete-item {
      display: block;
      font-size: 1rem;
      padding-left: 12px;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const SearchForm = styled.form`
  margin: 10px;
  top: 0;
  display: flex;
  flex-direction: column;

  input {
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    border-color: ${(props) => props.theme.primary7};
    font-size: 16px;
    height: auto;
  }

  i {
    position: absolute;
    top: 9px;
    right: 9px;
    color: ${(props) => props.theme.primary7};
  }

  .search-link {
    align-self: end;
    font-size: 0.9rem;
    margin-top: 15px;
  }
`;
