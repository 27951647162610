import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'src/client/redux';
import { usePromotions } from 'src/client/helpers/usePromotions';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';
import { HIDE_GUEST_GATEWAY_COOKIE } from 'src/shared/constants/cookies';

type PopupState = {
  // The MetArt User Gateway
  showGuestGateway: boolean;
};

export const usePopupBanners = () => {
  const { user } = useSelector((state) => state.auth);
  const { config } = useSelector((state) => state.app);

  const [cookies, setCookie] = useCookies([HIDE_GUEST_GATEWAY_COOKIE]);
  const { isPromoActive } = usePromotions();
  const showSfwSite = useShowSfwSite();

  // Everything used in this computed state is avaliable on the first render,
  // so there is no need to use any effects to calculate this state.
  const computeInitialState = () => {
    // This is the MetArt User Gateway (not the free access return modal)
    const showGuestGateway = Boolean(
      !cookies[HIDE_GUEST_GATEWAY_COOKIE] &&
        config.hasMemberUpgrades &&
        !isPromoActive &&
        window.innerWidth >= 1170 &&
        // user exists
        !!user &&
        // no valid subscription to the current site
        !user.validSubscription &&
        // at least one expired subscription or any other valid subscription
        (user.expiredSubscriptions.length > 0 || user.hasAnyValidSubscription) &&
        // not in sfw mode
        !showSfwSite,
    );

    // Find the first open modal and return the key
    // These modals are in order of priority, and only one the first one found
    // with a true value will be active.
    return Object.entries({
      showGuestGateway,
    }).find(([, value]) => value)?.[0] as keyof PopupState;
  };

  const [activeModal, setActiveModal] = useState<keyof PopupState | null>(() => computeInitialState());

  type CookieName = typeof HIDE_GUEST_GATEWAY_COOKIE;

  const closeModal = (cookieName: CookieName) => () => {
    const expires = new Date(new Date().getTime() + 86400000); // 24 Hrs
    setCookie(cookieName, true, { path: '/', expires });
    setActiveModal(null);
  };

  return [activeModal, closeModal] as const;
};
