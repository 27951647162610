import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';
import { useIntl } from 'react-intl';

import Fader from '@tovia/man-ui/lib/components/Fader/Fader';
import LineBehind from '@tovia/man-ui/lib/components/LineBehind/LineBehind';

export const TestimonialsClass = (props) => {
  const { className, content, show } = props;
  const intl = useIntl();

  if (!show) return null;

  return (
    <section className={`${className} testimonials`}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <LineBehind
              label={intl.formatMessage({ id: 'splash.testimonials.title', defaultMessage: 'TESTIMONIALS' })}
            />
          </div>
        </div>
        <Fader itemsToShow={3} speed={5000}>
          {content.map((ttml) => (
            <div className="col-md-4" key={ttml.UUID}>
              <div className="quote">
                <span className="quote-large">
                  <span className="begin-quote">&quot;</span>
                  {ttml.title}
                </span>
                <p className="paragraph">
                  {ttml.quote}
                  <span className="end-quote">&quot;</span>
                </p>
                <div className="quote-footer">- {ttml.name}</div>
              </div>
            </div>
          ))}
        </Fader>
      </div>
    </section>
  );
};

TestimonialsClass.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  theme: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  content: PropTypes.arrayOf(
    PropTypes.shape({
      UUID: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      quote: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

TestimonialsClass.defaultProps = {
  className: '',
  show: true,
};

export default withTheme(styled(TestimonialsClass)`
  &.testimonials {
    background-color: ${(props) => props.theme.splashOptional1};
  }

  & .quote {
    text-transform: uppercase;
    font-size: 0.85rem;
    margin: 0 10px;

    @media (max-width: 991px) {
      margin: 0 10px 20px 20px;
    }
  }

  & .quote-large {
    font-weight: 700;
    font-size: 2.6rem;
    line-height: 30px;

    @media (max-width: 1199px) {
      font-size: 1.43rem;
      line-height: 26px;
    }

    @media (max-width: 767px) {
      font-size: 1.6rem;
      line-height: 25px;
    }

    @media (max-width: 639px) {
      font-size: 1.43rem;
      line-height: 20px;
      font-weight: 500;
    }
  }

  & .paragraph {
    color: ${(props) => props.theme.footerBarPrimary2};
  }

  & .begin-quote,
  & .end-quote {
    font-style: italic;
    font-weight: 700;
    display: inline-block;
    position: absolute;
    padding-right: 1em;
  }

  & .begin-quote {
    font-size: 2.6rem;
    margin-left: -27px;
  }

  & .end-quote {
    font-size: 2.15rem;
    line-height: 23px;
    margin-left: 5px;
    margin-top: 1px;
  }

  & .quote-footer {
    font-weight: 700;
    padding-top: 10px;
    margin-bottom: 10px;
    color: ${(props) => props.theme.footerBarPrimary2};
  }

  .quote,
  .begin-quote,
  .end-quote,
  .quote-large {
    :not(.paragraph) {
      color: ${(props) => props.theme.footerBarPrimary3};
    }
  }
`);
