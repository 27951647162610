import React from 'react';
import moment from 'moment';
import { NetworkLink } from '../components/buttons/NetworkLink';

export const modelLinks = (models, isNetworkWideGallery = false) => {
  if (models) {
    return models
      .reduce(
        (r, model) => [
          ...r,
          <NetworkLink isNetworkWideGallery={isNetworkWideGallery} to={model.path} key={model.UUID}>
            {model.name}
          </NetworkLink>,
          ' & ',
        ],
        [],
      )
      .slice(0, -1);
  }
  return <span />;
};

export const secondsToRuntime = (seconds: number | undefined) => {
  if (!seconds) {
    return '';
  }

  return moment()
    .startOf('day')
    .seconds(seconds)
    .format(`${seconds > 3600 ? 'HH:' : ''}mm:ss`);
};

export const runtimeInMinutes = (runtime) => runtime.slice(-5);

export const formatViews = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
  return 0;
};

// new API
export const formatDateDefaultOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
  timeZone: 'America/Los_Angeles',
};

export const obj2queryString = (obj) =>
  `?${Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')}`;

// Help us parse the names from url (for ex. mila-azul will turn to Mila Azul)
export const parseSlug = (str) =>
  str
    .split(/-/g)
    .map((s) =>
      s.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()).replace(/[_-]/g, ' '),
    )
    .join(' ');

export const inToFt = (n) => {
  const realFeet = n / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return `${feet}'${inches}''`;
};

export const cmToIn = (n) => Math.round(n * 0.393701);

export const mToFeet = (n) => inToFt(cmToIn(n));

export const kgToLbs = (n) => Math.round(n * 2.20462);

export const formatDescription = (description: string) =>
  description
    .replace(/\r/g, '')
    .split('\n')
    .filter((text) => text.length)
    .map((text) => (
      <p dangerouslySetInnerHTML={{ __html: text }} key={text.substr(0, 10).replace(/\s+/g, '_')} /> // eslint-disable-line react/no-danger
    ));

export const displayModelStat = ({ statType, value, measurements }) => {
  const measurementsInInches = (cmSize) => cmSize.replace(/(\d+)/g, (match) => Math.round(match * 0.3937));

  const metricConverter = {
    height: (height) => `${height} cm`,
    weight: (weight) => `${weight} kg`,
  };

  const imperialConverters = {
    height: (height) => mToFeet(height),
    weight: (weight) => `${kgToLbs(weight)} lbs`,
    measurements: (x) => measurementsInInches(x),
  };

  const converter = measurements === 'imperial' ? imperialConverters[statType] : metricConverter[statType];

  return (converter && converter(value)) || value;
};
