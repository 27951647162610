import React from 'react';
import styled from 'styled-components/macro';
import Slider from '@tovia/man-ui/lib/components/Slider/Slider';

type Teaser = {
  name: string;
  models: string[];
  galleryUUID: string;
  imgSrc: string;
  textColor: string;
};

type Props = {
  settings: Record<string, string | number | boolean>;
  teasers: Teaser[];
  className?: string;
  hideSlider: () => void;
};

export const StraplezSlider = (props: Props) => {
  const { className, settings, teasers, hideSlider } = props;

  const orderedTeasers: Teaser[] = [];

  teasers.map((teaser) => {
    if (teaser.galleryUUID == '995F97508E6DC5F409C65F8A51D57D82') {
      teaser.textColor = '#e52b31';
      orderedTeasers[0] = teaser;
    }
    if (teaser.galleryUUID == '9C76485315942894A982FC54FE2BCFDE') {
      teaser.textColor = '#1cabf9';
      orderedTeasers[1] = teaser;
    }
    if (teaser.galleryUUID == '732355A08073B864A512CE25A1F2E518') {
      teaser.textColor = '#f6244b';
      orderedTeasers[2] = teaser;
    }
  });

  const renderContent = (orderedTeasers) =>
    orderedTeasers.map((teaser) => {
      const { name, models, galleryUUID, imgSrc, textColor } = teaser;
      return (
        <div key={galleryUUID}>
          <TitleWrap>
            {name && <SlideTitle style={{ color: `${textColor}` }}>{name}</SlideTitle>}
            <ModelNames>
              <span style={{ fontWeight: 400 }}>Starring</span> {models.length ? `${models.join(', ')}` : models}
            </ModelNames>
            <SlideButton type="button" onClick={hideSlider}>
              Watch for Free
            </SlideButton>
          </TitleWrap>
          <HeroSection className="hero" imgSrc={imgSrc} />
        </div>
      );
    });

  return (
    <Slider settings={settings} className={`${className} slider-wrap`}>
      {renderContent(teasers)}
    </Slider>
  );
};

export default styled(StraplezSlider)`
  &.slider-wrap {
    position: relative;
    height: 720px;
    background: #181818;
    z-index: 2;
    margin: 0 auto;
    padding: 0;
    width: 100%;

    @media (max-width: 430px) {
      height: 280px;
    }

    .slider {
      position: relative;
      display: block;
      height: 100%;
      padding: 0 !important;

      &:hover .slick-arrow {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .slick-slider,
    .slick-track,
    .slick-list {
      height: 100%;
    }

    .slick-track {
      margin: 0;
    }

    .slick-arrow {
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 70px;
      border: 0 none;
      border-radius: 4px;
      background-color: transparent;
      background-size: contain;
      color: transparent;
      transition: 0.5s opacity ease;
      opacity: 0;
      pointer-events: none;
      z-index: 3;

      @media (max-width: 1024px) {
        width: 24px;
        opacity: 1;
      }
      @media (max-width: 430px) {
        width: 20px;
        opacity: 0;
      }
    }

    .slick-prev {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAABACAMAAAC5rg4sAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAALaADAAQAAAABAAAAQAAAAAAEwpSWAAABGlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+zRnCkAAAAXnRSTlMAAQIDBAUGBwgJCgsMDQ4PEBESExQVFhcYGRobHB0eHyAhIiQlJicpKissLi8wMTIzNDY3ODk7PT4/QEFCQ0RGR0hJSktMTU5PUFJTVFZXWFlaW1xdXl9gYWJjZGVmYtz7mQAAAa5JREFUGBmNwYlS02AUBtAvSxcktgqt1AWEFkVAqoil1KUsUiF0AU23NPne/zVkZBjInbl/cw5SWPL9AtJ6PSH7OaTzPuKtjo009nmnhfmcH7xXxzz5Cz6owqww4COzFZisjJgwdKCrzih4UH2ktAeN3aQwq0KTOaMwfAHNYpdCz4Nm+S+Fsyw0q1MKhzY0WzGT4h1orAMK0zVo3DaFP0vQ5C8p+E+gKV5TaLvQVMYUvljQ1CImRe+gqlMYv4TGblEYFKHJnlPo5KDxehRaNjSlgMIeVG9DJoUbUG3HTArK0FgNCl0PGveYwmkGqiaFhgVdk8JXCzr3hMJJBjrrkMLVIgx2KAQlGKyHTArXYVAeUtiFwdM+hSMbumyHwnkOOvsbhX4BBp8pjCow2IyYFG3C4NWEwj4Mnt1Q+OlAt+BT8Begc48p3DyHzmpQmLyBwXbMpPgDDNZCCg0LulJA4TQDndej0PWgy/6iEJShs48ohBswqFPahUEtouDBoDJmwsiBSfGaj0QVmOUv+aCGedw2733CfNYB73xHKlsxb/12kM7qlBzkkdZyr1vAf/8AKvBpzLyDv3gAAAAASUVORK5CYII=);
      left: 15px;
      @media (max-width: 430px) {
        left: 5px;
      }
      :hover {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAABACAYAAACOcP4eAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAALaADAAQAAAABAAAAQAAAAAAEwpSWAAADZElEQVRoBc2aW4hNURjH584wBpNJuWaYlFwaJGXiQUI0pSgyKS+TkCKpeSAp5YUHHiQkDxRF8qBc4wHz4pIiEiXXkDTul3H8/uxde7TPYL69v32++rf2Wed86/9rrXU663KKigoocrncWHQVtaERBYQWjwLkTPQWhXGHh37xny6AWuCWo68hbaQ8z3N5ASB2RgBqSwQy7nF/54wMX0FXgQ7FUcbUtWaI+tsaqBp0KQYuX9UP3liUGTjmdehuProu6j/x3lR3cJmil12A/e2tF3yghxs4ZguRessSHSQPc4HGaD3SvLSE8jekDoxJKdptIQ1yP1Km/0XEpAqdCkwthebxFI8eHozRDQtpkHuLMv05jMl49DgwtRQapT4ePTwbo3YLaZC7i7LUA7gFo29G4O/kr/aALcZomxFW6RqhuR7APTE6IkdjPCJ/nAfwAIwuG2GV3oYGegDXY3RfjsY4Sn6lB3AjRq+NsErfioo9gBdj9FmOhvhC7rLUYWWAUSuyLno0QtNTB8akDO1D1tDCf5QHcDVGZ6y05F9A/T2Ah2KkBYs1NErpHwNgMhE9M9L6LNo1fIDOR++NwB/IX5D6dAiAV2GmRYslNEKTUgfGpARtt5AGuVr4D/EArsToeALAJ2mjKnXgYEoksfHcAXCJCzAmMkri97/DC/iXDz3UC51A1lAbvd3gMdMXcaeVmvxraJAbuIwwXIN09GSJJyQ3eIM3YaofB0vox6nJG3wyps8t1ORqxNZ5gw/H9Dayxh4aKHODx6wvOmelJv8s8rupwqwcHUDW0BVbnVuPywjDTVZq8l+had7gzZhqY2oJbYyXeoPPwPSNhTrI3ewNPhrjBwmAH6YN14ufWgx1pGWNKzRQ69brmGktfsxKTf5DNMYTPKldj/6BMMsNXEYYrkTW/aUO5Vd4g8/D9B2yhutOSD3egJ5aqcn323MGUyWp0ymf3X04H+mpanQ6gR7XqKV/jhIB14nr3gTA/U6sIvBJnG1rU7E2bNOlxDCJWwSBp3/tHO0RDBuR5b5Gf3DxW6uE8JjWo+7cjGlJ67sWD6FVYt6dO8gl0TYyeQb8f257N2YCGWcK+L/cqx+My828DvgWpMXSn3GRiorMAfMBADcHtUeo7/Fck+/zBVMP5AR0E11HI/OB/QQJDJysStYl2AAAAABJRU5ErkJggg==);
      }
    }

    .slick-next {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAABACAMAAAC5rg4sAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAALaADAAQAAAABAAAAQAAAAAAEwpSWAAABFFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+sfvwZAAAAXHRSTlMAAQIDBAUGBwgJCgsMDQ4PEBESExQVFxgZGhscHR4gISIjJCUmKSorLC4wMTIzNDY4OTo7PD0+P0BCQ0RFRkdISUpLTE9QUVJTVFVWV1hZWltcXV5fYGFiY2RlZkIYBA0AAAGuSURBVBgZjcENV9JgGAbge3N8CQIKipoaCpKaH2RlmR8kSkoIsWDO7f7//yOPHY/uOed5t+vCf6vD8wySqjyQ90UkMzvmk8kSknBu+eyxjnjWJV/sItYnvvpiw6zFt36mYLIQMuIuD4M6hXEFOvuMwsMadNYRhXAbBs2AwpEF3fKUwpkDXemewk0Wusw1hWEJOvsbhWkNBnsUgk0YvPcp7MFg0aVwYkNX6FO4SkOX6lDoF6Czjim4VRi0Qkb56zB45zEq/ACD+RGFtgVdrkfhwoHO+UGhl4PBIYVRGQaNgFHeCgxqE0YFDRgUBxQOYJDpUmhCl+5SaEI1N6BwCNXShFFBE6qtR0Z5K1DtU/gzD83MKYVfs9BkbyhcOtCURxSOLWhWPQo7UG2HjPI3oLHaFP4uQpO6oPB7Dpr8LYXrNDRVl8J3G5oNn8I+VLsUggY09lcK3jI06S6FURma4oBCLwdNbULh3IFmK6DQtqA5oBC2oLFPKfjrUNUpuFXoKozqF2Cyw7eu0jD7zFcnNmLYHb74iHipOz4LNpFE3uWTaQ3JVH1yWEJSa+NOFvH+AWTYYZWYH7EwAAAAAElFTkSuQmCC);
      right: 30px;
      @media (max-width: 1024px) {
        right: 15px;
      }
      @media (max-width: 430px) {
        right: 5px;
      }
      :hover {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAABACAYAAACOcP4eAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAALaADAAQAAAABAAAAQAAAAAAEwpSWAAADtElEQVRoBc2aS0hUYRTHHR9kJpmkRVZUUiRCkuDCEowIEgpcVFBk4UrIwE0tqoVFQbQoilpEINGmIsIoqBY9aBW9KCZ6UUgF0oNMKno/nX4HHNBhnPvNPd+c8cCfuY9zvv/vXu9cv8fk5IwQsVhsCepB3ah0hLTRcxjIGvQZxeMpG5WjhzCBBLgpqDdOO+Szj+36hPTs7wJVhO4OAU3c/M6BldknHSQAJhedS6RMsj/Asc2jAhyQ/UkAUx06zMm8rMFj3p6KLsW5i5wrNgfHdB76mwIs6FSUhApL8FzMqpDmzzyf+tuA15iBY5aHTiNtyHu9yRI8guFuLTX1f1CbGbgYYdiKfiFt7KGBiBk8Zo2oX0tN/SlUaAk+G8NnHsCv08ZES/BSDK95AJee4hxL8AIMj3oAl8etwQxcjDDcgqTPoYmfFK+2Bl+B6TcNNbVy4VutweswfaMEl/IulG8Gj9k0dB9p4xINjLcEL8bwvJaa+gdouiW4DBoOeAB/TRu1ZuBihOEGJH0OTXyheLk1+FJMP2moqZU+/UZr8GpMXyjBpXwfkn6+TWBWjm4gbZyhgbE21LhgVohOaqmpv4UmmYGLEYY7PYDL4ybDQbvAsAVJn0MTHyheZEeNE4YN6L2GmloZTa2zBq/E9IkSXMq3W4NPwPSKB/D1du9DnhR5WjzcKZsBM3d3Jnrs4S7v8nDRwU0AugC9UwL/pr412M1DBkZr0A8l8EfqF3vACW4Co06kHVe+pI3qYDdlBiZj0HGkjTs0MFmJE1yOSRmSCRptyIpEUbCjMgOTKvRcS0v9QZT5VzEmsuYoXxhN/KO4Q3nv3MoxakPa4ZbMpzS7OSqyMJGB7V6kjbc0UKdAcSvFZBw6q6Wl/hGa4eaqyMKkAt1D2rhKAyUKFLdSTGrRKy0t9cdQgZurIguTZvQVaaNTgeFeCuUmJK8kTcjoo8XdNWQmJvnoiIZ0sFbGeY0hMdzLMClBlwdNNR/yX3Kuu3PITExmIR9jupu0Ux4Sw70Mk4WoD2mjmwYyP0uEyVqknaeQi5X5uMyP5TDZgbQhM5/t7n/XkJmYyIL+CS0t9TLHvCwkRnplGK3yAGw6my+d7R40kN6lDst+yF59JBKJDjua6R3udEfIu227QpV4I4A+lCZ4F/l2a4GJwLIPgHwhLziAy7TAtmRtZOUYMLI+GE0BLu9w2/VtlzsB1FSUrM/cz3HbXxK4AMdzgJPOvrx349HDht1vNuIg6X4C2YR6kSwnl6Vbn8n8/4ZREsknsz2CAAAAAElFTkSuQmCC);
      }
    }

    .slick-dots {
      list-style: none;
      display: flex !important;
      position: absolute;
      bottom: 16px;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0;

      li {
        position: relative;
        display: inline-block;
        height: 18px;
        width: 18px;
        margin: 0 10px;
        padding: 0;
        cursor: pointer;
        background: white;
        border-radius: 50%;
        cursor: pointer;
        opacity: 0.25;
        @media (max-width: 1024px) {
          width: 16px;
          height: 16px;
        }
        @media (max-width: 430px) {
          width: 12px;
          height: 12px;
        }
      }

      li.slick-active {
        opacity: 0.75;
      }

      button {
        color: transparent;
        background: transparent;
        border: 0;
        outline: none;
      }
    }
  }
`;

const TitleWrap = styled.div`
  position: absolute;
  color: #ffffff;
  left: 0;
  bottom: 280px;
  text-align: center;
  width: 60%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 430px) {
    bottom: 76px;
  }
`;

const SlideTitle = styled.span`
  font-size: 64px;
  font-family: Urbanist, 'Open Sans', sans-serif;
  font-weight: 800;
  max-width: 600px;
  margin: 0 auto 28px;
  @media (max-width: 1024px) {
    font-size: 48px;
    margin-bottom: 16px;
  }
  @media (max-width: 430px) {
    font-size: 28px;
    margin-bottom: 12px;
  }
`;

const SlideButton = styled.button`
  background: ${({ theme }) => theme.primaryBtn1Bg};
  color: #fff;
  display: block;
  width: 380px;
  height: 80px;
  border: 0;
  border-radius: 3px;
  font-size: 32px;
  font-family: Urbanist, 'Open Sans', sans-serif;
  font-weight: 700;
  line-height: 1.2;
  outline: none;
  z-index: 2;
  margin: 0 auto;

  transition: 0.3s;
  :hover,
  :focus {
    background: ${({ theme }) => theme.primaryBtn1HoverBg};
  }

  @media (max-width: 1024px) {
    font-size: 18px;
    width: 220px;
    height: 60px;
  }
  @media (max-width: 430px) {
    font-size: 14px;
    width: 160px;
    height: 40px;
  }
`;

const ModelNames = styled.span`
  font-size: 20px;
  font-family: Urbanist, 'Open Sans', sans-serif;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 32px;
  display: block;
  @media (max-width: 1024px) {
    font-size: 18px;
    margin-bottom: 24px;
  }
  @media (max-width: 430px) {
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

const HeroSection = styled.div<{ imgSrc: string }>`
  height: 720px;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%),
    url(${(props) => props.imgSrc});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;

  @media (max-width: 2160px) {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 100%),
      url(${(props) => props.imgSrc});
  }

  @media (max-width: 1024px) {
    background-image: url(${(props) => props.imgSrc});
    background-position: center bottom;
    background-size: cover;
    display: none !important;
    background-color: rgba(0, 0, 0, 0.4);
    background-blend-mode: multiply;
    display: block !important;
  }

  @media (max-width: 430px) {
    height: 280px;
  }
`;
