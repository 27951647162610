import { initialState as defaultAppState } from '../redux/modules/app';
import { initialState as defaultSettingsState } from '../redux/modules/settings';
import { initialState as defaultNewsletterState } from '../redux/modules/newsletter';
import { initialState as defaultSitesState } from '../redux/modules/sites';

export const setupInitialReduxState = (appData) => {
  const initialState = {
    app: {
      ...defaultAppState,
      ...appData?.initialState.app,
      version: window.version,
    },
    theme: { ...appData?.initialState.theme },
    site: { ...appData?.initialState.site },
    sites: {
      ...defaultSitesState,
      ...appData?.initialState.sites,
    },
    settings: {
      ...defaultSettingsState,
      ...appData?.initialState.settings,
    },
    newsletter: {
      ...defaultNewsletterState,
    },
  };

  return initialState;
};
