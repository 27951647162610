import { CamModel } from '@tovia/man-protos/dist/types/cams.types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { CamTrackingPosition } from 'src/client/constants/camTracking';
import { useGenerateCamUrlWithParams } from 'src/client/helpers/urls/useGenerateCamUrlWithParams';
import { CamProvider } from 'src/shared/constants/cams';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';
import { BlurrableImage } from 'src/client/components/ui/BlurredImage';

type Props = {
  cam: CamModel;
  camTrackingPosition?: CamTrackingPosition;
  className?: string;
  eventContentType: string;
  provider: CamProvider;
};

export const CameraThumbnailCard = ({
  cam: { age, country, name, thumb },
  className,
  provider,
  camTrackingPosition,
  eventContentType,
}: Props) => {
  const [flagImgSrc, setFlagImgSrc] = useState<string | undefined>(
    country && `https://static.metart.com/view/images/flags/${country.toLowerCase()}.png`,
  );

  const generateCamUrlWithParams = useGenerateCamUrlWithParams({
    trackingPosition: camTrackingPosition,
    provider,
  });
  const showSfwSite = useShowSfwSite();

  const flagImageOnError = () => {
    setFlagImgSrc(undefined);
  };

  const onCardClick = () => {
    window.dataLayer.push({
      event: 'select_content',
      content_type: eventContentType,
      item_id: name,
    });
  };

  return (
    <CameraThumbnailCardWrapper
      className={className}
      href={generateCamUrlWithParams(`/cams/${name}`)}
      target="_blank"
      rel="nofollow noopener noreferrer"
      onClick={onCardClick}
    >
      <ImageWrapper>
        <CameraThumbnailImage src={thumb} alt={name} $isBlurred={showSfwSite} />
      </ImageWrapper>
      <CameraThumbnailModelInfo>
        <OnlineIndicator>&#9679;</OnlineIndicator>
        <ModelName>{name}</ModelName>
        <ModelAge hasFlag={!!flagImgSrc}>({age})</ModelAge>
        {flagImgSrc && <FlagImg alt={country} src={flagImgSrc} onError={flagImageOnError} />}
      </CameraThumbnailModelInfo>
    </CameraThumbnailCardWrapper>
  );
};

export const CameraThumbnailCardWrapper = styled.a`
  overflow: hidden;
  max-width: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.primary6};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 75%;
  position: relative;
  flex-grow: 1;
`;

const CameraThumbnailImage = styled(BlurrableImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 4 / 3;
`;

const CameraThumbnailModelInfo = styled.div`
  display: flex;
  align-items: center;

  padding: 5px;
  color: ${(props) => props.theme.primary4};
  font-size: 12px;
`;

const ModelName = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.25em;
`;

const ModelAge = styled.span<{ hasFlag: boolean }>`
  ${(props) =>
    props.hasFlag &&
    css`
      margin-right: 10px;
    `}
`;

const OnlineIndicator = styled.span`
  font-size: 12pt;
  margin-right: 4px;
  position: relative;
  top: -1px;
  color: #55ff00;
`;

const FlagImg = styled.img`
  margin-left: auto;
`;

export const MobileMenuCameraThumbnailCard = styled(CameraThumbnailCard)`
  width: 160px;
  height: 125px;
  position: relative;

  ${CameraThumbnailModelInfo} {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 0 2px 2px;
    font-size: 0.75rem;
    color: #fff;

    :not(${OnlineIndicator}) {
      text-shadow: 1px 1px 3px #000;
    }
  }

  ${CameraThumbnailImage} {
    height: 100%;
  }

  // Override mobile menu applied styles
  &&,
  &&:first-of-type,
  &&:hover {
    padding: 0;
    border: none;
    font-weight: normal;
  }
`;

export const LiveGirlsPopoverCamCard = styled(CameraThumbnailCard)`
  max-height: 180px;
`;

export const SideBarCamCard = styled(CameraThumbnailCard)`
  max-width: none;

  ${CameraThumbnailImage} {
    flex-grow: 0;
    object-fit: contain;
    height: auto;
  }
`;
