import React from 'react';
import styled from 'styled-components/macro';
import Placeholder from '@tovia/man-ui/lib/components/Placeholder/Placeholder';

const SortMenuComponent = () => {
  const { Line } = Placeholder;

  return (
    <SortMenuWrap>
      <Line short />
    </SortMenuWrap>
  );
};

const SortMenuWrap = styled(Placeholder)`
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
  width: auto;

  .line {
    width: 25%;
  }
`;

export default SortMenuComponent;
