import { createStore, applyMiddleware, compose, Store as StoreBase, Unsubscribe } from 'redux';
import createSagaMiddleware, { END, SagaMiddleware } from 'redux-saga';
import createMiddleware from '../middleware/clientMiddleware';
import reducer, { RootState } from '../modules/reducer';

interface Store extends StoreBase {
  runSaga: SagaMiddleware['run'];
  close: () => END;
  getState: () => RootState;
  subscribe: (listener: () => void) => Unsubscribe;
}

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export default function configureStore(
  data = {},
  options: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSagaError?: (error: Error, errorInfo: any) => void;
  } = {},
): Store {
  // Sync dispatched route actions to the history
  const sagaMiddleware = createSagaMiddleware({
    onError: options.onSagaError
      ? options.onSagaError
      : (error) => {
          // eslint-disable-next-line no-console
          console.error('Saga Error:', error);
        },
  });

  const middlewares = [createMiddleware(), sagaMiddleware];
  const store: Store = createStore(reducer, data, composeEnhancers(applyMiddleware(...middlewares)));

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((module as any).hot) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (module as any).hot.accept('../modules/reducer.ts', () => {
        store.replaceReducer(reducer);
      });
    }
  }

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}
