import { take, call, fork, select, put } from 'redux-saga/effects';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
// import { load as loadItemDecorators } from './itemDecorators';
import { loadGenerator as loadAuthGenerator } from './auth';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';

const LOAD_SAGA = 'man-site/favorites/LOAD_SAGA';
const LOAD_FAVORITES = 'man-site/favorites/LOAD_FAVORITES';
const LOAD_SUCCESS_FAVORITES = 'man-site/favorites/LOAD_SUCCESS_FAVORITES';
const LOAD_FAIL_FAVORITES = 'man-site/favorites/LOAD_FAIL_FAVORITES';

export const pathTypeToFavorited = {
  galleries: 'favoritedGalleries',
  images: 'favoritedImages',
  models: 'favoritedModels',
  movies: 'favoritedMovies',
  photographers: 'favoritedPhotographers',
};

export const pathTypeToRated = {
  galleries: 'ratedGalleries',
  images: 'ratedImages',
  models: 'ratedModels',
  movies: 'ratedMovies',
  photographers: 'ratedPhotographers',
};

export const pathTypes = {
  favorites: pathTypeToFavorited,
  ratings: pathTypeToRated,
};

export const FAVORITE_TYPES = Object.keys(pathTypeToFavorited);
export const RATING_TYPES = Object.keys(pathTypeToRated);

const endpoint = constructUrl(urls.get.favoritesAndRatings);

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  items: [],
  itemType: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_FAVORITES:
      return {
        ...initialState,
        loading: true,
        loaded: false,
        items: [],
      };
    case LOAD_SUCCESS_FAVORITES:
      return {
        ...state,
        items: action.result,
        itemType: action.itemType,
        loading: false,
        loaded: true,
      };
    case LOAD_FAIL_FAVORITES:
      return {
        ...state,
        items: initialState.items,
        itemType: '',
        loading: false,
        loaded: true,
        error: action.error,
      };
    default: {
      return state;
    }
  }
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const { objectTypes, itemType, page, first, queryType } = params;

  const getState = (state) => ({
    favoritesAndRatings: state.favoritesAndRatings,
    user: state.auth.user,
  });
  const currentState = yield select(getState);
  if (!currentState.favoritesAndRatings.loading && currentState.user) {
    const loadFunc = getRequestFunc(
      [LOAD_FAVORITES, LOAD_SUCCESS_FAVORITES, LOAD_FAIL_FAVORITES],
      (client) =>
        client.get(endpoint, {
          params: {
            objectTypes,
            page,
            first,
            queryType,
          },
        }),
      {
        itemType,
      },
    );
    yield call(loadFunc);

    const newState = yield select((state) => ({ favoritesAndRatings: state.favoritesAndRatings.items }));
    if (newState.favoritesAndRatings && newState.favoritesAndRatings.length > 0) {
      const objectUUIDs = newState.favoritesAndRatings.map((item) => item.UUID);

      yield put(
        loadFavoriteInfo({
          objectUUIDs,
        }),
      );

      yield put(
        loadRatingInfo({
          objectUUIDs,
        }),
      );
    }
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield call(loadAuthGenerator);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
