import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { useSitePickerList } from '../helpers/useSitePickerList';
import { MobileLink } from './MobileLink';
import styled from 'styled-components/macro';

import { useSelector } from '../../../redux';
import { ActiveNestedItem } from '../HeaderProvider';
import { DiscountRibbon } from 'src/client/components/ui/DiscountRibbon';
import { MobileExpandableMenuItem } from './MobileExpandableMenuItem';

export const MobileSitePicker = () => {
  const sitesList = useSitePickerList();
  const sitePickerIcon = useSelector((state) => state.app.icons?.sitePicker) as IconDefinition;

  return (
    <MobileExpandableMenuItem
      activeNestedItem={ActiveNestedItem.SITEPICKER}
      icon={sitePickerIcon}
      menuLabel="Network Sites"
    >
      {sitesList.map((site) => (
        <StyledMobileLink
          href={site.fullAffiliateLink}
          className="site-item"
          key={site.abbreviation}
          label={site.name}
          extraContent={<DiscountRibbon {...site} />}
        />
      ))}
    </MobileExpandableMenuItem>
  );
};

const StyledMobileLink = styled(MobileLink)`
  display: flex;
  justify-content: flex-start;
`;
