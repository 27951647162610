import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Button } from '@tovia/man-ui/lib/components/Button/Button';
import { Modal } from './Modal/Modal';

export function ModelSubscriptionModal(props) {
  const { showModal, className, onToggleFavor, hideModal } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(showModal);
  }, [showModal]);

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        onToggleFavor(false);
        hideModal();
      }}
      dialogClassName={`${className} subscription-modal`}
      modalBodyClassName="subscription-modal-body"
      className={`${className}`}
    >
      <span className="subscription-modal-title">Subscription</span>
      <span className="subscription-modal-description">
        Click OK to receive an email when this model has a new set scheduled. You can always unsubscribe from this email
        in the future by going to Favorites.
      </span>
      <div className="subscription-modal-footer">
        <Button
          className="submit-button btn btn-primary"
          onClick={() => {
            onToggleFavor(true);
            hideModal();
          }}
        >
          Ok - Add Subscription
        </Button>
        <Button
          className="btn close-button"
          onClick={() => {
            onToggleFavor(false);
            hideModal();
          }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
}

ModelSubscriptionModal.propTypes = {
  className: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  onToggleFavor: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

ModelSubscriptionModal.defaultProps = {
  className: '',
};

export default styled(ModelSubscriptionModal)`
  max-width: 365px;

  .man-modal-body {
    padding: 24px 0 24px 0;
  }

  .subscription-modal-title {
    font-size: 22px;
    display: block;
    margin-bottom: 10px;
    margin-top: 0;
    text-align: center;
    color: #000;
    letter-spacing: 0;
  }

  .subscription-modal-description {
    color: #999ca1;
    display: block;
    margin: 12px 0 15px;
    font-size: 13px;
    letter-spacing: 0;
    text-align: center;
  }

  .subscription-modal-footer {
    margin-top: 15px;
    padding: 19px 20px 20px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-evenly;

    .close-button {
      border: 1px solid #ccc;
      padding: 0 20px;
    }
  }
`;
