import styled from 'styled-components/macro';
import { Dropdown, DropdownButton as RBDropdownButton } from 'react-bootstrap';

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  column-gap: 5px;
`;

export const DropdownButton = styled(RBDropdownButton)`
  .dropdown-menu {
    min-width: auto;
  }

  .btn-primary {
    background-color: transparent;
    padding: 5px;

    &:focus {
      background-color: transparent;
    }
  }
  svg {
    color: ${(props) => props.theme.primary4};
  }
`;

export const DropdownItem = styled(Dropdown.Item)`
  white-space: nowrap;
`;
