import { GALLERY_HIGH, MOVIE_4K, MOVIE_HD, MOVIE_SD } from './constants/qualityTypes';
import { PurchaseProductPPS } from '@tovia/man-protos/dist/types/billing.types';

export const addDashesToUUID = (u: string) =>
  u.replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/, '$1-$2-$3-$4-$5').toLowerCase();

export function encodeDisplayName(displayname: string) {
  return displayname.replace(/\s/g, '+');
}

export function decodeDisplayName(displayname: string) {
  return displayname.replace(/\+/g, ' ');
}

type purchaseProductFn = (
  objectUUID: string,
  quality: string,
  siteUUID: string,
  customPrices?: {
    uhd?: string;
    hd?: string;
    sd?: string;
    high?: string;
  },
  isStaffSelection?: boolean,
) => PurchaseProductPPS | null;

export const generatePurchaseProduct: purchaseProductFn = (
  objectUUID,
  quality,
  siteUUID,
  customPrices = {},
  isStaffSelection = false,
) => {
  let qualityTypeID;
  let price = '';

  const finalPrices = isStaffSelection
    ? {
        uhd: '11.99',
        hd: '9.99',
        sd: '7.99',
      }
    : customPrices;

  switch (quality.toUpperCase()) {
    case 'UHD':
      qualityTypeID = MOVIE_4K;
      price = finalPrices?.uhd || '5.99';
      break;
    case 'HD':
      qualityTypeID = MOVIE_HD;
      price = finalPrices?.hd || '4.99';
      break;
    case 'SD':
      qualityTypeID = MOVIE_SD;
      price = finalPrices?.sd || '3.99';
      break;
    case 'HIGH':
      qualityTypeID = GALLERY_HIGH;
      price = finalPrices?.high || '2.99';
      break;
    default:
      qualityTypeID = null;
  }

  if (!qualityTypeID) {
    return null;
  }

  return {
    initialPrice: price,
    initialPeriod: 1,
    siteUUIDs: [addDashesToUUID(siteUUID)],
    currency: 'USD',
    objectUUID: addDashesToUUID(objectUUID),
    qualityTypeID,
  };
};
