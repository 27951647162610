import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled, { withTheme } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { JoinButton } from '../../buttons/JoinButton';

export const LastChanceSectionComponent = (props) => {
  const { show, className, site, discoverMoreLink } = props;
  const intl = useIntl();

  if (!show) return null;

  return (
    <section className={`${className} last-chance-wrapper`}>
      <div className="container text-center last-chance-buttons">
        <JoinButton className="btn btn-primary last-chance-button-join">
          {intl.formatMessage({ id: 'splash.lastChanceSection.buttons.signup', defaultMessage: 'Sign Up Today' })}
        </JoinButton>
        <NavLink to={discoverMoreLink} className="btn btn-secondary last-chance-button-skip">
          {intl.formatMessage({
            id: 'splash.lastChanceSection.buttons.discoverMore',
            defaultMessage: 'Discover More',
          })}{' '}
          {site.name}
        </NavLink>
      </div>
    </section>
  );
};

LastChanceSectionComponent.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  site: PropTypes.object.isRequired,
  discoverMoreLink: PropTypes.string.isRequired,
};

// Same approach for defaultProps too
LastChanceSectionComponent.defaultProps = {
  className: '',
  show: true,
};

export default withTheme(styled(LastChanceSectionComponent)`
  &.last-chance-wrapper {
    padding: 45px 0;

    @media (max-width: 767px) {
      padding: 0;
    }

    .last-chance-buttons {
      text-align: center;
    }

    .last-chance-buttons a.btn {
      min-width: 270px;
      box-sizing: border-box;
      padding: 15px;
      //width: 270px;
      //padding: 15px 0;
      border-radius: 3px;
      font-size: 1.15rem;
      margin: 30px 20px;
      transition: 0.3s color;
      display: inline-block;

      :active,
      :hover,
      :focus,
      :visited {
      }
    }

    a.last-chance-button-join {
      color: ${(props) => props.theme.primaryBtn1Text};

      @media (max-width: 767px) {
        margin: 13px auto;
      }
    }

    a.last-chance-button-skip {
      color: ${(props) => props.theme.secondaryBtn1Text};
      margin-left: auto;
      margin-right: auto;
    }

    a.last-chance-button-skip:not(.btn) {
      text-decoration: none;

      :hover,
      :focus,
      :visited {
        text-decoration: none;
      }
    }
  }
`);
