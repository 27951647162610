import { Direction, ModelOrderBy } from '@tovia/man-protos/dist/types/content.types';

type SortType = {
  id: string;
  title: string;
  order: keyof typeof ModelOrderBy;
  direction: keyof typeof Direction;
  intlID: string;
  requiresAuth?: boolean;
};

export const SORT_TYPE_TOP_RATED: SortType = {
  id: 'top',
  title: 'Top Rated',
  order: 'RATING',
  direction: 'DESC',
  intlID: 'dropdown.topRated',
};
export const SORT_TYPE_NEW: SortType = {
  id: 'new',
  title: 'Newest',
  order: 'DATE',
  direction: 'DESC',
  intlID: 'dropdown.newest',
};
export const SORT_TYPE_ALPHABETICAL: SortType = {
  id: 'all',
  title: 'Alphabetical',
  order: 'NAME',
  direction: 'ASC',
  intlID: 'dropdown.alphabetical',
};
export const SORT_TYPE_VIEWS: SortType = {
  id: 'viewed',
  title: 'Most Viewed',
  order: 'VIEWS',
  direction: 'DESC',
  intlID: 'dropdown.mostViewed',
};
const SORT_TYPE_FAVORITE: SortType = {
  id: 'favorited',
  title: 'Most Favorited',
  order: 'FAVORITES',
  direction: 'DESC',
  intlID: 'dropdown.mostFavorited',
};
export const SORT_TYPE_USER_RATED: SortType = {
  id: 'my-top',
  title: 'My Top Rated',
  order: 'MY_RATING',
  direction: 'DESC',
  intlID: 'dropdown.myTopRated',
  requiresAuth: true,
};
export const SORT_TYPE_MOST_APPEARANCES: SortType = {
  id: 'most-appearances',
  title: 'Most Appearances',
  order: 'MOST_APPEARANCES',
  direction: 'DESC',
  intlID: 'dropdown.mostAppearances',
};

export const SORT_TYPES: SortType[] = [
  SORT_TYPE_TOP_RATED,
  SORT_TYPE_NEW,
  SORT_TYPE_ALPHABETICAL,
  SORT_TYPE_VIEWS,
  SORT_TYPE_FAVORITE,
  SORT_TYPE_USER_RATED,
  SORT_TYPE_MOST_APPEARANCES,
];

export const MODEL_GALLERIES_PER_PAGE = 24;
