import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import CardBadgeList from './CardBadgeList';
import { BlurrableLazyImage } from '../BlurredImage';

type Props = {
  altText: string;
  animate?: boolean;
  badges?: ReactNode[];
  children?: ReactNode;
  className?: string;
  height?: string;
  imgSrc: string;
  lazy?: boolean;
  url?: string;
  width?: string;
  blurred?: boolean;
};

export const CardMedia = (props: Props) => {
  const {
    imgSrc,
    altText,
    animate = false,
    url,
    children,
    className,
    badges,
    lazy = false,
    width = '100%',
    height = '100%',
    blurred = false,
  } = props;

  const image = (
    <BlurrableLazyImage
      className="main-image"
      src={imgSrc}
      alt={altText}
      animate={animate}
      lazy={lazy}
      height={width}
      width={height}
      $isBlurred={blurred}
    />
  );

  const wrappedImage = url ? <Link to={url}>{image}</Link> : image;

  return (
    <CardMediaWrapper className={cx('card-media', className)}>
      {wrappedImage}
      {children}
      <CardBadgeList className="card-media-badges" badges={badges} />
    </CardMediaWrapper>
  );
};

const CardMediaWrapper = styled.div`
  position: relative;
  display: block;

  > a {
    width: 100%;
    height: 100%;
  }

  img.main-image {
    display: block;
    width: 100%;
  }

  .card-media-badges {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    transition: 0.3s background-color;

    background-color: rgba(0, 0, 0, 0.5);

    & .badge {
      color: white;
    }
  }
`;

export default CardMedia;
