import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartSolid, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components/macro';
import { useSelector } from '../../redux/modules/helpers/useSelector';

const PopupIconButton = styled.div`
  color: white;
  border-radius: 50%;
  border: 2px solid white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.72rem;
  align-self: center;
  margin: 5px;
  transition: transform 0.3s;
  cursor: pointer;

  &.favorited {
    color: #c10000;
  }

  &:hover:not(:active) {
    transform: scale(1.1);
  }
`;

const Border = styled.div`
  border-top: 30px solid rgba(0, 0, 0, 0.5);
  border-bottom: 30px solid transparent;
  border-left: 30px solid transparent;
  border-right: 30px solid rgba(0, 0, 0, 0.5);
`;

const CloseButton = styled.div`
  position: absolute;
  right: 0;
  padding: 10px;
  color: white;
  cursor: pointer;
`;

const OptionsMenu = styled.div<{ isOpen: boolean }>`
  ${(props) =>
    props.isOpen
      ? `
      left: 0;
  `
      : `left: -100%;
      `}
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  overflow: hidden;
  position: absolute;
  transition: left 0.8s;
  display: flex;
`;

const MenuButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-self: center;
  width: 100%;
`;

const Plus = styled.div`
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  color: #fff;

  &:hover .border {
    opacity: 0.8;
    border-top-color: rgba(0, 0, 0, 0.87);
    border-right-color: rgba(0, 0, 0, 0.87);
  }

  svg {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    width: 1em;
    height: 1.25em;
  }
`;

type Props = {
  onFavorite: () => void;
  setOpen: (open: boolean) => void;
  isFavorited?: boolean;
  isOpen?: boolean;
};

export default function GirdCardMenuOverlay(props: Props) {
  const { onFavorite, isOpen = false, setOpen, isFavorited = false } = props;
  const { config } = useSelector((state) => state.app);
  if (config.isWhiteLabel) {
    return null;
  }
  return (
    <div className={`${isOpen ? 'opened' : ''}`}>
      <OptionsMenu isOpen={isOpen}>
        <CloseButton onClick={() => setOpen(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <MenuButtons>
          <PopupIconButton
            className={`button ${isFavorited ? 'favorited' : ''}`}
            onClick={() => {
              onFavorite();
            }}
          >
            <FontAwesomeIcon icon={isFavorited ? faHeartSolid : faHeart} />
          </PopupIconButton>
        </MenuButtons>
      </OptionsMenu>
      {!isOpen && (
        <Plus
          onClick={(e) => {
            setOpen(true);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Border />
          <FontAwesomeIcon icon={faPlus} />
        </Plus>
      )}
      {/* <CardMenuOverlay */}
      {/*  icon={faChevronDown} */}
      {/*  onClick={(e) => { */}
      {/*    setOpen(true); */}
      {/*    e.preventDefault(); */}
      {/*    e.stopPropagation(); */}
      {/*  }} */}
      {/* /> */}
    </div>
  );
}
