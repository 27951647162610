import {
  take,
  call,
  fork,
  select,
  // put
} from 'redux-saga/effects';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

const LOAD = 'man-site/potd/LOAD';
const LOAD_SAGA = 'man-site/potd/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/potd/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/potd/LOAD_FAIL';
const limit = 120;

const endpoint = constructUrl(urls.get.potd);

const initialItems = [];

const initialState = {
  loading: false,
  loaded: false,
  items: initialItems,
  error: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...initialState,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default: {
      return state;
    }
  }
}

export function load() {
  return {
    type: LOAD_SAGA,
  };
}

/* SAGAS */
function* loadGenerator() {
  const getState = (state) => state.potd;
  const currentState = yield select(getState);
  if (!currentState.loaded && !currentState.loading) {
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint));
    yield call(loadFunc);
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    yield take(LOAD_SAGA);
    yield fork(loadGenerator, { params: limit });
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
