import { getDeviceTypeNoTablet } from '../utils/getDeviceType';
import { useSelector } from '../redux';
import { useMemo } from 'react';
import { addDashesToUUID } from 'src/shared/converters';
import { camTrackingDefinitions, CamTrackingPosition } from 'src/client/constants/camTracking';

const MA_MAN_UUIDS = ['e6b59510-4e34-11df-9879-0800200c9a66', 'a4c247f3-ed92-4a70-846d-2722fd8b50f3'];

export const useCamTrackingSearchParam = (position?: CamTrackingPosition): string | undefined => {
  const { userType } = useSelector((state) => state.auth);
  const site = useSelector((state) => state.site);

  return useMemo(() => {
    if (!position) return undefined;

    const siteUUID = addDashesToUUID(site.UUID);
    const deviceType = getDeviceTypeNoTablet();

    const trackingId = camTrackingDefinitions[position]?.find((tracking) => {
      if (tracking.deviceTypes && !tracking.deviceTypes.includes(deviceType)) {
        return false;
      }
      if (tracking.userTypes && !tracking.userTypes.includes(userType)) {
        return false;
      }

      if (tracking.siteExclusivity === 'MetartAndMetartNetwork' && !MA_MAN_UUIDS.includes(siteUUID)) {
        return false;
      } else if (
        tracking.siteExclusivity === 'EverySiteExceptMetartAndMetartNetwork' &&
        MA_MAN_UUIDS.includes(siteUUID)
      ) {
        return false;
      }
      return true;
    })?.id;

    if (trackingId) {
      return `1-${trackingId}`;
    }

    return undefined;
  }, [position, site.UUID, userType]);
};
