// @SEO_CLEANUP

import { MetArt } from 'src/client/constants/sites';

const seoModifiedModels = [
  '8607961C6BB78CB4A5375B8DEE2AB43D', // Jia Lissa
  'F399530B628B00D4610D886F484BC267', // Autumn Falls
  'D9F2C352369C8CB445ED520AA6A0A897', // Eva Elfie
  '066A7BC4EFA3C5D4C1874429E64E36CD', // Emily Bloom
  '634279FFF5EF9964313A2B61712FEF0E', // Leona Mia
  '9ABC857664A7BE74A91C8CFD67782635', // Tavia
  '3C03C85066504CE4999287050D10CCCD', // Aleksandrina
  '834EB4653E53B4A4A5014B5091B1AA85', // Lila Rouge
  '952616FA0FC0CFE4756F98B167EB5016', // Liana Luck
  '6F58D2A42862812495D6CE5144280149', // Elle Tan
  '27D5A7E3455262F4C949F1983A6E50A5', // Foxy Alissa
  '11A135120E3A5E140997EB1DE1C66B8C', // Ellie Luna
  'CA522A13E35158A41D27AC534B67414C', // Stella Cardo
  '8745978466DFF1F4418EE0533BCEA2C7', // Azzurra Moretti
  'DFBCB38BB8FD6AB40D44FDC46D5B0524', // Toree
  '198CC09EFD12E6244571964BAC9533D9', // Georgia
];

export const isSeoModifiedModel = (modelUUID: string | undefined, siteAbbreviation: string) => {
  if (!modelUUID) return false;
  return seoModifiedModels.includes(modelUUID) && siteAbbreviation === MetArt.abbreviation;
};
