import sanitizeHtml from 'sanitize-html';
import { htmlDecode } from 'js-htmlencode';

// replaces all spaces with - then lowercases
export const sanitizeLink = (str: string) => str && str.replace(/\s/g, '-').toLowerCase();

// remove all HTML except br tags.
export const sanitizeComment = (text: string) => {
  if (!text) {
    return '';
  }

  return sanitizeHtml(htmlDecode(text.replace(/\r/g, '').split('\n').join('<br />')), {
    allowedTags: ['br'],
  });
};
