import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { faTrophyAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Panel from '@tovia/man-ui/lib/components/Panel/Panel';
import PanelHeadline from '@tovia/man-ui/lib/components/Panel/PanelHeadline';
import { ModelResponse } from 'src/@types/app';

const PanelWrapper = styled.div`
  display: inline-block;

  & .sub-header {
    display: block;
    text-align: center;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.primary7};
  }

  & .rank {
    display: table;
    font-size: 3.2rem;
    font-weight: 300;
    padding-bottom: 15px;
    height: 44px;
    line-height: 44px;
    margin: 6px auto 0;

    > * {
      vertical-align: middle;
    }

    svg {
      font-size: 1.8rem;
      vertical-align: middle;
      margin-right: 10px;
      opacity: 0.5;
    }
  }

  & .ranks-buttons {
    padding-top: 10px;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${(props) => props.theme.primary3};
    margin-bottom: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    a {
      display: block;
      font-size: 0.85rem;
      width: 98px;
      height: 32px;
      line-height: 29px;
      text-align: center;
      border-width: 1px;
      border-style: solid;
      border-radius: 16px;
      cursor: pointer;
      color: ${(props) => props.theme.primary7};
      border-color: ${(props) => props.theme.primary3};
      transition: 0.3s background-color;

      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 0.72rem;
        width: 78px;
      }

      :focus,
      :active,
      :hover,
      :visited {
        text-decoration: none;
        background-color: transparent;
      }

      :hover {
        background-color: ${(props) => props.theme.primary3};
      }
    }

    a.active {
      text-decoration: none;
      background-color: ${(props) => props.theme.primary3};
    }
  }

  & .view-all {
    color: ${(props) => props.theme.primary5};

    :focus,
    :hover,
    :hover {
      color: ${(props) => props.theme.primary5};
    }
  }
`;

type Props = {
  ranks: ModelResponse['ranks'];
  type: string;
  className?: string;
};

export default function RankingDetailsPanel(props: Props) {
  const { ranks, type, className } = props;
  const [currentRank, setCurrentRank] = useState({
    period: 'week',
    rank: ranks.week,
  });
  const intl = useIntl();

  const changeTab = (nextRankPeriod) => {
    const nextRank = ranks[nextRankPeriod];
    setCurrentRank({ period: nextRankPeriod, rank: nextRank });
  };

  const activeClass = (rankPeriod) => (rankPeriod === currentRank.period ? 'active' : '');

  let urlType: string;

  switch (type) {
    case 'model':
      urlType = 'models';
      break;
    case 'photographer':
      urlType = 'photographers';
      break;
    case 'gallery':
    default:
      urlType = 'galleries';
      break;
  }

  return (
    <PanelWrapper className={className}>
      <Panel className="top-panel">
        <Link to={`/leaderboard/${urlType}/${currentRank.period}`} className="view-all pull-right">
          {intl.formatMessage({ id: 'rankingPanel.viewAll', defaultMessage: 'View All' })}
        </Link>
        <PanelHeadline>{intl.formatMessage({ id: 'rankingPanel.headline', defaultMessage: 'Ranking' })}</PanelHeadline>
        <span className="sub-header">
          {intl.formatMessage({ id: 'rankingPanel.subHeader', defaultMessage: 'Leaderboard' })}
        </span>
        <div className="rank">
          <FontAwesomeIcon icon={faTrophyAlt} />
          <span className="position">{currentRank.rank ? `#${currentRank.rank}` : 'N/A'}</span>
        </div>
        <ul className="list-unstyled list-inline ranks-buttons">
          <li>
            <a className={activeClass('week')} onClick={() => changeTab('week')}>
              {intl.formatMessage({ id: 'rankingPanel.lastWeek', defaultMessage: 'Last Week' })}
            </a>
          </li>
          <li>
            <a className={activeClass('month')} onClick={() => changeTab('month')}>
              {intl.formatMessage({ id: 'rankingPanel.thisMonth', defaultMessage: 'This Month' })}
            </a>
          </li>
          <li>
            <a className={activeClass('year')} onClick={() => changeTab('year')}>
              {intl.formatMessage({ id: 'rankingPanel.thisYear', defaultMessage: 'This Year' })}
            </a>
          </li>
        </ul>
      </Panel>
    </PanelWrapper>
  );
}
