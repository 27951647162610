import React from 'react';
import styled from 'styled-components/macro';

type Props = {
  data: {
    count: string;
    title: string;
  }[];
};

const CountsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
  }
  @media (max-width: 430px) {
    display: none;
  }
`;

const CountBox = styled.div`
  width: 150px;
  height: 85px;
  border: 2px solid #292929;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  @media (max-width: 1024px) {
    width: 120px;
    height: 60px;
  }
  @media (max-width: 430px) {
  }
`;

const Count = styled.span`
  color: #fff;
  font-size: 32px;
  font-family: 'Open Sans', sans-serif;
  display: block;
  font-weight: 600;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
`;

const CountTitle = styled.span`
  display: block;
  color: #a07a4c;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
`;

export const Counts = (props: Props) => {
  return (
    <CountsWrapper>
      {props.data.map((item) => (
        <CountBox key={item.title}>
          <Count>{item.count}</Count>
          <CountTitle>{item.title}</CountTitle>
        </CountBox>
      ))}
    </CountsWrapper>
  );
};
