import { useState } from 'react';

interface EventOptions {
  name: string;
  type: string;
  value: string;
}

export const useInfinityTagEvent = (options: EventOptions) => {
  const [eventSent, setEventSent] = useState(false);

  const sendInfinityTagEvent = (options: EventOptions) => {
    if (window.idsync && !eventSent) {
      setEventSent(true);
      window.idsync.send_event(options);
    }
  };

  return () => sendInfinityTagEvent(options);
};
