import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'src/client/redux';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';

type Props = {
  campaign?: string;
};

export const PlayboyBannerImage = (props: Props) => {
  const { campaign } = props;

  const { cdnUrl } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);
  const [href] = useJoinUrl({ campaign: campaign ?? 'playboy-splash-banner' });

  const desktopImage = `${cdnUrl}/playboyBanners/${site.abbreviation.toLowerCase()}.jpg`;
  const mobileImage = `${cdnUrl}/playboyBanners/${site.abbreviation.toLowerCase()}_mobile.jpg`;

  return <PlayboyBannerImageLink href={href} desktopImage={desktopImage} mobileImage={mobileImage} />;
};

interface PlayboyBannerImageProps {
  desktopImage: string;
  mobileImage: string;
}

const PlayboyBannerImageLink = styled.a<PlayboyBannerImageProps>`
  display: block;
  min-height: calc(100vw * (329 / 585));
  background-image: url(${({ mobileImage }) => mobileImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;

  @media (min-width: 585px) {
    min-height: calc(100vw * (658 / 1170));
    background-image: url(${({ desktopImage }) => desktopImage});
  }

  @media (min-width: 1170px) {
    min-height: auto;
    height: 658px;
  }
`;
