import { take, call, fork, select, put } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { RESET_DURATION } from './helpers/config';
import { SORT_TYPES } from 'src/shared/constants/models';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';
import { Leaderboard } from '@tovia/man-protos/dist/types/content.types';

const LOAD = 'man-site/models/LOAD';
const LOAD_SAGA = 'man-site/models/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/models/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/models/LOAD_FAIL';
const LOAD_NAMES = 'man-site/models/LOAD_NAMES';
const LOAD_NAMES_SUCCESS = 'man-site/models/LOAD_NAMES_SUCCESS';
const LOAD_NAMES_FAIL = 'man-site/models/LOAD_NAMES_FAIL';

const endpoint = constructUrl(urls.get.models);
const lettersEndpoint = constructUrl(urls.get.nameLetters);

type Models = {
  galleriesCount: number;
  headshotImagePath: string;
  headshotImagePathSfw: string;
  leaderboardViews: Leaderboard;
  moviesCount: number;
  name: string;
  path: string;
  ratingAverage: number;
  siteUUID: string;
  totalGalleries: number;
  UUID: string;
  views?: number;
};

type NameLetter = {
  firstNameLetter: string;
  total: number;
};

type NameLetters = {
  items: NameLetter[];
  loading: boolean;
  loaded: boolean;
  error?: SuperAgentError;
  lastCallParams: LoadParams;
};

type LoadParams = {
  country?: string;
  first?: number;
  firstNameLetter?: string;
  mainTab?: string;
  page?: number;
  rating?: number;
  sortBy?: string;
};

type LastCallParams = LoadParams;

type State = {
  loading: boolean;
  loaded: boolean;
  error?: SuperAgentError;
  firstNameLetter: string;
  lastCallParams: LoadParams;
  models: Models[];
  nameLetters: NameLetters;
  pageSize: number;
  timestamp: number;
  total: number;
};

type ActionResult = {
  models: Models[];
  total: number;
};

type LoadAction = {
  error?: SuperAgentError;
  firstNameLetter: string;
  lastCallParams: LastCallParams;
  mainTab?: string;
  type: typeof LOAD | typeof LOAD_SAGA;
};

type LoadSuccessAction = {
  type: typeof LOAD_SUCCESS;
  result: ActionResult;
};

type LoadFailAction = {
  type: typeof LOAD_FAIL;
  error?: SuperAgentError;
};

type LoadNamesAction = {
  type: typeof LOAD_NAMES;
  lastCallParams: LoadParams;
};

type LoadNamesSuccessAction = {
  type: typeof LOAD_NAMES_SUCCESS;
  result: NameLetter[];
};

type LoadNamesFailAction = {
  type: typeof LOAD_NAMES_FAIL;
  error?: SuperAgentError;
};

type Action =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | LoadNamesAction
  | LoadNamesSuccessAction
  | LoadNamesFailAction;

const initialState: State = {
  loading: false,
  loaded: false,
  error: undefined,
  models: [],
  total: 0,
  lastCallParams: {},
  pageSize: 40,
  firstNameLetter: '',
  nameLetters: {
    items: [],
    loading: false,
    loaded: false,
    error: undefined,
    lastCallParams: {},
  },
  timestamp: new Date().getTime(),
};

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case LOAD: {
      const { lastCallParams } = action;
      return {
        ...state,
        loading: true,
        loaded: false,
        error: action.error ?? undefined,
        lastCallParams,
        firstNameLetter: action.firstNameLetter,
      };
    }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        models: action.result.models,
        total: action.result.total,
        timestamp: new Date().getTime(),
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    case LOAD_NAMES: {
      return {
        ...state,
        nameLetters: {
          ...state.nameLetters,
          loading: true,
          loaded: false,
          lastCallParams: action.lastCallParams,
        },
      };
    }
    case LOAD_NAMES_SUCCESS: {
      return {
        ...state,
        nameLetters: {
          ...state.nameLetters,
          loading: false,
          loaded: true,
          items: action.result,
        },
      };
    }
    case LOAD_NAMES_FAIL:
      return {
        ...state,
        nameLetters: {
          ...state.nameLetters,
          loading: false,
          loaded: true,
          error: action.error,
        },
      };
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams: LastCallParams, params: LoadParams): boolean {
  return isEqual(lastCallParams, params);
}

export function load(params: LoadParams) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params: LoadParams) {
  const getState = (state: { models: State }) => state.models;
  let currentState: State = yield select(getState);
  const { lastCallParams } = currentState;

  const nameLettersLastCallParams = {
    ...(params.country ? { country: params.country } : {}),
    type: 'MODEL',
  };

  if (
    params.sortBy &&
    params.sortBy === 'all' &&
    !currentState.nameLetters.loading &&
    !isLoaded(currentState.nameLetters.lastCallParams, nameLettersLastCallParams)
  ) {
    const loadNameFunc = getRequestFunc(
      [LOAD_NAMES, LOAD_NAMES_SUCCESS, LOAD_NAMES_FAIL],
      (client) =>
        client.get(lettersEndpoint, {
          params: nameLettersLastCallParams,
        }),
      {
        lastCallParams: nameLettersLastCallParams,
      },
    );
    yield call(loadNameFunc);
  }

  if (params && params.sortBy === 'all') {
    currentState = yield select(getState);
  }

  const isOld = new Date().getTime() - currentState.timestamp > RESET_DURATION;

  if (!currentState.nameLetters.loading && (!isLoaded(lastCallParams, params) || isOld) && !currentState.loading) {
    const sortType = SORT_TYPES.find((ele) => ele.id === params.sortBy);

    const finalParams = {
      ...params,
      ...(sortType
        ? {
            order: sortType.order,
            direction: sortType.direction,
          }
        : {}),
    };

    delete finalParams.sortBy;

    if (params.sortBy && params.sortBy === 'all') {
      finalParams.firstNameLetter = params.firstNameLetter || currentState.nameLetters.items[0].firstNameLetter;
      const filteredFirstNameLetterObject = currentState.nameLetters.items.find(
        (object) => object.firstNameLetter === finalParams.firstNameLetter,
      );
      finalParams.first = filteredFirstNameLetterObject ? filteredFirstNameLetterObject.total : 0;
    }

    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client) =>
        client.get(endpoint, {
          params: finalParams,
        }),
      {
        lastCallParams: { ...params },
        mainTab: params.mainTab,
        firstNameLetter: finalParams.firstNameLetter || '',
      },
    );
    yield call(loadFunc);
  }

  yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

  const newState = yield select();
  if (newState.auth.user && newState.models.models.length > 0) {
    const objectUUIDs = newState.models.models.map((item: { UUID: string }) => item.UUID);
    yield put(
      loadRatingInfo({
        objectUUIDs,
      }),
    );
    yield put(
      loadFavoriteInfo({
        objectUUIDs,
      }),
    );
  }

  return true;
}

// Trigger
function* watchLoad() {
  while (true) {
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
