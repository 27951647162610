/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Modal from '@tovia/man-ui/lib/components/Modal/Modal';
import Button from '@tovia/man-ui/lib/components/Button/Button';

const Wrap = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  left: 0;
  height: 100%;

  .modal-body-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 300px;
    text-align: center;
    border-radius: 5px;
    margin-top: 90px;
    background-color: ${(props) => props.theme.primary6};
    color: ${(props) => props.theme.primary4};

    input {
      margin: 10px;
      height: 40px;
      width: 70px;
      border-radius: 4px;
      text-align: center;
    }

    input:focus {
      outline: none;
    }

    span {
      margin: 20px;
    }
  }
`;

const Div = styled.div`
  max-width: 600px;
  position: relative;

  @media screen and (max-width: 797px) {
    width: 90%;
    max-width: none;
  }

  .go-button {
    width: 100px;
    padding: 8px 0;
    margin: 10px 0 24px;
    font-size: 1rem;
  }
`;

const PaginationModal = (props) => {
  const { active, closeModal, totalPages, setPage } = props;
  const initialValue = '';
  const [inputValue, setInputValue] = useState(initialValue);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const intl = useIntl();

  if (!active) {
    return null;
  }

  const pageWithinBounds = (pageNumber) => (pageNumber <= totalPages && pageNumber > 0) || initialValue;

  const close = () => {
    setInputValue(initialValue);
    closeModal();
  };

  const handleOnChange = (e) => {
    const pageNumber = parseInt(e.target.value);
    setInputValue(pageNumber || initialValue);
    setButtonDisabled(!pageWithinBounds(pageNumber));
  };

  const handleOnClick = () => {
    if (inputValue) {
      setPage(inputValue);
      close();
    }
  };

  const handleOnKeyPress = (e) => {
    if (!inputValue || !pageWithinBounds(inputValue)) {
      return false;
    }

    const enterKey = e.keyCode === 13 || e.which === 13;
    if (enterKey) {
      setPage(inputValue);
      close();
    }
    return true;
  };

  return (
    <Wrap show={active} onClose={closeModal}>
      <Div className="modal-body-wrap">
        <span>
          {intl.formatMessage(
            { id: 'pagination.modal.title', defaultMessage: `Jump to Page (1 - ${totalPages})` },
            { maxPageNumber: totalPages },
          )}
        </span>
        <input
          type="tel"
          pattern="[0-9]*"
          onChange={handleOnChange}
          onKeyPress={handleOnKeyPress}
          autoFocus
          value={inputValue}
        />
        <Button className="go-button btn-primary" onClick={handleOnClick} disabled={buttonDisabled}>
          {intl.formatMessage({ id: 'pagination.modal.button', defaultMessage: 'Go' })}
        </Button>
      </Div>
    </Wrap>
  );
};

PaginationModal.propTypes = {
  active: PropTypes.bool,
  totalPages: PropTypes.number,
  closeModal: PropTypes.func,
  setPage: PropTypes.func,
};

PaginationModal.defaultProps = {
  active: false,
  totalPages: null,
  closeModal: () => {},
  setPage: () => {},
};

export default PaginationModal;
