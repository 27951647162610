import React from 'react';
import { WidePageContextProvider } from 'src/client/containers/App/WidePageContextProvider';
import { CamsContextProvider } from 'src/client/containers/Cams/CamsContextProvider';
import { LanguageProvider } from './LanguageProvider';
import { SFWSiteProvider } from './SFWSiteProvider';

export const ManSiteProviders: React.FC = ({ children }) => {
  return (
    <SFWSiteProvider>
      <CamsContextProvider>
        <LanguageProvider>
          <WidePageContextProvider>{children}</WidePageContextProvider>
        </LanguageProvider>
      </CamsContextProvider>
    </SFWSiteProvider>
  );
};
