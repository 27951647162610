import { call, fork, select, takeEvery } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import { RESET_DURATION } from './helpers/config';
import urls, { constructUrl } from 'src/shared/urls';
import { Blog, BlogCount } from '@tovia/man-protos/dist/types/content.types';
import { ResponseError } from 'superagent';
import { RootState } from './reducer';

const ActionType = {
  RESET: 'man-site/blog/RESET',
  LOAD: 'man-site/blog/LOAD',
  LOAD_SAGA: 'man-site/blog/LOAD_SAGA',
  LOAD_SUCCESS: 'man-site/blog/LOAD_SUCCESS',
  LOAD_FAIL: 'man-site/blog/LOAD_FAIL',
} as const;

const endpoint = constructUrl(urls.get.blogs);

type ActionType = typeof ActionType[keyof typeof ActionType];

type CallParams = {
  first?: number;
  page?: number;
  categoryNames?: string;
};

type Load = {
  lastCallParams: CallParams;
  params: CallParams;
  type: typeof ActionType.LOAD;
};

type LoadSuccess = {
  result: {
    blogs: Blog[];
    total: number;
    counts: BlogCount[];
  };
  type: typeof ActionType.LOAD_SUCCESS;
};

type LoadFail = {
  error: ResponseError;
  type: typeof ActionType.LOAD_FAIL;
};

type Reset = {
  type: typeof ActionType.RESET;
};

type Action = Load | LoadSuccess | LoadFail | Reset;

type State = {
  loading: boolean;
  loaded: boolean;
  error?: ResponseError;
  items: Blog[];
  itemCount: number;
  lastCallParams: CallParams;
  timestamp: number;
  blogCount: BlogCount[];
};

const initialState: State = {
  loading: false,
  loaded: false,
  items: [],
  itemCount: 0,
  lastCallParams: {},
  timestamp: new Date().getTime(),
  blogCount: [],
};

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case ActionType.LOAD: {
      const { lastCallParams } = action;
      return {
        ...initialState,
        loading: true,
        lastCallParams,
      };
    }
    case ActionType.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.result.blogs,
        itemCount: action.result.total,
        timestamp: new Date().getTime(),
        blogCount: action.result.counts,
      };
    case ActionType.LOAD_FAIL:
      return {
        ...initialState,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case ActionType.RESET:
      return {
        ...initialState,
      };
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams, params) {
  return isEqual(lastCallParams, params);
}

export function load(params) {
  return {
    type: ActionType.LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator({ params }: { type: string; params: CallParams }) {
  const currentState: State = yield select((state: RootState) => state.blogs);
  const { lastCallParams } = currentState;
  const isOld = new Date().getTime() - currentState.timestamp > RESET_DURATION;

  if (
    ((!isLoaded(lastCallParams, params) || isOld) && !currentState.loading) ||
    (!currentState.loaded && !currentState.loading)
  ) {
    // reload images after a failure
    const loadFunc = getRequestFunc(
      [ActionType.LOAD, ActionType.LOAD_SUCCESS, ActionType.LOAD_FAIL],
      (client) => client.get(endpoint, { params }),
      { lastCallParams: { ...params } },
    );
    yield call(loadFunc);
  }
}

// Trigger
function* watchLoad() {
  yield takeEvery(ActionType.LOAD_SAGA, loadGenerator);
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
