import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import type { BreadCrumb as BreadCrumbType } from './BreadCrumbs';

type Props = {
  breadCrumb: BreadCrumbType;
  isFinal: boolean;
};

const BreadCrumbWrapper = ({ url, isFinal, children }) => {
  if (isFinal || !url) {
    return <span>{children}</span>;
  }

  return <NavLink to={url}>{children}</NavLink>;
};

export const BreadCrumb = (props: Props) => {
  const { breadCrumb, isFinal } = props;

  if (breadCrumb.node) {
    return <li>{breadCrumb.node}</li>;
  }

  const text = breadCrumb.id ? (
    <FormattedMessage
      defaultMessage={breadCrumb.text}
      id={breadCrumb.id}
      // { key: 'foo', value: 'bar' } => { foo: 'bar' }
      values={breadCrumb?.values?.reduce((acc, v) => ({ ...acc, [v.key]: v.value }), {})}
    />
  ) : (
    breadCrumb.text
  );

  return (
    <li>
      <BreadCrumbWrapper url={breadCrumb.url} isFinal={isFinal}>
        {text}
      </BreadCrumbWrapper>
    </li>
  );
};
