import { take, call, fork, select } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { RESET_DURATION } from './helpers/config';

const LOAD = 'man-site/imageGrid/LOAD';
const LOAD_SAGA = 'man-site/imageGrid/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/imageGrid/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/imageGrid/LOAD_FAIL';

const endpoint = constructUrl(urls.get.gallery);

const initialState = {
  loading: false,
  loaded: false,
  items: null,
  gallery: null,
  lastCallParams: {},
  timestamp: new Date().getTime(),
  error: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      const { lastCallParams } = action;
      return {
        ...initialState,
        loading: true,
        lastCallParams,
      };
    }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        gallery: omit(action.result, 'photos'),
        items: action.result.photos.media,
        timestamp: new Date().getTime(),
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
        items: initialState.items,
        gallery: initialState.gallery,
      };
    default: {
      return state;
    }
  }
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function isLoaded(lastCallParams, params) {
  return isEqual(lastCallParams, params);
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => state.imageGrid;
  const currentState = yield select(getState);
  const isOld = new Date().getTime() - currentState.timestamp > RESET_DURATION;
  if ((!isLoaded(currentState.lastCallParams, params) || isOld) && !currentState.loading) {
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint, { params }), {
      lastCallParams: { ...params },
    });
    yield call(loadFunc);
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
