import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from 'react-bootstrap/Popover';
import styled, { css } from 'styled-components/macro';
import cx from 'classnames';
import { Button } from '@tovia/man-ui/lib/components/Button/Button';
import { NavLink } from 'react-router-dom';

export const Container = styled.div<{ extended?: boolean }>`
  a,
  i {
    transition: 0.3s color;
  }

  .image-btn,
  a:not(.btn):not(.active),
  .navbar-nav > li > a {
    color: ${(props) => props.theme.headerBarPrimary2};

    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.headerBarPrimary4};
    }

    &.mobile-link:hover {
      color: ${(props) => props.theme.headerBarPrimary2};
      font-weight: 400;
    }
  }

  ${(props) =>
    props.extended &&
    css`
      overflow: hidden;
    `}
`;

export const NavbarIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  vertical-align: middle;
  text-decoration: none;

  :hover & {
    text-decoration: none;
    color: ${(props) => props.theme.headerBarPrimary4};
  }
`;

export const NavbarHoverButton = styled.div`
  transition: 0.3s color;
  color: ${(props) => props.theme.headerBarPrimary2};

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.headerBarPrimary4};

    ${NavbarIcon} {
      text-decoration: none;
      color: ${(props) => props.theme.headerBarPrimary4};
    }
  }

  &.mobile-link:hover {
    color: ${(props) => props.theme.headerBarPrimary2};
    font-weight: 400;
  }
`;

export const ImageButton = styled(NavbarHoverButton)`
  position: relative;
  vertical-align: baseline;
  padding: 18px 10px;
  text-align: center;
  height: 62px;

  @media (max-width: 479px) {
    padding: 18px 5px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 18px 7px;
  }

  i {
    transition: color 0.3s;
    vertical-align: middle;
  }
`;

export const FlatPopover = styled(Popover)`
  background: ${(props) => props.theme.primary1};
  border-color: ${(props) => props.theme.primary3};
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 0;

  .popover-content {
    padding: 0;
  }
`;

export const NestedMenuLink = styled.div`
  order: 1;

  i {
    display: flex;
    width: 30px;
    justify-content: center;
    font-size: 2.15rem;
    margin-right: 10px;
  }

  > span {
    display: flex;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: ${(props) => props.theme.primary3};
    color: ${(props) => props.theme.headerBarPrimary2};
    padding: 10px;
    align-items: center;
  }

  a:first-of-type {
    border-top: 1px solid ${(props) => props.theme.primary3};
  }
`;

export const MobileMenuCaretWrapper = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const MobileMenuIcon = styled(FontAwesomeIcon)`
  display: flex;
  font-size: 1.55rem;
  margin-right: 10px;
  width: 1.25em !important;
  height: 25px;
  align-items: center;

  path {
    width: 22px;
  }
`;

export const MobileMenuButton = styled.div.attrs<{ mobileMenuOpened: boolean }>((props) => ({
  className: cx('visible-xs', 'visible-sm', { active: props.mobileMenuOpened }),
}))<{ mobileMenuOpened: boolean }>`
  transition: 0.3s color;
  font-size: 1.4rem;
  vertical-align: middle;
  margin: 0 10px;
  width: 15px;
  padding: 5px 0;
  color: ${(props) => props.theme.primary7};

  @media (max-width: 991px) {
    display: inline-block !important;
  }
`;

export const HeaderBarWrapper = styled.div`
  background: ${(props) => props.theme.headerBarPrimary1};
  position: sticky;
  top: 0;
  z-index: 1001;

  .sign-in {
    text-transform: capitalize;
  }

  .white-caret-down,
  .caret-down {
    font-size: 0.6rem;
  }

  .white-caret-down.live-girls-caret {
    margin-left: 6px;
  }

  .caret-down.live-girls-caret {
    margin-left: 6px;
  }

  .btn-site-picker .white-caret-down {
    position: relative;
  }

  .header-enter {
    max-width: 0;
    overflow: hidden;
  }

  .header-enter.header-enter-active {
    max-width: 280px;
    transition: max-width 500ms ease-in;
  }

  .header-leave {
    max-width: 280px;
  }

  .header-leave.header-leave-active {
    max-width: 0;
    overflow: hidden;
    transition: max-width 500ms ease-in;
  }

  .main-header {
    border-style: none;
    min-height: 64px; /* to keep guest and user headerbar same height */
    background: ${(props) => props.theme.headerBarPrimary1};
    z-index: 1030;

    @media (max-width: 991px) {
      background: ${(props) => props.theme.headerBarPrimary5};
    }
  }

  .main-header.navbar {
    margin-bottom: 0;
  }

  .container {
    .mobile-menu {
      .zEWidget-launcher {
        display: none;
      }
    }
  }

  .fa {
    vertical-align: middle;
  }

  .logo {
    display: inline-block;
    vertical-align: middle;
    line-height: 60px;

    img {
      max-width: 205px;
      pointer-events: none;
      user-select: none;

      @media (max-width: 479px) {
        max-width: 110px;
        max-height: 45px;
        top: 7px;
      }
    }
  }

  [data-site='gn'] .logo img {
    @media (min-width: 480px) {
      max-height: 60px;
    }
  }

  .lang-symbol {
    text-transform: uppercase;
  }

  .i18n-popover {
    .lang-icon {
      margin-right: 5px;
    }
  }

  & a.join-btn {
    border-radius: 2px;
    padding: 10px 20px;
    font-weight: 600;
    line-height: normal;
  }

  .page-links {
    > a {
      @media (max-width: 1199px) {
        display: none;
      }

      @media (min-width: 990px) {
        span {
          text-transform: uppercase;
        }
      }
    }

    a {
      display: inline-block;
      padding: 0 12px;
      height: 64px;
      line-height: 64px;
      font-size: 0.9rem;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      @media (max-width: 1199px) {
        padding: 0 7px;
        font-size: 0.85rem;
      }
    }
  }

  .btn-more-menu {
    position: relative;
    padding: 20px 15px 20px 9px;
  }

  .dropdown-more-menu {
    list-style: none;
    font-size: 0.9rem;
    padding: 10px 5px;
    margin: 0;
    position: static;
    display: block;
    box-shadow: none;
  }

  .popover-livegirls {
    max-width: none;

    .bottom {
      .arrow {
        margin-left: 112px;
      }
    }
  }

  .popover-user-menu {
    max-width: none;

    .bottom {
      margin-left: -10px;
      margin-top: 22px;

      .arrow {
        margin-left: 20px;
        margin-top: 1px;
      }
    }
  }

  .popover-site-picker {
    max-width: none;
    position: fixed;
    top: 42px !important;

    .bottom {
      margin-top: 24px;

      .popover-content {
        padding: 0;
      }
    }
  }

  .popover-flat {
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    padding: 0;

    .popover-content {
      padding: 0;
    }
  }

  .man-popover {
    position: absolute;
    z-index: 9999;
    top: calc(100% - 3px);
    left: 50%;
    border-radius: 1px;
    transform: translateX(-50%);

    .man-arrow {
      content: '';
      height: 0;
      width: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid ${(props) => props.theme.primary3};
      position: absolute;
      left: calc(50% - 5px);
      top: -10px;
      &.dark-man-arrow {
        border-bottom-color: ${(props) => props.theme.primary3};
      }
    }
  }

  .more-menu-popover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

    .bg-white {
      background-color: #ffffff;
    }
  }

  .live-girls-wrapper {
    position: relative;
  }

  .live-menu-popover {
    background: #ffffff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

    .man-arrow {
      border-bottom-color: #ffffff;
    }
  }

  .cam-list-drop-down-wrap {
    width: 200px;
    height: auto;

    a {
      display: block;
      line-height: 18px;
      color: #222;
      opacity: 1;
      font-size: 14px;
      padding: 8px 0 8px 10px;

      :hover {
        color: #000 !important;
      }
    }
  }
`;

export const BackButton = styled(Button)`
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  display: flex;
  width: 100%;
  align-items: center;
  height: 20px;
  white-space: nowrap;
  margin: 10px 0 10px 0;
  padding: 0 0 0 10px;

  .back-icon {
    margin-right: 10px;
  }
`;

export const NewBadge = styled.div`
  color: white;
  font-size: 0.6rem;
  border-radius: 10px;
  padding: 0 2px;
  background-color: ${(props) => props.theme.headerBarPrimary2};
  margin-left: 10px;
`;

export const HoverMenuLink = styled.a`
  cursor: pointer;
  display: block;
  font-size: 0.9rem;
  height: 64px;
  line-height: 64px;
  padding: 0 13px;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 0.85rem;
  }
`;

export const MenuNavLink = styled(NavLink)`
  text-transform: uppercase;
  display: inline-block;
  padding: 0 12px;
  height: 64px;
  line-height: 64px;
  font-size: 0.9rem;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.active {
    background-color: ${(props) => props.theme.headerBarPrimary3};
    color: ${(props) => props.theme.primaryBtn1Text};
  }

  @media (max-width: 1199px) {
    padding: 0 7px;
    font-size: 0.85rem;
  }
`;

export const HoverMenuCaret = styled(FontAwesomeIcon)`
  margin-left: 6px;

  @media (max-width: 1399px) {
    display: none;
  }
`;

// Copied from above to be exported elsewhere.
// Todo later: update the headerBar to use this one instead.
export const HeaderLogo = styled.img`
  max-width: 205px;

  @media (max-width: 479px) {
    max-width: 110px;
    max-height: 45px;
    top: 7px;
  }

  [data-site='gn'] & {
    @media (min-width: 480px) {
      max-height: 60px;
    }
  }
`;
