import React, { createContext, ReactNode, useEffect, useState } from 'react';

type SetStateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

export enum HeaderPopovers {
  ACTIVITY,
  PROFILE,
  LIVEGIRLS,
  SEO_CATEGORIES,
}

export enum ActiveNestedItem {
  SITEPICKER,
  MOREGIRLS,
  LIVEGIRLS,
  SEO_CATEGORIES,
}

export type ActiveNestedMobileItem = ActiveNestedItem | string;

type HeaderContext = {
  activePopover: HeaderPopovers | null;
  activeNestedMobileMenu: ActiveNestedMobileItem | null;
  mobileMenuOpened: boolean;
  setActivePopover: SetStateDispatch<HeaderPopovers | null>;
  setActiveNestedMobileMenu: SetStateDispatch<ActiveNestedMobileItem | null>;
  setMobileMenuOpened: SetStateDispatch<boolean>;
  closeMobileMenu: () => void;
  popoverContentLoaded: boolean;
  setPopoverContentLoaded: SetStateDispatch<boolean>;
};

type Props = {
  children?: ReactNode;
};

export const HeaderContext = createContext<HeaderContext>({} as HeaderContext);

export const HeaderProvider = (props: Props) => {
  const [activePopover, setActivePopover] = useState<HeaderPopovers | null>(null);
  const [activeNestedMobileMenu, setActiveNestedMobileMenu] = useState<ActiveNestedMobileItem | null>(null);
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  // Use if initial position is wrongly calculated by popper when making async calls within popover
  const [popoverContentLoaded, setPopoverContentLoaded] = useState<boolean>(false);

  const closeMobileMenu = () => {
    setActiveNestedMobileMenu(null);
    setMobileMenuOpened(false);
  };

  useEffect(() => {
    if (!activePopover) {
      setPopoverContentLoaded(false);
    }
  }, [activePopover]);

  return (
    <HeaderContext.Provider
      value={{
        activePopover,
        activeNestedMobileMenu,
        setActivePopover,
        setActiveNestedMobileMenu,
        mobileMenuOpened,
        setMobileMenuOpened,
        closeMobileMenu,
        popoverContentLoaded,
        setPopoverContentLoaded,
      }}
    >
      {props.children}
    </HeaderContext.Provider>
  );
};
