import { useWidePage } from 'src/client/helpers/useFeatureFlags';
import React, { ReactNode, createContext, useContext } from 'react';

export const WidePageContext = createContext(false);

type Props = {
  children: ReactNode;
};

export const WidePageContextProvider = (props: Props) => {
  const widePage = useWidePage();

  return <WidePageContext.Provider value={widePage}>{props.children}</WidePageContext.Provider>;
};

export const useWidePageContext = () => useContext(WidePageContext);
