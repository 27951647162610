import { SexArt } from 'src/client/constants/sites';

export type PartnerPromoConfig = {
  assetsDirectory: string;
  campaign: string;
  partnerID: number;
  sitesToDisplay: string[];
  url?: string;
};

type PromoConfig = {
  getPartnerPromoConfig: (partnerID: number, siteAbbreviation: string) => PartnerPromoConfig | undefined;
  promos: PartnerPromoConfig[];
};

export const partnerPromoConfig: PromoConfig = {
  getPartnerPromoConfig: (partnerID, siteAbbreviation) => {
    return partnerPromoConfig.promos.find(
      (promo) => promo.partnerID === partnerID && promo.sitesToDisplay.includes(siteAbbreviation),
    );
  },
  promos: [
    {
      assetsDirectory: 'redgifs-promo',
      campaign: 'redgifs-promo',
      partnerID: 8107,
      sitesToDisplay: [SexArt.abbreviation],
    },
  ],
};
