import React from 'react';
import styled from 'styled-components/macro';
import { JoinButton } from 'src/client/components/buttons/JoinButton';
import { TABLET_LANDSCAPE } from 'src/client/constants/mediaQueries';
import { useSelector } from 'src/client/redux';
import { AdvancedModal } from '@tovia/man-ui';
import { ModalDialog } from '@tovia/man-ui/lib/components/Modal/AdvancedModal';
import { UpgradeStripWrapper } from './UpgradeStrip';

export const NonNudeInfoStrip = () => {
  const { name } = useSelector((state) => state.site);
  const { abbreviation } = useSelector((state) => state.site);
  const [modalOpen, setModalOpen] = React.useState(false);

  const hideLearnMore = !siteTitles[abbreviation];

  return (
    <UpgradeStripWrapper>
      <SmallScreen>
        <JoinButton campaign="guest-verify-ribbon">
          <ExplicitText>Unlock fully explicit {name} content</ExplicitText>
        </JoinButton>
        <span hidden={hideLearnMore}>
          {' - '}
          <LearnMore onClick={() => setModalOpen(true)}>Learn more</LearnMore>
        </span>
      </SmallScreen>

      {/* Just use separate duplicated text for the small screens vs large screens */}
      {/* so we don't have to do some fake link weirdness to get the first half to */}
      {/* only be a link some of the time. */}
      <LargeScreen>
        <ExplicitText>Unlock fully explicit {name} content</ExplicitText>
        <VerifyText>
          {' - '}
          <JoinButton campaign="guest-verify-ribbon">
            <span>Become a member to verify you are 18 or older</span>
          </JoinButton>
        </VerifyText>
        <span hidden={hideLearnMore}>
          {' - '}
          <LearnMore onClick={() => setModalOpen(true)}>Learn more</LearnMore>
        </span>
      </LargeScreen>

      <StyledModal DialogComponent={StyledDialog} show={modalOpen} onClose={() => setModalOpen(false)} zIndex={10000}>
        <Title>{siteTitles[abbreviation]}</Title>
        <p>
          You are seeing an amended version because the law in your region requires you be at least 18 years old before
          you can view explicit material.
        </p>
        <p>Please signup here to verify your age and see the fully nude version.</p>

        <StyledJoinButton className="btn btn-primary join-btn" campaign="guest-verify-modal">
          Join Now
        </StyledJoinButton>
      </StyledModal>
    </UpgradeStripWrapper>
  );
};

const siteTitles = {
  als: 'ALS Scan features fresh faced, first time models in explicit nude films and photography.',
  dm: 'Domai is a huge collection of erotic nude art photography.',
  ea: 'Errotica-Archives is a huge collection of erotic nude art photography.',
  eb: 'Erotic Beauty is a huge collection of erotic nude art photography.',
  ed: 'Eternal Desire offers a huge collection of erotic nude art photography by award winning photographer Arkisi.',
  gn: 'Goddess Nudes is a huge collection of erotic nude art photography.',
  lh: 'Love Hairy is the world’s leading collection of natural, unshaven, nude beauty.',
  ma: 'MetArt is the world’s largest erotic nude art film & photography site.',
  man: 'MetartNetwork is full access to 10 premium sites, including Metart, Sexart, MetartX, Viv Thomas and more.',
  mx: 'Metart X has your favorite Metart models in explicit solo sex films and photography.',
  ra: 'Rylsky Art offers a huge collection of erotic nude art photography by award winning photographer Rylsky.',
  s18: 'Stunning 18 is a huge collection of erotic nude art photography.',
  sa: 'Sexart leads the world in elite, explicit sex filmmaking.',
  sl: 'Straplez is the world’s leading lesbian fetish film & photography site.',
  tle: 'The Life Erotic is the world’s leading kinky solo sex film & photography site.',
  vt: 'Viv Thomas is the world’s leading erotic lesbian film & photography site.',
};

const ExplicitText = styled.span`
  text-decoration: underline;

  @media ${TABLET_LANDSCAPE} {
    text-decoration: none;
  }
`;

const VerifyText = styled.span`
  span {
    text-decoration: underline;
  }
`;

const SmallScreen = styled.div`
  display: block;
  @media ${TABLET_LANDSCAPE} {
    display: none;
  }
`;

const LargeScreen = styled.div`
  display: none;
  @media ${TABLET_LANDSCAPE} {
    display: block;
  }
`;

const LearnMore = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0.25em;
`;

const StyledModal = styled(AdvancedModal)`
  text-align: center;
`;

const StyledDialog = styled(ModalDialog)`
  max-width: min(70vw, 650px);
`;

const Title = styled.p`
  font-weight: bold;
  margin-bottom: 20px;
`;

const StyledJoinButton = styled(JoinButton)`
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
`;
