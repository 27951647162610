import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LiveGirlsContentOneProvider } from './LiveGirlsContentOneProvider';
import { useSelector } from '../../redux';
import { loadCamsFunction } from '../../containers/Cams/CamsContextProvider';
import { LiveGirlsContentTwoProvider } from './LiveGirlsContentTwoProvider';
import { CamTrackingPosition } from 'src/client/constants/camTracking';
import styled from 'styled-components/macro';
import { HeaderContext } from 'src/client/containers/HeaderBar/HeaderProvider';

export const LiveGirlsPopover = () => {
  const { setPopoverContentLoaded } = useContext(HeaderContext);
  const config = useSelector((state) => state.app.config);
  const siteUUID = useSelector((state) => state.site.UUID);
  const { cams, loaded } = useSelector((state) => state.cams);
  const dispatch = useDispatch();
  const camTrackingPosition: CamTrackingPosition = 'header';

  useEffect(() => {
    if (window.matchMedia('(max-width: 768px)').matches) return;

    if (!loaded) {
      dispatch(loadCamsFunction(config.camProviders, siteUUID));
    }
  }, [dispatch, loaded, config.camProviders, siteUUID]);

  useEffect(() => {
    if (loaded && cams && cams.length) {
      setPopoverContentLoaded(true);
    }
  }, [cams, loaded, setPopoverContentLoaded]);

  if (!loaded || !cams || !cams.length) return null;

  return (
    <Wrapper>
      {cams.length >= 2 ? <LiveGirlsContentTwoProvider camTrackingPosition={camTrackingPosition} cams={cams} /> : null}
      {cams.length === 1 ? (
        <LiveGirlsContentOneProvider camTrackingPosition={camTrackingPosition} cam={cams[0]} />
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
`;
