import { take, call, fork, select } from 'redux-saga/effects';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

const LOAD = 'man-site/siteMarkdowns/LOAD';
const LOAD_SAGA = 'man-site/siteMarkdowns/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/siteMarkdowns/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/siteMarkdowns/LOAD_FAIL';

const endpoint = constructUrl(urls.get.markdowns);

const initialState = {
  loading: false,
  loaded: false,
  items: [],
  error: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...initialState,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default: {
      return state;
    }
  }
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => state.markdowns;
  const currentState = yield select(getState);
  if (!currentState.loaded && !currentState.loading) {
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint, { params }));
    yield call(loadFunc);
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
