import React from 'react';
import { useSelector } from 'src/client/redux';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

const SplashAboutBottomMA = React.lazy(() =>
  import('./SplashAboutBottomMA').then((m) => ({ default: m.SplashAboutBottomMA })),
);

const SplashAboutBottomMX = React.lazy(() =>
  import('./SplashAboutBottomMX').then((m) => ({ default: m.SplashAboutBottomMX })),
);

const SplashAboutBottomSA = React.lazy(() =>
  import('./SplashAboutBottomSA').then((m) => ({ default: m.SplashAboutBottomSA })),
);

const SplashAboutBottomSL = React.lazy(() =>
  import('./SplashAboutBottomSL').then((m) => ({ default: m.SplashAboutBottomSL })),
);

const SplashAboutBottomTLE = React.lazy(() =>
  import('./SplashAboutBottomTLE').then((m) => ({ default: m.SplashAboutBottomTLE })),
);

const SplashAboutBottomVT = React.lazy(() =>
  import('./SplashAboutBottomVT').then((m) => ({ default: m.SplashAboutBottomVT })),
);

export const SplashAboutBottom = () => {
  const showSfwSite = useShowSfwSite();
  const { abbreviation } = useSelector((state) => state.site);

  if (!showSfwSite) {
    return null;
  }

  switch (abbreviation.toLowerCase()) {
    case 'ma':
      return <SplashAboutBottomMA />;
    case 'mx':
      return <SplashAboutBottomMX />;
    case 'sa':
      return <SplashAboutBottomSA />;
    case 'sl':
      return <SplashAboutBottomSL />;
    case 'tle':
      return <SplashAboutBottomTLE />;
    case 'vt':
      return <SplashAboutBottomVT />;
    default:
      return null;
  }
};
