import { take, call, fork, select } from 'redux-saga/effects';

import { ResponseError } from 'superagent';
import { AnyAction } from 'redux';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

const LOAD = 'man-site/topImages/LOAD';
const LOAD_SAGA = 'man-site/topImages/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/topImages/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/topImages/LOAD_FAIL';

const endpoint = constructUrl(urls.get.topImages);

interface Model {
  UUID: string;
  globalUUID: string;
  name: string;
  path: string;
}

interface Photographer {
  UUID: string;
  name: string;
  path: string;
}

interface Gallery {
  UUID: string;
  imageCount: number;
  models: Model[];
  name: string;
  path: string;
  photographers: Photographer[];
  publishedAt: string;
  siteUUID: string;
}

export interface Image {
  UUID: string;
  gallery: Gallery;
  galleryUUID: string;
  ratingAverage: number;
}

interface InitialState {
  loading?: boolean;
  loaded?: boolean;
  items: Image[];
  error?: ResponseError;
}

const initialState: InitialState = {
  items: [],
};

interface ActionLoad extends AnyAction {
  type: typeof LOAD;
}

interface ActionLoadSuccess extends AnyAction {
  type: typeof LOAD_SUCCESS;
  items: Image[];
}

interface ActionLoadFail extends AnyAction {
  type: typeof LOAD_FAIL;
  error: ResponseError;
}

export default function reducer(
  state = initialState,
  action: ActionLoad | ActionLoadSuccess | ActionLoadFail,
): InitialState {
  switch (action.type) {
    case LOAD:
      return {
        ...initialState,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default: {
      return state;
    }
  }
}

export const load = () => ({
  type: LOAD_SAGA,
});

/* SAGAS */
function* loadGenerator() {
  const getState = (state) => state.topImages;
  const currentState = yield select(getState);
  if (!currentState.loaded && !currentState.loading) {
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint));
    yield call(loadFunc);
  }

  // const newState = yield select();
  // if (newState.auth.user && !__SERVER__ && newState.topImages.items.length > 0) {
  //   const objectUUIDs = [
  //     ...newState.topImages.items.map(item => item.UUID),
  //   ];
  //   yield put(loadRatingInfo({
  //     objectUUIDs,
  //   }));
  // }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    yield take(LOAD_SAGA);
    yield fork(loadGenerator);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
