import { useSelector } from 'src/client/redux';
import React, { FormEventHandler, useState, useRef } from 'react';
import { ApiClient } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import styled from 'styled-components/macro';
import { SkinnyPage } from 'src/client/components/layouts/SkinnyPage';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import { MOBILE_LANDSCAPE } from 'src/client/constants/mediaQueries';
import { MainPageTitle } from 'src/client/components/layouts/MainPageTitle';

export const ReportPiracy = () => {
  const site = useSelector((state) => state.site);
  const [showThankyouMessage, setShowThankyouMessage] = useState(false);
  const [error, showError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const reportRef = useRef<HTMLTextAreaElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [isReportValid, setIsReportValid] = useState(false);
  const intl = useIntl();

  const handleReportChange = () => {
    const reportLength = reportRef.current?.value.length || 0;
    setIsReportValid(reportLength >= 5);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const form = e.currentTarget;
    const data = {
      email: emailRef.current?.value || '',
      message: reportRef.current?.value || '',
    };

    try {
      const api = new ApiClient();
      const { body } = await api.post(constructUrl(urls.post.piracyReport), { data });

      if (body?.status) {
        form.reset();
        showError(false);
        setShowThankyouMessage(true);
      } else {
        showError(true);
      }
    } catch {
      showError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <p>
        If you come across the unauthorized use of photos or videos on another website, please report it in the
        following form. Include all URLs of the stolen content if possible. You may leave the email field empty if you
        wish to report anonymously.
      </p>
      <br />

      {error && (
        <div>
          <p style={{ color: 'red' }}>There was an error submitting your report. Please try again later.</p>
          <br />
        </div>
      )}
      <br />

      <div className="form-group">
        <label>Email Address (optional)</label>
        <Input ref={emailRef} className="form-control" type="email" name="email" placeholder="example@gmail.com" />
      </div>
      <br />

      <div className="form-group">
        <label>Your report:</label>
        <Textarea ref={reportRef} className="form-control" rows={5} name="message" onChange={handleReportChange} />
      </div>
      <br />

      <FormFooter>
        <button className="btn btn-primary btn-lg" type="submit" disabled={isSubmitting || !isReportValid}>
          {isSubmitting ? 'Sending...' : 'Send'}
        </button>
      </FormFooter>
    </form>
  );

  return (
    <SkinnyPage>
      <Helmet>
        <title>{`${site.name} - ${intl.formatMessage({
          id: 'piracy.title',
          defaultMessage: 'Report Piracy',
        })}`}</title>
      </Helmet>
      <MainPageTitle>Report Piracy</MainPageTitle>
      {showThankyouMessage ? <p>Thank you for your report.</p> : renderForm()}
    </SkinnyPage>
  );
};

const Input = styled.input`
  @media ${MOBILE_LANDSCAPE} {
    max-width: 50%;
  }

  &:not(:placeholder-shown):invalid {
    box-shadow: 0 0 0 1px red;
  }
`;

const Textarea = styled.textarea`
  @media (min-width: 768px) {
    max-width: 50%;
  }
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
