import React from 'react';

export const FeaturedGallery = React.lazy(() =>
  import('src/client/components/splash/Straplez/FeaturedGallery').then((m) => ({ default: m.FeaturedGallery })),
);
export const Outro = React.lazy(() =>
  import('src/client/components/splash/Straplez/Outro').then((m) => ({ default: m.Outro })),
);

export const GalleryUpdates = React.lazy(() =>
  import('src/client/components/splash/Straplez/GalleryUpdates').then((m) => ({ default: m.GalleryUpdates })),
);
