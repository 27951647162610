import React from 'react';
import styled from 'styled-components/macro';
import { SignupLink } from 'src/client/components/splash/Network/SignupLink';
import { Sites } from 'src/client/components/splash/Network/Sites';
import { destructUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';

type Props = {
  className?: string;
  topTitle?: string;
  title: string;
  background: string;
  backgroundSize: string;
};

const SlideWrapper = styled.div<{ backgroundImage: string; backgroundSize: string }>`
  max-height: 1200px;
  width: 100%;
  position: relative;
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    background-image: url(${(props) => props.backgroundImage});
    background-position: center center;
    background-size: ${(props) => props.backgroundSize};
  }
  @media (max-width: 1024px) {
    min-height: 768px;
  }
  @media (max-width: 430px) {
    min-height: 430px;
  }
  ::before {
    content: '';
    display: block;
    padding-top: calc(100% * (1200 / 2560));
  }
`;

const NetworkLogo = styled.img`
  margin: 0 auto 50px;
  @media (max-width: 1024px) {
    max-width: 180px;
  }
  @media (max-width: 768px) {
    max-width: 120px;
  }
  @media (max-width: 430px) {
    max-width: 90px;
  }
`;

const SlideTitle = styled.h1`
  color: #fff;
  font-size: 72px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  margin: 0 auto 50px;
  text-align: center;
  @media (max-width: 1440px) {
    font-size: 50px;
  }
  @media (max-width: 1024px) {
    font-size: 36px;
    margin: 0 auto 30px;
  }
  @media (max-width: 768px) {
    font-size: 28px;
    margin: 0 auto 20px;
  }
  @media (max-width: 430px) {
    font-size: 24px;
  }
`;

const TopTitle = styled(SlideTitle)`
  margin: 0 auto 12px;
  @media (max-width: 430px) {
    margin: 0 auto 6px;
  }
`;

const Premium = styled.span`
  color: #fff;
  font-size: 62px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin: 0 auto 11px;
  text-align: center;
  @media (max-width: 1440px) {
    font-size: 46px;
  }
  @media (max-width: 1024px) {
    font-size: 36px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 430px) {
    font-size: 24px;
  }
`;

const InnerWrapper = styled.div`
  flex-basis: 100%;
  width: 100%;
  text-align: center;
  @media (max-width: 1024px) {
    max-width: 860px;
    margin-left: 0;
  }
  @media (max-width: 430px) {
    max-width: 100%;
  }
`;

export const Slide = (props: Props) => {
  const site = useSelector((state) => state.site);
  const { cdnUrl } = useSelector((state) => state.app);

  return (
    <SlideWrapper backgroundImage={props.background} backgroundSize={props.backgroundSize}>
      <InnerWrapper>
        <NetworkLogo src={`${cdnUrl}/splash/${destructUUID(site.networkUUID)}/network_logo.png`} />
        {(props.topTitle && <TopTitle>{props.topTitle}</TopTitle>) || <Premium>Your Premium Sites.</Premium>}
        <SlideTitle>{props.title}</SlideTitle>
        <SignupLink title="Access 10 Sites Now" campaign="network-slider" />
        <Sites />
      </InnerWrapper>
    </SlideWrapper>
  );
};
