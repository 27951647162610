import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components/macro';
import { useSelector } from 'src/client/redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  defaultHeight?: boolean;
  streamItem?: boolean;
  className?: string;
  theme: object;
};

const RawLoaderComponent = ({ className = '', defaultHeight = false, streamItem = false }: Props) => {
  const intl = useIntl();
  const { config, icons } = useSelector((state) => state.app);
  const { loaderIcon } = config;
  const sitePickerIcon = icons?.sitePicker;

  const iconMarkup = useMemo(() => {
    switch (loaderIcon) {
      case 'generic':
        return (
          <svg className="loader-spinner" viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        );
      default:
        return <FontAwesomeIcon className="spin" icon={sitePickerIcon} />;
    }
  }, [loaderIcon, sitePickerIcon]);

  return (
    <div className={`${className} ${defaultHeight ? 'default-height' : 'loader'} ${streamItem ? 'stream' : ''}`}>
      {iconMarkup}
      <span className="sr-only">{`${intl.formatMessage({ id: 'loader.loading', defaultMessage: 'Loading' })}...`}</span>
    </div>
  );
};

const rotateY = keyframes`
  to { transform: rotateY(360deg); }
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

export const LoaderComponent = styled(RawLoaderComponent)`
  font-size: 5.7rem;

  &.loader {
    height: 1px;
    position: relative;
    margin: 256px auto;

    i {
      position: absolute;
      top: 40%;
      left: 50%;
      margin-left: -41px; // horizontal align
      margin-top: -24px; // vertical align
      opacity: 0.6;
    }
  }

  &.default-height {
    min-height: 740px;
    text-align: center;
    vertical-align: middle;
    line-height: 650px; // account for size of icon

    @media (max-width: 768px) {
      min-height: 400px;
      line-height: 310px;
    }
  }

  &.stream {
    i {
      position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 50px;
      max-width: inherit;
      width: 100%;
    }
  }

  .spin {
    animation: ${rotateY} 1.5s infinite linear;
  }

  & .loader-spinner {
    animation: ${rotate} 2s linear infinite;
    margin: 25px;
    width: 50px;
    height: 50px;

    .path {
      stroke: #b7b6b7;
      stroke-linecap: round;
      animation: ${dash} 1.5s ease-in-out infinite;
    }
  }
`;
