import React from 'react';
import { useIntl } from 'react-intl';

export default function Loading() {
  const intl = useIntl();

  return (
    <div style={{ textAlign: 'center' }}>{`${intl.formatMessage({
      id: 'loader.loading',
      defaultMessage: 'Loading',
    })}...`}</div>
  );
}
