import { mamCookieName } from '@tovia/man-app-utils/lib/helpers/affiliate';
import { useCookies } from 'react-cookie';
import { useSelector } from 'src/client/redux';

export const usePlayboyLandingPage = (): boolean => {
  const {
    config: { playboyLanderMAMCodes },
  } = useSelector((state) => state.app);

  const [cookies] = useCookies([mamCookieName]);

  return playboyLanderMAMCodes?.includes(cookies[mamCookieName]) || false;
};
