import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'src/client/redux';
import { TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
import { PartnerPromoConfig, partnerPromoConfig } from 'src/client/components/PartnerBanners/partnerPromoConfig';
import { useCookies } from 'react-cookie';
import { getPartnerTrackingData } from 'src/client/utils/affiliateTracking';
import { useJoinUrlGenerator } from 'src/client/components/buttons/JoinButton';
import { MAM_COOKIE_NAME } from 'src/shared/constants/cookies';

export const PartnerHeaderBanner = () => {
  const cdnUrl = useSelector((state) => state.app.cdnUrl);
  const promoConfig = usePartnerPromoConfig();

  return promoConfig ? (
    <PartnerRibbonContainer>
      <PartnerRibbonLink href={promoConfig.url}>
        <img
          src={`${cdnUrl}/promoBanners/${promoConfig.assetsDirectory}/header-left.jpg`}
          alt="Exclusive deal for visitors"
        />
        <img src={`${cdnUrl}/promoBanners/${promoConfig.assetsDirectory}/header-right.jpg`} alt="Enter now for free" />
      </PartnerRibbonLink>
    </PartnerRibbonContainer>
  ) : null;
};

const usePartnerPromoConfig = () => {
  const site = useSelector((state) => state.site);
  const { user } = useSelector((state) => state.auth);

  const [cookies] = useCookies([MAM_COOKIE_NAME]);
  const generateJoinUrl = useJoinUrlGenerator();

  const partnerTrackingData = getPartnerTrackingData(cookies[MAM_COOKIE_NAME]);
  const promoConfig = partnerPromoConfig.getPartnerPromoConfig(partnerTrackingData?.partnerID || 0, site.abbreviation);

  let url = '';
  if (promoConfig) {
    url = generateJoinUrl({ campaign: promoConfig.campaign });
  }

  // only guests and guests members with valid MAM links will see promo
  return promoConfig && !user?.validSubscription ? ({ ...promoConfig, url } as PartnerPromoConfig) : undefined;
};

const PartnerRibbonContainer = styled.div`
  background-color: #321339;
  display: flex;
  justify-content: center;
`;

const PartnerRibbonLink = styled.a`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${TABLET_PORTRAIT} {
    flex-direction: row;
    gap: 14px;
  }
`;
