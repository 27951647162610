import React, { ReactNode } from 'react';
import Badges from '@tovia/man-ui/lib/components/Badge/Badges';

type Props = {
  badges?: ReactNode[];
  className?: string;
};

export const CardBadgeList = (props: Props) => {
  if (!props.badges?.length) return null;

  return (
    <div className={props.className}>
      <Badges>{props.badges}</Badges>
    </div>
  );
};

export default CardBadgeList;
