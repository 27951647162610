import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';
import Slider from '@tovia/man-ui/lib/components/Slider/Slider';

export const NormalSliderComponent = (props) => {
  const { className, settings, teasers, hideSlider } = props;

  const renderContent = () =>
    teasers.map((teaser) => {
      const { name, models, galleryUUID, imgSrc } = teaser;
      return (
        <div key={galleryUUID}>
          <div className="title-wrap">
            {name && <h1 className="title-text">{name}</h1>}
            <span>{models.length ? `${models.join(' and ')}` : models}</span>
            <span className="mobile-title">{`${name} - ${models.length ? `${models.join(' and ')}` : models}`}</span>
          </div>
          <div className="hero" style={{ backgroundImage: `url(${imgSrc})`, backgroundSize: 'cover' }} />
        </div>
      );
    });

  return (
    <>
      <Slider settings={settings} className={`${className} slider-wrap`}>
        {renderContent(teasers)}
      </Slider>
      <button type="button" onClick={hideSlider} className="hide-btn btn btn-primary">
        Watch Full Movie for Free
      </button>
    </>
  );
};

NormalSliderComponent.propTypes = {
  settings: PropTypes.object.isRequired,
  teasers: PropTypes.array.isRequired,
  className: PropTypes.string,
  hideSlider: PropTypes.func.isRequired,
};

// Same approach for defaultProps too
NormalSliderComponent.defaultProps = {
  className: '',
};

export default withTheme(styled(NormalSliderComponent)`
  &.slider-wrap {
    position: relative;
    height: 585px;
    background: #181818;
    z-index: 2;
    margin: 0 auto;
    padding: 0;
    width: 100%;

    @media (max-width: 768px) {
      width: 100vw;
      height: 200px;
      position: relative;

      .hide-btn {
        left: 0;
        width: 100vw;
        bottom: 0;
      }
    }

    .title-wrap {
      position: absolute;
      color: #ffffff;
      left: 10px;
      top: auto;
      bottom: 280px;
      width: 450px;
      text-align: center;

      .title-text {
        text-transform: uppercase;
      }

      @media (max-width: 768px) {
        width: 100vw;
        left: 0;
        color: ${(props) => props.theme.primaryBtn1Text};
        background-color: rgba(0, 0, 0, 0.36);
        bottom: 30px;
      }

      h1 {
        font-weight: 400;
        font-size: 3rem;
        @media (max-width: 768px) {
          display: none;
        }
      }

      span {
        @media (max-width: 768px) {
          display: none;
        }
      }

      .mobile-title {
        display: none;
        @media (max-width: 768px) {
          display: inline-block;
          font-size: 0.85rem;
          line-height: 34px;
        }
      }
    }

    .slider {
      position: relative;
      display: block;
      height: 100%;
      padding: 0 !important;

      &:hover .slick-arrow {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .hero {
      height: 585px;
      background-size: cover;

      @media (max-width: 768px) {
        height: 173px;
        width: 100vw;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .slick-slider,
    .slick-track,
    .slick-list {
      height: 100%;
    }

    .slick-track {
      margin: 0;
    }

    .slick-arrow {
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 70px;
      border: 0 none;
      border-radius: 4px;
      background-color: #1c1c1c;
      color: transparent;
      transition: 0.5s opacity ease;
      opacity: 0;
      pointer-events: none;
      z-index: 3;

      :hover {
        background-color: #000000;
      }
    }

    .slick-prev {
      left: 15px;
    }

    .slick-next {
      right: 15px;
    }

    .slick-dots {
      list-style: none;
      display: flex !important;
      position: absolute;
      bottom: 16px;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0;

      @media (max-width: 768px) {
        display: none !important;
      }

      li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 10px;
        padding: 0;
        cursor: pointer;
        background: white;
        border-radius: 50%;
        cursor: pointer;
        opacity: 0.25;
      }

      li.slick-active {
        opacity: 0.75;
      }

      button {
        color: transparent;
        background: transparent;
        border: 0;
        outline: none;
      }
    }
  }

  & + .hide-btn {
    background: ${(props) => props.theme.primaryBtn1Bg};
    color: ${(props) => props.theme.primaryBtn1Text};
    :hover {
      background: ${(props) => props.theme.primaryBtn1HoverBg};
    }
    display: block;
    position: absolute;
    width: 270px;
    height: 52px;
    border: 0;
    border-radius: 3px;
    font-size: 1.15rem;
    top: auto;
    bottom: 200px;
    left: 100px;
    outline: none;
    z-index: 2;

    @media (max-width: 768px) {
      left: 0;
      width: 100vw;
      bottom: 0;
      font-size: 0.85rem;
      height: 30px;
    }
  }
`);
