import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { faCreditCardFront, faLockAlt, faShieldCheck } from '@fortawesome/pro-light-svg-icons';
import { StyledIcon } from '../../ui/StyledIcon';

type Props = {
  show?: boolean;
};

export const SecuritySection = (props: Props) => {
  const { show = true } = props;
  const { config } = useSelector((state) => state.app);

  if (!show) return null;

  return (
    <Wrapper>
      <div className="container text-center">
        <div className="row">
          <div className="col-sm-4">
            <Title>
              <StyledIcon $spaceRight icon={faLockAlt} />
              <FormattedMessage id="splash.securitySection.trustedSite.title" defaultMessage="Trusted Site" />
            </Title>
            <Description>
              <FormattedMessage
                id="splash.securitySection.trustedSite.description"
                defaultMessage="All of your billing and account information is encrypted via SSL on our secure servers."
              />
            </Description>
          </div>
          <div className="col-sm-4">
            <Title>
              <StyledIcon $spaceRight icon={faShieldCheck} />
              <FormattedMessage id="splash.securitySection.privacy.title" defaultMessage="Privacy Guaranteed" />
            </Title>
            <Description>
              <FormattedMessage
                id="splash.securitySection.privacy.description"
                defaultMessage="We are commited to your privacy and security. Please visit our {cc_icon} {privacy_policy} for more information."
                values={{
                  cc_icon: <StyledIcon icon={faCreditCardFront} />,
                  privacy_policy: (
                    <a className="light-link" href={config.footer.privacyUrl}>
                      <FormattedMessage id="splash.securitySection.privacy.button" defaultMessage="privacy policy" />
                    </a>
                  ),
                }}
              />
            </Description>
          </div>
          <div className="col-sm-4">
            <Title>
              <StyledIcon $spaceRight icon={faCreditCardFront} />
              <FormattedMessage id="splash.securitySection.payment.title" defaultMessage="Payment Options" />
            </Title>
            <Description>Visa, MasterCard, Discover, Paypal, Bitcoin, Cheque/Debit, DirectPay, uKash</Description>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: ${(props) => props.theme.splashOptional1};
  padding: 30px 0;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  line-height: 28px;
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 0.85rem;
  margin-bottom: 10px;
  line-height: 20px;

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }

  svg {
    vertical-align: middle;
  }
`;
