import React, { Fragment, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import Slider from '../../../components/sliders/Slider/Slider';
import { loadBanner } from '../../../redux/modules/updates';
import { useSelector } from 'src/client/redux';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';
import { BlurrableImage } from 'src/client/components/ui/BlurredImage';

type Props = {
  name?: string;
};

export const UpdatesSlider = (props: Props) => {
  const { name } = props;

  const banners = useSelector((state) => state.updates.banners);
  const site = useSelector((state) => state.site);
  const contentCdnUrl = useSelector((state) => state.app.contentCdnUrl);
  const dispatch = useDispatch();
  const isBlurred = useShowSfwSite();

  useEffect(() => {
    dispatch(loadBanner({ name }));
  }, [dispatch, name]);

  const settings = {
    autoplay: true,
    autoplaySpeed: 7000,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 650,
  };

  if (!banners?.length) {
    return null;
  }

  return (
    <StyledSlider settings={settings}>
      {banners.map((banner) => (
        <Fragment key={banner.name}>
          <NavLink to={banner.path}>
            <BlurrableImage
              className="image"
              src={`${contentCdnUrl}/${site.UUID}${banner.coverImagePath}`}
              alt={banner.name}
              $isBlurred={isBlurred}
            />
            <span className="text">
              {banner.models.length ? `${banner.models.join(' & ')} in ` : ''}
              {banner.name}
              {banner.photographers.length ? ` by ${banner.photographers.join(' & ')}` : ''}
            </span>
          </NavLink>
        </Fragment>
      ))}
    </StyledSlider>
  );
};

const StyledSlider = styled(Slider)`
  margin: 0 -15px;
  padding: 0;

  & .previous,
  & .next {
    height: 70px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
  }

  & .slick-prev.previous,
  & .slick-next.next {
    background-color: ${(props) => props.theme.primary2};
    color: ${(props) => props.theme.primary4};

    :hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.primary3};
    }
  }

  & .slick-prev.previous,
  & .previous {
    left: 5px;
  }

  & .slick-next.next,
  & .next {
    right: 5px;
  }

  & .image {
    width: 100%;
  }

  & .text {
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 0;
    display: block;
    padding: 5px 0 5px 10px;
    position: absolute;
    width: 100%;
    color: white;
  }
`;
