import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import NewsletterFormComponent, { Status } from '../../components/forms/NewsletterForm';
import { newsletterStatusPropTypes, subscribe } from '../../redux/modules/newsletter';

export const newsletterForm = 'newsletterForm';

export class NewsletterFormClass extends Component {
  static propTypes = {
    subscribe: PropTypes.func.isRequired,
    submitBtnClassName: PropTypes.string,
    status: newsletterStatusPropTypes.isRequired,
    userEmail: PropTypes.string,
    submitting: PropTypes.bool.isRequired,
    newsletterType: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    submitBtnClassName: '',
    userEmail: '',
  };

  onSubmit = (values) => {
    this.props.subscribe({
      email: values.email,
      type: values.type,
    });
  };

  getInitialValues = () => {
    const { userEmail, newsletterType, intl } = this.props;
    // const submitMessage = userEmail ? 'Join newsletter, get free content' : 'Subscribe';
    const submitMessage = intl.formatMessage({ id: 'newsletterForm.buttons.subscribe', defaultMessage: 'Subscribe' });
    const result = {
      submit: submitMessage,
    };
    if (userEmail) {
      result.email = userEmail;
    }
    result.type = newsletterType;
    return result;
  };

  render() {
    const { submitBtnClassName, status, userEmail, submitting } = this.props;

    return (
      <div className="news-letter-form">
        <NewsletterFormComponent
          form={newsletterForm}
          onSubmit={this.onSubmit}
          initialValues={this.getInitialValues()}
          submitBtnClassName={submitBtnClassName}
          status={Status[status]}
          email={userEmail}
          disabled={submitting}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    status: state.newsletter.status,
    error: state.newsletter.error,
    userEmail: state.auth.user && state.auth.user.email,
    submitting: state.newsletter.submitting,
  }),
  (dispatch) => bindActionCreators({ subscribe }, dispatch),
)(injectIntl(NewsletterFormClass));
