// sortBy key used, order & direction not used
export const SORT_TYPE_RELEVANCE = {
  id: 'relevance',
  title: 'Relevance',
  sortBy: 'relevance',
  intlID: 'relevance',
};
export const SORT_TYPE_LATEST_GALLERY = {
  id: 'latest',
  title: 'Latest',
  order: 'date',
  direction: 'desc',
  sortBy: 'latest-gallery',
  intlID: 'latest',
};
export const SORT_TYPE_TOP_GALLERY = {
  id: 'top',
  title: 'Top Rated Gallery',
  order: 'rating',
  direction: 'desc',
  sortBy: 'top-gallery',
  intlID: 'top',
};
export const SORT_TYPE_MOST_VIEWED = {
  id: 'viewed',
  title: 'Most Viewed Gallery',
  order: 'views',
  direction: 'desc',
  sortBy: 'viewed-gallery',
  intlID: 'viewed',
};
export const SORT_TYPE_POPULAR = {
  id: 'popular',
  title: 'Most Popular Gallery',
  order: 'favorites',
  direction: 'desc',
  sortBy: 'popular-gallery',
  intlID: 'popular',
};
export const SORT_TYPE_TOP_MODEL = {
  id: 'top-model',
  title: 'Top Rated Models',
  order: 'model-rating',
  direction: 'desc',
  sortBy: 'top-model',
  intlID: 'topModel',
};
export const SORT_TYPE_NEW_MODEL = {
  id: 'new-model',
  title: 'Newest Model',
  order: 'model-date',
  direction: 'desc',
  sortBy: 'new-model',
  intlID: 'newModel',
};
export const SORT_TYPE_MODEL_NAME = {
  id: 'name',
  title: 'Name',
  order: 'name',
  direction: 'asc',
  sortBy: 'name',
  intlID: 'name',
};

export const SORT_TYPES = [
  SORT_TYPE_RELEVANCE,
  SORT_TYPE_LATEST_GALLERY,
  SORT_TYPE_TOP_GALLERY,
  SORT_TYPE_MOST_VIEWED,
  SORT_TYPE_POPULAR,
  SORT_TYPE_TOP_MODEL,
  SORT_TYPE_NEW_MODEL,
  SORT_TYPE_MODEL_NAME,
];
