import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { faReply } from '@fortawesome/pro-regular-svg-icons/faReply';
import { faThumbsUp } from '@fortawesome/pro-regular-svg-icons/faThumbsUp';

import { SharedProps } from './Comment';
import { Badges, Badge } from '@tovia/man-ui';
import CommentForm from './CommentForm';

type Props = {
  isReplyFormVisible: boolean;
  toggleReplyForm: () => void;
  onCommentSubmit: (data: unknown) => void;
} & SharedProps;

export const CommentActions = (props: Props) => {
  const {
    commentForm,
    disallowRate,
    isRated,
    isReplyFormVisible,
    isUser,
    link,
    onCommentSubmit,
    onRate,
    ratesCount = 0,
    replyText,
    toggleReplyForm,
    UUID,
  } = props;

  const commentFormProps = {
    commentForm,
    toggleReplyForm,
    onCommentSubmit,
    commentUUID: UUID,
  };

  return (
    <Actions className="actions">
      <StyledBadges id="actions">
        {!link && isUser && (
          <>
            <Badge
              className="comment-actions"
              icon={<FontAwesomeIcon icon={faReply} />}
              onClick={toggleReplyForm}
              text={replyText}
            />
          </>
        )}
        {isUser && (
          <Badge
            active={isRated}
            className={cx('comment-actions favorite')}
            icon={<FontAwesomeIcon icon={faThumbsUp} />}
            onClick={disallowRate ? undefined : onRate}
            text={ratesCount + (isRated ? 1 : 0)}
          />
        )}
      </StyledBadges>
      {isReplyFormVisible && <CommentForm {...commentFormProps} />}
    </Actions>
  );
};

const StyledBadges = styled(Badges)`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Actions = styled.div`
  margin-top: 0;
  max-width: 635px;
  overflow: hidden;

  .badges {
    margin-left: -5px;
  }

  .badge {
    cursor: pointer;
    margin-right: 5px;

    :hover,
    :focus {
      text-decoration: none;
    }

    svg {
      margin-right: 3px;
    }
  }
`;

export default CommentActions;
