import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type Props = {
  className?: string;
  href: string;
  icon: IconProp;
  title: string;
  visible: boolean;
};

export const SocialMediaLink = (props: Props) => {
  if (!props.visible) return null;

  return (
    <a title={props.title} href={props.href} target="_blank" rel="noopener noreferrer" className={props.className}>
      <FontAwesomeIcon icon={props.icon} size="2x" className="icon" />
    </a>
  );
};
