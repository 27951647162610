import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import Helmet from 'react-helmet';
import { useSelector } from '../redux/modules/helpers/useSelector';

export const VwoTest = () => {
  const { test } = useSelector((state) => state.app);

  const params = useParams<{ testId: string }>();
  const { testId } = params;

  const activateVWO = useCallback(() => {
    window.VWO = window.VWO || [];
    window.VWO.push(['activate', false, [testId], true]);
    // eslint-disable-next-line no-console
    console.log('Manually Activated:', testId);
  }, [testId]);

  return (
    <div className="content container vwo-test-page">
      <Helmet>
        <script>{`var testIdOverride = ${testId};`}</script>
        {!test && (
          <script>
            {`
window._vwo_code = window._vwo_code || (function(){
var account_id=555654,
settings_tolerance=4000,
library_tolerance=10000,
use_existing_jquery=false,
is_spa=1,
hide_element='',

/* DO NOT EDIT BELOW THIS LINE */
f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
window.settings_timer=setTimeout(function () {_vwo_code.finish() },settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());`}
          </script>
        )}
      </Helmet>
      <span>Note: If text below is green and underlined, the campaign activated</span>
      <br />
      <br />
      <p>Test Id is: {testId}</p>
      <p>VWO script is {window.VWO ? 'active' : 'disabled'}</p>
      <button onClick={activateVWO}>Activate Test</button>
    </div>
  );
};
