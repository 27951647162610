import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';

type Props = {
  children: ReactNode;
  className?: string;
  isNetworkWideGallery: boolean;
  to: string;
};

const staffSelectionDomain = 'metartnetwork.com';

export const NetworkLink: FunctionComponent<Props> = (props) => {
  const { siteSubdomain } = useSelector((state) => state.app);

  if (props.isNetworkWideGallery) {
    return (
      <a href={`https://${siteSubdomain}.${staffSelectionDomain}${props.to}`} className={props.className}>
        {props.children}
      </a>
    );
  }

  return (
    <Link to={props.to} className={props.className}>
      {props.children}
    </Link>
  );
};
