import React from 'react';
import { useSelector } from 'src/client/redux';
import { STATUS_OPTED_IN, TYPE_TOP_10 } from 'src/shared/constants/newsletter';
import { StyledIcon } from 'src/client/components/ui/StyledIcon';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import NewsletterForm, { newsletterForm } from 'src/client/containers/NewsletterForm/NewsletterForm';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';
import styled from 'styled-components/macro';

type Props = {
  setInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  infoModal: boolean;
};

export const FooterBarNewsletter = (props: Props) => {
  const { status, submitted } = useSelector((state) => state.newsletter);
  const { footer, isWhiteLabel } = useSelector((state) => state.app.config);
  const showSfwSite = useShowSfwSite();
  const newsletterStatus = status !== STATUS_OPTED_IN || submitted;

  if (showSfwSite || !newsletterStatus || !footer.hasWeeklyTopModels) {
    return null;
  }

  return (
    <NewsletterWrapper className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
      <div>
        <span className="h4 text-uppercase hidden-xs">
          {isWhiteLabel ? (
            <>Get free content in your email</>
          ) : (
            <>
              <strong className="highlight">Free</strong> Weekly Top Ten Models
            </>
          )}
        </span>
        <span className="h4 visible-xs dont-miss">Don&#39;t Miss Out</span>
        <NewsletterText>
          {isWhiteLabel ? (
            <>Get our newsletter and receive free content right to your inbox!</>
          ) : (
            <>
              <strong>Receive</strong> the <strong>Weekly Top 10 Models</strong> directly to your <strong>inbox</strong>
              . You'll also get <strong>exclusive deals</strong> only available through our newsletters.
            </>
          )}
          <br />
          <div className="more-info-button" onClick={() => props.setInfoModal(!props.infoModal)}>
            <StyledIcon $spaceRight icon={faInfoCircle} />
            <span>More Information</span>
          </div>
        </NewsletterText>
        <NewsletterForm
          newsletterType={TYPE_TOP_10}
          submitBtnClassName="btn btn-primary subscribe-btn"
          form={newsletterForm}
        />
        <div className="hidden-lg v-offset-35" />
        <div className="clear" />
      </div>
    </NewsletterWrapper>
  );
};

const NewsletterWrapper = styled.li`
  .subscribe-btn {
    @media (max-width: 767px) {
      position: relative;
      max-width: 90%;
      left: 5%;
    }
  }
`;

const NewsletterText = styled.span`
  font-size: 0.9rem;
  display: block;
  margin: 0 40px 18px 0;
  line-height: 24px;

  @media (max-width: 767px) {
    font-size: 0.85rem;
    margin: 0 0 15px;
    padding: 0 22px;
    line-height: 18px;
    text-align: center;
  }

  .highlight {
    padding-left: 4px;
    color: ${(props) => props.theme.footerBarPrimary3};
  }

  strong {
    color: ${(props) => props.theme.footerBarPrimary3};
  }
`;
