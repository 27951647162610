import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Field } from 'redux-form';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components/macro';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { StyledIcon } from '../ui/StyledIcon';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

const transitionDuration = 300; // must match transition speed in css

type Props = {
  submitting: boolean;
  isOpened: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: (data: any) => void;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  change: (field: string, value: any) => void;
  autoCompleteValues: string[];
  handleClick: () => void;
};

export const SearchBoxComponent = (props: Props) => {
  const { submitting, isOpened, handleSubmit, onClose, autoCompleteValues = [], handleClick } = props;

  const searchInput = useRef<Field<HTMLInputElement> | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<number | undefined>();
  const searchBox = useRef<HTMLDivElement | null>(null);
  const history = useHistory();

  useEffect(() => {
    if (searchInput && isOpened) {
      // @ts-ignore
      setSearchTerm(searchInput?.current?.getRenderedComponent().value || '');
      // @ts-ignore
      searchInput?.current?.getRenderedComponent().focus();
    }
  }, [searchInput, isOpened]);

  useEffect(() => {
    setSelectedOption(undefined);
  }, [autoCompleteValues]);

  useEffect(() => {
    if (searchInput && isOpened) {
      // @ts-ignore
      searchInput?.current?.getRenderedComponent().focus();
    }
  }, [autoCompleteValues, isOpened, selectedOption, searchInput]);

  const handleMouseClick = useCallback(
    (e) => {
      if (searchBox.current) {
        if (searchBox.current.contains(e.target)) {
          return;
        }
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, [handleMouseClick]);

  const clearSearchField = useCallback(() => {
    props.change('searchTerm', null);
  }, [props]);

  const boldValue = useCallback(
    (value) => {
      const regex = new RegExp(`(${searchTerm})`, 'gi');
      return value.replace(regex, '<b>$1</b>');
    },
    [searchTerm],
  );

  const keyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        if (selectedOption !== undefined) {
          e.preventDefault();
          history.push(`/search/${autoCompleteValues[selectedOption].replace(/\s+/g, '+')}`);
          clearSearchField();
          onClose();
          return;
        }
        return;
      }
      if ((e.keyCode === 38 || e.keyCode === 40) && autoCompleteValues.length) {
        // up
        e.preventDefault();
        let value;
        if (selectedOption !== undefined) {
          value = selectedOption + (e.keyCode === 40 ? 1 : -1);
        } else {
          value = e.keyCode === 40 ? 0 : autoCompleteValues.length - 1;
        }
        setSelectedOption(value >= 0 && value < autoCompleteValues.length ? value : undefined);
      }
    },
    [onClose, clearSearchField, autoCompleteValues, history, selectedOption],
  );

  // @ts-ignore
  return (
    <SearchWrapper>
      <CSSTransition in={isOpened} classNames="searchbox" timeout={transitionDuration} unmountOnExit>
        <div>
          <div className="container" ref={searchBox}>
            <fieldset disabled={submitting}>
              <form
                onSubmit={(data) => {
                  handleSubmit(data);
                  clearSearchField();
                }}
                className="form"
              >
                <div className={`input-group ${autoCompleteValues.length ? 'autocomplete-borders' : ''}`}>
                  <a
                    className="input-group-addon search-button"
                    onClick={(data) => {
                      handleSubmit(data);
                      clearSearchField();
                    }}
                  >
                    <StyledIcon icon={faSearch} />
                  </a>
                  <div className="input">
                    <Field
                      // @ts-ignore
                      forwardRef
                      ref={searchInput}
                      component="input"
                      name="searchTerm"
                      type="text"
                      autoComplete="off"
                      placeholder="Search galleries and models"
                      className="form-control input-lg search-input"
                      onKeyDown={keyDown}
                    />
                    <a
                      className="btn-close"
                      onClick={() => {
                        onClose();
                        clearSearchField();
                      }}
                    >
                      <StyledIcon icon={faTimes} size="2x" />
                    </a>
                  </div>
                  {autoCompleteValues.length > 0 && (
                    <div
                      className="search-autocomplete"
                      onMouseOver={() => setSelectedOption(undefined)}
                      onFocus={() => {}}
                      onKeyDown={keyDown}
                    >
                      {autoCompleteValues.map((value, index) => (
                        <NavLink
                          key={value}
                          to={`/search/${value.replace(/\s+/g, '+')}`}
                          onClick={() => {
                            handleClick();
                            onClose();
                            clearSearchField();
                          }}
                          className={`autocomplete-item ${selectedOption === index ? 'active-item' : ''}`}
                        >
                          <span
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: boldValue(value) }}
                          />
                        </NavLink>
                      ))}
                    </div>
                  )}
                </div>
              </form>
            </fieldset>
            <NavLink
              to="/search"
              className="advanced pull-right"
              onClick={() => {
                onClose();
                clearSearchField();
              }}
            >
              Advanced Search
            </NavLink>
          </div>
        </div>
      </CSSTransition>
    </SearchWrapper>
  );
};

const SearchWrapper = styled.div`
  position: relative;
  z-index: 3;

  & .container {
    padding-top: 11px;
    padding-bottom: 10px;
    margin-bottom: -5px;
    display: block;
  }

  & .form {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
  }

  & .input-group {
    position: relative;
    display: table;
    border-collapse: separate;
    border-radius: 5px;
    background: #fff;
    border: 1px solid ${(props) => props.theme.primary3};

    &.autocomplete-borders {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  & .search-form {
    height: 0;
  }

  & .search-button {
    margin-right: 12px;
    border: 0;
    box-shadow: none;
    font-size: 1.3rem;
    cursor: pointer;
    transition: 0.3s opacity;
    display: table-cell;
    background: #fff;

    ${StyledIcon} {
      color: #adadad;
    }

    :hover,
    :focus {
      opacity: 1;
    }
  }

  & .form input.search-input {
    font-weight: 300;
    font-size: 1.6rem;
    border: 0;
    box-shadow: none;
    height: 46px;
    padding: 10px 16px;
    line-height: 1.33;
    max-width: 97%;
    display: block;
    background: #fff;
    color: #555;

    :focus {
      border: 0;
      box-shadow: none;
    }
  }

  & .btn-close {
    float: right;
    position: absolute;
    height: 100%;
    display: inline-flex;
    align-items: center;

    ${StyledIcon} {
      color: #a94442;
      cursor: pointer;
      transition: 0.3s opacity;
    }

    :hover,
    :focus {
      border: 0;
      text-decoration: none;
      outline: none;
    }
  }

  & .searchbox-enter {
    max-height: 0;
    overflow: hidden;
  }

  & .searchbox-enter.searchbox-enter-active {
    max-height: 85px;
    transition: max-height ${transitionDuration}ms ease-in;
  }

  & .searchbox-exit {
    max-height: 85px;
  }

  & .searchbox-exit.searchbox-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: max-height ${transitionDuration}ms ease-in;
  }

  & .search-autocomplete {
    background-color: #fff;
    border: 1px solid ${(props) => props.theme.primary3};
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    left: 0;
    line-height: 1.33;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;

    .autocomplete-item {
      color: #555;
      display: block;
      font-size: 0.85rem;
      padding: 5px 0 5px 58px;

      &:hover,
      &.active-item {
        background-color: #e6e6e6;
        text-decoration: none;
      }
    }
  }
`;
