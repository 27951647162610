import React, { ChangeEvent, FormEventHandler, useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from '@tovia/man-ui/lib/components/Button/Button';
import { SkinnyPage } from '../components/layouts/SkinnyPage';
import { ApiClient } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

const Input = styled.input`
  @media (min-width: 768px) {
    max-width: 50%;
  }

  &:not(:placeholder-shown):invalid {
    box-shadow: 0 0 0 1px red;
  }
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Red = styled.span`
  color: red;
`;

const errorMessage = (
  <div>
    <Red as="p">There was an error submitting the. Plesae try again later.</Red>
    <br />
  </div>
);

const successMessage = (
  <div>
    <p>Your email address has been removed.</p>
    <br />
  </div>
);

const confirmMessage = (email: string) => (
  <>
    <p>
      Please confirm that you would like to opt-out the following email: <Red>{email}</Red>
    </p>
    <p>
      By confirming this email you will be removed from receiving all special offers from MetArt.com and affiliated
      sites.
    </p>
    <p>Email received from our billing companies may continue if you've signed-up for one of our sites.</p>
    <p>
      If you need to speak to our staff about the Opt-Out process please email us at{' '}
      <a href="maito:support@metart.com">support@metart.com</a>
    </p>
    <br />

    <p>
      By clicking the button below, you are confirming that you do not want to be a part of the best erotic nude site on
      the internet.
    </p>

    <p>
      Sincerely,
      <br />
      Met Staff :)
    </p>
  </>
);

export const OptOut = () => {
  const [error, showError] = useState(false);
  const [confirm, showConfirmMessage] = useState(false);
  const [email, setEmail] = useState('');
  const [success, showSuccess] = useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!confirm) {
      showConfirmMessage(true);
      return;
    }

    const form = e.currentTarget;
    const data = { email };

    try {
      const api = new ApiClient();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { body } = await (api as any).post(constructUrl(urls.post.optOut), { data });

      if (body?.status) {
        form.reset();
        showError(false);
        showConfirmMessage(false);
        showSuccess(true);
      }
    } catch (e) {
      showError(true);
    }
  };

  return (
    <SkinnyPage>
      <h2>Email Optout</h2>
      {error && errorMessage}

      {success ? (
        successMessage
      ) : (
        <form onSubmit={handleSubmit}>
          {confirm ? (
            confirmMessage(email)
          ) : (
            <>
              <p>
                This form allows you to remove yourself from receiving promotional offers from MetArt or its affiliated
                sites.
              </p>
              <br />
              <div className="form-group">
                <label>Email Address</label>
                <Input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="example@gmail.com"
                  required
                  value={email}
                  autoFocus
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                />
              </div>
            </>
          )}

          <br />
          <FormFooter>
            <Button className="btn btn-info" type="submit">
              {confirm ? 'Confirm email' : 'Remove my email addresss'}
            </Button>
          </FormFooter>
        </form>
      )}
    </SkinnyPage>
  );
};
