import React from 'react';
import { useSelector } from '../../redux/modules/helpers/useSelector';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import cx from 'classnames';
import { StyledIcon } from '../ui/StyledIcon';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

type Props = {
  displayNameStatus: null | boolean | 'loading';
  displayNameError: null | string;
  newDisplayName: string;
  setNewDisplayName: (name: string) => void;
  displayNameFieldRef: React.RefObject<HTMLInputElement>;
  displayNameValidationError: null | string;
};

export const DisplayNameField = (props: Props) => {
  const user = useSelector((state) => state.auth.user);
  const displayName = user?.displayname;
  const { newDisplayName, setNewDisplayName, displayNameError, displayNameStatus } = props;

  if (displayName) {
    return (
      <Headline>
        <NavLink to={`/user/${displayName}/comments/`}>{displayName}</NavLink>
      </Headline>
    );
  }

  return (
    <DisplayNameWrapper
      className={cx('form-group', 'display-name-wrapper', {
        'display-name-valid': displayNameStatus === true,
        'display-name-invalid': displayNameStatus === false,
      })}
      data-validation-error={props.displayNameValidationError}
    >
      <InputWrapper>
        <DisplayNameInput
          name="displayName"
          type="text"
          value={newDisplayName}
          onChange={(e) => setNewDisplayName(e.target.value)}
          className="form-control input-lg"
          placeholder="Choose a name"
          ref={props.displayNameFieldRef}
        />
        {displayNameStatus === 'loading' && <LoadingIcon $spaceLeft icon={faSpinner} spin />}
      </InputWrapper>
      {displayNameError ? (
        <span className="error-msg">{displayNameError}</span>
      ) : (
        <FormattedMessage
          tagName={DisplayNameInfo}
          id="comments.form.displayNameTitle"
          defaultMessage="Once chosen, your display name cannot be changed."
        />
      )}
    </DisplayNameWrapper>
  );
};

const LoadingIcon = styled(StyledIcon)`
  margin-left: 0.5em;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
`;

const Headline = styled.h4`
  font-size: 1.3rem;
`;

const DisplayNameWrapper = styled.div`
  position: relative;

  .tooltip {
    opacity: 1;
    top: -30px;

    .tooltip-inner {
      em {
        padding-right: 0.5em;
      }
    }
  }
`;

const DisplayNameInput = styled.input`
  max-width: 240px;
  border: 1px solid #eee;
  background-color: #ffffff;
  color: #000000;

  .display-name-invalid && {
    border-color: #d9534f;
  }

  .display-name-valid && {
    border-color: #5cb85c;
  }
`;

const DisplayNameInfo = styled.div`
  display: block;
  margin: 10px 0;
`;
