import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Modal from '@tovia/man-ui/lib/components/Modal/AdvancedModal';
import FreeTrialForm from '../../components/forms/FreeTrialForm';
import { useSelector } from '../../redux/modules/helpers/useSelector';
import { freeTrialSignup as freeTrialSignupFn } from '../../redux/modules/marketing';
import { logoUrl } from 'src/client/helpers/urls/logoUrl';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

type Props = {
  show: boolean;
  onClose: () => void;
  className?: string;
};

const FreeTrialDialogComponent = (props: Props) => {
  const { onClose, className } = props;
  const { loading, error: freeTrialError, freeTrialSuccess } = useSelector((state) => state.marketing);

  const intl = useIntl();
  const site = useSelector((state) => state.site);
  const cdnUrl = useSelector((state) => state.app.cdnUrl);
  const dispatch = useDispatch();
  const freeTrialSignup = useCallback((values) => dispatch(freeTrialSignupFn(values)), [dispatch]);

  const showSfwSite = useShowSfwSite();

  const modalBackground = showSfwSite ? 'freeTrialDialogBackground_non_nude.jpg' : 'freeTrialDialogBackground.jpg';

  return (
    <StyledModal
      show={props.show}
      onClose={onClose}
      dialogClassName={`${className} free-trial-modal`}
      className={`free-trial-modal-body`}
      background={`${cdnUrl}/freeTrialSignup/${modalBackground}`}
    >
      <DialogContainer data-testid="free-trial-dialog">
        <Greeting>
          <img
            className="option option-1"
            src={`${cdnUrl}/freeTrialSignup/1_freeTrialDialogText.png`}
            alt={`Enjoy your FREE 24 hour membership to ${site.name}`}
          />
          <img
            className="option option-2"
            src={`${cdnUrl}/freeTrialSignup/2_freeTrialDialogText.png`}
            alt={`Enjoy your FREE 24 hour membership to ${site.name}`}
          />
          <img
            className="option option-3"
            src={`${cdnUrl}/freeTrialSignup/3_freeTrialDialogText.png`}
            alt={`Enjoy your FREE 24 hour membership to ${site.name}`}
          />
          <img
            className="option option-4"
            src={`${cdnUrl}/freeTrialSignup/7_freeTrialDialogText.png`}
            alt={`Enjoy your FREE 24 hour membership to ${site.name}`}
          />
          <img className="greeting-logo" src={logoUrl(cdnUrl, site)} alt={site.name} />
        </Greeting>
        <FormBody background={`${cdnUrl}/freeTrialSignup/freeTrialDialogFormBackground.png`}>
          <FreeTrialForm
            form="freeTrialSignupDialog"
            // @ts-ignore
            loading={loading}
            onSubmit={(values) => {
              freeTrialSignup(values);
            }}
            success={freeTrialSuccess}
            submissionError={!!freeTrialError}
          />
        </FormBody>
        <SignupDisclaimer>
          {`* ${intl.formatMessage({
            id: 'freeTrialDialog.disclaimer.p1',
            defaultMessage: 'You will receive one full free day of access to',
          })}`}
          {site.name}
          {`, ${intl.formatMessage(
            { id: 'freeTrialDialog.disclaimer.p2', defaultMessage: 'rebilling at 29.99 every month after that.' },
            { monthlyPrice: '29.99' },
          )}`}
          <br />
          {`${intl.formatMessage({
            id: 'freeTrialDialog.disclaimer.p3',
            defaultMessage: 'You are also signing up for our special offers email list that will infrequently send you',
          })}`}
          <br />
          {`${intl.formatMessage(
            {
              id: 'freeTrialDialog.disclaimer.p4',
              defaultMessage: 'EXCLUSIVE deals on the MetArt Network. You can unsubscribe at any time.',
            },
            { networkName: 'MetArt Network' },
          )}`}
        </SignupDisclaimer>
      </DialogContainer>
    </StyledModal>
  );
};

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Greeting = styled.div`
  margin: 0 auto 10px;

  .greeting-logo {
    max-width: 150px;
  }

  img:not(.greeting-logo) {
    display: inline;

    &.option:not(.option-1) {
      display: none;
    }
  }
`;

const StyledModal = styled(Modal)<{ background: string }>`
  & > div {
    height: 100%;
  }

  &.free-trial-modal-body {
    width: 100%;
    height: 100%;
    background: transparent url(${(props) => props.background}) no-repeat center/cover;
    text-align: left;
    opacity: 1;
    padding: 30px 0;
    border-width: 1px;
    border-style: solid;
  }
`;

const SignupDisclaimer = styled.div`
  width: 674px;
  text-align: center;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 0.7rem;
  color: ${(props) => props.theme.primary7};
  margin-top: 8px;
`;

const FormBody = styled.div<{ background: string }>`
  background: transparent url(${(props) => props.background}) no-repeat;
  height: 177px;
  margin: 0 auto;
  position: relative;
  width: 616px;
`;

export const FreeTrialDialog = styled(FreeTrialDialogComponent)`
  // this style needs to be here to target modal dialog
  &.free-trial-modal {
    width: 674px;
    height: 500px;
    border-radius: 0;
    box-shadow: rgb(0 0 0 / 50%) 0 3px 9px;
    border: 0;
  }
`;
