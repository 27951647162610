import React, { ReactElement } from 'react';

type Props = {
  commentForm?: ReactElement;
  toggleReplyForm: () => void;
  onCommentSubmit: (data: unknown) => void;
  commentUUID: string;
};

const getFormName = (uuid: string | null) => `parent-${uuid}`;

export const CommentForm = (props: Props) => {
  const { commentForm, toggleReplyForm, onCommentSubmit, commentUUID = null } = props;

  if (!commentForm) return null;

  return React.cloneElement(commentForm, {
    onCancel: toggleReplyForm,
    onSubmit: onCommentSubmit,
    form: getFormName(commentUUID),
  });
};

export default CommentForm;
