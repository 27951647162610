import isEqual from 'lodash/isEqual';
import { call, fork, select, put, takeEvery } from 'redux-saga/effects';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';
import { ResponseError } from 'superagent';
import { ImageRequest, ImageResponse } from 'src/@types/app';
import { RootState } from 'src/client/redux/modules/reducer';

const RESET = 'man-site/image/RESET';
const LOAD = 'man-site/image/LOAD';
const LOAD_SAGA = 'man-site/image/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/image/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/image/LOAD_FAIL';
const FAVORITE_LOADED = 'man-site/image/FAVORITE_LOADED';

const endpoint = constructUrl(urls.get.image);

type State = {
  error?: ResponseError;
  favoriteLoaded?: string;
  item?: ImageResponse;
  lastCallParams?: ImageRequest;
  loaded: boolean;
  loading: boolean;
};

const initialState: State = {
  error: undefined,
  favoriteLoaded: undefined,
  item: undefined,
  lastCallParams: undefined,
  loaded: false,
  loading: false,
};

interface ActionLoad {
  type: typeof LOAD;
  lastCallParams: ImageRequest;
}

interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  result: ImageResponse;
}

interface ActionLoadFail {
  type: typeof LOAD_FAIL;
  error: ResponseError;
}

interface ActionFavoriteLoaded {
  type: typeof FAVORITE_LOADED;
  imageKey: string;
}

interface ActionReset {
  type: typeof RESET;
  name: string;
}

type Action = ActionLoad | ActionLoadSuccess | ActionLoadFail | ActionFavoriteLoaded | ActionReset;

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case LOAD: {
      const { lastCallParams } = action;
      return {
        ...state,
        loading: true,
        loaded: false,
        lastCallParams,
      };
    }
    case LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.result,
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
        item: initialState.item,
      };
    case FAVORITE_LOADED:
      return {
        ...state,
        favoriteLoaded: action.imageKey,
      };
    case RESET:
      return {
        ...initialState,
      };
    default: {
      return state;
    }
  }
}

export function load(params: ImageRequest) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}

export function isLoaded(params: ImageRequest, lastCallParams?: ImageRequest) {
  return isEqual(lastCallParams, params);
}

/* SAGAS */
const getState = (state: RootState) => state.image;

function* loadGenerator({ params }: { type: string; params: ImageRequest }) {
  const currentState: State = yield select(getState);
  const { item, lastCallParams } = currentState;

  if (!isLoaded(params, lastCallParams) && !currentState.loading) {
    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client) =>
        client.get(endpoint, {
          params,
        }),
      {
        lastCallParams: { ...params },
        item,
      },
    );
    yield call(loadFunc);
  }

  yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

  const imageKey = `${params.name}_${params.order}`;
  const newState: RootState = yield select();
  if (newState.auth.user && newState.image.favoriteLoaded !== imageKey && newState.image.item) {
    yield put({ type: FAVORITE_LOADED, imageKey });
    // Get array of uuids from media call
    const objectUUIDs = newState.image.item.media.reduce((uuids: string[], image) => {
      uuids.push(image.UUID);
      return uuids;
    }, []);
    yield put(
      loadRatingInfo({
        objectUUIDs,
      }),
    );
    yield put(
      loadFavoriteInfo({
        objectUUIDs,
      }),
    );
  }
}

// Trigger
function* watchLoad() {
  yield takeEvery(LOAD_SAGA, loadGenerator);
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
