import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { faTh, faThList } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setValue } from '../redux/modules/settings';
import { useSelector, useSettingsSelector } from '../redux/modules/helpers/useSelector';

type Props = {
  className?: string;
};

export const ConnectedViewOptions = (props: Props) => {
  const dispatch = useDispatch();
  const view = useSettingsSelector('listView');
  const showViewOptions = useSelector((state) => state.app.config.hasViewOptions);
  const onSelect = (value: string) => dispatch(setValue('listView', value));

  if (!showViewOptions) return null;

  return (
    <div className={props.className}>
      <ViewOptionsLink onClick={() => onSelect('detailed')} className={view === 'detailed' ? 'active' : ''}>
        <FontAwesomeIcon icon={faThList} />
      </ViewOptionsLink>
      <ViewOptionsLink onClick={() => onSelect('grid')} className={view === 'grid' ? 'active' : ''}>
        <FontAwesomeIcon icon={faTh} />
      </ViewOptionsLink>
    </div>
  );
};

const ViewOptionsLink = styled.a`
  cursor: pointer;
  font-size: 1.7rem;
  margin: 0 10px;
  color: ${(props) => props.theme.primary4};
  opacity: 0.5;
  transition: 0.3s opacity;

  svg {
    height: 22px;
  }

  &.active,
  &:hover,
  &:visited,
  &:focus {
    color: ${(props) => props.theme.primary4};
  }

  &.active,
  &:hover {
    opacity: 1;
  }
`;

export default ConnectedViewOptions;
