import React from 'react';
import { useDispatch } from 'react-redux';
import { formatViews } from 'src/client/utils/converters';
import ModelGridCard from '../Grid/Model/ModelGridCard';
import { rateItem } from 'src/client/redux/modules/ratingInfo';
import { addFavor, removeFavor } from 'src/client/redux/modules/favoriteInfo';
import { GridCardsWrapper } from 'src/client/containers/Cards/GridCardsWrapper';
import ModelDetailedCard from '../Detailed/Model/ModelDetailedCard';
import { useSelector, useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';
import { redirectToJoin, useJoinUrlGenerator } from 'src/client/components/buttons/JoinButton';
import { Photographer } from 'src/@types/Photographer';
import { useGetGalleryCoverUrl } from 'src/client/helpers/urls/useGetGalleryCoverUrl';

type Props = {
  photographers: Photographer[];
  viewPeriod?: string;
};

export default function PhotographerCards(props: Props) {
  const { photographers, viewPeriod = null } = props;
  const dispatch = useDispatch();
  const favoriteInfo = useSelector((state) => state.favoriteInfo.favoritingObjectUUIDs);
  const contentCdnUrl = useSelector((state) => state.app.contentCdnUrl);
  const user = useSelector((state) => state.auth.user);
  const config = useSelector((state) => state.app.config);
  const view = useSettingsSelector('listView');
  const ratingsScale = useSettingsSelector<number>('ratingsScale');
  const ratingInfo = useSelector((state) => state.ratingInfo.ratings);
  const generateJoinUrl = useJoinUrlGenerator();
  const getGalleryCover = useGetGalleryCoverUrl();

  const isUser = user && user.validSubscription;

  const PhotographerCardComponent = view === 'detailed' ? ModelDetailedCard : ModelGridCard;

  const cards = photographers.map((photographer) => {
    const isFavorited = favoriteInfo.includes(photographer.UUID);

    let { views } = photographer;
    if (viewPeriod) {
      views = photographer.leaderboardViews[viewPeriod];
    }

    const galleryCover = getGalleryCover(contentCdnUrl, photographer, {
      clean: config.cleanGalleryCovers,
      thumbnail: true,
      scrapeUUID: true,
    });

    return (
      <PhotographerCardComponent
        key={photographer.UUID}
        name={photographer.name}
        url={photographer.path}
        imgSrc={photographer.coverImagePath && galleryCover.url}
        isBlurred={galleryCover.blurImage}
        imgAlt={photographer.name}
        ratingAverage={
          photographer.ratingAverage && parseFloat(photographer.ratingAverage.toString()).toFixed(ratingsScale)
        }
        isFavorited={favoriteInfo.includes(photographer.UUID)}
        views={formatViews(views)}
        galleriesCount={photographer.galleriesCount}
        moviesCount={photographer.moviesCount}
        onFavorite={
          isUser
            ? () => {
                const favorParams = {
                  objectUUID: photographer.UUID,
                  objectType: 'PHOTOGRAPHER',
                  // favoriteThis: !(decoration[gallery.type] && decoration[gallery.type][gallery.UUID]
                  //     && decoration[gallery.type][gallery.UUID].favorite),
                };

                if (isFavorited) {
                  dispatch(removeFavor(favorParams));
                } else {
                  dispatch(addFavor(favorParams));
                }
                // toggleFavor(favorParams);
              }
            : () => redirectToJoin(generateJoinUrl, 'favorite-photographer-card')
        }
        ratingStars={{
          userRating: ratingInfo[photographer.UUID],
          submitRating: isUser
            ? (rating) =>
                dispatch(
                  rateItem({
                    objectUUID: photographer.UUID,
                    objectType: 'PHOTOGRAPHER',
                    rating,
                  }),
                )
            : () => redirectToJoin(generateJoinUrl, 'rate-photographer-card'),
          hideLabel: true,
          rating:
            (photographer.ratingAverage &&
              (parseFloat(photographer.ratingAverage.toString()).toFixed(ratingsScale) as string)) ||
            '0',
        }}
      />
    );
  });

  return <GridCardsWrapper>{cards}</GridCardsWrapper>;
}
