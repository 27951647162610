import React from 'react';
import styled from 'styled-components/macro';
import CardMedia from 'src/client/components/ui/Card/CardMedia';
import CardInformation from '@tovia/man-ui/lib/components/Card/CardInformation';
import Card from '@tovia/man-ui/lib/components/Card/Card';
import { Link } from 'react-router-dom';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

const Wrapper = styled.div`
  .card-media {
    overflow: hidden;
    padding-top: 150%;

    > a {
      position: absolute;
      top: 0;

      img {
        object-fit: contain;
      }
    }
  }
`;
type Props = {
  name: string;
  path: string;
  imgSrc: string;
  imgAlt: string;
};

export function MediaSectionGalleryCard(props: Props) {
  const { name, path, imgSrc, imgAlt } = props;

  const isBlurred = useShowSfwSite();

  return (
    <Wrapper className="grid-card-wrapper">
      <Card>
        <CardMedia altText={imgAlt} blurred={isBlurred} imgSrc={imgSrc} url={path} lazy animate></CardMedia>
        <CardInformation className="card-info-1" title={<Link to={path}>{name}</Link>} />
      </Card>
    </Wrapper>
  );
}
