import React from 'react';
import styled from 'styled-components/macro';
import { Button } from '@tovia/man-ui/lib/components/Button/Button';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  className?: string;
  direction: 'left' | 'right';
  onClick?: React.EventHandler<React.MouseEvent>;
};

export const NavigationButton = (props: Props) => {
  const { direction } = props;

  return (
    <StyledButton direction={direction} onClick={props.onClick} className={props.className}>
      <FontAwesomeIcon icon={props.direction === 'left' ? faAngleLeft : faAngleRight} />
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{ direction: 'left' | 'right' }>`
  border: 0 none;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  background-image: none !important;
  background-color: ${(props) => props.theme.primary6};
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  // extra 5px for the negative margin
  right: ${(props) => (props.direction === 'right' ? '5px' : '35px')};

  // push the buttons to the top of the slider
  // same margin as the title in NetworkGalleriesSliderV2.tsx
  bottom: calc(100% + 10px);

  :hover {
    background-color: ${(props) => props.theme.primary3};
  }
`;
