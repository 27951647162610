import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { NavOrExternalLink } from 'src/client/components/ui/NavOrExternalLink';

type CategoryLink = {
  className?: string;
  isExternal?: boolean;
  label: string;
  openNewWindow?: boolean;
  to: string;
};

type Props = {
  id: string;
  label: string;
  links: CategoryLink[];
  openedCategories: string[];
  setOpenCategories: (categories: string[]) => void;
};

export const FooterCategoryMenu = (props: Props) => {
  const { id, label, links, openedCategories, setOpenCategories } = props;
  const isOpen = openedCategories.includes(id);

  const toggleCategorySection = () => {
    if (isOpen) {
      setOpenCategories(openedCategories.filter((cat) => cat !== id));
      return;
    }
    setOpenCategories([...openedCategories, id]);
  };

  const items = links.map((item) => {
    return (
      <li className={cx('link', item.className)} key={`${id}-${item.to}`}>
        <NavOrExternalLink to={item.to} openNewWindow={item.openNewWindow || item.isExternal}>
          {item.label}
        </NavOrExternalLink>
      </li>
    );
  });

  return (
    <li className="col-lg-2 col-md-3 col-sm-3 col-xs-12" key={id}>
      <div onClick={toggleCategorySection} data-group={id}>
        <span className={cx('h4', { active: isOpen })}>
          {label}
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="pull-right visible-xs" />
        </span>
      </div>
      <ul className="list-unstyled">
        <CSSTransition component="div" classNames="ani-sub-expand" in={isOpen} unmountOnExit timeout={500}>
          <div className="">
            <div className="visible-xs">{items}</div>
          </div>
        </CSSTransition>
        <div className="hidden-xs">{items}</div>
      </ul>
    </li>
  );
};
