import React, { ReactNode, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { LocalRoute, routes } from 'src/client/utils/router';
import redirectHomepages from 'src/shared/constants/redirectHomepages';
import { useSelector, useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';
import { BreadCrumb } from './BreadCrumb';
import { ConstrainedContainer, TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
import { useWidePageContext } from '../App/WidePageContextProvider';

export type BreadCrumb = {
  url?: string;
  text?: string;
  id?: string;
  values?: Array<Record<string, string | number>>;
  node?: ReactNode;
};

const handleBCroute = (route: Partial<LocalRoute>, { match, state }): BreadCrumb[] => {
  if (!route.breadcrumb) {
    return [];
  }

  try {
    const bc = route.breadcrumb({ match, state });
    if (bc) {
      return Array.isArray(bc) ? bc : [bc];
    }
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.error('Cant generate breadcrumbs', error);
  }
  return [];
};

const List = styled.ol`
  margin: 0 auto;
  padding: 8px 15px;
  list-style: none;
  border-radius: 3px;
  background: transparent;
  display: none;

  @media ${TABLET_PORTRAIT} {
    display: block;
  }

  & a {
    color: ${(props) => props.theme.primary5};
  }

  & > li {
    display: inline-block;

    &:last-child {
      font-style: italic;
    }
  }

  & > li + li {
    color: ${(props) => props.theme.primary7};

    ::before {
      content: '/\\A0';
      padding: 0 5px;
      font-style: normal;
      color: ${(props) => props.theme.primary5};
    }
  }
`;

const useBreadcrumbs = () => {
  const homepage = useSettingsSelector('homepage');
  const storeState = useSelector((state) => state);
  const location = useLocation();

  // @TODO - This is messy. Should be cleaned up with some tweaks to router.tsx too.
  // `handleBCRoute` should just always return an array, maybe its just empty.
  const breadcrumbs = useMemo(() => {
    const matches: BreadCrumb[] = [];
    Object.keys(routes).forEach((routeName) => {
      const route = routes[routeName];
      if (!route.routes) {
        const match = matchPath(location.pathname, {
          path: route.path,
          exact: true,
        });
        if (!match) {
          return;
        }
        matches.push(...handleBCroute(route, { match, state: storeState }));
        return;
      }
      let found = false;

      Object.keys(route.routes).forEach((subRouteName) => {
        if (found) {
          return;
        }
        const subRoute = route?.routes?.[subRouteName];
        const match = matchPath(location.pathname, {
          path: subRoute?.path,
          exact: true,
        });
        if (!match) {
          return;
        }
        found = true;
        if (route.breadcrumb) {
          matches.push(...handleBCroute(route, { match, state: storeState }));
        }
        if (subRoute?.breadcrumb) {
          matches.push(...handleBCroute(subRoute, { match, state: storeState }));
        }
      });
    });

    // If there are any matches, we will prepend the homepage to the front.
    if (matches.length > 0) {
      matches.unshift({
        text: 'Home',
        url: redirectHomepages[homepage],
      });
    }

    return matches;
  }, [storeState, location.pathname, homepage]);

  return breadcrumbs;
};

export const BreadCrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  if (breadcrumbs.length === 0) {
    return null;
  }

  return (
    <BreadCrumbsListSwitcher>
      {breadcrumbs.map((breadcrumb, i) => (
        <BreadCrumb breadCrumb={breadcrumb} isFinal={i === breadcrumbs.length - 1} key={`bc-${i}`} />
      ))}
    </BreadCrumbsListSwitcher>
  );
};

// @TODO
// Remove after all pages are converted to wide mode.
const BreadCrumbsListSwitcher: React.FC = (props) => {
  const widePage = useWidePageContext();

  if (widePage) {
    return (
      <List as={ConstrainedContainer} extended>
        {props.children}
      </List>
    );
  }

  return <List className="breadcrumbs container">{props.children}</List>;
};
