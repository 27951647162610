import { take, call, fork } from 'redux-saga/effects';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

const LOAD = 'man-site/advancedSearch/LOAD';
const LOAD_SAGA = 'man-site/advancedSearch/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/advancedSearch/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/advancedSearch/LOAD_FAIL';

const endpoint = constructUrl(urls.get.searchForm);

const initialState = {
  attributes: false,
  error: false,
  loaded: false,
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        attributes: action.result.attributes,
        loaded: true,
        error: false,
        loading: false,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default: {
      return state;
    }
  }
}

export function load() {
  return {
    type: LOAD_SAGA,
  };
}

/* SAGAS */
function* loadGenerator() {
  const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint));
  yield call(loadFunc);
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    yield take(LOAD_SAGA);
    yield fork(loadGenerator);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
