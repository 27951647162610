import {
  DESKTOP,
  MOBILE_LANDSCAPE,
  TABLET_LANDSCAPE,
  XXXXX_LARGE,
  XXXX_LARGE,
  XXX_LARGE,
  XX_LARGE,
  X_LARGE,
} from 'src/client/constants/mediaQueries';
import styled from 'styled-components/macro';

export const StandardGrid = styled.section`
  display: grid;
  column-gap: 10px;
  row-gap: 20px;
  width: 100%;
  position: relative;
  grid-auto-rows: 1fr;

  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media ${MOBILE_LANDSCAPE} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media ${TABLET_LANDSCAPE} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media ${DESKTOP} {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  &.wide-page {
    @media ${X_LARGE} {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    @media ${XXX_LARGE} {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    @media ${XXXXX_LARGE} {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    .model-page & {
      @media ${TABLET_LANDSCAPE} {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @media ${DESKTOP} {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      @media ${X_LARGE} {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }

      @media ${XX_LARGE} {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }

      @media ${XXX_LARGE} {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }

      @media ${XXXX_LARGE} {
        grid-template-columns: repeat(7, minmax(0, 1fr));
      }

      @media ${XXXXX_LARGE} {
        grid-template-columns: repeat(8, minmax(0, 1fr));
      }
    }
  }
`;
