import { faInstagram, faRedditSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import React, { useContext, useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import styled from 'styled-components/macro';
import { SocialMediaLink } from 'src/client/components/buttons/SocialMediaLink';
import { MobileSplashLinks } from './MobileSplashLinks';
import { MobileLinksList } from './MobileLinksList';
import { MobileLink } from './MobileLink';
import { FormattedMessage } from 'react-intl';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { MobileLiveGirls } from './MobileLiveGirls';
import { MobileMoreGirls } from './MobileMoreGirls';
import { MobileSitePicker } from './MobileSitePicker';
import MobileSearchBox from './MobileSearchBox';
import { HeaderContext } from '../HeaderProvider';
import { MobileMenuTransition } from './MobileMenuTransition';
import { loadCamsFunction } from 'src/client/containers/Cams/CamsContextProvider';
import { useDispatch } from 'react-redux';
import { MobileSeoCategoriesPicker } from 'src/client/containers/HeaderBar/MobileMenu/MobileSeoCategoriesPicker';
import { MobileCamProviderLiveGirlsWrapper } from 'src/client/containers/HeaderBar/MobileMenu/MobileCamProviderLiveGirlsWrapper';

type Props = {
  isSplash: boolean;
};

export const MobileMenuDrawer = (props: Props) => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.app.config);
  const siteUUID = useSelector((state) => state.site.UUID);
  const history = useHistory();

  const { mobileMenuOpened, closeMobileMenu, activeNestedMobileMenu, setActiveNestedMobileMenu } = useContext(
    HeaderContext,
  );

  const { isSplash } = props;
  const isWideNestedMenu = activeNestedMobileMenu?.toString().startsWith('mobile-live-cam-girls-');

  const handleClose = () => {
    setActiveNestedMobileMenu(null);
    closeMobileMenu();
  };

  useEffect(() => {
    if (!mobileMenuOpened) return;

    dispatch(loadCamsFunction(config.camProviders, siteUUID));
  }, [dispatch, mobileMenuOpened, config.camProviders, siteUUID]);

  return (
    <MobileMenuTransition mobileMenuOpened={mobileMenuOpened}>
      <MobileSearchBox
        isSplash={isSplash}
        onSearch={(searchTerm) => history.push(`/search/${searchTerm}`)}
        onClose={handleClose}
      />

      <MobileMenu
        className={cx('mobile-menu', {
          'in-nested-menu': activeNestedMobileMenu !== null,
          isSplash,
          isWideNestedMenu,
        })}
      >
        <MobileMenuNav>
          <MobileSplashLinks isSplash={isSplash} closeMobileMenu={closeMobileMenu} />
          <MobileLinksList isSplash={isSplash} />
          {config.featureFlags?.enableSeoCategoriesMenu && <MobileSeoCategoriesPicker />}
          <MobileCamProviderLiveGirlsWrapper />
          <MobileLiveGirls />
          <MobileMoreGirls isSplash={isSplash} />
          <MobileSitePicker />
          <MobileLink
            label={<FormattedMessage id="header.links.support" defaultMessage="Support" />}
            href="https://metart.atlassian.net/servicedesk/customer/portals"
            icon={faQuestionCircle}
            className="support"
          />
        </MobileMenuNav>

        <SocialMedia>
          <SocialMediaLink
            href={`https://twitter.com/${config.twitter}`}
            icon={faTwitterSquare}
            title="Twitter"
            visible={!!config.twitter}
          />
          <SocialMediaLink
            href={`https://instagram.com/${config.instagram}`}
            icon={faInstagram}
            title="Instagram"
            visible={!!config.instagram}
          />
          <SocialMediaLink
            href={`https://reddit.com/r/${config.reddit}`}
            icon={faRedditSquare}
            title="reddit"
            visible={!!config.reddit}
          />
        </SocialMedia>
      </MobileMenu>
    </MobileMenuTransition>
  );
};

const SocialMedia = styled.div`
  margin: 20px 0 10px 0;
  text-align: center;
  display: flex;
  justify-content: center;

  a {
    position: relative;
    display: inline-flex;
    padding: 0 5px;
  }
`;

const MobileMenu = styled.div`
  width: 280px;
  transition: 0.3s ease transform;

  &.isWideNestedMenu {
    width: 360px;
  }

  &.isSplash {
    margin-top: 0;
  }

  &.in-nested-menu {
    transform: translateX(-100%);
  }
`;

const MobileMenuNav = styled.nav`
  border-top: 1px solid ${(props) => props.theme.primary3};
  display: flex;
  flex-direction: column;

  a {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.primary3};
    color: ${(props) => props.theme.primary4};
    padding: 10px;
    text-decoration: none;

    &.join-now {
      order: -1;
    }

    &.support {
      order: 2;
    }

    i:not(.exit) {
      color: ${(props) => props.theme.primary4};
    }

    i.exit {
      color: red;
    }
  }

  a.active {
    font-weight: bold;
    background-color: ${(props) => props.theme.primary3};
  }

  a:hover {
    font-weight: bold;
    color: ${(props) => props.theme.primary4};
  }

  .level {
    position: absolute;
    display: none;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 0;
  }

  .level.active {
    display: block;
    opacity: 1;
  }
`;
