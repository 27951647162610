import React from 'react';
import styled from 'styled-components/macro';
import Placeholder from '@tovia/man-ui/lib/components/Placeholder/Placeholder';

type Props = {
  width?: number;
  height?: number;
  className?: string;
};

export const Panel = (props: Props) => {
  const { width = 32, height = 192, className } = props;
  const { Line } = Placeholder;

  return (
    <PanelWrapper className={className} width={width} height={height}>
      <Placeholder>
        <Line />
      </Placeholder>
    </PanelWrapper>
  );
};

const PanelWrapper = styled.div<{ width?: number; height?: number }>`
  width: ${(props) => props.width}%;

  @media (max-width: 991px) {
    width: 100%;
  }

  .line {
    margin: 0;
    height: ${(props) => props.height}px;

    @media (max-width: 991px) {
      height: 320px;
    }
  }
`;
