import React from 'react';
import { useSelector } from 'src/client/redux';
import { SortType } from 'src/shared/constants/sortType';
import { IconOnlySortDropdown } from './IconOnlySortDropdown';
import { IconOnlyViewDropdown } from './IconOnlyViewDropdown';
import { DropdownContainer } from './styles';

type Props = {
  modelName?: string;
  pageType: string;
  photographerName?: string;
  sortSettingsKey?: 'commentSorting' | 'galleriesSorting' | 'filmsSorting' | 'modelsSorting';
  sortTypes: SortType[];
  tab: string;
  country?: string;
};

export const IconOnlySortAndViewDropdown = (props: Props) => {
  const { sortSettingsKey, sortTypes = [] } = props;

  const showViewOptions = useSelector((state) => state.app.config.hasViewOptions);
  if (!showViewOptions) return null;

  return (
    <DropdownContainer>
      {sortSettingsKey && sortTypes.length > 0 && (
        <IconOnlySortDropdown
          modelName={props.modelName}
          pageType={props.pageType}
          photographerName={props.photographerName}
          sortSettingsKey={sortSettingsKey}
          sortTypes={props.sortTypes}
          tab={props.tab}
          country={props.country}
        />
      )}
      <IconOnlyViewDropdown />
    </DropdownContainer>
  );
};
