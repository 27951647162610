import React from 'react';
import { useSelector } from 'src/client/redux';
import { MobileCamProviderLiveGirls } from 'src/client/containers/HeaderBar/MobileMenu/MobileCamProviderLiveGirls';

export const MobileCamProviderLiveGirlsWrapper = () => {
  const { cams, loaded } = useSelector((state) => state.cams);

  if (!loaded || !cams || cams.length === 1) return null;

  return (
    <>
      {cams.map((provider) => (
        <MobileCamProviderLiveGirls key={provider.provider} provider={provider} />
      ))}
    </>
  );
};
