import { SortType } from './sortType';

const SORT_TYPE_LATEST: SortType = {
  id: 'latest',
  title: 'Latest',
  order: 'DATE',
  direction: 'DESC',
  intlID: 'dropdown.latest', // corresponds to static/langs/X.json
};
const SORT_TYPE_TOP_RATED: SortType = {
  id: 'top',
  title: 'Top Rated',
  order: 'RATING',
  direction: 'DESC',
  intlID: 'dropdown.topRated',
};
const SORT_TYPE_MOST_VIEWED: SortType = {
  id: 'viewed',
  title: 'Most Viewed',
  order: 'VIEWS',
  direction: 'DESC',
  intlID: 'dropdown.mostViewed',
};

export const SORT_TYPE_MOST_FAVORITED: SortType = {
  id: 'favorited',
  title: 'Most Favorited',
  order: 'FAVORITES',
  direction: 'DESC',
  intlID: 'dropdown.mostFavorited',
};

export const SORT_TYPES: SortType[] = [
  SORT_TYPE_LATEST,
  SORT_TYPE_TOP_RATED,
  SORT_TYPE_MOST_VIEWED,
  SORT_TYPE_MOST_FAVORITED,
];
