import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { useSelector } from 'src/client/redux';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';
import { DiscountRibbon } from 'src/client/components/ui/DiscountRibbon';
import { SitePickerItem } from 'src/@types/SiteConfig';
import { addDashesToUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import { logoUrl } from 'src/client/helpers/urls/logoUrl';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

type Props = {
  sitePickerList: SitePickerItem[];
};

export const SitePickerDropdown = (props: Props) => {
  const { cdnUrl } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const [hoverSite, setHoverSite] = useState<SitePickerItem>(props.sitePickerList[0]);
  const [joinUrl] = useJoinUrl({ campaign: 'site-picker', overrideSite: hoverSite?.abbreviation });
  const intl = useIntl();
  const showSFWSite = useShowSfwSite();

  // Don't show join buttons on any existing subscriptions, or external sites.
  const hoverSiteSubscription = !!user?.subscriptions.find((sub) => addDashesToUUID(sub.siteUUID) === hoverSite.UUID);
  const showJoinButton = !hoverSiteSubscription && hoverSite.isInternalSite;
  const hoverBackground = showSFWSite ? `${hoverSite.abbreviation}_non_nude` : hoverSite.abbreviation;

  return (
    <SitepickerWrapper>
      <Menu className="list-unstyled">
        {props.sitePickerList.map((site) => (
          <MenuItem
            key={site.abbreviation}
            onMouseEnter={() => setHoverSite(site)}
            className={`unselectable clickable ${hoverSite.name === site.name ? 'active' : ''}`}
          >
            <MenuLink href={site.fullAffiliateLink} target="_blank" rel="noopener noreferrer">
              {site.name}
            </MenuLink>
          </MenuItem>
        ))}
      </Menu>
      <PreviewWrapper>
        <Preview
          className={hoverSite.abbreviation}
          background={`${cdnUrl}/sitepicker/background/${hoverBackground}.jpg`}
        >
          <DiscountRibbon {...hoverSite} />
          <PreviewLogo
            className={hoverSite.abbreviation}
            logo={logoUrl(cdnUrl, hoverSite, hoverSite.useInvertedLogo)}
          />
          <PreviewButtonsWrapper>
            {showJoinButton && (
              <PreviewButton href={joinUrl} background={hoverSite.joinLinkBackground} color={hoverSite.joinLinkColor}>
                {intl.formatMessage({ id: 'sitePicker.buttons.join', defaultMessage: 'Join Right Now!' })}
              </PreviewButton>
            )}
            <PreviewButton
              href={hoverSite.fullAffiliateLink}
              target="_blank"
              rel="noopener noreferrer"
              background={hoverSite.accessLinkBackground}
              color={hoverSite.accessLinkColor}
            >
              {intl.formatMessage({ id: 'sitePicker.buttons.accessSite', defaultMessage: 'Access Site' })}
            </PreviewButton>
          </PreviewButtonsWrapper>
        </Preview>
      </PreviewWrapper>
    </SitepickerWrapper>
  );
};

const Menu = styled.ul`
  flex: 1;
  margin: 0;
`;

const MenuItem = styled.li`
  display: block;
  text-align: right;

  &.active {
    background-color: ${(props) => props.theme.primary3};
  }
`;

const MenuLink = styled.a`
  line-height: 32px;
  height: 32px;
  display: block;
  font-size: 1rem;
  padding-left: 15px;
  margin-right: 30px;

  .active & {
    text-decoration: none;
  }
`;

const PreviewWrapper = styled.div`
  flex: 1;
  width: 344px;
  min-height: 100%;
  background: ${(props) => props.theme.primary3};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: background-image 0.3s;
`;

const Preview = styled.div<{ background: string }>`
  width: 344px;
  height: 454px;
  background: url(${(props) => props.background}) top no-repeat;
  position: relative;
`;

const PreviewLogo = styled.span<{ logo: string }>`
  width: 323px;
  height: 85px;
  display: block;
  margin: 15px auto 0;
  background: url(${(props) => props.logo}) top center no-repeat;
  background-size: contain;
  background-origin: content-box;
  padding: 4px;
`;

const PreviewButtonsWrapper = styled.div`
  position: absolute;
  left: 17px;
  bottom: 10px;
`;

const PreviewButton = styled.a<{ background?: string; color?: string }>`
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};

  width: 310px;
  height: 60px;
  line-height: 60px;
  font-size: 1.4rem;
  display: block;
  margin: 0 auto 10px;
  text-align: center;
  border-radius: 6px;
  text-transform: uppercase;

  :hover {
    text-decoration: none;
  }
`;

const SitepickerWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 8px;
`;
