import React, { ReactNode, useContext } from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { HeaderContext } from '../HeaderProvider';

type Props = {
  agentName?: string;
  didWhat: ReactNode;
  onWhat: {
    name: string;
    url: string;
  };
  moreInfo?: ReactNode;
  secondLine?: ReactNode;
};

export const FeedItem = (props: Props) => {
  const intl = useIntl();
  const { setActivePopover } = useContext(HeaderContext);

  const {
    agentName = intl.formatMessage({ id: 'activityFeed.agentName', defaultMessage: 'You' }),
    didWhat,
    onWhat: { name, url },
    moreInfo,
    secondLine,
  } = props;

  return (
    <FeedItemWrapper className="clickable" onClick={() => setActivePopover(null)} to={url}>
      <Avatar className="va-m inline-block">{agentName.slice(0, 1).toUpperCase()}</Avatar>
      <ActivityBody className="va-m inline-block ">
        <strong>{agentName}</strong>
        <LightText>{didWhat}</LightText>
        <span>{name}</span>
        <span>{moreInfo}</span>
        <div>{secondLine}</div>
      </ActivityBody>
    </FeedItemWrapper>
  );
};

export const FeedItemWrapper = styled(NavLink)`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: ${(props) => props.theme.primary6};
  padding: 10px 0;
  transition: background-color 0.3s;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: normal;
  text-align: left;
  font-size: 1rem;

  & :hover,
  :focus {
    text-decoration: none;
  }

  :hover {
    background-color: ${(props) => props.theme.primary6};
  }
`;

const LightText = styled.span`
  color: ${(props) => props.theme.primary7};
`;

const ActivityBody = styled.div`
  max-width: none;

  & > *:not(:last-child) {
    margin-right: 0.25em;
  }
`;

const Avatar = styled.div`
  font-size: 22pt;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
  width: 44px;
  height: 44px;
  display: inline-block;
  margin: 7px 15px;
  vertical-align: middle;
  background: ${(props) => props.theme.primary3};
  color: ${(props) => props.theme.primary4};
`;
