import { call, fork, take } from 'redux-saga/effects';

import { ResponseError } from 'superagent';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { Gallery } from 'src/@types/Gallery';

const LOAD = 'man-site/category/LOAD';
const LOAD_SAGA = 'man-site/category/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/category/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/category/LOAD_FAIL';

const endpoint = constructUrl(urls.get.category);

interface InitialState {
  loading?: boolean;
  loaded?: boolean;
  error?: ResponseError;
  galleries: Gallery[];
  name?: string;
  pageSize: number;
  itemCount: number;
}

const initialState: InitialState = {
  galleries: [],
  pageSize: 60,
  itemCount: 0,
};

interface ActionLoad {
  type: typeof LOAD;
}

interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  category: string;
  result: {
    items: [];
    total: number;
  };
}

interface ActionLoadFail {
  type: typeof LOAD_FAIL;
  error: ResponseError;
}

export default function reducer(
  state = initialState,
  action: ActionLoad | ActionLoadSuccess | ActionLoadFail,
): InitialState {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        name: action.category,
        galleries: action.result.items,
        itemCount: action.result.total,
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default: {
      return state;
    }
  }
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

function* loadGenerator(params) {
  const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint, { params }), {
    page: params.page,
    size: params.pageSize,
    hideGalleries: params.hideGalleries,
  });
  yield call(loadFunc);
}

function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
