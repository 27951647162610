import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'src/client/redux';
import styled from 'styled-components/macro';
import { ButtonAndText } from './Buttons';

type Props = {
  children?: ReactNode;
  overlayMediaUrl?: string;
  verifyMyAgeUrl?: string;
  closeOverlay: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const modalRoot = document.getElementById('modal-root')!;

const getMediaElement = (overlayMediaUrl: string) => {
  if (overlayMediaUrl.endsWith('.mp4')) {
    return (
      <Video autoPlay controls>
        <source src={overlayMediaUrl} type="video/mp4" />
      </Video>
    );
  }

  return <Image src={overlayMediaUrl} />;
};

export const MediaOverlay = (props: Props) => {
  const { verifyMyAgeRedirectUrl } = useSelector((state) => state.app);

  if (!props.overlayMediaUrl) return null;

  return createPortal(
    <Overlay>
      <div>
        <MediaContainer>{getMediaElement(props.overlayMediaUrl)}</MediaContainer>
        <h2>To view the full uncensored version</h2>
        <ButtonAndText type="verify" url={verifyMyAgeRedirectUrl} />
        <IconWrapper onClick={props.closeOverlay}>
          <FontAwesomeIcon icon={faTimes} />
        </IconWrapper>
      </div>
    </Overlay>,
    modalRoot,
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  text-align: center;

  h2 {
    padding: 0 10px;
  }
`;

const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 60px;
  height: 70vh;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 2 / 3;
`;

const Video = styled.video`
  max-height: 1080px;
  max-width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
  cursor: pointer;
  padding: 16px;

  && svg {
    width: 24px;
    height: 24px;
    display: block;
  }
`;
