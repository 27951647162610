import React, { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { IS_TRANSLATED_COOKIE } from 'src/shared/constants/cookies';
import styled from 'styled-components/macro';

export const LangChangeObserverComponent = () => {
  const [cookies, setCookie] = useCookies([IS_TRANSLATED_COOKIE]);
  const isTranslated = !!cookies[IS_TRANSLATED_COOKIE];

  const observer = useMemo(() => {
    if (isTranslated) {
      return null;
    }
    return new MutationObserver((mutationsList, observer) => {
      if (isTranslated) {
        observer.disconnect();
        return;
      }

      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
          const lang = (mutation.target as Element).getAttribute('lang');
          window.gtag('event', 'browser_translate', {
            lang: lang,
          });
          setCookie(IS_TRANSLATED_COOKIE, true, { path: '/' });
        }
      }
    });
  }, [isTranslated, setCookie]);

  useEffect(() => {
    if (!observer) {
      return;
    }

    observer.observe(document.documentElement, {
      attributes: true,
    });
    return () => {
      observer.disconnect();
    };
  }, [observer]);
  return <HiddenDiv id="lang_observer" />;
};

const HiddenDiv = styled.div`
  display: none;
`;
