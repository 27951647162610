import React, { ReactNode } from 'react';
import cx from 'classnames';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Badge, Card, CardInformation, CardMediaOverlay } from '@tovia/man-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt, faPlayCircle } from '@fortawesome/pro-light-svg-icons';
import { faStar, faVideo } from '@fortawesome/pro-solid-svg-icons';
import { faEye, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { formatViews } from 'src/client/utils/converters';
import { RibbonCorner } from '../Ribbon/RibbonCorner';
import CardMedia from 'src/client/components/ui/Card/CardMedia';

type Props = {
  alt: string;
  type: string;
  UUID: string;
  galleryUrl: string;
  galleryCoverUrl: string;
  title: string;
  imageCount?: number;
  runtime?: string;
  publishedAt?: ReactNode;
  ratingAverage?: string | number;
  favoriteCount?: number;
  views?: number;
  overlayTitle?: string;
  overlaySubTitle?: string;
  wrapperClassName?: string;
  isPromoGallery?: boolean;
  isBlurred: boolean;
};

export const UpdateStreamCard = (props: Props) => {
  const { wrapperClassName = 'col-md-4 col-sm-6 col-xs-6' } = props;
  const isGallery = props.type === 'GALLERY';
  const ribbonContent = isGallery ? 'BONUS SET' : 'BONUS FILM';

  return (
    <div className={cx(wrapperClassName, 'item', 'update-stream-card-wrapper')} style={{ position: 'relative' }}>
      <RibbonCorner text={props.isPromoGallery ? ribbonContent : ''} ribbonType="grid" />
      <StyledLink to={props.galleryUrl}>
        <StyledCard className="update-stream-card">
          <StyledCardMedia blurred={props.isBlurred} imgSrc={props.galleryCoverUrl} altText={props.alt} lazy animate>
            <CardMediaOverlay
              icon={props.overlayTitle ? undefined : <FontAwesomeIcon icon={isGallery ? faCameraAlt : faPlayCircle} />}
              title={props.overlayTitle}
              subTitle={props.overlaySubTitle}
              badges={[
                <Badge
                  key="runtime"
                  text={isGallery ? props.imageCount : props.runtime}
                  icon={<FontAwesomeIcon icon={isGallery ? faCameraAlt : faVideo} />}
                />,
              ]}
            />
          </StyledCardMedia>
          <CardInformation
            title={props.title}
            subTitle={props.publishedAt}
            badges={[
              ...(props.ratingAverage
                ? [<Badge key="rating" text={props.ratingAverage} icon={<FontAwesomeIcon icon={faStar} />} />]
                : []),
              ...(props.views
                ? [<Badge key="views" text={formatViews(props.views)} icon={<FontAwesomeIcon icon={faEye} />} />]
                : []),
              ...(props.favoriteCount
                ? [
                    <Badge
                      key="favorites"
                      text={formatViews(props.favoriteCount)}
                      icon={<FontAwesomeIcon icon={faThumbsUp} />}
                    />,
                  ]
                : []),
            ]}
          />
        </StyledCard>
      </StyledLink>
    </div>
  );
};

const StyledLink = styled(Link)`
  &,
  &:hover {
    text-decoration: none;
  }
`;

const StyledCard = styled(Card)`
  display: block;
`;

const StyledCardMedia = styled(CardMedia)`
  aspect-ratio: 100 / 150;
  position: relative;

  img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    top: 0;
  }
`;
