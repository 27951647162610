import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import CountButton from '@tovia/man-ui/lib/components/CountButton/CountButton';
import { useDispatch } from 'react-redux';
import { addFavor, removeFavor } from '../../redux/modules/favoriteInfo';
import { formatViews } from '../../utils/converters';
import ModelSubscription from '../../components/modals/ModelSubscriptionModal';
import { useSelector } from '../../redux/modules/helpers/useSelector';
import { redirectToJoin, useJoinUrlGenerator } from 'src/client/components/buttons/JoinButton';

const Div = styled.div`
  .btn.favorited {
    background-color: ${(props) => props.theme.primary7};
  }

  .count-btn {
    margin-top: 12px;
  }

  .count {
    border-color: ${(props) => props.theme.primary3};

    ::before {
      border-right-color: ${(props) => props.theme.primary3};
    }
    ::after {
      border-right-color: ${(props) => props.theme.primary1};
    }
  }
`;

const notifiableTypes = ['MODEL'];

export default function FavoriteButton(props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const favoriteInfo = useSelector((state) => state.favoriteInfo.favoritingObjectUUIDs);
  const intl = useIntl();
  const generateJoinUrl = useJoinUrlGenerator();

  const [showFollowModal, setFollowModalVisibility] = useState(false);

  const isFavorited = favoriteInfo.includes(props.objectUUID);
  const onToggle = user
    ? (notifyUser = false) => {
        const favorParams = {
          objectUUID: props.objectUUID,
          objectType: props.objectType?.toUpperCase(),
          notify: notifyUser,
        };

        if (isFavorited) {
          dispatch(removeFavor(favorParams));
        } else {
          dispatch(addFavor(favorParams));
        }
      }
    : () => redirectToJoin(generateJoinUrl, 'favorite-button');

  const favoriteText = ['GALLERY', 'MOVIE'].includes(props.objectType)
    ? intl.formatMessage({ id: 'gallery.buttons.favorite', defaultMessage: 'Favorite' })
    : intl.formatMessage({ id: 'gallery.buttons.follow', defaultMessage: 'Follow' });

  const unfavoriteText = ['GALLERY', 'MOVIE'].includes(props.objectType)
    ? intl.formatMessage({ id: 'gallery.buttons.unfavorite', defaultMessage: 'Unfavorite' })
    : intl.formatMessage({ id: 'gallery.buttons.unfollow', defaultMessage: 'Unfollow' });

  return (
    <Div>
      {props.objectType === 'MODEL' && (
        <ModelSubscription
          showModal={showFollowModal}
          onToggleFavor={onToggle}
          hideModal={() => setFollowModalVisibility(false)}
        />
      )}
      <CountButton
        count={formatViews(props.favoriteCount + (isFavorited ? 1 : 0))}
        btnClassName={`btn-primary ${isFavorited ? 'favorited' : ''}`}
        onClick={() =>
          user && !isFavorited && notifiableTypes.includes(props.objectType)
            ? setFollowModalVisibility(true)
            : onToggle()
        }
      >
        {isFavorited ? unfavoriteText : favoriteText}
      </CountButton>
    </Div>
  );
}

FavoriteButton.propTypes = {
  favoriteCount: PropTypes.number.isRequired,
  objectUUID: PropTypes.string.isRequired,
  objectType: PropTypes.oneOf(['GALLERY', 'MOVIE', 'MODEL', 'PHOTOGRAPHER']).isRequired,
};
