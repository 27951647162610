export type Site = {
  abbreviation: string;
  domain: string;
  name: string;
  networkUUID: string;
};

const networkUUID = 'A4C247F3ED924A70846D2722FD8B50F3';
const emptyUUID = '00000000000000000000000000000000';
export const MetArt: Site = {
  abbreviation: 'ma',
  name: 'MetArt',
  domain: 'metart.com',
  networkUUID,
};
export const SexArt: Site = {
  abbreviation: 'sa',
  name: 'SexArt',
  domain: 'sexart.com',
  networkUUID,
};
export const AlsScan: Site = {
  abbreviation: 'als',
  name: 'ALSScan',
  domain: 'alsscan.com',
  networkUUID,
};
export const Domai: Site = {
  abbreviation: 'dm',
  name: 'Domai',
  domain: 'domai.com',
  networkUUID,
};
export const ErroticaArchives: Site = {
  abbreviation: 'ea',
  name: 'Errotica Archives',
  domain: 'errotica-archives.com',
  networkUUID,
};
export const EroticBeauty: Site = {
  abbreviation: 'eb',
  name: 'Erotic Beauty',
  domain: 'eroticbeauty.com',
  networkUUID,
};
export const GoddesNudes: Site = {
  abbreviation: 'gn',
  name: 'Goddes Nudes',
  domain: 'goddessnudes.com',
  networkUUID,
};
export const LoveHairy: Site = {
  abbreviation: 'lh',
  name: 'Love Hairy',
  domain: 'lovehairy.com',
  networkUUID,
};
export const MetArtX: Site = {
  abbreviation: 'mx',
  name: 'MetArtX',
  domain: 'metartx.com',
  networkUUID,
};
export const TheLifeErotic: Site = {
  abbreviation: 'tle',
  name: 'The Life Erotic',
  domain: 'thelifeerotic.com',
  networkUUID,
};
export const VivThomas: Site = {
  abbreviation: 'vt',
  name: 'Viv Thomas',
  domain: 'vivthomas.com',
  networkUUID,
};
export const Straplez: Site = {
  abbreviation: 'sl',
  name: 'Straplez',
  domain: 'straplez.com',
  networkUUID,
};
export const EternalDesire: Site = {
  abbreviation: 'ed',
  name: 'Eternal Desire',
  domain: 'eternaldesire.com',
  networkUUID,
};
export const RylskyArt: Site = {
  abbreviation: 'ra',
  name: 'RylskyArt',
  domain: 'rylskyart.com',
  networkUUID,
};
export const Stunning18: Site = {
  abbreviation: 's18',
  name: 'Stunning18',
  domain: 'stunning18.com',
  networkUUID,
};
export const Vixen: Site = {
  abbreviation: 'vixen',
  name: 'Vixen',
  domain: 'vixen.com',
  networkUUID: emptyUUID,
};
export const PlayBoyPlus: Site = {
  abbreviation: 'playboyplus',
  name: 'Playboy +',
  domain: 'playboyplus.com',
  networkUUID: emptyUUID,
};
export const ArtJoyX: Site = {
  abbreviation: 'ajx',
  name: 'ArtJoyX',
  domain: 'artjoyx.com',
  networkUUID,
};
export const EroJoyX: Site = {
  abbreviation: 'ejx',
  name: 'EroJoyX',
  domain: 'erojoyx.com',
  networkUUID,
};
export const MetArtNetwork: Site = {
  abbreviation: 'man',
  name: 'MetArtNetwork',
  domain: 'metartnetwork.com',
  networkUUID,
};
