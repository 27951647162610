import React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { validateEmail } from 'src/client/utils/validators';
import styled from 'styled-components/macro';

const formName = 'newsletterForm';

export enum Status {
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  OPTED_IN = 'OPTED_IN',
}

type Props = {
  theme: object;
  submitBtnClassName?: string;
  invalid: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: (...args: any[]) => any;
  status: Status;
  submitFailed: boolean;
  submitting: boolean;
  disabled: boolean;
  email?: string;
  className?: string;
};

const renderInputField = (
  { meta, input, ...rest }, //eslint-disable-line
) => (
  <input
    {...input}
    {...rest}
    className={`${rest.className || ''} ${meta.error && input.value !== '' ? 'has-error' : ''}`} // eslint-disable-line react/prop-types
    disabled={rest.disabled}
  />
);

const NewsletterFormComponent = ({ submitBtnClassName = '', email = '', className = '', ...props }: Props) => {
  const { handleSubmit, invalid, submitting, disabled, status, submitFailed } = props;
  const intl = useIntl();
  const renderGuestForm = () => (
    <fieldset disabled={submitting || disabled} className="inline-block">
      <form onSubmit={handleSubmit}>
        <Field
          component={renderInputField}
          type="email"
          name="email"
          className="input"
          placeholder={intl.formatMessage({ id: 'newsletterForm.placeholder.email', defaultMessage: 'Email Address' })}
          validate={validateEmail}
        />
        <Field component={renderInputField} type="hidden" name="type" />
        <Field
          component={renderInputField}
          type="submit"
          name="submit"
          className={`submit ${submitBtnClassName}`}
          disabled={invalid}
        />
      </form>
    </fieldset>
  );

  const renderMemberForm = () => (
    <fieldset disabled={submitting || disabled} className="block">
      <form onSubmit={handleSubmit}>
        <Field component={renderInputField} type="hidden" name="type" />
        <Field
          component={renderInputField}
          type="submit"
          name="submit"
          className={`submit submit-full-width ${submitBtnClassName}`}
          disabled={invalid}
        />
      </form>
    </fieldset>
  );

  return (
    <div className={className}>
      {status === Status.NOT_SENT && !email && renderGuestForm()}
      {status === Status.NOT_SENT && email && renderMemberForm()}
      <div>
        {status === Status.SENT && !submitFailed && (
          <span>
            {intl.formatMessage({
              id: 'newsletterForm.messages.success',
              defaultMessage:
                'Please check your inbox. A newsletter confirmation email has been sent to your email address',
            })}
          </span>
        )}
        {status === Status.OPTED_IN && (
          <span>
            {intl.formatMessage({
              id: 'newsletterForm.messages.optedIn',
              defaultMessage: 'Your email is already on our list',
            })}
          </span>
        )}
        {submitFailed && (
          <span>
            {intl.formatMessage({
              id: 'newsletterForm.messages.submitError',
              defaultMessage: 'An error occurred please try again.',
            })}
          </span>
        )}
      </div>
    </div>
  );
};

const StyledComp = styled(NewsletterFormComponent)`
  & .input {
    width: 215px;
    font-size: 1.15rem;
    height: 49px;
    line-height: 49px;
    padding: 0 8px;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;
  }

  & .input.has-error {
    border-color: red;
  }

  & .submit {
    outline: none;
    border: 0;
    border-radius: 0;
    font-size: 1rem;
    height: 49px;
    line-height: 49px;
    padding: 0 15px;
    display: inline-block;
    vertical-align: middle;
  }

  & .submit-full-width {
    display: block;
    width: 100%;
    border-radius: 4px;
  }
`;

export default reduxForm<Props>({
  form: formName,
  destroyOnUnmount: false,
})(StyledComp);
