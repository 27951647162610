import { MOBILE_LANDSCAPE } from '@tovia/man-ui';
import React, { ReactNode } from 'react';
import { DESKTOP, TABLET_LANDSCAPE } from 'src/client/constants/mediaQueries';
import styled from 'styled-components/macro';

type Props = {
  show?: boolean;
  content: ReactNode;
};

export const SplashModelGrid = (props: Props) => {
  if (!props.show) return null;
  return <ModelGridWrapper>{props.content}</ModelGridWrapper>;
};

export const ModelGridWrapper = styled.div`
  padding: 0 15px;
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  width: 100%;
  position: relative;
  grid-auto-rows: 1fr;

  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media ${MOBILE_LANDSCAPE} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media ${TABLET_LANDSCAPE} {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @media ${DESKTOP} {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
`;
