import React, { ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type MobileBarProps = {
  className?: string;
  icon?: IconProp;
  label: ReactNode;
};

export const MobileBar = ({ className, icon, label }: MobileBarProps) => (
  <MobileMenuBar className={className}>
    {icon && <MobileMenuIcon fixedWidth icon={icon} aria-hidden="true" />}
    <MobileMenuLabel>{label}</MobileMenuLabel>
  </MobileMenuBar>
);

const MobileMenuBar = styled.span`
  background: ${(props) => props.theme.primary3};
  border: none;
  outline: none;
  box-shadow: none;
  display: flex;
  width: 100%;
  align-items: center;
  height: 20px;
  white-space: nowrap;
  padding: 20px 10px;
`;

const MobileMenuLabel = styled.span`
  font-weight: lighter;
`;

const MobileMenuIcon = styled(FontAwesomeIcon)`
  display: flex;
  font-size: 1.25rem;
  margin-right: 10px;
  width: 1.25em !important;
  height: 25px;
  align-items: center;

  path {
    width: 22px;
  }
`;
