import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Badges, Badge } from '@tovia/man-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/pro-regular-svg-icons';
import { faGlobe, faInfoSquare } from '@fortawesome/pro-light-svg-icons';
import { faFilm } from '@fortawesome/pro-duotone-svg-icons';
import { ImageDetailsPanel } from 'src/client/components/panels/ImageDetailsPanel';
import { NavOrExternalLink } from 'src/client/components/ui/NavOrExternalLink';
import FavoriteButton from '../FavoriteButton/FavoriteButton';
import { modelHeadshotUrl } from 'src/client/helpers/urls';
import { sanitizeLink } from 'src/shared/sanitize';
import ModelStatsModal from 'src/client/components/modals/ModelStatsModal';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

const ModelImageDetailsPanel = styled(ImageDetailsPanel)`
  img {
    margin-bottom: 0;
    margin-right: 20px;
    max-width: 100px;
  }

  .basic-attributes {
    font-size: 0.95rem;
  }

  .country {
    margin-bottom: 5px;
    .country-text {
      margin-left: 4px;
    }
  }

  & .stats {
    @media (max-width: 480px) {
      display: none;
    }
  }

  .name {
    :active,
    :hover,
    &.visited {
      text-decoration: none;
    }
  }
`;

const ModelName = styled.h1`
  margin-top: 0;
  font-weight: 100;
  font-size: 1.65rem;
  margin-bottom: 10px;
  white-space: pre-wrap;

  a,
  a:active,
  a:not(.btn):hover,
  a:visited {
    font-weight: 100;
    text-decoration: none;
    font-size: 1.6rem;
  }
`;

const StyledBadges = styled(Badges)`
  margin-top: 10px;
`;

type Props = {
  isModelPage: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: any;
  className?: string;
};

export const ModelDetailsPanel = (props: Props) => {
  const contentCdnUrl = useSelector((state) => state.app.contentCdnUrl);
  const config = useSelector((state) => state.app.config);
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();
  const showSfwSite = useShowSfwSite();
  const { hideModelAge, showGalleryCounts } = config.components.modelDetailsPanel;

  const { model, isModelPage } = props;
  const { UUID, path, name, publishAge, moviesCount, galleriesCount, eyes, hair, height, weight } = model;
  const hasStats = (!!eyes && !!hair) || (!!height && !!weight);
  const country = model.country.name && model.country.name.toLowerCase() !== 'unknown' ? model.country.name : '';
  const { coverUrl, blurImage } = modelHeadshotUrl(contentCdnUrl, model, model.siteUUID, showSfwSite);
  const { favoriteCount } = model;

  const badges = [
    ...(showGalleryCounts && moviesCount > 0
      ? [<Badge key="movies" text={moviesCount} icon={<FontAwesomeIcon icon={faFilm} />} />]
      : []),
    ...(showGalleryCounts && galleriesCount > 0
      ? [<Badge key="images" text={galleriesCount} icon={<FontAwesomeIcon icon={faImages} />} />]
      : []),
    ...(hasStats
      ? [
          <Badge
            onClick={() => setShowModal(true)}
            key="stats"
            text={intl.formatMessage({ id: 'modelDetailsPanel.stats', defaultMessage: 'Stats' })}
            icon={<FontAwesomeIcon icon={faInfoSquare} />}
          />,
        ]
      : []),
  ];

  return (
    <ModelImageDetailsPanel isBlurred={blurImage} imgSrc={coverUrl} imgAlt={name} className={props.className}>
      <ModelStatsModal
        attributes={model}
        coverImageUrl={coverUrl}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <ModelName>
        <NavOrExternalLink to={path}>{name}</NavOrExternalLink>
      </ModelName>
      <div className="basic-attributes">
        {country && (
          <div className="country">
            <Link to={`/models/country/${sanitizeLink(country)}`}>
              <FontAwesomeIcon icon={faGlobe} />
              <span className="country-text">{country} </span>
            </Link>
          </div>
        )}
        {publishAge >= 18 && !hideModelAge && (
          <div className="age">
            {isModelPage
              ? intl.formatMessage({ id: 'modelDetailsPanel.debutAge', defaultMessage: 'Debut Age' })
              : intl.formatMessage({ id: 'modelDetailsPanel.publishAge', defaultMessage: 'Age When Published' })}
            {': '}
            {publishAge}
          </div>
        )}
      </div>
      {!config.isWhiteLabel && <FavoriteButton favoriteCount={favoriteCount} objectType="MODEL" objectUUID={UUID} />}
      {badges.length > 0 && <StyledBadges>{badges}</StyledBadges>}
    </ModelImageDetailsPanel>
  );
};
