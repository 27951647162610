import styled from 'styled-components/macro';

export const MainPageTitle = styled.h1`
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: inherit;
  font-weight: 300;
  line-height: 1.1;
  color: inherit;
`;
