import React from 'react';
import { useSelector } from '../../redux';
import styled from 'styled-components/macro';
import { CamTrackingPosition } from 'src/client/constants/camTracking';
import { CameraThumbnailCardWrapper } from 'src/client/components/cards/CameraThumbnailCard';
import { useGenerateCamUrlWithParams } from 'src/client/helpers/urls/useGenerateCamUrlWithParams';
import { CamsResponse } from 'src/@types/Cam';
import { camLogoUrl } from 'src/client/helpers/urls/camLogoUrl';

type Props = {
  camTrackingPosition: CamTrackingPosition;
  provider: CamsResponse;
};

export const ViewAllCamsCard = ({ camTrackingPosition, provider: { provider, domain, description: label } }: Props) => {
  const cdnUrl = useSelector((state) => state.app.cdnUrl);

  const generateCamUrlWithParams = useGenerateCamUrlWithParams({ trackingPosition: camTrackingPosition, provider });

  return (
    <ViewAllCamsCardWrapper href={generateCamUrlWithParams('/cams')} target="_blank" rel="nofollow noopener noreferrer">
      <img src={camLogoUrl(cdnUrl, domain, 'light')} alt={label} />
      <Button>
        <span>See All</span>
        <br />
        <span>{domain} Girls</span>
      </Button>
    </ViewAllCamsCardWrapper>
  );
};

const ViewAllCamsCardWrapper = styled(CameraThumbnailCardWrapper)`
  position: relative;
  align-items: center;
  cursor: pointer;
  height: 180px;

  img {
    margin: 0 1em;
    position: absolute;
    top: 68px;
  }
`;

const Button = styled.button`
  width: 150px;
  padding: 0.3em;
  border-radius: 2px;
  border: 0;
  background-color: #000;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 14px;

  position: absolute;
  bottom: 1.25em;
`;
