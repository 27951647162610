import { UserTypes, UserType } from '../../shared/constants/userTypes';

export const getUserTypeAsString = (userType: UserType) => {
  switch (userType) {
    case UserTypes.Guest:
      return 'Guest';
    case UserTypes.Free:
      return 'Free Member';
    case UserTypes.Paid:
      return 'Active Member';
    case UserTypes.Expired:
      return 'Expired';
    default:
      return 'Unknown';
  }
};
