import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { HoverMenuCaret, MenuNavLink } from 'src/client/containers/HeaderBar/styles';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { HeaderContext, HeaderPopovers } from 'src/client/containers/HeaderBar/HeaderProvider';
import styled from 'styled-components/macro';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { TABLET_LANDSCAPE } from 'src/client/constants/mediaQueries';

type ArrowStyle = 'white';

type Props = {
  arrowStyle?: ArrowStyle;
  headerPopover: HeaderPopovers;
  externalLink?: boolean;
  menuLink: string;
  menuText: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const HeaderItemWithPopover = (props: PropsWithChildren<Props>) => {
  const { arrowStyle, children, headerPopover, menuLink, menuText, onClick } = props;
  const { activePopover, setActivePopover, popoverContentLoaded } = useContext(HeaderContext);

  const [referenceElement, setReferenceElement] = useState<HTMLAnchorElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    strategy: 'fixed',
  });

  const menuIsOpen = activePopover === headerPopover;
  const showMenu = () => setActivePopover(headerPopover);
  const hideMenu = () => setActivePopover(null);

  useEffect(() => {
    if (popoverContentLoaded) {
      update?.();
    }
  }, [popoverContentLoaded, update]);

  return (
    <Wrapper>
      <MenuNavLink
        exact
        to={menuLink}
        onMouseOver={showMenu}
        onFocus={showMenu}
        onBlur={hideMenu}
        onMouseLeave={hideMenu}
        onClick={onClick}
        ref={setReferenceElement}
      >
        {menuText}
        <HoverMenuCaret icon={faCaretDown} size="lg" />
      </MenuNavLink>
      {menuIsOpen &&
        ReactDOM.createPortal(
          <PopoverWrapper
            ref={setPopperElement}
            style={styles.popper}
            onMouseEnter={showMenu}
            onMouseLeave={hideMenu}
            {...attributes.popper}
          >
            {popoverContentLoaded && <Arrow arrowStyle={arrowStyle} ref={setArrowElement} style={styles.arrow} />}
            {children}
          </PopoverWrapper>,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          document.querySelector('#portals')!,
        )}
    </Wrapper>
  );
};

const PopoverWrapper = styled.div`
  z-index: 9999;
`;

const Wrapper = styled.div`
  position: relative;
  display: none;

  @media ${TABLET_LANDSCAPE} {
    display: inline-block;
  }
`;

const Arrow = styled.div<{ arrowStyle?: ArrowStyle }>`
  &,
  &::before {
    position: absolute;
    width: 12px;
    height: 12px;
    top: -3px;
    background-color: ${(props) => (props.arrowStyle === 'white' ? '#fff' : props.theme.primary3)};
    z-index: -1;
  }

  & {
    visibility: hidden;
  }

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;
