import React, { useEffect, useState, useCallback } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import Helmet from 'react-helmet';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { parseSlug } from 'src/client/utils/converters';
import { load as loadFn } from 'src/client/redux/modules/markdowns';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import urls from 'src/shared/urls';

const PageContainer = styled(ReactMarkdown)`
  padding: 40px 0;
`;

export default function PagesContainer() {
  const [markDownString, setMarkdownString] = useState('');
  const params = useParams<{ pageName: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pageName } = params;
  const load = useCallback((...args) => dispatch(loadFn(...args)), [dispatch]);
  const pages = useSelector((state) => state.app.config.pages);
  const markdowns = useSelector((state) => state.markdowns);
  const { domain, name: siteName } = useSelector((state) => state.site);

  const hasPage = pages && pages.find((page) => page === pageName);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    const { items, loaded } = markdowns;

    if (loaded) {
      const markdown = items.length && items.find((m) => m.name === pageName);
      if (markdown && hasPage) {
        setMarkdownString(markdown.text);
      } else {
        history.replace(urls.get.notFound);
      }
    }
  }, [history, hasPage, markdowns, pageName]);

  return markDownString ? (
    <>
      <Helmet>
        <title>{`${siteName} | Pages - ${parseSlug(pageName)}`}</title>
        <meta property="og:title" content={`${siteName} | Pages - ${parseSlug(pageName)}`} />
        <meta property="og:url" content={`https://www.${domain}/pages/pageName}`} />
      </Helmet>
      <PageContainer source={markDownString} className="container" escapeHtml={false} />
    </>
  ) : null;
}
