import { useCamTrackingSearchParam } from 'src/client/helpers/useCamTrackingSearchParam';
import { useCallback } from 'react';
import { CamTrackingPosition } from 'src/client/constants/camTracking';
import { CamProvider } from 'src/shared/constants/cams';

type GenerateCamUrlOptions = {
  trackingPosition?: CamTrackingPosition;
  provider: CamProvider;
};

export const useGenerateCamUrlWithParams = ({
  provider,
  trackingPosition,
}: GenerateCamUrlOptions): ((path: string) => string) => {
  const trackingParam = useCamTrackingSearchParam(trackingPosition);

  return useCallback(
    (path: string) => {
      const url = new URL(path, document.location.origin);

      if (trackingParam) {
        url.searchParams.set('AFNO', trackingParam);
      }

      url.searchParams.set('camProvider', provider);

      return url.toString();
    },
    [trackingParam, provider],
  );
};
