import { call, fork, select, put, takeEvery } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { RESET_DURATION } from './helpers/config';
import { SORT_TYPES } from 'src/shared/constants/galleries';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';
import { Gallery } from 'src/@types/Gallery';
import { ResponseError } from 'superagent';

const ActionType = {
  LOAD: 'man-site/galleries/LOAD',
  LOAD_SAGA: 'man-site/galleries/LOAD_SAGA',
  LOAD_SUCCESS: 'man-site/galleries/LOAD_SUCCESS',
  LOAD_FAIL: 'man-site/galleries/LOAD_FAIL',
} as const;

const endpoint = constructUrl(urls.get.galleries);
const ppsEndpoint = constructUrl(urls.get.PPS);
const initialItems = [];

type CallParams = {
  direction?: string | null;
  endTimestamp?: string | null;
  first?: string | null;
  page?: string | null;
  PPSOnly?: boolean;
  sortBy?: string;
  startTimestamp?: string | null;
  type?: string | null;
};

type State = {
  error?: ResponseError;
  galleries: Gallery[];
  itemCount: number;
  lastCallParams: CallParams;
  loaded: boolean;
  loading: boolean;
  pageSize: number;
  timestamp: number;
};

type Result = {
  galleries: Gallery[];
  total: number;
};

type ActionType = typeof ActionType[keyof typeof ActionType];

type Load = {
  lastCallParams: CallParams;
  params: CallParams;
  type: typeof ActionType.LOAD;
};

type LoadSuccess = State & {
  result: Result;
  type: typeof ActionType.LOAD_SUCCESS;
};

type LoadFail = {
  error: ResponseError;
  type: typeof ActionType.LOAD_FAIL;
};

type Action = Load | LoadSuccess | LoadFail;

const initialState: State = {
  galleries: initialItems,
  itemCount: initialItems.length,
  loaded: false,
  loading: false,
  lastCallParams: {
    direction: null,
    endTimestamp: null,
    first: null,
    page: null,
    startTimestamp: null,
    type: null,
  },
  pageSize: 60,
  timestamp: new Date().getTime(),
};

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case ActionType.LOAD: {
      const { lastCallParams } = action;
      return {
        ...initialState,
        loading: true,
        loaded: false,
        lastCallParams,
      };
    }
    case ActionType.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        galleries: action.result.galleries,
        itemCount: action.result.total,
        timestamp: new Date().getTime(),
      };
    case ActionType.LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default: {
      return state;
    }
  }
}

function isLoaded(lastCallParams, params) {
  return isEqual(lastCallParams, params);
}

export function load(params) {
  return {
    type: ActionType.LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator({ params }: { type: string; params: CallParams }) {
  const galleriesState = (yield select()).galleries;
  const { lastCallParams } = galleriesState;
  const isOld = new Date().getTime() - galleriesState.timestamp > RESET_DURATION;
  if (
    ((!isLoaded(lastCallParams, params) || isOld) && !galleriesState.loading) ||
    (!galleriesState.loaded && !galleriesState.loading)
  ) {
    // reload images after a failure
    const sortType = SORT_TYPES.find((ele) => ele.id === params.sortBy);
    const finalParams = {
      ...params,
      ...(sortType
        ? {
            order: sortType.order.toString(),
            direction: sortType.direction.toString(),
          }
        : {}),
    };

    delete finalParams.sortBy;
    const loadFunc = getRequestFunc(
      [ActionType.LOAD, ActionType.LOAD_SUCCESS, ActionType.LOAD_FAIL],
      (client) =>
        client.get(params.PPSOnly ? ppsEndpoint : endpoint, {
          params: finalParams,
        }),
      {
        lastCallParams: { ...params },
      },
    );
    yield call(loadFunc);
  }

  yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

  const newState = yield select();
  if (newState.auth.user && newState.galleries.galleries.length > 0) {
    const objectUUIDs = newState.galleries.galleries.map((item) => item.UUID);
    yield put(
      loadRatingInfo({
        objectUUIDs,
      }),
    );
    yield put(
      loadFavoriteInfo({
        objectUUIDs,
      }),
    );
  }
}

// Trigger
function* watchLoad() {
  yield takeEvery(ActionType.LOAD_SAGA, loadGenerator);
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
