import { useEffect, useState } from 'react';
import { usePromotions } from 'src/client/helpers/usePromotions';

export const usePromoPopup = (isPopupAllowedOnPage: boolean, isGatewayOpened: boolean) => {
  const { isPromoActive, popupClickCount } = usePromotions();
  const [userClickCount, setUserClickCount] = useState<number>(0);

  const maxClickCount = popupClickCount ?? 3;

  useEffect(() => {
    if (!isPromoActive) {
      return;
    }

    const onClick = (event) => {
      const links = ['join', 'signup', 'auth/man', 'login'];
      const isUserClickPrevented = links.some((value) => event.target.href?.includes(value));

      if (isPopupAllowedOnPage && !isUserClickPrevented && !isGatewayOpened && userClickCount < maxClickCount) {
        setUserClickCount(userClickCount + 1);
      }
    };

    document.body.addEventListener('click', onClick);

    return () => document.body.removeEventListener('click', onClick);
  }, [isGatewayOpened, isPromoActive, maxClickCount, isPopupAllowedOnPage, setUserClickCount, userClickCount]);

  // based on the above check, userClickCount should never be set to a value over the maxClickCount
  return userClickCount === maxClickCount;
};
