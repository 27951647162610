import React from 'react';
import { GalleryModel } from '@tovia/man-protos/dist/types/content.types';
import { Link } from 'react-router-dom';
import { useSelector } from 'src/client/redux';

const networkDomain = 'metartnetwork.com';

type Props = {
  models: Pick<GalleryModel, 'name' | 'path'>[];
  useExternalLinkForModelOrArtist?: boolean;
};

export const CardModelLinks = (props: Props) => {
  const { models } = props;
  const { siteSubdomain } = useSelector((state) => state.app);

  if (!models.length) {
    return null;
  }

  return models
    .map((model) => {
      if (props.useExternalLinkForModelOrArtist) {
        const href = `//${siteSubdomain}.${networkDomain}${model.path}`;
        return (
          <a title={model.name} aria-label={model.name} href={href} key={model.path}>
            {model.name}
          </a>
        );
      }

      return (
        <Link title={model.name} aria-label={model.name} to={model.path} key={model.path}>
          {model.name}
        </Link>
      );
    })
    .reduce((result, value) => (
      <>
        {result}, {value}
      </>
    ));
};
