import React from 'react';
import { User } from 'src/@types/app';
import { useSelector } from 'src/client/redux';
import { Site } from 'src/client/redux/modules/site';
import {
  AffiliateCode,
  createAffiliateURL,
  getAffiliateCodeForRedirect,
} from '@tovia/man-app-utils/lib/helpers/affiliate';
import { addDashesToUUID } from '@tovia/man-app-utils/lib/helpers/uuids';

type GeneratorProps = {
  accessSubdomain: string;
  site: Site;
  siteSubdomain: string;
  sites: Site[];
  user?: User;
};

type HookProps = {
  campaign: string;
  extraParams?: Record<string, string>;
  overrideSite?: string | boolean;
};

const joinUrlGenerator = ({ accessSubdomain, site, siteSubdomain, sites, user }: GeneratorProps) => ({
  campaign,
  overrideSite,
  extraParams = {},
}: HookProps) => {
  let linkSiteAbbreviation;
  if (overrideSite === undefined) {
    linkSiteAbbreviation = site.abbreviation;
  } else if (overrideSite === false) {
    linkSiteAbbreviation = '';
  } else {
    linkSiteAbbreviation = overrideSite;
  }

  const linkSite = sites.find((site) => site.abbreviation === linkSiteAbbreviation);

  // by default, a join will send the user to the linked site
  let siteDomain = linkSite?.domain.toLowerCase() ?? '';
  let path = 'join';
  let siteUUID = linkSite?.UUID ?? '';

  if (user?.hasAnyValidSubscription) {
    // Here we can assume that there is a valid logged in user.  If you send the
    // user to `/login`, they will end up getting redirected to the members portal
    // as if they had clicked the login button.
    //
    // The `auth/man` route is designed to sidestep this behavior.
    path = 'auth/man';

    // we want to use the current site the user is on when they have a valid subscription
    siteDomain = linkSite ? linkSite.domain.toLowerCase() : site.domain.toLowerCase();
    siteUUID = linkSite ? linkSite.UUID : site.UUID;
    extraParams.r = 'upgrades';
  }

  const queryStringParams = {
    campaign,
    site: user?.hasAnyValidSubscription ? linkSiteAbbreviation : '',
    ...extraParams,
  };

  let affiliateCode: AffiliateCode | undefined = undefined;

  // if they have made a purchase in the past and this is a normal site (not an external one) we will apply an affiliate code
  if (user?.firstPurchase && siteUUID) {
    affiliateCode = getAffiliateCodeForRedirect(user.firstPurchase, addDashesToUUID(siteUUID));
  }

  return createAffiliateURL({
    accessSubdomain,
    affiliateCode,
    path,
    queryStringParams,
    siteDomain,
    siteSubdomain,
  });
};

export const redirectToJoin = (generator: ReturnType<typeof joinUrlGenerator>, campaign: string) => {
  const joinUrl = generator({ campaign });
  return setTimeout(() => window.location.assign(joinUrl), 100);
};

export const useJoinUrlGenerator = () => {
  const user = useSelector((state) => state.auth.user);
  const site = useSelector((state) => state.site);
  const sites = useSelector((state) => state.sites.items);
  const { accessSubdomain, siteSubdomain } = useSelector((state) => state.app);

  return joinUrlGenerator({ accessSubdomain, site, siteSubdomain, sites, user });
};

export const useJoinUrl = ({ campaign, overrideSite, extraParams = {} }: HookProps) => {
  const generateJoinUrl = useJoinUrlGenerator();
  const href = generateJoinUrl({ campaign, overrideSite, extraParams });

  return [href] as const;
};

type Props = React.HTMLProps<HTMLAnchorElement> & HookProps;

export const JoinButton = ({ campaign, overrideSite, extraParams, ...props }: Props) => {
  const [href] = useJoinUrl({ campaign, overrideSite, extraParams });

  return <a {...props} href={href} />;
};
