import React from 'react';
import { useHistory } from 'react-router';
import Helmet from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { Button } from '@tovia/man-ui/lib/components/Button/Button';
import { formData } from './settingsData';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { SettingsSelectBox } from './SettingsSelectBox';

export const SettingsPage = () => {
  const { disabledSettings } = useSelector((state) => state.app.config);
  const site = useSelector((state) => state.site);
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>{`${site.name} - Settings`}</title>
        <meta property="og:title" content={`${site.name} - Settings`} />
        <meta property="og:url" content={`https://www.${site.domain}/settings`} />
      </Helmet>
      <Container className="container">
        <h2>
          <FormattedMessage id="settings.title" defaultMessage="Quick Settings" />
        </h2>
        <p>
          <FormattedMessage
            id="settings.subtitle"
            defaultMessage="Please choose from the options below to personalize your browsing experience."
          />
        </p>
        <div className="row text-uppercase settings-values">
          {formData
            .filter((setting) => !disabledSettings || !disabledSettings.includes(setting.settingKey))
            .map((setting) => (
              <SettingsSelectBox {...setting} key={setting.settingKey} />
            ))}
        </div>
        <CloseButton className="btn btn-primary" onClick={() => history.goBack()}>
          <FormattedMessage defaultMessage="Close Settings" id="settings.closeSettings" />
        </CloseButton>
      </Container>
    </>
  );
};

const Container = styled.div`
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled(Button)`
  margin-top: 40px;
  color: #fff;
`;
