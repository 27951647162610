import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import cx from 'classnames';

import { ConstrainedContainer, TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
import { ToggleAnimated } from 'src/client/containers/ToggleAnimated/ToggleAnimated';
import { OnPageTextWrapper } from 'src/client/components/OnPageSeo/OnPageText/OnPageText';
import { useWidePage } from 'src/client/helpers/useFeatureFlags';

type Props = {
  className?: string;
  collapsedElement: ReactNode;
  expandedElement: ReactNode;
};

const ANIMATION_NAME = 'read-more';
const ANIMATION_DURATION = 250;

export const OnPageFooterComponent = (props: Props) => {
  const { className, collapsedElement, expandedElement } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    setIsExpanded(false);
  }, [expandedElement]);

  return (
    <FooterWrapper className={cx(className)}>
      <FooterContainerSwitcher expanded={isExpanded}>
        <FooterTextWrapper>{collapsedElement}</FooterTextWrapper>
        {!isExpanded && (
          <ReadMoreWrapper>
            <ReadMoreText onClick={() => setIsExpanded(true)}>Read more</ReadMoreText>
          </ReadMoreWrapper>
        )}
        <ToggleAnimated animationName={ANIMATION_NAME} animationDuration={ANIMATION_DURATION} isExpanded={isExpanded}>
          <FooterTextWrapper>{expandedElement}</FooterTextWrapper>
        </ToggleAnimated>
      </FooterContainerSwitcher>
    </FooterWrapper>
  );
};

const FooterContainerSwitcher = ({ children, expanded }) => {
  const widePage = useWidePage();

  if (widePage) {
    return (
      <Container expanded={expanded}>
        <ConstrainedContainer extended>{children}</ConstrainedContainer>
      </Container>
    );
  }

  return (
    <Container className="container" expanded={expanded}>
      {children}
    </Container>
  );
};

const FooterWrapper = styled.div`
  width: 100vw;
  background-color: ${(props) => props.theme.primary2};
`;

const Container = styled.div<{ expanded: boolean }>`
  position: relative;
  padding: 1rem 1rem ${(props) => `${props.expanded ? '1rem' : 0}`};

  @media ${TABLET_PORTRAIT} {
    padding: 1rem 0 ${(props) => `${props.expanded ? '1rem' : 0}`};
  }
`;

export const FooterTextWrapper = styled(OnPageTextWrapper)`
  * {
    opacity: 1;
  }

  *:not(h2):not(h3) {
    text-align: justify;
  }

  h2,
  h3 {
    padding: 0.5rem 0;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    text-indent: 0;
  }

  ul,
  div,
  li {
    padding-bottom: 10px;
  }

  a {
    text-decoration: underline;
  }
`;
export const ReadMoreWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
  background: ${(props) => `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${props.theme.primary2} 70%)`};
`;

const ReadMoreText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  padding-bottom: 6px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
`;
