import React from 'react';

export const AllSites = React.lazy(() => import('./AllSites/AllSites'));
export const Blog = React.lazy(() => import('./Blog/Blog/Blog'));
export const FavoritesAndRatings = React.lazy(() => import('./FavoritesAndRatings/FavoritesAndRatings'));
export const Galleries = React.lazy(() => import('./Galleries/Galleries/Galleries'));
export const ModelsAndArtists = React.lazy(() =>
  import('./Models/ModelsAndArtists/ModelsAndArtists').then((m) => ({
    default: m.ModelsAndArtists,
  })),
);
export const Movies = React.lazy(() => import('./Movies/Movies'));
export const Updates = React.lazy(() => import('./Updates/Updates'));
export const ReportAbuse = React.lazy(() => import('./ReportAbuse').then((m) => ({ default: m.ReportAbuse })));
export const ReportPiracy = React.lazy(() =>
  import('./Piracy/ReportPiracy').then((m) => ({ default: m.ReportPiracy })),
);
