import React, { ReactNode } from 'react';
import { useLocation } from 'react-router';
import { MOBILE_LANDSCAPE } from 'src/client/constants/mediaQueries';
import styled from 'styled-components/macro';

type Props = {
  children: ReactNode;
  slogan: ReactNode;
};

const SloganContainer = styled.div`
  margin: 3% auto;
  width: 97%;
`;

const LineWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
`;

const HorizontalLine = styled.hr`
  position: absolute;
  z-index: 1;
  width: 100%;
  border: 0.5px solid #ffffff63;
`;

const SloganText = styled.h3`
  z-index: 2;
  background-color: ${(props) => props.theme.footerBarPrimary1};
  font-family: Georgia, serif;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.4rem;
  font-size: 3vw;
  font-weight: lighter;
  width: 70%;
  margin: 0;

  @media ${MOBILE_LANDSCAPE} {
    font-size: 1vw;
  }
`;
const pagesWithH2TagSlogan = ['/erotic-films', '/updates/upcoming'];

export const Slogan = (props: Props) => {
  const { slogan } = props;
  const location = useLocation();

  /* @SEO_CLEANUP */
  const isH2Tag = () => {
    return pagesWithH2TagSlogan.some((item) => location.pathname.startsWith(item) && location.pathname != '/');
  };

  return (
    <SloganContainer>
      {props.children}
      {slogan && (
        <LineWrapper>
          <SloganText as={isH2Tag() ? 'h2' : 'h3'}>{slogan}</SloganText>
          <HorizontalLine />
        </LineWrapper>
      )}
    </SloganContainer>
  );
};
