import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { withTheme } from 'styled-components/macro';
import { withCookies } from 'react-cookie';
import { injectIntl } from 'react-intl';
import { load } from '../../../redux/modules/whiteLabelSplash';
import WhiteLabelSplashSection from './WhiteLabelSplashSection';
import FooterBar from '../../FooterBar/FooterBar';

const galleryPropType = PropTypes.arrayOf(
  PropTypes.shape({
    coverCleanImagePath: PropTypes.string.isRequired,
    coverImagePath: PropTypes.string.isRequired,
    splashImagePath: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    publishedAt: PropTypes.string.isRequired,
    photographers: PropTypes.arrayOf(
      PropTypes.shape({
        UUID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      }),
    ),
    runtime: PropTypes.number.isRequired,
    siteUUID: PropTypes.string.isRequired,
    thumbnailCoverPath: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    UUID: PropTypes.string.isRequired,
    hasCover: PropTypes.bool.isRequired,
    permalink: PropTypes.string.isRequired,
  }),
).isRequired;

export class WhiteLabelSplashClass extends Component {
  static propTypes = {
    load: PropTypes.func.isRequired,
    className: PropTypes.string,
    theme: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
    user: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
    contentCdnUrl: PropTypes.string.isRequired,
    cdnUrl: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        site: PropTypes.shape({
          UUID: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          abbreviation: PropTypes.string.isRequired,
          domain: PropTypes.string.isRequired,
          shortName: PropTypes.string.isRequired,
          networkUUID: PropTypes.string.isRequired,
          createdAt: PropTypes.string.isRequired,
          updatedAt: PropTypes.string.isRequired,
          v2: PropTypes.bool.isRequired,
          description: PropTypes.string.isRequired,
        }),
        galleries: galleryPropType,
        movies: galleryPropType,
        models: PropTypes.arrayOf(
          PropTypes.shape({
            headshotImagePath: PropTypes.string,
            name: PropTypes.string,
            path: PropTypes.string,
            siteUUID: PropTypes.string,
            UUID: PropTypes.string,
          }),
        ).isRequired,
      }),
    ).isRequired,
  };

  static defaultProps = {
    user: null,
    className: '',
  };

  componentWillMount() {
    this.props.load({});
  }

  render() {
    const { className, contentCdnUrl, cdnUrl, sections } = this.props;

    return (
      <div className={className}>
        {sections.map((section) => (
          <WhiteLabelSplashSection
            key={section.site.abbreviation}
            section={section}
            contentCdnUrl={contentCdnUrl}
            cdnUrl={cdnUrl}
          />
        ))}
        <FooterBar />
      </div>
    );
  }
}

export default withCookies(
  connect(
    (state) => ({
      sections: state.whiteLabelSplash.sections || [],
      site: state.site,
      user: state.auth.user,
      contentCdnUrl: state.app.contentCdnUrl,
      cdnUrl: state.app.cdnUrl,
      settings: state.settings.values,
    }),
    (dispatch) => bindActionCreators({ load }, dispatch),
  )(
    injectIntl(
      withTheme(styled(WhiteLabelSplashClass)`
        color: ${(props) => props.theme.splashPrimary2};
        background-color: ${(props) => props.theme.splashPrimary1};
        padding-top: 10px;
      `),
    ),
  ),
);
