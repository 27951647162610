import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';
import { injectIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { useSelector } from '../../../redux/modules/helpers/useSelector';
import { logoUrl } from 'src/client/helpers/urls/logoUrl';

const galleryPropType = PropTypes.arrayOf(
  PropTypes.shape({
    coverCleanImagePath: PropTypes.string.isRequired,
    coverImagePath: PropTypes.string.isRequired,
    splashImagePath: PropTypes.string.isRequired,
    imageCount: PropTypes.number.isRequired,
    models: PropTypes.arrayOf(
      PropTypes.shape({
        UUID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        globalUUID: PropTypes.string.isRequired,
      }),
    ),
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    publishedAt: PropTypes.string.isRequired,
    photographers: PropTypes.arrayOf(
      PropTypes.shape({
        UUID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      }),
    ),
    runtime: PropTypes.number.isRequired,
    siteUUID: PropTypes.string.isRequired,
    thumbnailCoverPath: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    UUID: PropTypes.string.isRequired,
    hasCover: PropTypes.bool.isRequired,
    permalink: PropTypes.string.isRequired,
  }),
).isRequired;

export const WhiteLabelSplashSectionClass = (props) => {
  const { className, section, contentCdnUrl, cdnUrl } = props;

  const { site, galleries, movies, models } = section;

  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  return (
    <div className={className}>
      <div className="wlsection-logo">
        <img src={logoUrl(cdnUrl, site)} alt={site.name} />
      </div>
      <div className="wlsection-flex">
        {movies.map((movie) => (
          <div className="wlsection-movie" key={movie.UUID}>
            <a
              className="wlsection-movie-link"
              href={movie.path}
              style={{
                backgroundImage: `url('${contentCdnUrl}/${movie.siteUUID}${movie.splashImagePath}')`,
              }}
            >
              <span className="hidden">{movie.name}</span>
            </a>
          </div>
        ))}
      </div>
      <div className="wlsection-flex wlsection-bottom">
        <div className="wlsection-models">
          {models.map((model) => (
            <a key={model.UUID} className="wlsection-model-link" href={model.path}>
              <img src={`${contentCdnUrl}/${model.siteUUID}${model.headshotImagePath}`} alt={model.name} />
              <span className="hidden">{model.name}</span>
            </a>
          ))}
        </div>
        <div className="wlsection-galleries">
          {galleries.map((gallery) => (
            <a key={gallery.UUID} className="wlsection-gallery-link" href={gallery.path}>
              <img src={`${contentCdnUrl}/${gallery.siteUUID}${gallery.coverImagePath}`} alt={gallery.name} />
              <span className="hidden">{gallery.name}</span>
            </a>
          ))}
        </div>
      </div>
      {!user && (
        <div className="wlsection-auth">
          <a href={`/login?r=${location.pathname.replace(/^\//, '')}`} className="btn login-btn btn-lg" key="login">
            LOGIN NOW
          </a>
        </div>
      )}
    </div>
  );
};

WhiteLabelSplashSectionClass.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  contentCdnUrl: PropTypes.string.isRequired,
  cdnUrl: PropTypes.string.isRequired,
  section: PropTypes.shape({
    galleries: galleryPropType,
    movies: galleryPropType,
    models: PropTypes.arrayOf(
      PropTypes.shape({
        headshotImagePath: PropTypes.string,
        name: PropTypes.string,
        path: PropTypes.string,
        siteUUID: PropTypes.string,
        UUID: PropTypes.string,
      }),
    ).isRequired,
    site: PropTypes.shape({
      UUID: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      abbreviation: PropTypes.string.isRequired,
      domain: PropTypes.string.isRequired,
      shortName: PropTypes.string.isRequired,
      networkUUID: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      v2: PropTypes.bool.isRequired,
      description: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

WhiteLabelSplashSectionClass.defaultProps = {
  className: '',
};

export default injectIntl(
  withTheme(styled(WhiteLabelSplashSectionClass)`
    color: ${(props) => props.theme.splashPrimary2};
    background-color: ${(props) => props.theme.splashPrimary4};
    margin: 5px auto 15px auto;
    padding: 16px;
    max-width: 1000px;

    & .wlsection-logo img {
      display: block;
      max-height: 50px;
      width: auto;
      margin-bottom: 5px;
    }

    & .wlsection-flex {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: stretch;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    & .wlsection-movie {
      flex-basis: 50%;
      flex-grow: 1;
      margin-left: 5px;
      margin-right: 0;

      &:first-child {
        margin-right: 5px;
        margin-left: 0;
      }

      @media screen and (max-width: 600px) {
        flex-basis: auto;
        flex-grow: 0;
        margin: 5px 0 0 0;

        &:first-child {
          margin: 0 0 5px 0;
        }
      }
    }

    & .wlsection-movie-link {
      display: block;
      width: 100%;
      height: 0;
      padding-top: 56%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    & .wlsection-bottom {
      margin-top: 10px;
      justify-content: start;
      align-items: start;
    }

    & .wlsection-models {
      flex-basis: 37.5%;
      flex-grow: 1;
      margin: 0 0 0 -5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      @media screen and (max-width: 600px) {
        flex-basis: auto;
        flex-grow: 0;
        margin: 0;
      }
    }

    & .wlsection-model-link {
      flex-grow: 1;
      flex-basis: 20%;

      img {
        display: inline-block;
        margin: 5px;
        width: calc(100% - 10px);
        height: auto;
      }
    }

    & .wlsection-galleries {
      flex-basis: 60%;
      flex-grow: 1;
      margin: 0 -5px 0 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      @media screen and (max-width: 600px) {
        flex-basis: auto;
        flex-grow: 0;
      }
    }

    & .wlsection-gallery-link {
      flex-grow: 1;
      flex-basis: 25%;

      img {
        display: inline-block;
        margin: 5px;
        width: calc(100% - 10px);
        height: auto;
      }
    }

    & .wlsection-auth {
      text-align: right;
    }

    & .login-btn {
      color: #fff;
    }

    a.login-btn {
      background: ${(props) => props.theme.headerBarPrimary3};
      color: ${(props) => props.theme.primaryBtn1Text} !important;
      opacity: 1;
      text-transform: uppercase;
      margin-top: 10px;

      :hover {
        opacity: 0.9;
      }

      @media (max-width: 1200px) {
        padding: 8px 11px;
      }
    }
  `),
);
