import React from 'react';
import styled, { DefaultTheme, ThemeProvider } from 'styled-components/macro';
import { PropsWithChildren } from 'react';
import { useSelector } from '../../redux';

export const LiveGirlsContainer = ({ className, children }: PropsWithChildren<{ className?: string }>) => {
  const siteConfig = useSelector((state) => state.app.config);

  return (
    <Container className={className}>
      <ThemeProvider theme={siteConfig.theme?.colors.light as DefaultTheme}>{children}</ThemeProvider>
    </Container>
  );
};

const Container = styled.div`
  color: #000000;
  padding: 30px;
  top: 20px !important;
  width: 1040px;
  display: flex;
  flex-direction: column;
  --gap: 20px;
  gap: calc(var(--gap) / 2);
`;
