import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import ReactSlick, { Settings } from 'react-slick';
import { GlobalContent } from 'src/@types/app';
import CardInformation from '@tovia/man-ui/lib/components/Card/CardInformation';
import Card from '@tovia/man-ui/lib/components/Card/Card';
import { NavigationButton } from './NavigationButton';
import { useSelector } from 'src/client/redux';
import {
  AffiliateCode,
  createAffiliateURL,
  getAffiliateCodeForRedirect,
} from '@tovia/man-app-utils/lib/helpers/affiliate';
import { addDashesToUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import { generateGalleryAlt } from 'src/client/utils/generateGalleryAlt';
import { GalleryModel } from '@tovia/man-protos/dist/types/content.types';
import CardMedia from 'src/client/components/ui/Card/CardMedia';
import { useGetGalleryCoverUrl } from 'src/client/helpers/urls/useGetGalleryCoverUrl';

// The default setting for the slider is the largest breakpoint, and the
// responsive settings go down from there.
const defaultSliderSettings: Settings = {
  dots: false,
  infinite: false,
  // the onclick event is added by the slider.
  nextArrow: <NavigationButton direction="right" />,
  prevArrow: <NavigationButton direction="left" />,
  responsive: [
    { breakpoint: 576, settings: { slidesToScroll: 2, slidesToShow: 2 } },
    { breakpoint: 992, settings: { slidesToScroll: 3, slidesToShow: 3 } },
    { breakpoint: 1200, settings: { slidesToScroll: 4, slidesToShow: 4 } },
  ],
  slidesToScroll: 5,
  slidesToShow: 5,
};

type Props = {
  galleries: GlobalContent[];
  className?: string;
  reactSlickSettings?: Settings;
  title?: string;
};

type SliderItem = {
  name: string;
  models: GalleryModel[];
  url: string;
  siteName?: string;
  imgSrc: string;
  blurImage: boolean;
};

export const NetworkGalleriesSlider = (props: Props) => {
  const { title = 'More sets of our models in the MetArt Network', reactSlickSettings = {}, galleries = [] } = props;

  const sites = useSelector((state) => state.sites.items);
  const site = useSelector((state) => state.site);
  const { accessSubdomain, defaultCdnUrl, siteSubdomain } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const getGalleryCover = useGetGalleryCoverUrl();

  const networkGalleries: SliderItem[] = useMemo(() => {
    return galleries.map((gallery) => {
      const gallerySite = sites.find((cSite) => cSite.UUID === gallery.siteUUID);
      const { domain } = gallerySite || site;

      let affiliateCode: AffiliateCode | undefined;
      if (user?.firstPurchase) {
        affiliateCode = getAffiliateCodeForRedirect(user.firstPurchase, addDashesToUUID(gallery.siteUUID));
      }

      const galleryURL = `https://${siteSubdomain}.${domain.toLowerCase()}${gallery.path}`;
      const galleryAffiliateURL = createAffiliateURL({
        accessSubdomain,
        affiliateCode,
        siteDomain: domain.toLowerCase(),
        redirect: galleryURL,
        siteSubdomain,
      });

      const galleryCover = getGalleryCover(defaultCdnUrl, gallery, { thumbnail: true });

      return {
        name: gallery.name,
        models: gallery.models,
        url: galleryAffiliateURL,
        siteName: gallerySite?.name,
        imgSrc: galleryCover.url,
        blurImage: galleryCover.blurImage,
      };
    });
  }, [accessSubdomain, defaultCdnUrl, galleries, getGalleryCover, site, siteSubdomain, sites, user?.firstPurchase]);

  if (!networkGalleries.length) {
    return null;
  }

  const settings: Settings = {
    ...defaultSliderSettings,
    ...reactSlickSettings,
  };

  return (
    <Container className={props.className}>
      {title && <Title>{title}</Title>}
      <StyledSlider {...settings}>
        {networkGalleries.map((gallery) => (
          <a key={gallery.url} className="gallery-slide" href={gallery.url} target="_blank" rel="noopener noreferrer">
            <StyledCard>
              <StyledCardMedia
                imgSrc={gallery.imgSrc}
                altText={generateGalleryAlt(gallery.name, gallery.models)}
                blurred={gallery.blurImage}
              />
              <CardInformation className="card-info-1" title={gallery.name} subTitle={gallery.siteName} />
            </StyledCard>
          </a>
        ))}
      </StyledSlider>
    </Container>
  );
};

const Container = styled.div`
  margin: 20px 0;
  overflow: hidden;
`;

const Title = styled.div`
  font-weight: 300;
  min-height: 28px;
  line-height: 28px;
  font-size: 24px;
  margin-bottom: 10px;
`;

const StyledCard = styled(Card)`
  margin: 0 5px;
`;

const StyledCardMedia = styled(CardMedia)`
  & > img {
    aspect-ratio: 2 / 3;
  }
`;

const StyledSlider = styled(ReactSlick)`
  margin: 0 -5px;

  & > .slick-list > .slick-track {
    margin-left: 0;
  }

  & > .slick-disabled {
    cursor: default;
    pointer-events: none;
  }

  a:hover {
    text-decoration: none;
  }
`;
