import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FeedItem } from './FeedItem';
import { ActionProps, getActivityTypeInfo } from './ActivityFeed';

export const FavoriteAction = (props: ActionProps) => {
  const { activity } = props;
  const { objectType } = activity;
  const info = getActivityTypeInfo(activity)[objectType];

  return (
    <FeedItem
      didWhat={
        <FormattedMessage
          id="activityFeed.actions.favorites.action"
          defaultMessage="added {label}"
          values={{ label: info.label }}
        />
      }
      onWhat={{ name: info.name, url: info.path }}
      moreInfo={<FormattedMessage id="activityFeed.actions.favorites.moreInfo" defaultMessage="to your favorites" />}
    />
  );
};
