import { useEffect, useRef, useState } from 'react';

type Props = {
  isExpanded: boolean;
  mountCb?: (mounted: boolean) => void;
};

export const useTransitionCalculator = (props: Props) => {
  const { isExpanded, mountCb } = props;
  const transitionRef = useRef<HTMLDivElement>(null);

  const [activeHeight, setActiveHeight] = useState('0px');
  const [componentMounted, setComponentMounted] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      setComponentMounted(true);
    }
  }, [isExpanded]);

  useEffect(() => {
    mountCb?.(componentMounted);
  }, [componentMounted, mountCb]);

  const onExitAnimationEnd = () => {
    setComponentMounted(false);
  };

  const updateHeight = () => {
    setTimeout(() => {
      const newHeight = transitionRef.current?.offsetHeight || 0;
      setActiveHeight(newHeight + 'px');
    }, 10);
  };

  useEffect(() => {
    updateHeight();
  }, [componentMounted]);

  // add resize listener to keep the height updated on screen resize or device
  // orientation change
  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return {
    transitionRef,
    activeHeight,
    onExitAnimationEnd,
    componentMounted,
  };
};
