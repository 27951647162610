import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  children: React.ReactNode;
  openNewWindow?: boolean;
};

export const NavOrExternalLink = (props: Props) => {
  const { to, children, openNewWindow } = props;
  const isAnchor = /^https?:\/\//.test(to) || openNewWindow;
  const target = openNewWindow ? '_blank' : undefined;

  if (!isAnchor) {
    return <Link to={to}>{children}</Link>;
  }

  return (
    <a href={to} rel="noopener noreferrer" target={target}>
      {children}
    </a>
  );
};
