import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled, { withTheme } from 'styled-components/macro';
import { JoinButton } from 'src/client/components/buttons/JoinButton';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';
import { useSelector } from 'src/client/redux';

export const SpecialFeaturesClass = ({ className, hideSignUp }) => {
  const { cdnUrl } = useSelector((state) => state.app);
  const { UUID } = useSelector((state) => state.site);
  const showSfwSite = useShowSfwSite();

  const sfwFolder = showSfwSite ? 'sfw' : '';
  const basePath = [cdnUrl, 'splash', UUID, sfwFolder].filter(Boolean).join('/');

  return (
    <section className={`${className} special-features`}>
      <div className="container">
        <div className="special-features-title">Special Features: Inside ALS World </div>
        <div className="special-features-content">
          <div className="special-features-picks">
            <NavLink to="/category/Director_Picks" className="special-features-pick">
              <div className="colorized director">ALS/Director Picks</div>
              <img src={basePath + '/category_director.jpg'} alt="ALS/Director Picks" />
            </NavLink>
            <NavLink to="/category/Vacations" className="special-features-pick">
              <div className="colorized vacations">ALS/Vacations</div>
              <img src={basePath + '/category_vacation.jpg'} alt="ALS/Vacations" />
            </NavLink>
            <NavLink to="/category/Casting_Calls" className="special-features-pick">
              <div className="colorized casting">ALS/Casting Calls</div>
              <img src={basePath + '/category_casting.jpg'} alt="ALS/Casting Calls" />
            </NavLink>
            <NavLink to="/category/Productions" className="special-features-pick">
              <div className="colorized productions">ALS/Productions</div>
              <img src={basePath + '/category_production.jpg'} alt="ALS/Productions" />
            </NavLink>
          </div>
          {!hideSignUp && (
            <div className="special-features-access">
              <section className="access-section">
                <ul>
                  <li>1000's of starlets </li>
                  <li>Create favorites </li>
                  <li>Unlimited downloads </li>
                </ul>
                <ul>
                  <li>500,000 images</li>
                  <li>1000&apos;s of movies</li>
                  <li>Sexy live cams</li>
                </ul>
              </section>
              <section className="access-section">
                <JoinButton campaign="special-features" className="btn btn-primary special-features-btn">
                  Sign Up Today
                </JoinButton>
              </section>
              <section className="access-section">
                <NavLink to="/updates" className="tour-btn">
                  &gt;&gt; Enjoy a free tour
                </NavLink>
              </section>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

SpecialFeaturesClass.propTypes = {
  className: PropTypes.string,
  hideSignUp: PropTypes.bool,
};

SpecialFeaturesClass.defaultProps = {
  className: '',
  hideSignUp: false,
};

export default withTheme(styled(SpecialFeaturesClass)`
  &.special-features {
    background-color: ${(props) => (!props.hideSignUp ? props.theme.splashOptional1 : 'inherit')};
    padding: 36px 0;
  }

  & .special-features-title {
    color: ${(props) => props.theme.splashPrimary1};
    background-color: ${(props) => props.theme.primary9};
    display: inline-block;
    font-size: 1.72rem;
    padding: 12px 24px;
    text-transform: uppercase;
  }

  & .special-features-content {
    background-color: ${(props) => props.theme.splashPrimary1};

    @media (min-width: 768px) {
      padding: 24px;
    }
  }

  & .special-features-picks {
    display: flex;
    flex-direction: column;
    padding-top: 12px;

    @media (min-width: 568px) {
      flex-direction: row;
      flex-flow: wrap;
    }

    @media (min-width: 768px) {
      flex-flow: unset;
    }

    .special-features-pick {
      margin: 0 8px 8px;
      padding-top: 0;
      width: calc(100% - 16px);

      @media (min-width: 568px) and (max-width: 767px) {
        width: calc(50% - 16px);
      }

      @media (min-width: 768px) {
        margin: unset;
        flex-grow: 1;
        flex-basis: 0;
        width: 100%;
      }

      .colorized {
        color: ${(props) => props.theme.splashPrimary1};
        display: block;
        font-size: 1.15rem;
        padding: 8px;

        @media (min-width: 1024px) {
          font-size: 1.3rem;
        }

        &.director {
          background-color: ${(props) => props.theme.primary9};
        }
        &.vacations {
          background-color: ${(props) => props.theme.primary10};
        }
        &.casting {
          background-color: ${(props) => props.theme.primary12};
        }
        &.productions {
          background-color: ${(props) => props.theme.primary11};
        }
      }

      &:not(:last-child) {
        @media (min-width: 768px) {
          margin-right: 8px;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  & .special-features-access {
    display: flex;
    flex-direction: column;
    margin-top: 4px;

    @media (min-width: 768px) {
      flex-flow: unset;
    }

    .access-section {
      background-color: ${(props) => props.theme.splashOptional1};
      width: 100%;

      @media (min-width: 568px) {
        //width: 50%;
      }

      @media (min-width: 768px) {
        flex-grow: 1;
        flex-basis: 0;
        padding: 36px 0;
      }

      ul {
        margin: 0 12px;
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        @media (min-width: 414px) and (max-width: 767px) {
          float: left;
          text-align: unset;
          width: calc(50% - 24px);
        }

        @media (min-width: 1200px) {
          float: left;
          text-align: unset;
          width: unset;
        }

        li {
          list-style-type: none;
          margin: 4px 0;
        }
      }

      &:not(:last-child) {
        @media (min-width: 768px) {
          margin-right: 8px;
        }
      }
    }

    .special-features-btn {
      color: ${(props) => props.theme.splashPrimary1};
      display: block;
      font-size: 1.4rem;
      margin: 0 24px;
      padding: 12px 0;
      position: relative;

      @media (min-width: 768px) {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .tour-btn {
      display: block;
      color: ${(props) => props.theme.splashPrimary2};
      font-size: 1.15rem;
      position: relative;
      text-align: center;
      text-transform: uppercase;

      @media (min-width: 768px) {
        top: 50%;
        transform: translateY(-50%);
      }

      @media (min-width: 1200px) {
        font-size: 2rem;
      }
    }
  }
`);
