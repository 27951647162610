import PropTypes from 'prop-types';
// import config from '../../config/config';
//
// const { theme } = config;

const initialState = {
  // default: theme.default,
  // colors: theme.colors,
};

export const themeProps = PropTypes.shape({
  primary1: PropTypes.string.isRequired,
  primary2: PropTypes.string.isRequired,
  primary3: PropTypes.string.isRequired,
  primary4: PropTypes.string.isRequired,
  primary5: PropTypes.string.isRequired,
  primary6: PropTypes.string.isRequired,
  primary7: PropTypes.string.isRequired,
  primary8: PropTypes.string.isRequired,
  inputBGColor: PropTypes.string.isRequired,
  inputTextColor: PropTypes.string.isRequired,
  primaryBtn1Text: PropTypes.string.isRequired,
  primaryBtn1Bg: PropTypes.string.isRequired,
  primaryBtn1HoverBg: PropTypes.string.isRequired,
  secondaryBtn1Text: PropTypes.string.isRequired,
  secondaryBtn1Bg: PropTypes.string.isRequired,
  secondaryBtn1HoverBg: PropTypes.string.isRequired,
  headerBarPrimary1: PropTypes.string.isRequired,
  headerBarPrimary2: PropTypes.string.isRequired,
  headerBarPrimary3: PropTypes.string.isRequired,
  headerBarPrimary4: PropTypes.string.isRequired,
  headerBarPrimary5: PropTypes.string.isRequired,
  splashPrimary1: PropTypes.string.isRequired,
  splashPrimary2: PropTypes.string.isRequired,
  splashPrimary3: PropTypes.string.isRequired,
  splashPrimary4: PropTypes.string.isRequired,
  splashPrimary5: PropTypes.string.isRequired,
  footerBarPrimary1: PropTypes.string.isRequired,
  footerBarPrimary2: PropTypes.string.isRequired,
  footerBarPrimary3: PropTypes.string.isRequired,
  footerBarPrimary4: PropTypes.string.isRequired,
  footerBarPrimary5: PropTypes.string.isRequired,
  footerBarPrimary6: PropTypes.string.isRequired,
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}
