import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ModelGridCard from '../Grid/Model/ModelGridCard';
import { modelHeadshotUrl } from 'src/client/helpers/urls';
import { formatViews } from 'src/client/utils/converters';
import { rateItem } from 'src/client/redux/modules/ratingInfo';
import { addFavor, removeFavor } from 'src/client/redux/modules/favoriteInfo';
import { GridCardsWrapper } from 'src/client/containers/Cards/GridCardsWrapper';
import ModelDetailedCard from '../Detailed/Model/ModelDetailedCard';
import { useSelector, useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';
import { redirectToJoin, useJoinUrlGenerator } from 'src/client/components/buttons/JoinButton';
import cx from 'classnames';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

export default function ModelCards(props) {
  const dispatch = useDispatch();
  const favoriteInfo = useSelector((state) => state.favoriteInfo.favoritingObjectUUIDs);
  const contentCdnUrl = useSelector((state) => state.app.contentCdnUrl);
  const user = useSelector((state) => state.auth.user);
  const view = useSettingsSelector('listView');
  const ratingsScale = useSettingsSelector<number>('ratingsScale');
  const ratingInfo = useSelector((state) => state.ratingInfo.ratings);
  const generateJoinUrl = useJoinUrlGenerator();
  const showSfwSite = useShowSfwSite();

  const isUser = user && user.validSubscription;

  const ModelCardComponent = view === 'detailed' ? ModelDetailedCard : ModelGridCard;

  const cards = props.models.map((model) => {
    const isFavorited = favoriteInfo.includes(model.UUID);

    let { views } = model;
    if (props.viewPeriod) {
      views = model.leaderboardViews[props.viewPeriod];
    }

    const { coverUrl, blurImage } = modelHeadshotUrl(contentCdnUrl, model, model.siteUUID, showSfwSite);

    return (
      <ModelCardComponent
        key={model.UUID}
        name={model.name}
        url={model.path}
        imgSrc={coverUrl}
        isBlurred={blurImage}
        imgAlt={model.name}
        ratingAverage={parseFloat(model.ratingAverage).toFixed(ratingsScale)}
        isFavorited={favoriteInfo.includes(model.UUID)}
        views={formatViews(views)}
        galleriesCount={model.galleriesCount}
        moviesCount={model.moviesCount}
        onFavorite={
          isUser
            ? (notifyUser = false) => {
                const favorParams = {
                  objectUUID: model.UUID,
                  objectType: 'MODEL',
                  notify: notifyUser,
                };

                if (isFavorited) {
                  dispatch(removeFavor(favorParams));
                } else {
                  dispatch(addFavor(favorParams));
                }
                // toggleFavor(favorParams);
              }
            : () => redirectToJoin(generateJoinUrl, 'favorite-model-card')
        }
        ratingStars={{
          userRating: ratingInfo[model.UUID],
          submitRating: isUser
            ? (rating) =>
                dispatch(
                  rateItem({
                    objectUUID: model.UUID,
                    objectType: 'MODEL',
                    rating,
                  }),
                )
            : () => redirectToJoin(generateJoinUrl, 'rate-model-card'),
          hideLabel: true,
          rating: parseFloat(model.ratingAverage).toFixed(ratingsScale),
        }}
      />
    );
  });
  if (props.seoInjectedComponent) {
    cards.push(props.seoInjectedComponent);
  }

  return (
    <GridCardsWrapper className={cx({ 'injected-seo-text': props.seoInjectedComponent })}>{cards}</GridCardsWrapper>
  );
}

ModelCards.propTypes = {
  models: PropTypes.array.isRequired,
  viewPeriod: PropTypes.string,
  seoInjectedComponent: PropTypes.element,
};

ModelCards.defaultProps = {
  viewPeriod: null,
};
