import countryCodes from '@tovia/man-i18n/lib/data/countries';

const timePeriods = ['day', 'week', 'month', 'year'];
const leaderboardTabs = ['models', 'galleries', 'movies'];
const yearRegex = /^(20[0-9][0-9]|200[0-9]|[0-1][0-9]{3})$/;
const dateRegex = /^(20[0-9][0-9]|200[0-9]|[0-1][0-9]{3})(1[0-2]|0[1-9])(3[01]|[0-2][1-9]|[12][0])$/;
const numberRegex = /^\d+$/;
const removeDashes = (word) => word.split('-').join(' ');

export const validateDate = (date) => {
  if (date) {
    return date.toString().match(dateRegex);
  }
  return true;
};

export const validateMonth = (month) => {
  if (month) return parseInt(month) > 0 && parseInt(month) < 13;
  return true;
};

export const validateYear = (year) => {
  if (year) return year.toString().match(yearRegex);
  return true;
};

export const validateTab = (tabKeyword, configTabs) => {
  const tabs = configTabs || leaderboardTabs;
  if (tabKeyword) return tabs.includes(tabKeyword);
  return false;
};

export const validateTimePeriod = (timePeriod) => {
  if (timePeriod) return timePeriods.includes(timePeriod);
  return true;
};

export const validateCountry = (country) => {
  if (country) {
    return countryCodes.find((value) => value.name.toLowerCase() === removeDashes(country).toLowerCase());
  }
  return true;
};

export const validateSingleLetter = (letter) => {
  if (letter) return letter.length === 1 && letter.toString().match(/^[a-z]$/i);
  return true;
};

export const validatePageNumber = (pageNumber) => {
  if (pageNumber) return numberRegex.test(pageNumber) && parseInt(pageNumber) > 0;
  return true;
};

export const validateSorting = (sortKeyword, sortType) => {
  if (sortKeyword) return !!sortType.filter((sort) => sort.id === sortKeyword).length;
  return false;
};

export const validateCategory = (allowedCategories, category) => allowedCategories.includes(category);

export const validateMediaType = (mediaType) => mediaType.match(/^(gallery|movie)$/);

export const validateRating = (rating) => {
  if (rating) return numberRegex.test(rating) && parseInt(rating) > 0 && parseInt(rating) <= 10;
  return true;
};
