import React from 'react';
import { htmlDecode } from 'js-htmlencode';
import { FormattedMessage } from 'react-intl';
import { FeedItem } from './FeedItem';
import { ActionProps, getActivityTypeInfo } from './ActivityFeed';

export const CommentAction = (props: ActionProps) => {
  const { activity } = props;
  const { objectType, text, commentUUID } = activity;

  if (!text) {
    return null;
  }
  const info = getActivityTypeInfo(activity)[objectType];

  return (
    <FeedItem
      didWhat={
        <FormattedMessage
          id="activityFeed.actions.commented.action"
          defaultMessage="commented on {label}"
          values={{ label: info.label }}
        />
      }
      onWhat={{
        name: info.name,
        url: `${info.path}/#comment_${commentUUID?.substring(0, 6)}`,
      }}
      secondLine={htmlDecode(text)}
    />
  );
};
