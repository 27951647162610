import isEqual from 'lodash/isEqual';
import { call, fork, select, put, takeEvery } from 'redux-saga/effects';

import { load as loadGalleryPermissions } from './galleryPermissions';
import urls, { constructUrl } from 'src/shared/urls';
import { getRequestFunc } from 'src/client/helpers';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';
import { GalleryResponseModel, GlobalContent } from 'src/@types/app';
import { RootState } from './reducer';
import { GalleryCategories, Media, Photographer, getMediaResponse } from '@tovia/man-protos/dist/types/content.types';
import { Gallery as ContentGallery } from '@tovia/man-protos/dist/types/content.types';
import { ResponseError } from 'superagent';

const ActionType = {
  RESET: 'man-site/gallery/RESET',
  LOAD: 'man-site/gallery/LOAD',
  LOAD_SAGA: 'man-site/gallery/LOAD_SAGA',
  LOAD_SUCCESS: 'man-site/gallery/LOAD_SUCCESS',
  LOAD_FAIL: 'man-site/gallery/LOAD_FAIL',
  LOAD_DECORATORS_SUCCESS: 'man-site/gallery/LOAD_DECORATORS_SUCCESS',
  LOAD_COVER: 'man-site/gallery/LOAD_COVER',
  LOAD_COVER_SAGA: 'man-site/gallery/LOAD_COVER_SAGA',
  LOAD_COVER_SUCCESS: 'man-site/gallery/LOAD_COVER_SUCCESS',
  LOAD_COVER_FAIL: 'man-site/gallery/LOAD_COVER_FAIL',
} as const;

const endpoint = constructUrl(urls.get.gallery);
const coverEndpoint = constructUrl(urls.get.cover);

type ActionType = typeof ActionType[keyof typeof ActionType];

type CallParams = {
  date: string;
  name: string;
  thumbnailPage: number;
  thumbnailsPerPage: number;
};
export type ModelGalery = GalleryResponseModel & {
  comments?: { total: 0; comments: [] };
  publishAge?: number;
  globalUUID: string;
};

type GalleryPhotographer = Photographer & {
  comments: { total: 0; comments: [] };
  ratingAverage: number;
};

export type GalleryPhotoMedia = Media & {
  imgPath: {
    low: string;
    medium: string;
    high: string;
  };
};
export type Gallery = Omit<
  ContentGallery,
  | 'categories'
  | 'comments'
  | 'isIntimateSelection'
  | 'isPromoGallery'
  | 'photographers'
  | 'models'
  | 'photos'
  | 'ratingAverage'
  | 'type'
> & {
  categories: string[] | GalleryCategories[];
  comments: { total: 0; comments: [] };
  isIntimateSelection: boolean;
  isPromoGallery?: boolean;
  models: ModelGalery[] | GalleryResponseModel[];
  photographers: GalleryPhotographer[];
  photos?: getMediaResponse & { megapixels?: number; total: number; media: GalleryPhotoMedia }[];
  ratingAverage: number;
  type: string;
};

type State = {
  activeDetailsTabIndex: number;
  cover?: CoversResponse;
  coverCallParams: CallParams;
  coverError?: ResponseError;
  decoratorAdded?: { [key: string]: boolean };
  error?: ResponseError;
  globalContent?: GlobalContent[];
  item: Gallery & { hasPermissions?: boolean };
  lastCallParams: CallParams;
  loaded: boolean;
  loading: boolean;
  gallery: Gallery;
};

const initialState = {
  activeDetailsTabIndex: 0,
  cover: undefined,
  coverCallParams: {},
  coverError: undefined,
  error: undefined,
  globalContent: undefined,
  item: {},
  lastCallParams: {},
  loaded: false,
  loading: false,
} as State;

type Result = Gallery & {
  globalContent: GlobalContent;
};

type Cover = {
  ID: number;
};

type CoversResponse = {
  name: string;
  coverImagePath: string;
  UUID: string;
  covers: Cover[];
  total: number;
};

type Load = {
  lastCallParams: CallParams;
  params: CallParams;
  type: typeof ActionType.LOAD;
};

type LoadSaga = {
  params: CallParams;
  type: typeof ActionType.LOAD_SAGA;
};

type LoadSuccess = State & {
  result: Result;
  type: typeof ActionType.LOAD_SUCCESS;
};

type LoadFail = {
  error: ResponseError;
  type: typeof ActionType.LOAD_FAIL;
};

type Reset = {
  type: typeof ActionType.RESET;
};

type LoadDecoratorSuccess = State & {
  decorators: { [key: string]: boolean };
  type: typeof ActionType.LOAD_DECORATORS_SUCCESS;
};

type LoadCover = {
  type: typeof ActionType.LOAD_COVER;
};

type LoadCoverSaga = {
  galleryUUID: string;
  type: typeof ActionType.LOAD_COVER_SAGA;
};

type LoadCoverSuccess = {
  result: CoversResponse;
  type: typeof ActionType.LOAD_COVER_SUCCESS;
};

type LoadCoverFail = State & {
  type: typeof ActionType.LOAD_COVER_FAIL;
};

type Action =
  | Load
  | LoadSuccess
  | LoadFail
  | Reset
  | LoadDecoratorSuccess
  | LoadCover
  | LoadCoverSuccess
  | LoadCoverFail;

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case ActionType.LOAD: {
      const { lastCallParams } = action;
      return {
        ...state,
        loading: true,
        loaded: false,
        lastCallParams,
      };
    }
    case ActionType.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.result,
        globalContent: Array.isArray(action.result.globalContent)
          ? action.result.globalContent
          : Array(action.result.globalContent),
      };
    case ActionType.LOAD_FAIL:
      return {
        ...initialState,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case ActionType.LOAD_DECORATORS_SUCCESS: {
      const { decorators } = action;
      return {
        ...state,
        decoratorAdded: decorators,
      };
    }
    case ActionType.LOAD_COVER:
      return {
        ...state,
        cover: undefined,
        loading: true,
        error: undefined,
      };
    case ActionType.LOAD_COVER_SUCCESS:
      return {
        ...state,
        loading: false,
        cover: action.result,
      };
    case ActionType.LOAD_COVER_FAIL:
      return {
        ...state,
        loading: false,
        coverError: action.error,
      };
    case ActionType.RESET:
      return {
        ...initialState,
      };
    default: {
      return state;
    }
  }
}

export function reset() {
  return {
    type: ActionType.RESET,
  };
}

export function isLoaded(lastCallParams: CallParams | string, params: CallParams | string): boolean {
  return isEqual(lastCallParams, params);
}

export function load(params): LoadSaga {
  return {
    type: ActionType.LOAD_SAGA,
    params,
  };
}

export function loadCover(galleryUUID: string): LoadCoverSaga {
  return {
    galleryUUID,
    type: ActionType.LOAD_COVER_SAGA,
  };
}

/* SAGAS */
const getState = (state: RootState) => state.gallery;

function* dispatchLoadGalleryPermissions(params) {
  const newState: RootState['gallery'] = yield select(getState);
  const { item } = newState;
  if (item) {
    yield put(
      loadGalleryPermissions({
        gallery: item.UUID,
        token: params.token,
      }),
    );
  }
}

function* loadGenerator({ params }: { type: string; params: CallParams }) {
  const currentState: RootState['gallery'] = yield select(getState);
  const { lastCallParams } = currentState;

  if ((!isLoaded(lastCallParams, params) && !currentState.loading) || (!currentState.loaded && !currentState.loading)) {
    const { thumbnailPage, thumbnailsPerPage, ...rest } = params;

    const finalParams = {
      ...rest,
      page: thumbnailPage || 1,
      mediaFirst: thumbnailsPerPage,
    };

    const loadFunc = getRequestFunc(
      [ActionType.LOAD, ActionType.LOAD_SUCCESS, ActionType.LOAD_FAIL],
      (client) =>
        client.get(endpoint, {
          params: finalParams,
        }),
      {
        lastCallParams: { ...params },
      },
    );
    yield call(loadFunc);
  }

  yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

  yield call(dispatchLoadGalleryPermissions, params);
  type CombinedType = {
    gallery: RootState['gallery'];
    auth: RootState['auth'];
  };
  const newState: CombinedType = yield select(({ gallery, auth }: RootState) => ({ gallery, auth }));
  if (newState.auth.user && Object.keys(newState.gallery.item).length > 0) {
    const objectUUIDs = [
      newState.gallery.item.UUID,
      ...newState.gallery.item.models.map((model) => model.UUID),
      ...newState.gallery.item.photographers.map((photographer) => photographer.UUID),
    ];
    yield put(
      loadRatingInfo({
        objectUUIDs,
      }),
    );
    yield put(
      loadFavoriteInfo({
        objectUUIDs,
      }),
    );
  }
}

function* loadCoverGenerator({ galleryUUID }: { type: string; galleryUUID: string }) {
  const newState: RootState['gallery'] = yield select(getState);
  const { cover, loading } = newState;
  if ((!cover && !loading) || (cover && !isLoaded(cover.UUID, galleryUUID))) {
    const loadFunc = getRequestFunc(
      [ActionType.LOAD_COVER, ActionType.LOAD_COVER_SUCCESS, ActionType.LOAD_COVER_FAIL],
      (client) =>
        client.get(coverEndpoint, {
          params: { galleryUUID },
        }),
    );

    yield call(loadFunc);
  }
}

// Trigger
function* watchLoad() {
  yield takeEvery(ActionType.LOAD_SAGA, loadGenerator);
}

function* watchLoadCover() {
  yield takeEvery(ActionType.LOAD_COVER_SAGA, loadCoverGenerator);
}

export const watchers = [fork(watchLoad), fork(watchLoadCover)];
/* EOF SAGAS */
