import { useSelector } from '../redux';

export const usePages = (path: string) => {
  const {
    config: { pages },
  } = useSelector((state) => state.app);

  const hasSlash = path[0] === '/';
  let finalPath = hasSlash ? path.substring(1) : path;

  if (Array.isArray(pages) && pages.includes(finalPath)) {
    finalPath = `${hasSlash ? '/' : ''}pages/${finalPath}`;
  } else {
    finalPath = path;
  }

  return finalPath;
};
