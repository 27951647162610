import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';
import { useIntl } from 'react-intl';

export const TextBlockSectionClass = (props) => {
  const intl = useIntl();
  const { className } = props;

  return (
    <section className={`${className} text-block`}>
      <div className="container">
        <div className="text-block-title">
          {intl.formatMessage(
            {
              id: 'alsscan.txtBlockSection.title',
              defaultMessage: 'ALS Scan is the ultimate in modern adult entertainment',
            },
            { siteName: 'ALS Scan' },
          )}
        </div>
        <div className="text-block-box">
          <p>
            {intl.formatMessage(
              {
                id: 'alsscan.txtBlockSection.p1',
                defaultMessage: `The most beautiful nude amateur models in the world: at ALS Scan they are our inspiration, our obsession,
            our reason for being. Fresh, natural, and completely uninhibited, the girls of ALS Scan are uniquely
            beautiful as individuals, but they all share one quality: they are real. You will find no pornstars at ALS
            Scan. We are not interested in strippers, celebrities, and surgically enhanced professional X-rated
            entertainers. You won&apos;t find unattainable superstars or unapproachable glamour goddesses at ALS Scan.
            What you will find are real, flesh-and-blood females. In all their natural glory. Exposed, revealed, and
            sharing themselves with the world - and you - for the first time.`,
              },
              { siteName: 'ALS Scan' },
            )}
          </p>
          <p>
            {intl.formatMessage(
              {
                id: 'alsscan.txtBlockSection.p2',
                defaultMessage: `The girls of ALS Scan are girls from the real world, your world. They are not airbrushed creations from a
              glossy Hollywood fantasy. They are the kind of girls you see every day... and always wanted to see more of.
              They might live next door or down the street. They might be students or secretaries. They could be shop
              girls or cashiers. You might see her hanging out with friends. Riding a bike, waiting for a bus, or shopping
              at the mall. These are amateur models, from cities and towns all around the world. And every one of these
              natural, amateur beauties has been hand picked to appear at ALS Scan. If you&apos;re tired of the false and
              the fake, and want to see genuine amateur females you&apos;re ready for ALS Scan.`,
              },
              { siteName: 'ALS Scan' },
            )}
          </p>
        </div>
      </div>
    </section>
  );
};

TextBlockSectionClass.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
};

// Same approach for defaultProps too
TextBlockSectionClass.defaultProps = {
  className: '',
};

export default withTheme(styled(TextBlockSectionClass)`
  &.text-block {
    background-color: ${(props) => props.theme.splashOptional1 || props.theme.splashPrimary2};

    .text-block-title {
      display: inline-block;
      font-size: 1.72rem;
      padding: 12px;
      text-transform: uppercase;
      text-align: center;

      @media (min-width: 768px) {
        text-align: unset;
      }
    }

    .text-block-box {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      & p {
        flex-grow: 1;
        margin: 12px;

        @media (min-width: 768px) {
          margin: 0 12px;
        }
      }
    }
  }
`);
