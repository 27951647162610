import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import cx from 'classnames';
import {
  DESKTOP,
  DESKTOP_WIDE,
  TABLET_LANDSCAPE,
  TABLET_PORTRAIT,
  XXXXX_LARGE,
  XXXX_LARGE,
  XX_LARGE,
  X_LARGE,
} from 'src/client/constants/mediaQueries';
import { useWidePageContext } from '../../App/WidePageContextProvider';

export const DetailedCardsWrapper = styled.section`
  display: grid;
  column-gap: 10px;
  row-gap: 20px;
  margin: 20px 0;
  width: 100%;
  position: relative;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media ${TABLET_PORTRAIT} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media ${DESKTOP} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &.wide-page {
    @media ${X_LARGE} {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media ${XX_LARGE} {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @media ${XXXX_LARGE} {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .model-page & {
      @media ${TABLET_PORTRAIT} {
        grid-template-columns: repeat(2, 1fr);
      }

      @media ${TABLET_LANDSCAPE} {
        grid-template-columns: repeat(3, 1fr);
      }

      @media ${DESKTOP} {
        grid-template-columns: repeat(2, 1fr);
      }

      @media ${DESKTOP_WIDE} {
        grid-template-columns: repeat(3, 1fr);
      }

      @media ${XX_LARGE} {
        grid-template-columns: repeat(4, 1fr);
      }

      @media ${XXXX_LARGE} {
        grid-template-columns: repeat(5, 1fr);
      }

      @media ${XXXXX_LARGE} {
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }

  .cams-wrapper {
    display: none;
    height: 100%;
    grid-row-start: 1;

    @media ${TABLET_PORTRAIT} {
      display: block;
      grid-column: -2 / span 1;
      grid-row-end: 6;
    }
  }

  .detailed-card-wrapper {
    transition: 0.5s background-color;
    position: relative;
    background: none;
    font-weight: 400;

    .card {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      padding: 10px;
      gap: 10px;
      height: 100%;

      &.highlighted {
        background-color: ${(props) => props.theme.primary3};
      }
    }
  }

  .photo {
    aspect-ratio: 120 / 180;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90px;
    position: relative;
    flex-shrink: 0;

    @media ${TABLET_PORTRAIT} {
      width: 110px;
    }

    @media ${TABLET_LANDSCAPE} {
      width: 120px;
    }

    a {
      width: 100%;
      height: 100%;
      display: block;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .photo-stats {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    flex: 1 1 auto;

    .detailed-item-icon {
      font-size: 1rem;
      margin-right: 4px;
      height: 14px;
    }

    h3 {
      margin: 0;
      font-size: 1.25rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.4;

      @media ${TABLET_LANDSCAPE} {
        font-size: 1.6rem;
      }
    }

    .attrs {
      font-size: 0.9rem;

      .attr-label {
        color: ${(props) => props.theme.primary7};
      }
    }
  }

  .badges {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    > * {
      margin: 0;
    }
  }
`;

type Props = {
  className?: string;
  children?: ReactNode;
};

export const DetailedCards = (props: Props) => {
  const widePage = useWidePageContext();

  return (
    <DetailedCardsWrapper className={cx(props.className, 'detailed-cards', { 'wide-page': widePage })}>
      {props.children}
    </DetailedCardsWrapper>
  );
};
