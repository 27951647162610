import { Dispatch, SetStateAction } from 'react';
import { useLocalStorage } from 'react-use';
import { SupportedLanguage, supportedLanguages } from 'src/shared/constants/languages';

//      const localeFromSettings = ensure(s.settings.values.find((set) => set.key === 'language')).value as string;

export const isSupportedLanguage = (language: string): language is SupportedLanguage => {
  return supportedLanguages.includes(language as SupportedLanguage);
};

const getDefaultLanguage = () => {
  // remove region code from language
  const [browserLanguage] = navigator.language.split(/[-_]/);
  const defaultLanguage = isSupportedLanguage(browserLanguage) ? browserLanguage : 'en';

  return defaultLanguage;
};

export const useSelectedLanguage = () => {
  const defaultLanguage = getDefaultLanguage();

  // The useLocalStorage hook can also return undefined (from its type definition),
  // so we're giving typescript a default here to avoid having to check for undefined everywhere
  const [selectedLanguage = defaultLanguage, setLanguage] = useLocalStorage<SupportedLanguage>(
    'selected-language',
    defaultLanguage,
  );

  // The dispatch is re-defined here to disallow setting undefined.
  return [selectedLanguage, setLanguage as Dispatch<SetStateAction<SupportedLanguage>>] as const;
};
