import { Direction, GalleryOrderBy, ModelOrderBy } from '@tovia/man-protos/dist/types/content.types';
import {
  faEye,
  faFilm,
  faHeart,
  faSortAmountDown,
  faSortAmountUp,
  faStar,
  faThumbsUp,
  IconDefinition,
  faFont,
  faFireAlt,
} from '@fortawesome/pro-light-svg-icons';

export type SortType = {
  id: string;
  title: string;
  order: keyof typeof GalleryOrderBy | keyof typeof ModelOrderBy | 'TIMESTAMP' | '4K';
  direction: keyof typeof Direction;
  intlID: string;
  requiresAuth?: boolean;
  filter?: string;
};

export const sortIcons: Record<SortType['id'], IconDefinition> = {
  all: faFont,
  favorited: faHeart,
  latest: faSortAmountDown,
  'most-appearances': faFireAlt,
  'my-top': faThumbsUp,
  new: faSortAmountUp,
  newest: faSortAmountUp,
  oldest: faSortAmountDown,
  resolution: faFilm,
  thumbsUp: faThumbsUp,
  top: faStar,
  viewed: faEye,
};
