import { take, call, fork } from 'redux-saga/effects';
import { ToastsStore } from 'react-toasts';
import PropTypes from 'prop-types';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import {
  STATUS_NOT_SENT,
  STATUS_SENT,
  STATUS_OPTED_IN,
  ERROR_EMAIL_ALREADY_EXIST,
} from 'src/shared/constants/newsletter';

const SUBSCRIBE = 'man-site/newsletter/SUBSCRIBE';
const SUBSCRIBE_SAGA = 'man-site/newsletter/SUBSCRIBE_SAGA';
const SUBSCRIBE_SUCCESS = 'man-site/newsletter/SUBSCRIBE_SUCCESS';
const SUBSCRIBE_FAIL = 'man-site/newsletter/SUBSCRIBE_FAIL';
const SET_SETTINGS = 'man-site/newsletter/SET_SETTINGS';

const endpoint = constructUrl(urls.post.signup.newsletter);

export const newsletterStatusPropTypes = PropTypes.oneOf([STATUS_NOT_SENT, STATUS_SENT, STATUS_OPTED_IN]);

export const initialState = {
  loading: false,
  error: null,
  canSubmit: true,
  status: STATUS_NOT_SENT,
  submitting: false,
  submitted: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SETTINGS: {
      return {
        ...state,
        ...action.settings,
      };
    }
    case SUBSCRIBE: {
      return {
        ...initialState,
        loading: true,
        submitting: true,
      };
    }
    case SUBSCRIBE_SUCCESS: {
      const {
        result: { error },
      } = action;
      ToastsStore.info('Subscribed to newsletter');
      return {
        ...state,
        loading: false,
        status: error === ERROR_EMAIL_ALREADY_EXIST ? STATUS_OPTED_IN : STATUS_SENT,
        submitting: false,
        submitted: true,
        error,
      };
    }
    case SUBSCRIBE_FAIL: {
      ToastsStore.info('Error subscribing to newsletter');
      return {
        ...state,
        loading: false,
        error: action.error,
        submitting: false,
      };
    }
    default: {
      return state;
    }
  }
}

export function setSettings(settings) {
  return {
    type: SET_SETTINGS,
    settings,
  };
}

export function subscribe(data) {
  return {
    type: SUBSCRIBE_SAGA,
    data,
  };
}

/* SAGAS */
function* subscribeGenerator(data) {
  const loadFunc = getRequestFunc([SUBSCRIBE, SUBSCRIBE_SUCCESS, SUBSCRIBE_FAIL], (client) =>
    client.post(endpoint, { data }),
  );
  yield call(loadFunc);
}

// Trigger
function* watchSubscribe() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { data } = yield take(SUBSCRIBE_SAGA);
    yield fork(subscribeGenerator, data);
  }
}

export const watchers = [fork(watchSubscribe)];
/* EOF SAGAS */
