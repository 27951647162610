export const DeviceTypes = {
  Desktop: 1,
  Mobile: 2,
  Tablet: 3,
  Unknown: 99,
} as const;

export const MeasurementTypes = {
  View: 1,
  Conversion: 2,
} as const;

export const ConversionTypes = {
  None: 0,
  PageView: 1,
  Click: 2,
  NewSubscription: 3,
} as const;

export const CategoryIds = {
  PageViewsAndNewSubscriptions: 1,
  MembersPortal: 2,
  MembersPortalFloaty: 3,
  FreeGallery: 4,
} as const;

export type MeasurementType = typeof MeasurementTypes[keyof typeof MeasurementTypes];
export type DeviceType = typeof DeviceTypes[keyof typeof DeviceTypes];
export type ConversionType = typeof ConversionTypes[keyof typeof ConversionTypes];
export type CategoryId = typeof CategoryIds[keyof typeof CategoryIds];

export type MeasurementMessage = {
  site_uuid: string;
  unique_uuid?: string;
  created_at: string;
  type: number;
  data: string;
};

export type AnalyticsDataPayload = {
  deviceType: DeviceType;
  conversionType?: ConversionType;
  categoryId: number;
};

export type UseAnalyticsProps = {
  type: MeasurementType;
  categoryId: CategoryId;
  conversionType?: ConversionType;
  deviceType: DeviceType;
};
