import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';

export const TaglineSectionComponent = (props) => {
  const { show, tagline, className } = props;

  if (!show || tagline === '') return null;

  return (
    <section className={`${className} tagline-wrapper`}>
      <div className="tagline text-center">{tagline}</div>
    </section>
  );
};

TaglineSectionComponent.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  tagline: PropTypes.string,
};

// Same approach for defaultProps too
TaglineSectionComponent.defaultProps = {
  className: '',
  show: true,
  tagline: '',
};

export default withTheme(styled(TaglineSectionComponent)`
  &.tagline-wrapper {
    margin: 0 auto;
    padding: 0;

    .tagline {
      font-size: 1.6rem;
      font-weight: 100;
      line-height: 48px;
      letter-spacing: 10px;
      text-transform: uppercase;

      @media (max-width: 767px) {
        line-height: 26px;
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 0.7rem;
      }
    }
  }
`);
