import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';
import { useHistory, useLocation } from 'react-router';
import bgHDImg from './images/bg@2x.jpg';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import urls from 'src/shared/urls';
import { JoinButton } from 'src/client/components/buttons/JoinButton';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  padding: 0;
  margin: 0;
  z-index: 2;

  @media (min-width: 569px) {
    color: #fff;
  }

  .wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 569px) {
      height: 220px;
      justify-content: center;
    }
  }

  .error-code-text {
    position: relative;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 500;
    margin: 2.5px 0;

    @media (max-width: 569px) {
      font-size: 0.8rem;
    }
  }

  .sorry-text {
    position: relative;
    display: block;
    text-align: center;
    text-transform: uppercase;
    line-height: 4.28rem;
    font-weight: 700;
    font-size: 4.28rem;
    margin: 2.5px 0;

    @media (max-width: 569px) {
      font-size: 2rem;
      line-height: 2rem;
    }
  }

  .not-found-text {
    position: relative;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 2.5px 0;

    @media (max-width: 569px) {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 569px) {
    top: 0;
    justify-content: flex-start;
    max-height: 568px;
  }

  .not-found-button {
    position: relative;
    display: flex;
    min-width: 160px;
    height: 60px;
    z-index: 2;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 3px;
    white-space: nowrap;
    width: 240px;
    font-size: 1.25rem;
    text-transform: capitalize;

    @media (max-width: 569px) {
      font-size: 1.14rem;
      width: 280px;
      height: 40px;
    }
  }

  .back-button {
    background: #9d9d9d;
    color: white;
    margin: 5px 0 20px;
    font-weight: 500;
    font-size: 1.42rem;
    opacity: 0.9;
    transition: 0.3s opacity ease;

    :hover {
      opacity: 1;
    }

    @media (max-width: 569px) {
      font-size: 1.14rem;
    }
  }

  .join-btn {
    background: ${(props) => props.theme.primaryBtn1Bg};
  }
`;

const NotFoundImage = styled.div`
  background-image: url(${bgHDImg});
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;

  @media (max-width: 569px) {
    position: relative;
    max-height: 348px;
    top: 0;
  }
`;

export default function NotFoundComponent(props: {
  // eslint-disable-next-line react/require-default-props
  className?: string;
}) {
  const { className } = props;
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const site = useSelector((state) => state.site);
  const config = useSelector((state) => state.app.config);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;

    if (location.pathname !== urls.get.notFound) {
      history.replace(urls.get.notFound);
    }
  }, [location, history]);

  return (
    <Div className={`container ${className}`}>
      <Helmet>
        <title>{`${site.name} - Page Not Found`}</title>
        <meta name="robots" content="noindex" />
        <meta name="render:status_code" content="404" />
      </Helmet>
      <NotFoundImage />
      <div className="wrap">
        <span className="error-code-text">
          {intl.formatMessage({ id: 'notFound.errorCode', defaultMessage: 'Error Code: 404' })}
        </span>
        <span className="sorry-text">{intl.formatMessage({ id: 'notFound.sorry', defaultMessage: 'Sorry!' })}</span>
        <span className="not-found-text">
          {intl.formatMessage({ id: 'notFound.pageNotFound', defaultMessage: 'Page Not Found' })}
        </span>
        <NavLink to="/" className="back-button not-found-button">
          {intl.formatMessage({ id: 'notFound.button', defaultMessage: 'Take me home' })}
        </NavLink>
        {!user && !config.isWhiteLabel && (
          <JoinButton className="btn btn-primary not-found-button join-btn" data-value="regular" campaign="404-page">
            {intl.formatMessage({ id: 'notFound.signUp', defaultMessage: 'Sign up today' })}
          </JoinButton>
        )}
      </div>
    </Div>
  );
}
