import React, { useMemo, useState } from 'react';

import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Tabs from 'src/client/components/splash/MediaSection/Tabs';
import { runtimeInMinutes, secondsToRuntime } from 'src/client/utils/converters';
import photographyImg from '../images/photography.jpg';
import modelsImg from '../images/models.jpg';
import filmsImg from '../images/films.jpg';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { SplashMediaGrid, SplashModelGrid } from 'src/client/components/grids';
import { SplashGalleryCard } from 'src/client/components/cards/SplashGalleryCard';
import { MediaSectionGalleryCard } from './MediaSectionGalleryCards';
import { SplashModelCard } from 'src/client/components/cards';
import { useIsMobile } from 'src/client/utils/useIsMobile';
import { MetArt } from 'src/client/constants/sites';
import { useGetGalleryCoverUrl } from 'src/client/helpers/urls/useGetGalleryCoverUrl';

type Props = {
  show: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  movies: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  models: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  galleries: any[];
};

export const MediaSection = (props: Props) => {
  const { movies, models, galleries } = props;
  const [activeTab, setActiveTab] = useState<'models' | 'photography' | 'films'>('photography');
  const contentCdnUrl = useSelector((state) => state.app.contentCdnUrl);
  const site = useSelector((state) => state.site);
  const getGalleryCover = useGetGalleryCoverUrl();

  const intl = useIntl();
  const tabsData = [
    {
      title: intl.formatMessage({ id: 'splash.mediaSection.tabs.photography', defaultMessage: 'photography' }),
      value: 'photography',
      image: photographyImg,
    },
    {
      title: intl.formatMessage({ id: 'splash.mediaSection.tabs.films', defaultMessage: 'films' }),
      value: 'films',
      image: filmsImg,
    },
    {
      title: intl.formatMessage({ id: 'splash.mediaSection.tabs.models', defaultMessage: 'models' }),
      value: 'models',
      image: modelsImg,
    },
  ];

  const toggleTab = (tab) => setActiveTab(tab);

  const isMobile = useIsMobile();
  const modelCards = useMemo(() => {
    if (isMobile && site.abbreviation === MetArt.abbreviation) {
      return models.map((modelProps) => {
        return <MediaSectionGalleryCard {...modelProps} key={modelProps.UUID} />;
      });
    }
    return models.map((modelProps) => <SplashModelCard {...modelProps} key={modelProps.UUID} />);
  }, [models, site.abbreviation, isMobile]);

  const movieCards = useMemo(() => {
    return movies.map((movie) => (
      <SplashGalleryCard
        gallery={movie}
        thumbnailUrl={getGalleryCover(contentCdnUrl, { ...movie }).url}
        key={movie.UUID}
        details={runtimeInMinutes(secondsToRuntime(movie.runtime))}
      />
    ));
  }, [movies, getGalleryCover, contentCdnUrl]);

  const photographyCards = useMemo(() => {
    return galleries.map((gallery) => (
      <SplashGalleryCard
        gallery={gallery}
        thumbnailUrl={getGalleryCover(contentCdnUrl, { ...gallery }, { clean: true }).url}
        key={gallery.UUID}
        details={gallery.imageCount}
      />
    ));
  }, [galleries, getGalleryCover, contentCdnUrl]);

  if (!props.show) return null;

  return (
    <MediaSectionWrapper className="media-section-wrap">
      <Tabs activeTab={activeTab} tabs={tabsData} toggleTab={toggleTab} />
      <SplashMediaGrid show={activeTab === 'photography'} content={photographyCards} />
      <SplashModelGrid show={activeTab === 'models'} content={modelCards} />
      <SplashMediaGrid show={activeTab === 'films'} content={movieCards} />
    </MediaSectionWrapper>
  );
};

const MediaSectionWrapper = styled.div`
  left: 0;
  max-width: 2130px;
  margin: 0 auto;
`;
